.CardHomeTravel {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px;
  margin: 25px 25px 0;
}
.CardHomeTravel .upperPart {
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
  height: 90px;
}
.CardHomeTravel .imgBox {
  width: 90px;
  height: 90px;
  margin-right: 15px;
  float: left;
}
.CardHomeTravel .imgBox img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.CardHomeTravel .title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
  padding-top: 8px;
  margin-bottom: 5px;
}
.CardHomeTravel .supplement {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}

.CardHomeTravel .scoreBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CardHomeTravel .reviews {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #7d8fa1;
  margin-left: 10px;
}
.CardHomeTravel .describe {
  margin-top: 20px;

  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;

  background: radial-gradient(
        49.73% 79.17% at 72.17% 12.5%,
        rgba(98, 206, 245, 0.75) 0%,
        rgba(102, 136, 255, 0.75) 50%,
        rgba(130, 53, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #7d8fa1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.CardHomeTravel .scoreControl {
  font-size: 11px;
  min-width: 60px;
}
.CardHomeTravel .ant-rate-star {
  margin-right: 1px;
}
.CardHomeTravel .ant-rate-star-zero .anticon svg {
  color: #dadfe7;
}
.CardHomeTravel .ant-rate-star-full .anticon svg {
  color: #959fb1;
}
