.addToContent {
  min-height: 20px;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 9;
  font-family: "Ak-Regular";
  .addContentBtn {
    height: 20px;
    opacity: 0;
    border: 1px dashed #0a9dff;
    backdrop-filter: blur(200px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.2s linear;
    position: relative;
    & > div {
      margin-left: 5px;
    }
    .cancelBtn {
      position: absolute;
      right: 10px;
      top: 12.5px;
      height: 20px;
      width: 20px;
      margin: 0px;
      cursor: pointer;
      svg path {
        stroke: var(--addContentColor, "#12192B");
      }
    }
    .buttonText {
      color: var(--addContentColor, "#12192B");
      span {
        display: flex;
        align-items: center;
        line-height: 12px;
        svg {
          width: 14px;
          height: 14px;
          margin: 0 2px;
        }
      }
    }
  }
  .showBtn {
    height: 45px;
    opacity: 1;
  }
  .done {
    height: 28px;
    width: 73px;
    position: absolute;
    background: #fff;
    bottom: -28px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-radius: 0px 0px 10px 10px;
    background: #f7f7f8;
    box-shadow: 0px 4px 6px 0px rgba(58, 57, 76, 0.06), 0px 6px 20px -2px rgba(58, 57, 76, 0.14);
  }
  .buttonText {
    span {
      display: flex;
      align-items: center;
      line-height: 12px;
      svg {
        width: 14px;
        height: 14px;
        margin: 0 2px;
      }
    }
  }
  .darkButtonText {
    color: #fff;
  }
}

.sendToAppIcon {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    left: -20px;
    filter: drop-shadow(rgba(10, 157, 255, 1) 20px 0);
  }
}
