.Chain-Thought-Icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}
.Chain-Thought-Icon span {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #e7edf4;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-family: "Ak-Regular";
  font-size: 13px;
  color: #22232f;
  margin-bottom: 10px;
}
