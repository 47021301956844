.modalWrapper {
  :global(.ant-modal-content) {
    border-radius: 20px;
  }
  :global(.ant-modal-body) {
    padding: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 400;
  font-family: Ak-Regular;
  font-size: 15px;
  color: #12192b;
  line-height: 20px;

  padding: 20px 0 0;
  width: 100%;
  position: relative;
}

.close {
  margin-bottom: 5px;
  margin-left: auto;
  width: 20px;
  height: 20px;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #12192b;
}
.discription {
  color: #12192ba6;
  font-size: 15px;
}

.QRCode {
  width: 205px;
  height: 205px;
  margin: 5px 0 -10px;
  position: relative;
}
.loadingBox {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.QRCode img {
  width: 100%;
  height: 100%;
}

.mobileDeviceTitle {
  color: #12192ba6;
  margin-bottom: 5px;
  position: relative;
  font-size: 15px;
}

.downloadBar {
  width: 201px;
  height: 40px;
  justify-content: center;
  background-color: white;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  border-radius: 20px;
  color: #676e7f;
  cursor: pointer;
  border: 1px solid #bde3ff;
  user-select: none;

  &:hover {
    background-color: #bde3ff;
  }
}

.downloadBarMobile {
  padding: 10px;
  border: 1px solid rgba(2, 12, 38, 0.35);
}

.linkBar {
  margin: 20px 0;
  background-color: #020c260d;
  border-radius: 5px;
  display: flex;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  overflow: hidden;

  .link {
    color: #656a75;
    background: unset;
    cursor: text;
  }

  .viewSite {
    flex: 1;
    color: #676e7f;
    height: 100%;
    min-width: 83px;
    background-color: white;
    text-align: center;
    vertical-align: center;
    line-height: 30px;
    cursor: pointer;
    transition: 0.2s ease-out;
    border-radius: 5px;
    border: 1px solid #bde3ff;
    user-select: none;

    &:hover {
      background: linear-gradient(0deg, #bde3ff, #bde3ff), linear-gradient(0deg, #bde3ff, #bde3ff);
    }
  }
}
