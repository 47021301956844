.dialog-modal-box :global(.ant-modal) {
  width: 80vw !important;
  height: 40vw !important;
  padding-bottom: 0;
  margin-top: -20vw;
  top: 45%;
}
.dialog-modal-box :global(.ant-modal-content) {
  box-shadow: unset;
  background-color: unset;
}
.dialog-modal-box :global(.ant-modal-body) {
  padding: 0;
}
.dialog-modal-box :global(.ant-modal-footer) {
  display: none;
}
.dialog-modal-box :global(.ant-modal-close) {
  top: -28px;
  right: -28px;
}
.video {
  width: 100%;
}
