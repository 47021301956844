$inputColor: #12192b;
$placeholderColor: #a9a9a9;

.customNode {
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: "Ak-Regular";
  background: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 8px;
}

.minHeight {
  min-height: 260px;
}

.hasParentNode {
  position: relative;
}

.textContent {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  height: calc(100% - 16px);
  font-size: 13px;
  line-height: 20px;
  white-space: pre-wrap;
}

.paddingContent {
  padding-top: 16px;
  padding-bottom: 0px;
  position: absolute;
  bottom: 10px;
}

.textContentPadding {
  width: calc(100% - 30px);
  padding-right: 15px;
  margin-right: 10px;
}

.previewing {
  height: calc(100% - 70px);
}

.text-textArea {
  // 将底部空间剩余出来，可进入拖拽，100% 会将剩余内容充满导致无法拖动
  // height: auto;
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.text-textArea::-webkit-scrollbar {
  display: none;
}
.max-textarea-height {
  max-height: 300px;
}
.show-text-textArea::-webkit-scrollbar,
.text-content::-webkit-scrollbar,
.custom-api::-webkit-scrollbar,
.customNode::-webkit-scrollbar,
.customNode textarea::-webkit-scrollbar,
.input-mask::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.customNode textarea::placeholder {
  color: $placeholderColor;
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.show-text-textArea::-webkit-scrollbar-thumb,
.text-content::-webkit-scrollbar-thumb,
.custom-api::-webkit-scrollbar-thumb,
.customNode::-webkit-scrollbar-thumb,
.customNode textarea::-webkit-scrollbar-thumb,
.input-mask::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}
.show-text-textArea::-webkit-scrollbar-track,
.text-content::-webkit-scrollbar-track,
.custom-api::-webkit-scrollbar-track,
.customNode::-webkit-scrollbar-track,
.customNode textarea::-webkit-scrollbar-track,
.input-mask::-webkit-scrollbar-track {
  -webkit-box-shadow: none; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
.guideTextnodeDisable.customNode {
}
.guideTextnodeDisable .text-box {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}
.guideTextnodeDisable .text-content {
  width: 100%;
  margin: 0;
  position: relative;
  bottom: 0;
}
.guideTextnodeDisable .text-textArea {
  max-height: unset;
}

textarea:not(:focus) {
  cursor: pointer !important;
}

.componentItem {
  margin-bottom: 10px;
}

.componentItemTitle {
  display: inline-block;
  height: 32px;
  border-radius: 20px;
  background-color: #fafafb;
  border: 0.5px solid rgba(2, 12, 38, 0.0509803922);
  padding: 0 10px;
  gap: 10px;
  font-family: "Ak-Regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.reasonPopover :global(.ant-popover-inner-content) {
  text-align: left;
}

.componentItemTitleImage {
  margin-bottom: 30px;
}

.component-item-text {
  margin: 10px auto 20px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 20px;

  font-weight: 400;
  font-family: "Ak-Regular";
  white-space: pre-wrap;
}

.customNode-expand {
  background: #e6f5ff;
}

.padding-0 {
  padding-left: 0px;
}

.padding-1 {
  padding-left: 5px;
}

.padding-2 {
  padding-left: 10px;
}

.padding-3 {
  padding-left: 15px;
}

.padding-4 {
  padding-left: 20px;
}

.padding-5 {
  padding-left: 25px;
}
