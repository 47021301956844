body {
  background: #fff;
  overscroll-behavior: contain;
}
body::-webkit-scrollbar {
  display: none;
}

.home-plugin {
  position: fixed;
  font-family: "Ak-Regular";
  width: 100%;
  height: 100%;
  /* background: #fff; */
  /* background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Background.png"); */
  /* background-size: cover;
  background-position: center center;
  background-attachment: fixed; */
}
.background-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.canvas-overflow-hidden :global(.react-flow__pane) {
  clip-path: polygon(0 60px, 100% 60px, 100% 100%, 0 100%);
}
.background-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: linear-gradient(72.44deg, #757f9a 0%, #d7dde8 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65));
  /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.994477) 8.59%, rgba(255, 255, 255, 0.978216) 17.48%,
    rgba(255, 255, 255, 0.95168) 26.53%, rgba(255, 255, 255, 0.91533) 35.61%, rgba(255, 255, 255, 0.86963) 44.59%,
    rgba(255, 255, 255, 0.81504) 53.34%, rgba(255, 255, 255, 0.752024) 61.73%, rgba(255, 255, 255, 0.681043) 69.63%,
    rgba(255, 255, 255, 0.60256) 76.9%, rgba(255, 255, 255, 0.517037) 83.41%, rgba(255, 255, 255, 0.424936) 89.03%,
    rgba(255, 255, 255, 0.32672) 93.63%, rgba(255, 255, 255, 0.22285) 97.08%, rgba(255, 255, 255, 0.11379) 99.25%,
    rgba(255, 255, 255, 0) 100%),
    url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/background-img.png"), #FFFFFF; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.top-pane {
  margin: 0;
  width: 100%;
}
:global(.react-flow__panel) {
  margin: 0 !important;
  user-select: none;
}

.nav-page-pane {
  width: 100%;
  top: 80px;
  bottom: 0;
  background: #fff;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Background.png");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  margin: 0;
  z-index: 11;
  user-select: none;
  overflow-x: hidden;
  overflow-y: auto;

  /* scrollbar-width: auto; */
  -webkit-overflow-scrolling: touch;
}
/* .nav-page-pane::-webkit-scrollbar {
  display: block;
} */
/* .save-controls {
  position: fixed;
  right: 46px;
  top: 5px;
  z-index: 10;
} */
.add-think {
  position: fixed;
  top: 120px;
  width: 400px;
  right: 10%;
  left: 10%;
  margin: 0 auto;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.think-btn {
  margin-top: 16px;
}
.home-plugin :global(.react-flow__attribution) {
  display: none;
}
.shrink-addicon {
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: 15%;
  right: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 2px solid #1890ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1890ff;
  z-index: 10;
}
.add-input {
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-family: "Ak-Regular";
  font-weight: bold;
  padding: 13px 15px;
  box-shadow: 0px 0px 0px 1px #0000001f;
}

.right-menu {
  width: 300px;
  visibility: hidden;
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  /* border-radius: 10px; */
  border-radius: 15px;
  /* padding: 10px; */
  padding: 5px, 0px, 5px, 0px;
  font-family: "Ak-Regular";
  /* box-shadow: 0 30px 60px 0 #0000001f; */
  box-shadow: 0px 30px 39px 0px rgba(123, 133, 142, 0.08);
}

.right-menu .right-text {
  font-family: "Ak-Regular";
  font-size: 15px;
  cursor: pointer;
  /* padding: 2px 10px; */
  margin-bottom: 5px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  margin: 0;
  color: rgba(18, 25, 43, 0.65);
  border-radius: 10px;
}

.right-menu .right-text:hover {
  background-color: rgba(10, 157, 255, 0.1);
  border-radius: 10px;
}

.right-menu .right-text[aria-disabled="true"] {
  color: #ccc;
}
.right-menu .right-text[aria-disabled="true"]:hover {
  cursor: not-allowed;
  background-color: initial;
}

:global(.ant-popover-content),
:global(.ant-dropdown) {
  user-select: none;
}
div:global(.react-flow__panel).node-preview-pane {
  display: flex;
  flex-direction: column;
  width: 390px;
  top: 85px;
  bottom: 90px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px #eee;
  overflow: hidden;
  z-index: 11;
  padding: 15px 20px 20px;
}
.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 40px; */
  /* stud-1046 为了暂时放大拖动视口 */
  margin-bottom: 10px;
  user-select: none;
}
.preview-select-title {
  font-family: "SF-Regular";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3a394c;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  margin-right: 5px;
}
.preview-content {
  box-shadow: 0 0 5px #eee;
  /* flex: 1; */
  height: 100%;
  position: relative;
  user-select: none;
  /* overflow: hidden; */
}
.preview-header :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border: none;
}
.preview-header :global(.icon-fanhui) {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 25px;
  color: #6590e6;
  cursor: pointer;
  transform: rotateY(180deg);
}
/* .preview-header :global(.share-v2) {
  color: rgba(14, 20, 36, 1);
} */
/* .loading-home-box {
  position: relative;
  width: 100%;
  height: 100%;
} */

:global(.share-app) {
  background: #fff;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 10 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

:global(.share-app) .preview-content {
  height: 100%;
  background: linear-gradient(
    126deg,
    #2f40ad 27.90508270263672%,
    #394fb7 39.71376419067383%,
    #2a3ba9 50.911521911621094%,
    #394fb7 63.58194351196289%,
    #354ab5 91.60547256469727%
  );
}
.preview-content-v2 {
  height: 100%;
  background: rgba(14, 20, 36, 1);
  position: relative;
  user-select: none;
}
.operate-box {
  display: flex;
  align-items: center;
}
.operate-box :global(.icon-shanchu) {
  margin-right: 15px;
}

.dark :global(.icon-shanchu),
.dark :global(.icon-gengduo) {
  color: #fff;
}

.share-icon-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.share-icon-box .close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.preview-btns {
  display: flex;
  justify-content: space-between;
}
.preview-btns .publish-btn {
  flex: 0.6;
  background: #6aa2f8;
  border-radius: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-btns .saas-button-proportion {
  flex: 0.8;
}
.preview-btns .publish-btn span {
  font-family: "Ak-regular";
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.preview-btns .publish-btn svg {
  margin-bottom: 1px;
}

.preview-btns .disabled-publish-btn {
  color: #c5c8ce;
  background-color: #f7f7f7;
  border-color: #dcdee2;
  cursor: no-drop !important;
}
.preview-btns .disabled-publish-btn span {
  color: #c5c8ce;
}
.preview-btns :global(.ant-btn > .ant-btn-loading-icon .anticon) {
  font-size: 20px;
}

.preview-btns :global(.setting-btn) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.3;
  background: #fbfbfb;
  box-shadow: 0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03);
  border-radius: 30px;
  height: 40px;
  border: none;
}
.preview-btns :global(.setting-btn span) {
  font-family: "Ak-regular";
  font-size: 14px;
  line-height: 20px;
  color: #898893;
}
.preview-btns .setting-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
.publish-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 20px;
}
.publish-tips {
  font-family: "sf-regular";
  font-size: 12px;
  line-height: 16px;
  color: #898893;
  margin: 0 10px 0 0;
  user-select: none;
}
.preview-link {
  background: #ffffff;
  border: 1px solid #ebebed;
  border-radius: 8px;
  margin-bottom: 20px;
  user-select: none;
}
.preview-link input:global(.ant-input) {
  font-family: "sf-regular";
  font-size: 12px;
  line-height: 16px;
  user-select: none;
}
.figma-mode :global(.react-flow__node),
.figma-mode :global(.react-flow__node.dragging),
.figma-mode :global(.react-flow__pane.selection) {
  cursor: unset;
  user-select: none;
}
.copy-mouse :global(.react-flow__node) {
  cursor: copy;
}
.model-box {
  z-index: 2;
  position: absolute;
  top: 46px;
  bottom: 0;
  min-height: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 34px 0 54px;
}
.model-box :global(.ant-spin-nested-loading) {
  height: 100%;
}
.model-box :global(.ant-spin-container) {
  height: 100%;
}

:global(.react-flow__renderer) {
  position: fixed !important;
}

@media only screen and (max-width: 767px) {
  div:global(.react-flow__panel).node-preview-pane {
    width: 100%;
    margin: 0;
  }
}
/* .icon-setting {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
} */

.saas-content {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/aiSaasBackground2x.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  user-select: none;
}
.dark {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/darkModeBackground2x.png");
}

.preview-btn-group {
  /* margin: 15px 0; */
  /* stud-1046 */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  user-select: none;
}

.preview-btn-add-content {
  height: 40px;
  padding: 5px 30px;
  background-color: #05be75;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  border-radius: 25px;
  cursor: pointer;
}
.preview-btn-done {
  height: 40px;
  padding: 5px 30px;
  background: #cc295f;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.background {
  background: #d2d5de;
}
