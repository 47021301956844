.listPanel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.listPanelItem {
  flex: 0 0 240px;
  margin: 0 5px;
  display: flex;
  width: 240px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--radius-small, 10px);
  border: 1px solid var(--grayscale-gray-15, #e1e1e4);
  background: var(--grayscale-gray-0, #fbfbfb);
}
