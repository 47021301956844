// @import "../../../AISaasLayout/vars.scss";

.toolbar-dnd {
  position: absolute;
  z-index: 12;

  width: 30px;
  height: 78px;
  // left: -($silder-width + $silder-diff-width + $silder-panel-gap);
  left: -(30px + (30px - 20px));
  top: 50%;
  .handle-drag svg {
    cursor: move;
  }

  .handle-prev,
  .handle-next {
    width: 20px;
    height: 20px;
    line-height: 0;
    font-size: 0;
  }
}
.toolbarInputPosition {
  transform: translateY(-30%);
}
.toolbarOutputPosition {
  transform: translateY(-50%);
}
.vertical-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;

  > * {
    cursor: pointer;
  }
}

.Toolbar {
  position: relative;

  .toolbar-overlay {
    position: absolute;
    // z-index: 11;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;

    border: 1px dashed #0a9dff;
    background: rgba(10, 157, 255, 0.1);
  }

  .toolbar-dnd,
  .toolbar-overlay,
  .toolbar-actions {
    opacity: 0;
  }

  &:hover {
    .toolbar-dnd,
    .toolbar-overlay,
    .toolbar-actions {
      opacity: 1;
    }

    // border: 1px dashed #0A9DFF;
    // background: rgba(10, 157, 255, 0.1);
    // opacity: 0;
  }

  .toolbar-actions {
    position: absolute;
    z-index: 12;
    right: 0;
    width: 30px;
    min-height: 80px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
