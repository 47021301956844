.saasHeadPc {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.saas-top {
  text-align: center;
  max-width: 580px;
  margin-top: 50px;
  position: relative;
}

.title-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 5px;
  box-sizing: content-box;
  margin-bottom: 14px;
}
.saas-title {
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 800;
  font-size: 68px;
  line-height: 75px;
  text-align: center;
  color: var(--saasTitleColor, "#12192B");
  letter-spacing: -0.68px;
}

.saas-subTitle {
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: var(--saasTitleColor, "#12192B");
}

/* 适配间距优化 1920px 1680px 1440px 1280px 1024px 800px 568px */
@media only screen and (min-width: 1921px) {
  .saas-subTitle {
    margin-bottom: 52px;
  }
}
@media only screen and (min-width: 1680px) and (max-width: 1920px) {
  .saas-subTitle {
    margin-bottom: 52px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .saas-top {
    max-width: 480px;
  }
}
@media only screen and (min-width: 568px) and (max-width: 799px) {
  .saas-top {
    max-width: 480px;
    margin-top: 80px;
  }
  .saas-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
  }
  .saas-subTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 567px) {
  .saas-top {
    max-width: 90%;
    margin-top: 80px;
  }
  .saas-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
  }
  .title-box {
    margin-bottom: 0;
  }
  .saas-subTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}
