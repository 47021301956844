.submit-button {
  border: var(--submitButtonBorder);
  background: var(--submitButtonBgClr, "#12192B");
  color: var(--submitButtonColor, "#fff");
  border-radius: var(--submitButtonBorderRadius, 25px);
  height: 50px;
  padding: var(--inputPadding, 5px 30px);
  padding-left: 35px;
  margin: 47px auto 0;
  width: 180px;

  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.AiSaasHeader {
  width: 100%;
}
.cannot-select {
  transition: 0.3s;
  background: #d9d9d9;
  color: #00000040;
  border-color: transparent;
}
.saas-loading {
  background-color: #d9d9d930;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
.other-operate-box {
  position: absolute;
  top: -10px;
  right: 20px;
  transition: 0.3s;
}
.saasLoginBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 70px 0 65px;
}
.saasLogin {
  width: fit-content;
  // position: relative;
  // top: 30px;
  // left: 65px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--appSignUpHeight, 50px);
  svg {
    width: 54px;
    height: 40px;
  }
  svg path {
    fill: var(--aiLogoBrightColor, "#12192B");
  }
}
// .account {
//   top: 30px;
//   right: 70px;
//   position: absolute;
// }
.logoBoxImg {
  max-width: 90px;
  max-height: 90px;
  object-fit: cover;
}
.saas-input-content {
  position: initial;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobileSubmitStyle {
  width: 100%;
}

.saas-price-text {
  width: 750px;
  margin: 0 auto;
}

.priceText {
  margin-top: 10px;
}

@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .mobileSubmitStyle {
    width: 646px;
  }
  .saas-price-text {
    width: 646px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .mobileSubmitStyle {
    width: 550px;
  }
  .saas-price-text {
    width: 550px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .mobileSubmitStyle {
    width: 550px;
  }
  .saas-price-text {
    width: 550px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .mobileSubmitStyle {
    width: 450px;
  }
  .saas-price-text {
    width: 450px;
  }
}
@media only screen and (min-width: 568px) and (max-width: 799px) {
  .mobileSubmitStyle {
    width: 470px;
    max-width: 450px;
  }
  .saas-price-text {
    width: 470px;
    max-width: 450px;
  }
}

@media only screen and (max-width: 567px) {
  .mobileSubmitStyle {
    width: 90%;
  }
  .saas-price-text {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1920px) {
  .saasLoginBox {
    padding: 30px 50px 0 45px;
  }
  // .saasLogin {
  //   left: 45px;
  // }
  // .account {
  //   right: 50px;
  // }
}

@media screen and (max-width: 768px) {
  .saasLoginBox {
    padding: 20px 20px 0;
  }
  // .saasLogin {
  //   top: 20px;
  //   left: 20px;
  // }
  // .account {
  //   top: 20px;
  //   right: 20px;
  // }
}

.spacingBox {
  margin-bottom: 20px;
}
