/*@import "../../base/style/Common.scss";*/

.box {
  flex: 1;
}
.topBox {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.waveform_box {
  flex: 1;
  min-height: 40px;
  // margin: 25px 10px 20px;
  padding: 0px;
}
.waveformMargin {
  margin: 0 10px;
}
.playBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.play_icon {
  width: 50px;
  height: 50px;
}

.playTimeBox {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileName {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* @include themeify {
    color: themed(audioFileNameColor);
  } */
  color: var(--audioFileNameColor, #12192b);
  max-lines: 3;
  max-height: 60px;
  word-break: break-all;
  overflow: hidden;
}

.currentTime {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* @include themeify {
    color: themed(audioCurrentTimeColor);
  } */
  color: var(--audioCurrentTimeColor, #12192b);
}

.totalTime {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* @include themeify {
    color: themed(audioTotalTimeColor);
  } */
  color: var(--audioTotalTimeColor, rgba(2, 12, 38, 0.35));
}
.voice_Img {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  filter: var(--audioVoiceBrightness);
}
