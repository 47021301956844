.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  font-family: "Ak-Regular";
  background: #ffffff;
  height: 100%;
}

.whatTodo {
  /* Dark 5 */
  box-sizing: border-box;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 10px;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* Dark */
  border: none;
  color: #12192b;
  padding: 15px 20px;
  outline: none;
  resize: none;
  height: 200px;
  width: 100%;
  border: 1px solid transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.whatTodo:focus {
  border: 1px solid transparent;
  box-shadow: none;
}

.whatTodo::-webkit-scrollbar {
  display: none;
}

.bottom_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.label {
  width: 100%;
  display: block;
  text-align: left;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* Dark 65 */

  color: rgba(18, 25, 43, 0.65);
  padding-top: 15px;
  padding-bottom: 5px;
}

.select {
  width: 100%;
}

.select :global(.ant-select-selector) {
  height: 50px !important;

  /* Dark 5 */

  background: rgba(2, 12, 38, 0.05) !important;
  border-radius: 10px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  border: none !important;
}

.selectItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #12192b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-family: Ak-Light;
  height: 100%;
}

.selectItemDisabled {
  opacity: 0.22;
}

.selectItem img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.form input {
  height: 50px;

  /* Dark 5 */

  background: rgba(2, 12, 38, 0.05);
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
}

.makeACall {
  height: 40px;

  /* Blue */

  background: #0a9dff;
  box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
  border-radius: 25px;
  font-size: 15px;
  /* identical to box height, or 133% */

  text-align: center;

  /* White */

  color: #ffffff;
  cursor: pointer;
  width: 100%;
  margin: 15px 0;
}

.chatBox {
  /* Dark 5 */

  border: 1px solid rgba(2, 12, 38, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px 15px;
  margin-top: 15px;
  width: 100%;
}

.chatBoxInner {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  user-select: text;
}

.chatBoxInner::-webkit-scrollbar {
  display: none;
}

.chatBoxInner p {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* Dark */

  color: #12192b;
  padding-bottom: 20px;
  margin: unset;
}

.chatBoxInner > div {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* Dark 35 */

  color: rgba(2, 12, 38, 0.35);
  margin-bottom: 10px;
}

.progressBtn {
  background: rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  /* height: 30px; */
  min-height: 30px;
  display: inline-flex;
  justify-content: space-between;
  /* padding: 0 15px; */
  padding: 2px 15px;
  align-content: center;
  font-weight: 600;
  font-size: 15px;
  color: rgba(18, 25, 43, 0.65);
  /* line-height: 30px; */
  line-height: 20px;
  align-items: center;
  font-family: "Ak-Regular";
  margin-bottom: 10px;
}

.progressBtn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.socketIndicator {
  margin-top: 5px;
  margin-left: calc(100% - 15px);
  margin-bottom: 0 !important;
  line-height: 10px !important;
}

.lottieLoader {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.focused {
  border: 1px solid rgb(98 153 249) !important;
}
