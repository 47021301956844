.wraper {
  height: 100%;

  :global(.ant-spin-nested-loading),
  :global(.ant-spin-container) {
    height: 100%;
  }
}

.code {
  /* margin: 25px; */
  /* height: calc(100% - 120px); */
  height: 100%;
  background: var(--codeBg, #fff);
  color: var(--codeColor, #12192b);
  /*box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);*/
  box-shadow: var(--nodeWrapperShadow, none);
  backdrop-filter: var(--nodeBackdropFilter, none);
  border-radius: 15px;
  overflow: hidden;
}

.rightBtn {
  min-width: 100px;
  max-height: 40px;
  background: var(--rightBtnBg, #5182ff);
  color: #fff;
  border-radius: 25px;
  padding: 10px 20px 10px 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.rightDisableBtn {
  min-width: 100px;
  max-height: 40px;
  background: #ccc;
  border-radius: 25px;
  padding: 10px 20px 10px 15px;
  height: 100%;
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: not-allowed;
}

.codeMain {
  height: 100%;
  display: flex;
  flex-direction: column;

  iframe {
    min-height: 150px;
    overflow: scroll;
    // min-height: 400px;
    // height: auto;
    flex: 2;
  }

  .codeEditor {
    min-height: 150px;
    flex: 2;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    padding: 15px 20px;
    border-top: 0.5px solid rgba(34, 35, 47, 0.1);
    position: sticky;
    bottom: 0;
    background: var(--codeFooterBg, #fff);
    color: var(--codeFooterColor, #12192b);
  }
}

.codeEditor {
  overflow: hidden auto !important;
  white-space: pre-wrap !important;
  height: 100% !important;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    margin: 10px 10px 10px 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(138, 138, 138);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(98, 98, 98);
  }

  > div {
    height: 100%;
  }

  pre {
    min-height: 100%;
    white-space: pre-wrap !important;
    word-wrap: normal !important;
  }

  textarea {
    white-space: pre !important;
    word-wrap: normal !important;
  }
}
