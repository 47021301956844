.guidance-content {
  width: 260px;
}
.guidance-content h5 {
  font-weight: bolder;
}
.guidance-content span {
  color: #1890ff;
}
.guidance-content .input-node {
  margin-top: 16px;
}
.hight-light-text {
  margin: 0 5px;
}
