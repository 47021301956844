.carouselBox {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 135px;
    height: 90px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
  }
}
.title {
  font-family: "Ak-Regular";
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12192b;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.DesignTemplateMobile {
  .carouselBox {
    gap: 10px;
    box-sizing: border-box;
    .carouselImg {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
}

:global {
  .ant-carousel .designCarouselBox .slick-dots-bottom {
    bottom: -10px;
  }
  .ant-carousel .designCarouselBox .slick-dots li {
    width: 5px;
    height: 5px;
    opacity: 1;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    button {
      width: 5px;
      height: 5px;
      background: #12192b1a;
      border-radius: 50%;
      opacity: 1;
    }
  }
  .ant-carousel .designCarouselBox .slick-dots li.slick-active {
    width: 5px;
  }
  .ant-carousel .designCarouselBox .slick-dots li.slick-active button {
    background: #12192ba6;
  }
}
.carouselBox .activeImg {
  border-color: #0a9dff;
}
