.right_menu {
  width: 300px;
  visibility: hidden;
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  /* border-radius: 10px; */
  border-radius: 15px;
  /* padding: 10px; */
  padding: 5px, 0px, 5px, 0px;
  font-family: "Ak-Regular";
  /* box-shadow: 0 30px 60px 0 #0000001f; */
  box-shadow: 0px 30px 39px 0px rgba(123, 133, 142, 0.08);
  // 防止频繁闪动
  overflow: hidden;
}

.right_menu .right_text {
  font-family: "Ak-Regular";
  font-size: 15px;
  cursor: pointer;
  /* padding: 2px 10px; */
  margin-bottom: 5px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  margin: 0;
  color: rgba(18, 25, 43, 0.65);
}

.right_menu .right_text:hover {
  background-color: rgba(10, 157, 255, 0.1);
  border-radius: 10px;
}

.right_menu .right_text[aria-disabled="true"] {
  color: #ccc;
}
.right_menu .right_text[aria-disabled="true"]:hover {
  cursor: not-allowed;
  background-color: initial;
}
