.itineraryPlanning {
  padding: 24px;
  gap: 24px;
  border-radius: 44px;
  background: #fff;
}

.itineraryPlanningBox {
  margin-top: 24px;
  .title {
    color: #12192b;
    font-size: 24px;
    line-height: 30px;
  }
  .itineraryPlanningList {
    margin-top: 24px;
  }
}

.itineraryPlanningItem {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 20px 20px 1fr;
  grid-template-areas:
    "logo time"
    "logo description"
    ". description";
  font-size: 14px;
  line-height: 20px;
  .logo {
    grid-area: logo;
    // 子元素连接线
    // &.logoLine {
    //   position: relative;
    //   &::before {
    //     position: absolute;
    //     top: -20px;
    //     content: "";
    //     display: block;
    //     width: 40px;
    //     height: 40px;
    //     background: url("./assets/line.png") no-repeat top center;
    //   }
    // }
  }
  .time {
    grid-area: time;
    color: rgba(18, 25, 43, 0.65);
  }
  .description {
    grid-area: description;
    color: #12192b;
  }
}
