.loadMoreBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 6px 20px;
    border-radius: 20px;
    background: rgba(18, 25, 43, 0.05);
    color: rgba(18, 25, 43, 0.7);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
}
