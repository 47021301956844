.post-body-param-evaluated {
  background-color: white !important;
  color: black !important;
}

.beautify-btn {
  position: absolute;
  right: 20px;
  top: 1px;
}

.error-tips {
  color: #ff4d4f;
  position: absolute;
  right: 10px;
  top: 10px;
}
