.newsVideoListWrapper {
  .scrollView {
    max-height: 780px;
    overflow: auto;
    margin-bottom: 16px;
  }
  .scrollView::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: flex;
    align-items: center;
  }
  .item:not(:last-child) {
    margin-bottom: 16px;
  }
  .imgBox {
    position: relative;
    width: 156px;
    height: 156px;
    object-fit: cover;
    border-radius: 44px;
    overflow: hidden;
    margin-right: 16px;
    flex-shrink: 0;
  }
  .videoDetail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .videoHeadLine {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: rgba(18, 25, 43, 1);
    margin-bottom: 4px;
  }
  .videoDuration {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(18, 25, 43, 0.6);
  }
  .videoSource {
    display: flex;
    align-items: center;
    margin-top: 16px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 50%;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgba(18, 25, 43, 0.6);
    }
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
