.delete-box {
  position: absolute;
  z-index: 5;
  bottom: 6px;
  right: 35px;
  cursor: pointer;
}
.icon-gengduo {
  font-size: 20px;
}

.more-box {
  position: absolute;
  z-index: 5;
  top: 6px;
  right: 35px;
  cursor: pointer;
}

.horizontalScrolling .more-box {
  top: 0;
  right: 18px;
}

.horizontalScrolling .delete-box {
  bottom: 30px;
  right: 18px;
}
.Editor-UI section.cardhome-response-img {
  width: 140px;
  height: 105px;
  margin: 0 10px 25px 0;
  padding: 0;
  overflow: hidden;
}
.CardHome {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}
