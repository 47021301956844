.response-box {
  padding: 10px;
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.response-box :global(.ant-tabs-content-holder) {
  overflow: auto;
}
.response-box :global(.ant-tabs-content-holder)::-webkit-scrollbar {
  display: none;
}
.response-status {
  display: flex;
}
.response-status span:first-child {
  font-size: 14px;
  font-family: ak-light;
}
.response-status span:last-child {
  font-size: 14px;
  font-family: sf-regular;
}
.response-status p:not(:last-child) {
  margin-right: 10px;
}
.ok-status {
  color: #52c41a;
}
.failed-status {
  color: #ff4d4f;
}

.json-response {
  white-space: pre-wrap;
  word-break: break-word;
}
.custom-api:global(.nodrag) .json-response {
  user-select: text;
}
.img-response {
  width: 100%;
  height: 100%;
}
.img-response img {
  width: 100%;
  height: 100%;
}
.response-body {
  height: calc(100% - 80px);
  text-align: center;
}
.response-body img {
  width: auto;
}
.iframe-response iframe {
  width: 100%;
  height: 100%;
}

.white-frame {
  width: 100%;
  height: 100%;
  min-width: 285px;
  min-height: 320px;
  background: #fbfbfb;
  box-shadow: 0px 20px 80px rgb(93 122 152 / 20%);
  border-radius: 15px;
  position: relative;
}

.white-frame .image-box {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}
.white-frame .type-title {
  color: rgba(2, 12, 38, 0.35);
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 13px;
  padding: 10px 10px 10px 15px;
  height: 40px;
  line-height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.white-frame .image-content {
  min-width: 275px;
  min-height: 275px;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

:global(.ai-saas) .white-frame,
:global(.ai-saas) .white-frame .image-content {
  min-width: auto;
}

.custom-api {
  background-color: #fff;
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 5px;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
}
.custom-api::-webkit-scrollbar {
  display: none;
}
.default-resize {
  width: 300px;
  height: 100%;
}
.adaptive-width {
  width: 100%;
}
.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.delete-content img {
  width: 100%;
  height: 100%;
}

:global(.dark) .white-frame {
  background: rgba(255, 255, 255, 0.1);
}

:global(.dark) .custom-api {
  background: none !important;
}
