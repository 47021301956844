.login_imagica {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* 处理退出按钮显示 */
  z-index: 20;
}
.bg-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-box {
  position: relative;
  padding: 20px 7.473958333333333vw 5vh;
  margin: 0 auto;
}

.logo-box-default {
  height: 10vh;
}
.logo-box-default img {
  display: none;
}
.signUp-content {
  box-sizing: border-box;
  width: 43vw;
  min-width: 370px;
  max-width: 860px;
  /* height: 64.25925925925927vh;
  min-height: 400px;
  max-height: 694px; */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.signUp-content::-webkit-scrollbar {
  display: none;
}

.signUp-box {
  background: rgba(236, 236, 236, 0.34);
  border: 1px solid #ffffff;
  backdrop-filter: blur(70px);
  border-radius: 30px;
  padding: min(60px, 7.4074074074074066vh) min(190px, 7vw);
  word-break: break-word;
}

.main-information {
  font-family: "Ak-Regular";

  font-weight: 700;
  font-size: 1.875vw;
  line-height: 3.7037037037037033vh;
  color: #12192b;
  margin-bottom: 20px;
}
.auxiliary-information {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 1.0416666666666665vw;
  line-height: 2.7777777777777777vh;
  color: #12192b;
}
.error-prompt {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 1.0416666666666665vw;
  line-height: 2.7777777777777777vh;
  color: #ff4d4f;
}

.input-box {
  margin-top: 5.555555555555555vh;
  margin-bottom: 3.4074074074074066vh;
}
.enter-input {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 1.0416666666666665vw;
  line-height: 1.5625vw;
  margin-bottom: 5px;
  padding-left: 0;
}
.enter-input::-webkit-input-placeholder {
  color: rgba(2, 12, 38, 0.35);
}
.underline {
  border-bottom: 1px solid rgba(2, 12, 38, 0.2);
}
.reset {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 1.0416666666666665vw;
  line-height: 1.5625vw;
  color: #0a9dff;
  cursor: pointer;
}

.log-in-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  font-family: "Ak-Regular";
  font-weight: 400;
  /* font-size: 1.5vw; */
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  height: 4vw;
  color: #ffffff;
  background: #0a9dff;
  box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
  border-radius: 30px;
}
.or-text {
  color: #737373;
  text-align: center;
  font-family: ak-regular;
  /* font-size: 1.5vw; */
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin: 0.75vw auto;
}
.sign-up-link {
  font-family: "Ak-Regular";
  text-align: center;
  margin: 19px 0 37px 0;
  position: relative;
  padding: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-link span {
  margin-left: 10px;
  color: #12192b;
  font-family: "Ak-Regular";
  font-weight: 600;
  cursor: pointer;
}

.declaration-consent {
  color: rgba(2, 12, 38, 0.35);
  text-align: center;
  font-family: "Ak-Regular";
  font-style: normal;
  /* font-size: 1.5vw; */
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 40px;
}
.declaration-consent .text-bold {
  color: #0a9dff;
  display: contents;
}

:global(.ant-form-item-feedback-icon-success) {
  color: #0a9dff;
  font-size: 30px;
  margin-left: 4px;
}
:global(.ant-form-item-feedback-icon-error) {
  display: none;
}

.sign-box {
  position: relative;
}

.resend-msg {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.0416666666666665vw;
  font-family: "Ak-Regular";

  font-weight: 400;
  line-height: 30px;
}

.resend-text {
  position: absolute;
  bottom: -29px;
  font-size: 1.0416666666666665vw;
  font-family: "Ak-Regular";
  font-weight: 400;
  line-height: 30px;
}
.clickable {
  color: #0a9dff;
  cursor: pointer;
}

:global(.ant-form-item-explain-error) {
  font-family: "Ak-Regular";
  font-size: 1.0416666666666665vw;
  /* color: #ff4d4f; */
  color: rgba(204, 41, 95, 1);
  font-weight: 400;
  margin-top: 5px;
}
:global(.ant-form-item-has-error) {
  border: none;
}
:global(.ant-form-item-has-error .ant-form-item-control-input) {
  border-bottom: 1px solid #cc295f;
}

/* 文字大小超出限制 */
@media screen and (min-width: 1920px) {
  .signUp-box .main-information {
    font-size: 36px;
    font-family: "Ak-Regular";
    font-weight: 700;
    line-height: 40px;
  }
  .signUp-box {
    .auxiliary-information,
    .error-prompt,
    .log-in-button,
    .or-text {
      font-size: 20px;
      font-family: "Ak-Regular";
      font-weight: 400;
      line-height: 30px;
    }
  }
  .underline .enter-input {
    font-family: "Ak-Regular";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
  .reset,
  .signUp-box .declaration-consent {
    font-size: 20px;
    font-family: "Ak-Regular";
    font-weight: 400;
    line-height: 30px;
  }
  .input-box :global(.ant-form-item-explain-error) {
    font-size: 18px;
    font-family: "Ak-Regular";
    font-weight: 400;
    line-height: 30px;
  }
  .signUp-box .log-in-button {
    /* width: 487px; */
    height: 60px;
    padding: 5px 30px;
  }
  .signUp-box .or-text {
    margin: 10px auto;
  }
}
@media screen and (min-width: 720px) {
  .signUp-box .main-information {
    /* font-size: 26px;
    line-height: 34px; */
    font-size: 36px;
    font-family: "Ak-Regular";
    font-weight: 700;
    line-height: 40px;
  }
  .signUp-box {
    .auxiliary-information,
    .log-in-button {
      /* font-size: 14px;
      line-height: 22px; */
      font-size: 20px;
      font-family: "Ak-Regular";
      font-weight: 400;
      line-height: 30px;
    }
  }
  .underline .enter-input {
    /* font-size: 14px; */
    font-family: "Ak-Regular";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
  .log-in-button {
    padding: 5px 20px;
    /* font-size: 16px; */
    height: 45px;
  }
  .or-text {
    /* font-size: 16px; */
    font-family: "Ak-Regular";
    font-weight: 400;
    line-height: 30px;
    margin: 8px auto;
  }
  .declaration-consent {
    font-size: 16px;
  }
}

/* 文字大小最小限制 */
@media screen and (max-width: 720px) {
  .login_imagica {
    justify-content: unset;
  }
  .signUp-box {
    margin-top: 20px;
    padding: min(40px, 7.4074074074074066vh) min(190px, 7vw);
  }
  .signUp-box .main-information {
    /* font-size: 26px;
    line-height: 34px; */
    font-size: 36px;
    font-family: "Ak-Regular";
    font-weight: 700;
    line-height: 40px;
  }
  .signUp-box {
    .auxiliary-information,
    .log-in-button,
    .error-prompt {
      /* font-size: 14px;
      line-height: 22px; */
      font-size: 20px;
      font-family: "Ak-Regular";
      font-weight: 400;
      line-height: 30px;
    }
  }
  /* .error-prompt {
    font-size: 14px;
  } */
  .underline .enter-input {
    /* font-size: 14px; */
    font-family: "Ak-Regular";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
  .reset {
    font-size: 14px;
  }
  .input-box :global(.ant-form-item-explain-error) {
    /* font-size: 14px; */
    font-size: 16px;
    line-height: 25px;
  }
  .input-box {
    /* margin-top: 30px; */
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .log-in-button {
    padding: 5px 20px;
    /* font-size: 16px; */
    height: 45px;
  }
  .logo-box {
    width: 216px;
  }
  .or-text {
    /* font-size: 16px; */
    font-size: 20px;
    font-family: "Ak-Regular";
    font-weight: 400;
    line-height: 30px;
    margin: 8px auto;
  }
  .declaration-consent {
    font-size: 16px;
    /* line-height: 25px; */
    line-height: 20px;
  }
}
