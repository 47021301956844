.unpublishBox {
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  // box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  margin: 20px 0;
}

.title {
  color: #acaeb5;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.subTitle {
  margin-top: 20px;
  color: #656a75;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}

.buttonBox {
  margin-top: 10px;
  border-radius: 20px;
  background: #12192b;
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  color: #f6f6f6;
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
}

.modalWrap {
  :global(.ant-modal-content) {
    border-radius: 20px;
    box-shadow: 0px 20px 80px 0px #5d7a9833;
  }

  .modalIcon {
    color: #ff335c;
    font-size: 20px;
    vertical-align: middle;
  }

  .modalTitle {
    vertical-align: middle;
    margin-left: 10px;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 500;
    color: #12192b;
  }

  .modalSubtitle {
    margin-top: 15px;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 400;
    color: #12192b;
  }

  .modalFooter {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  [ant-click-animating-without-extra-node="true"]::after {
    display: none;
  }

  .modalButtonNormal {
    color: #12192ba6;
    background: #020c260d;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 500;
    border: none;
    width: 110px;
  }

  .modalButtonDanger {
    color: #fff;
    background: #ff335c;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 500;
    border: none;
    width: 130px;
  }
}
