.back_layer {
  position: fixed;
  inset: 0;
  background: rgba(18, 25, 43, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 71vw;
    background-color: white;
    padding: 30px 30px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;

    .title {
      // font-family: AktivGrotesk;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.6px;
      text-align: center;
      color: #12192b;
      margin-bottom: 10px;
    }

    .msg {
      // font-family: Aktiv Grotesk;
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: center;
      color: #12192b;
      margin-bottom: 20px;
    }

    .button {
      display: flex;
      justify-content: center;

      //.cancel {
      //  width: 82px;
      //  height: 40px;
      //  font-size: 13px;
      //  font-weight: 400;
      //  line-height: 20px;
      //  text-align: center;
      //  color: #12192ba6;
      //  display: flex;
      //  align-items: center;
      //  justify-content: center;
      //}

      .confirm {
        width: 90px;
        height: 40px;
        background: #12192b;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
