/* ResizableDiv.css */

.resizable-container {
  display: flex;
  height: calc(100% - 52px);
  flex-direction: column;
}

.resizable-div {
  width: 100%;
  overflow: hidden;
}

.top {
  min-height: 100px;
  flex-grow: 1;
}

.bottom {
  min-height: 100px;
  flex-grow: 1;
}

.resize-bar {
  width: 100%;
  height: 10px;
  background-color: #eee;
  cursor: row-resize;
}
