.recipe .ContentFrameAngle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.recipeCardHomeIngredient {
  width: 290px;
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4px 10px 20px 0;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.03);
}

.recipeCardHomeIngredient img {
  width: 50px;
  height: 50px;
  margin: 0 15px 0 0;
}

.recipeCardHomeIngredient .title {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
}
