.item-label {
  font-family: ak-regular;
  color: #656a75;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
.cp-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  background: #f3f3f4;
  flex-shrink: 0;
  cursor: pointer;
  min-width: 70px;
}

.cp-box-mobile {
  background: var(--talkBuilderBg, #12192b);

  .item-label {
    color: var(--talkBuilderText, #fff);
  }
}

.cp-box:hover {
  background: #0a9dff;
}

.cp-box:hover .item-label {
  color: #ffffff;
}

.cp-box.disable {
  opacity: 0.4;
  cursor: not-allowed !important;
  &:hover {
    outline: none !important;
  }
}
