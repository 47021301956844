.loadingWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingWrapper .lottieLoader {
  width: 200px !important;
  height: 200px !important;
}
.loadingText {
  margin-top: -10px;
  margin-bottom: 50px;
  font-size: 15px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
}
:global(.dark) .loadingText {
  color: #ffffff;
}
