@import "../../../AISaas/AISaasLayout/vars.scss";

:global(.react-flow__panel).coverSettingPane {
  width: 800px;
}
p {
  margin: 0;
}
.previewPane {
  margin-left: 20px;
  width: 500px;
  height: calc(100% - 20px);
  border-radius: 25px;
  position: absolute;
  top: 0px;
  bottom: 20px;
  right: 20px;

  background: transparent;
  background-color: #fff;

  .closePreviewApp {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
  }

  .historyContent {
    visibility: visible;
  }

  .publishContent {
    visibility: visible;
  }

  .previewContent {
    visibility: visible;
  }

  .hidePublishContent {
    display: none;
  }
}

.previewLayout {
  height: 100%;
  padding: 25px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-size: cover;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg");
}

.title {
  color: #12192b;
  text-align: center;
  font-family: Ak-Medium;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
}

.description {
  color: #656a75;
  text-align: center;
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.modeText {
  padding-right: 10px;
  color: rgba(18, 25, 43, 0.65);
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.modeImgWrapper {
  margin-top: 20px;
  height: calc(100% - 110px);
  overflow-y: scroll;
}

.modeImg {
  width: 100%;
  height: auto;
}

.saasContent {
  position: relative;
  background-image: var(--background, url("../../assets/callerbot-bg.png"));
  background-color: var(--renderingTransitionColors, #ecf2fc);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: calc(100% - 60px);
  border-radius: 25px;
  // user-select: none;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
  }
}

.highlightMode {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: var(--Dark-10, rgba(18, 25, 43, 0.1));
    left: 0;
    top: 0;
    position: absolute;
    z-index: 100;
  }
}

.highlightComponent {
  position: relative;
  z-index: 1000;
}

.header {
  margin-bottom: 20px;
}

.logo {
  padding: 20px 20px 50px 0;
  text-align: left;
  margin-left: -30px;
}

.contentBox {
  position: relative;
  padding: 0 45px;
  flex: 1;
  color: rgba(18, 25, 43, 1);
  line-height: 20px;
  font-size: 15px;
}
.contentBox .contentTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}
.contentBox .contentSubTitle {
  font-size: 16px;
  line-height: 25px;
  padding: 10px 5px;
}
.contentBox .bgContentBox {
  background: #fff;
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 10px;
}
.contentBox .contactHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0;
  height: 20px;
}
.contentBox .contactHeader p {
  height: 20px;
  font-weight: 500;
  color: #12192b;
  font-family: "Ak-Medium";
}

.contentBox .imageBox {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  transition: all 0.5s;
  cursor: pointer;
  &.rotateArrow {
    transform: rotate(90deg);
  }
}
.contentBox .contactDescription {
  padding: 10px;
  text-align: left;
  color: rgba(101, 106, 117, 1);
}
.contactAdd {
  cursor: pointer;
}
.contentBox .contactUpload,
.contentBox .contactAdd {
  height: 80px;

  border-radius: 15px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.contentBox .contactUpload .uploadImage {
  margin: 0 15px 0 20px;
}
.contentBox .contactUpload .uploadTextBox {
  font-size: 13px;
  text-align: left;
}
.contentBox .contactUpload .uploadTextBox .uploadDescription {
  color: rgba(172, 174, 181, 1);
}
.contentBox .contactAdd {
  height: 60px;
  border: 1px dashed rgba(10, 157, 255, 1);
}
.contentBox .contactAdd .addImage {
  margin: 0 25px;
  width: 20px;
}
.contentBox .bgContentBox.callBtn {
  height: 40px;
  margin: 15px 0;
  color: #fff;
  background-color: #12192b;
}
.contentBox .bgContentBox.callBtnDisable {
  background-color: #b8b4b6 !important;
}

.footer {
  bottom: 0px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: rgba(18, 25, 43, 0.35);
}
