.mobileProjectsView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .scollWrap {
    flex: 1;
    height: calc(100% - 55px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .viewList {
    display: flex;
    flex-direction: column;
  }

  .viewGrid {
    display: flex;
    flex-wrap: wrap;
    padding-left: 3.8vw;
  }

  .loader_more {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;

    span {
      font-size: 20px;
    }
  }
}
