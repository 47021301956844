.Studio-Container {
  height: 100%;
}
.Studio-Background {
  background-color: #909090;
}
.Main-Container {
  top: 52px;
  height: calc(100% - 52px);
  position: absolute;
  width: 100%;
}

.Studio-Fot-UI {
  border-radius: 10px;
  overflow: hidden;
  height: calc(100% - 45px);
  margin-top: 35px;
}

.Studio-UI-Body {
  position: relative;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  /* min-height: 94vh;
  display: grid; */
  /* grid-template-columns: repeat(1, 0fr); */
}

.Studio-Left-Section {
  padding: 0 0 20px 20px;
  position: relative;
}

.Studio-Right-Section {
  padding-right: 20px;
  padding-top: 30px;
}

.shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0.3rem;
}

.shadow:hover {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.Card-Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  padding-left: 23%;
}

.Studio-Variable-List {
  height: 100%;
  max-height: 70vh;
}

.Studio-Variable-Item-Card {
  margin-bottom: 10px;
}

.Studio-Variable-Item-Card-Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 50%;
}

.Studio-Variable-Item-Card-Footer .ant-btn {
  border-radius: 4px;
  margin-right: 5px;
}

/* .ant-btn  {
  border-radius: 7px;
} */

.Studio-Logic-List:first-child {
  margin-right: 10px;
}

.Studio-Logic-Item-Card {
  margin-top: 2px;
}

.bold {
  font-weight: 600;
}

.Studio-Prompt-Card .ant-card-body {
  padding: 24px;
  position: absolute;
  top: 65px;
  height: calc(100% - 122px);
  width: 100%;
  overflow: auto;
  bottom: 57px;
}

.Studio-Prompt-Card .ant-card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Studio-Prompt-Raw {
  background-color: #fff;
  color: black;
}

.Studio-Prompt-Raw[disabled] {
  background-color: #fff;
  color: black;
}

.Studio-Logic-Card .ant-card-body {
  padding: 24px;
  position: absolute;
  top: 65px;
  height: calc(100% - 122px);
  width: 100%;
  overflow: auto;
  bottom: 57px;
}

.Studio-Logic-IO-Item-Card .ant-card-body {
  position: relative;
  top: auto;
  bottom: auto;
}

.Studio-Logic-Card .ant-card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Studio-Variable-Card .ant-card-body {
  height: calc(100% - 122px);
  position: absolute;
  width: 100%;
  overflow: auto;
  top: 65px;
  bottom: 57px;
}

.Studio-Variable-Card .ant-card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Studio-Variable-Item-Card .ant-card-body {
  position: relative;
  top: auto;
  bottom: auto;
}

.Studio-Variable-Item-Card .hidden-content {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-break: break-word;
  overflow: hidden;
}

.Studio-Variable-Item-Card .expand-content {
  -webkit-line-clamp: initial;
}

.Studio-Variable-Item-Card .ant-card-actions {
  position: relative;
}

.ant-tabs-top,
.Studio-Logic-Card,
.Studio-Logic-List-Container,
.Studio-Variable-Card,
.ant-card-body,
.ant-tabs-mobile,
.ant-tabs-content-holder,
.ant-tabs-tabpane,
.ant-tabs-content-top,
.Studio-Prompt-Card {
  height: 100%;
}

.Studio-Left-Section-Icon {
  color: #fff;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 20px;
}

.Main-Container .ant-tabs-tab-btn {
  color: #fff;
}

.Studio-Left-Section-Stow {
  display: none;
}
.Studio-Left-Section-display {
  color: #fff;
  font-size: 24px;
  margin: 15px 0 0 31px;
}

.Studio-Variable-Plus-Button {
  min-height: 55px;
  min-width: 55px !important;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.Studio-Variable-Delete-Icon {
  color: red;
  position: absolute;
  right: 8px;
  top: 20px;
  cursor: pointer;
}

.Studio-Variable-Card::-webkit-scrollbar {
  display: none;
}

.Studio-Logic-IO-OR {
  text-align: center;
  padding: 10px 0px 10px 0px;
  font-size: 14px;
}

.horizontal-line {
  display: inline-block;
  margin-top: 20px;
  border-top: 1px solid #909090;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
.hr1 {
  width: 40%;
  margin-right: 10px;
}
.hr2 {
  width: 40%;
  margin-left: 10px;
}

.description {
  color: #5c5c5c;
}

.Studio-Layout-Card {
  height: calc(100% - 20px);
  overflow-y: scroll;
}

.Studio-Layout-Card .ant-card-body {
  height: calc(100% - 122px);
  overflow: auto;
  position: absolute;
  width: 100%;
}

.Studio-Layout-Component-Card .ant-card-body {
  position: relative;
  top: auto;
  bottom: auto;
}

.Studio-Layout-Card .ant-card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* .Studio-Layout-Plus-Button {
  min-height: 55px;
  min-width: 55px!important;
  position: absolute;
  right: 10px;
  bottom: 70px;
} */

.Studio-Layout-Component-Card {
  margin-bottom: 10px;
}

.Studio-Layout-Component-Card .ant-form-item {
  margin-bottom: 10px;
}

.Studio-Layout-Component-Card .ant-card-head-title {
}

.Studio-Layout-Card-Container {
  height: 60vh;
}

.Studio-Layout-Card::-webkit-scrollbar {
  display: none;
}

.shadow-shallow:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shadow-shallow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Studio-Existing-Projects-Modal {
  min-width: 520px;
  width: 45% !important;
}

.Studio-Existing-Projects-Modal .ant-modal-body {
  min-height: min(400px, 60vh);
}

.Run-All-Button {
  position: absolute !important;
  right: 175px;
  top: 4px;
  height: 36px;
  line-height: 20px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .Studio-Existing-Projects-Modal {
    min-width: 100%;
    top: 10vh;
  }
  .Studio-Existing-Projects-Modal .ant-modal-body {
    min-height: 50vh;
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (max-width: 1300px) {
  /* .Card-Footer {
    padding-left: 0px;
  } */
}

@media screen and (max-height: 1120px) {
  /* .Studio-Variable-Card {
    margin-top: 3vh;
  }

  .Studio-Logic-Card {
    margin-top: 3vh;
  } */

  /* .Studio-Variable-Item-Card {
    padding: 0px;
  } */

  /* .Studio-Prompt-Text {
    max-height: 150px;
  } */

  /* .ant-card-head {
    height: 30px;
  } */

  /* .Studio-Left-Section {
    padding-top: 15px;
  } */

  .Studio-Variable-Plus-Button {
    min-height: 45px;
    min-width: 45px !important;
  }

  .Studio-Layout-Plus-Button {
    min-height: 45px;
    min-width: 45px !important;
  }
}

@media screen and (max-height: 915px) {
  /* .Studio-Variable-Card {
    margin-top: 1vh;
  }

  .Studio-Logic-Card {
    margin-top: 1vh;
  } */

  /* .Studio-Prompt-Text {
    max-height: 100px;
  } */

  /* .ant-card-head {
    height: 20px;
  } */

  /* .Studio-Left-Section {
    padding-top: 10px;
  } */
}
