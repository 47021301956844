.myProject {
  // padding: 66px 103px;
  padding: 66px 0 0 0;
  width: 100%;
  position: relative;
}

.view {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-spacing-small, 10px);
  align-self: stretch;
  position: relative;
  top: 0px;

  &__title {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-spacing-small, 10px) 0px;
    align-items: center;
    align-self: stretch;

    color: var(--dark, #12192b);
    font-family: Ak-Regular;
    font-size: 24px;

    font-weight: 500;
    line-height: 30px;
    /* 125% */
  }

  .searchbar {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__content {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    width: 100%;
    overflow: unset !important;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .input {
    width: 268px;
    height: 40px;
    border-radius: 20px;
    border: 0.5px solid #12192b1a;
    background: #ffffff59;

    :global(.ant-input) {
      background: #ffffff00;
    }
  }
}

:global(.infinite-scroll-component__outerdiv) {
  width: 100%;
}

.notify {
  .ant-notification-notice-close {
    top: 0px;
    right: 15px;
  }

  :global(.ant-notification-notice-close-x) {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

.loader_more {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;

  span {
    font-size: 20px;
  }
}
