.container {
  border-radius: 44px;
  border: 0.5px solid rgba(255, 255, 255, 0.35);
  background: rgba(255, 255, 255, 0.35);
  align-self: stretch;
  min-width: 280px;
}
.topContainer {
  padding: 24px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
}
.upperLayer {
  display: flex;
  align-items: flex-start;
  gap: 36px;
  height: 106px;
}
.weatherImage {
  width: 72px;
  height: 72px;
}
.weatherInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.temperature {
  color: #12192b;
  text-align: right;
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 8px;
}

.description {
  color: rgba(18, 25, 43, 0.65);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.middleLayer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow: auto;
}

.middleLayer::-webkit-scrollbar {
  display: none;
}

.weatherItem {
  display: flex;
  padding: 9px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
}

.icon {
  height: 20px;
  margin-top: 20px;
}

.name {
  color: rgba(18, 25, 43, 0.4);
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.value {
  margin-top: 8px;
  color: #12192b;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.lowerLayer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-self: stretch;
  color: #12192b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
  border-bottom-left-radius: 44px;
  border-bottom-right-radius: 44px;
}

.lowerLayerIcon {
  width: 32px;
  height: 32px;
}
