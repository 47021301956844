.listNodeBox {
  margin: 10px 0;
}

.listNodeItem {
  margin-bottom: 5px;
  padding: 5px 15px;
  border: 0.5px solid var(--Dark-5, rgba(2, 12, 38, 0.05));
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;

  font-weight: 400;
  line-height: 20px;
}
