.custom-node {
  position: relative;
  z-index: 1;
  min-width: 200px;
  /* max-width: 400px; */
  min-height: 200px;
  /* max-height: 400px; */
  width: 100%;
  height: 100%;
  font-family: "Ak-Regular";
  background: #f69795;
  box-shadow: 0 0 5px 1px #ccc;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.custom-node .default-resize {
  width: 200px;
  height: 200px;
}
.custom-node :global(.ant-input-affix-wrapper-textarea-with-clear-btn) {
  z-index: 3;
  width: calc(100% - 30px);
  margin-left: 15px;
  background: #f69795;
}
.custom-node :global(.ant-input-clear-icon) {
  right: 0;
  top: 15px;
}

.custom-node textarea {
  outline: none;
  border: none;
  background: #f69795;
  padding: 20px 15px 15px;
  resize: none;
  height: 100%;
  margin: 0;
  width: 100%;
  line-height: 20px;
  font-size: 13px;
}
.custom-node textarea::-webkit-input-placeholder {
  color: #666;
}
.custom-node textarea:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.input-mask {
  width: calc(100% - 30px);
  height: calc(100% - 40px);
  margin: 20px 15px;
  position: absolute;
  z-index: 10;
  word-break: break-word;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.input-mask.hidden-mask {
  z-index: -1;
}

.alternate-text {
  font-size: 13px;
  line-height: 20px;
  /* padding: 0 5px 15px 0; */
  white-space: pre-wrap;
  font-family: "Ak-Regular";
}
.alternate-text.place-color {
  color: #666;
}

.input-delete-box {
  position: relative;
}
.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.delete-content img {
  width: 100%;
  height: 100%;
}
.input-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999999;
}
.text-textArea::-webkit-scrollbar,
.text-content::-webkit-scrollbar,
.custom-api::-webkit-scrollbar,
.custom-node::-webkit-scrollbar,
.custom-node textarea::-webkit-scrollbar,
.input-mask::-webkit-scrollbar {
  display: none;
}
