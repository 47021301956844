.UI {
  height: 100%;
  max-width: 390px;
  margin: 0 auto;
}

.UI .log-out {
  top: 20px;
}
.move-left {
  right: 64px;
}
.Editor-UI {
  width: 100%;
  position: relative;
}
.UI-Body {
  height: 100%;
}
.Mid-Section {
  background-color: #fff;
  height: 100%;
}

.IPhone {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.UI-View {
  height: 100%;
}

.UI-Bottom {
  position: absolute;
  bottom: 10px;
}
.UI-Bottom-Grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 0 0 2.67em 2.67em;
}

.UI-Bottom-Button {
  background-color: #d1e5ea;
  grid-row: 1 / -1;
  grid-column: 2 / 3;
  border: solid black 0.01em;
  border-top: solid black 0.1em;
  z-index: 4;
}

.UI-Bottom-Button:first-of-type {
  grid-column: 1 / 2;
  border-bottom-left-radius: 3em;
}

.UI-Bottom-Button:last-of-type {
  grid-column: 3 / 4;
  border-bottom-right-radius: 3em;
}

.UI-Bottom-Hidden {
  background-color: rgba(15, 42, 48, 0.5);
  height: 100%;
  border-radius: 2.67em;
  position: relative;
  padding-top: 0.5em;
}

.UI-Bottom-Hidden-Button {
  background-color: #66a8b9;
  color: #e3e5ef;
  border: 0;
  border-bottom-left-radius: 40em;
  border-bottom-right-radius: 40em;
  display: block;
  width: 94%;
  margin: 0 auto;
}
.UI-Bottom-Hidden-Button:hover,
.UI-Bottom-Button:hover {
  background-color: #99c5d0;
  color: white;
}

.Domain {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  height: 40px;
  line-height: 20px;
  align-items: flex-start;
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  background: #e7edf4;
  color: #22232f;
  cursor: pointer;
}

.SelectedDomain {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;

  height: 40px;
  line-height: 20px;
  padding: 10px 20px;
  align-items: flex-start;
  opacity: inherit;
  border-radius: 20px;

  background: #22232f;
  color: #ffffff;
}

.Feed {
  background: #f0f6f8;
  border-radius: inherit;
  border: none;
}

/* .Top-Menu-Focus {
  background: #FFFFFF;
  border: none;
  margin: auto;
  border-bottom: 0.5px solid rgba(34, 35, 47, 0.1);
} */

.Focus-Content {
  padding: 20px 25px;
}

/* .CardHomeThought {
  height: 50px;
  font-family: 'Ak-Regular';
  
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #22232F;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 0.2em 0.5em 0.2em 0;
} */

.Domains {
  display: grid;
  grid-template-columns: 1fr 3fr;
  min-height: 2em;
  margin: 20px 0 0 -3%;
  max-height: none;
}

.DomainsThought {
  padding: inherit;
  border: none;
  margin: auto;
  border: 0.5px solid #68edff;
  box-shadow: inset 0px 0px 10px rgba(128, 210, 235, 0.3);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.DomainsThought .Domain {
  padding: 5px 20px;
  background: rgba(104, 237, 255, 0.2);
}
.DomainsThought .SelectedDomain {
  background: rgba(104, 237, 255, 0.2);
}
.DomainsThought img {
  width: 30px;
  height: 30px;
}

.CardHomeImplication {
  padding: 15px;
  margin-bottom: 20px;
  background: #ffffff;
  /* Shadow */

  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  word-break: break-word;
}

.CardHomeImplication p {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
}
.box-zIndex4 {
  z-index: 4;
  position: relative;
}
