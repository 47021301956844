.noResult {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .noResultIcon {
    width: 100px;
    height: 100px;
    background-image: var(
      --shoppingNoResultIcon,
      url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/noResultLightIcon.jpg")
    );
    background-repeat: no-repeat;
    background-size: cover;
  }
  .title {
    color: var(--shoppingNoResultTitleColor, #12192b);
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 20px;
  }

  .des {
    color: var(--shoppingNoResultDesColor, rgba(18, 25, 43, 0.65));
    text-align: center;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
  }

  .search {
    border-radius: 25px;
    background: #0a9dff;
    box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
    margin-top: 20px;
    span {
      color: #fff;
      text-align: center;
      font-family: var(--fontFamily, Ak-Regular);
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
