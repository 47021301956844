.button {
  display: inline-flex;
  padding: 6px 10px 6px 6px;
  align-items: center;
  gap: 7px;
  border-radius: 40px;
  background-color: rgba(10, 157, 255, 0.05);
  border: none;
  outline: none;

  transition: 0.3s background-color ease-out, 0.3s box-shadow ease-out;
}

.button__icon {
  display: inline-flex;
}

.button__text {
  font-family: Ak-Regular;
  color: var(--primary-primary-50, #0a9dff);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 13px;
  line-height: normal;
}

.button:hover {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(58, 57, 76, 0.03), 0px 2px 8px 0px rgba(58, 57, 76, 0.08);
}
