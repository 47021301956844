.select-border_animated {
  animation: 0.2s ease-out;
  animation-name: appearAnim;
}

@keyframes appearAnim {
  0% {
    stroke-width: 0;
  }
  100% {
    stroke-width: 10;
  }
}
