.videoView {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0000006b;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoBox {
  position: relative;
  width: 80%;
}

.videobox > div {
  width: 80vw !important;
  height: 45vw !important;
  position: relative;
}

.iconClose {
  position: absolute;
  right: -13px;
  top: -13px;
  cursor: pointer;
  z-index: 9999999;
}
