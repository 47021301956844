$padWidth: 580px;

.ChatContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.generating {
  padding: 60px 0;
  color: var(--inputPlaceholderColor, "rgba(18, 25, 43, 0.35)");
  position: relative;
}

.rightItem {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.generateAnimation {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: -55px;
  transform: translateY(-50%);
}

@mixin mobileStyle {
  .generateAnimation {
    left: 0;
  }
  .generating span {
    width: calc(100% - 70px);
    margin-left: 70px;
  }
}
.canvasChatContent {
  @include mobileStyle;
}
@media only screen and (max-width: $padWidth) {
  @include mobileStyle;
}
