.plainText {
  /* @include themeify {
    color: themed(textColor);
  } */
  color: var(--textColor, rgba(2, 12, 38, 0.35));
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;

  font-weight: 400;
  line-height: 20px; /* 133.333% */
  text-align: left;
  width: 80%;
}

.linkText {
  color: #0a9dff;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
  width: 80%;
  cursor: pointer;
}
