.TooltipWhatTodoPopoverNode {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.TooltipWhatTodoPopoverV3 :global(.ant-popover) {
  top: -150px !important;
  left: initial !important;
}
.TooltipWhatTodoPopover :global(.ant-popover) {
  top: -185px !important;
  left: initial !important;
}

.TooltipWhatTodoPopover-cot-editor-0 :global(.ant-popover) {
  top: -105px !important;
  left: initial !important;
}

.TooltipWhatTodoPopovereditor-2 :global(.ant-popover) {
  top: -150px !important;
}
.TooltipWhatTodoPopovergroup-2 :global(.ant-popover) {
  top: -165px !important;
}
.TooltipWhatTodoPopovereditor-2-split :global(.ant-popover) {
  top: -190px !important;
  left: initial !important;
  /* transform: translateX(-50%) !important; */
}
.desc {
  white-space: pre-line;
}
.TooltipWhatTodoPopoveredge-0-input-right :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-input-left :global(.ant-popover) {
  top: -190px !important;
  left: initial !important;
}
.TooltipWhatTodoPopoveredge-0-input-bottom :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-input-top :global(.ant-popover) {
  top: -190px !important;
  left: initial !important;
}

.TooltipWhatTodoPopoveredge-0-run-right :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-run-left :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-run-bottom :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-run-top :global(.ant-popover) {
  top: -190px !important;
  left: initial !important;
}
.TooltipWhatTodoPopoveredge-0-generating-right :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-generating-left :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-generating-bottom :global(.ant-popover),
.TooltipWhatTodoPopoveredge-0-generating-top :global(.ant-popover) {
  top: -190px !important;
  left: initial !important;
}
.TooltipWhatTodoPopover :global(.ant-popover-inner-content),
.TooltipWhatTodoPopoverV3 :global(.ant-popover-inner-content) {
  padding: unset !important;
}

.TooltipWhatTodoPopover :global(.ant-popover-inner),
.TooltipWhatTodoPopoverV3 :global(.ant-popover-inner) {
  /* White 35 */

  background: rgba(255, 255, 255, 1);
  /* White 35 */

  border: 1px solid rgba(255, 255, 255, 0.35);
  /* Shadow L [Blur] */

  box-shadow: 0px 30px 60px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
}

.TooltipWhatTodoPopover :global(.ant-popover-arrow-content),
.TooltipWhatTodoPopoverV3 :global(.ant-popover-arrow-content) {
  background: rgba(255, 255, 255, 0.8);
}
.contentBold {
  font-weight: bolder;
}
.codeHighlight {
  background: rgba(2, 12, 38, 0.1);
  border-radius: 5px;
  padding: 0px 10px;
  display: inline-block;
  color: rgba(2, 12, 38, 0.35);
}
.content {
  font-family: "Ak-Regular";
  font-weight: 400;
  font-size: 15px;
  color: #12192b;
  line-height: 20px;
  text-align: center;
  padding: 30px;
  width: 320px;
  position: relative;
}
.newOnboardingFlowContent {
  width: 350px;
}
.title {
  font-family: "Ak-Regular";
  font-weight: 500;
  white-space: pre-line;
}

.content > span {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.initialPopover > div[style="position: absolute; top: 0px; left: 0px; width: 100%;"] > div:first-child {
  display: flex;
  justify-content: center;
}
