.viewBuilder {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // min-height: 60px;
}
.viewBuilderMinHgt {
  min-height: 60px;
}
.spinBox {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.viewBuilderWrapper {
  overflow: auto;
  // min-height: 250px;
  min-height: 225px;
}
.viewBuilderWrapper::-webkit-scrollbar {
  display: none;
}

.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pre {
    height: 100%;
    white-space: pre-wrap;
    align-self: flex-start;
  }
  pre::-webkit-scrollbar {
    display: none;
  }
}

.bottomButton {
  position: relative;
  // margin: 10px 0 20px;
  margin: 10px 0 15px;
  z-index: 100;
  width: 100%;
  // padding: 0 10px;
  padding: 0 15px;
  & :global(.ant-btn) {
    width: 100%;
    border-radius: 20px;
    background: var(--viewBuilderButtonBackground, rgba(244, 245, 246, 1));
    color: var(--viewBuilderButtonTextColor, rgba(18, 25, 43, 0.65));
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;

    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    border: 0.5px solid transparent;
    height: unset;
  }
  & :global(.ant-btn):hover {
    background: var(--viewBuilderButtonHoverBackground, rgba(2, 12, 38, 0.05));
    color: var(--viewBuilderButtonHoverTextColor, rgba(18, 25, 43, 1));
    border: 0.5px solid var(--viewBuilderButtonHoverBorderColor, rgba(18, 25, 43, 0.1));
  }
  & :global(.ant-btn):active,
  & :global(.ant-btn):focus {
    background: var(--viewBuilderButtonClickBackground, rgba(232, 232, 232, 1));
    color: var(--viewBuilderButtonHoverTextColor, rgba(18, 25, 43, 1));
    border: 0.5px solid var(--viewBuilderButtonClickBorderColor, rgba(2, 12, 38, 0.35));
  }
}
