.request-box {
  padding: 10px;
  flex: 1;
  height: 100%;
  overflow: hidden;
}
.request-box :global(.ant-tabs-top) {
  height: calc(100% - 40px);
}
.request-box :global(.ant-tabs-content-holder) {
  overflow: auto;
}
.request-box :global(.ant-tabs-content-holder)::-webkit-scrollbar {
  display: none;
}
.request-box :global(textarea.ant-input) {
  max-height: 100%;
  min-height: 100%;
}
.request-box :global(textarea.ant-input)::-webkit-scrollbar {
  display: none;
}
.send-box {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.error-tips {
  color: #ff4d4f;
}

.disabled-btn :global(.ant-btn) {
  background-color: #0000000a;
  border-color: #0000000a;
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
}

.disabled-btn :global(.ant-btn):hover {
  outline: none;
  background-color: none;
}
