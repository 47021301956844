.content_web {
  height: 40px;
  // min-width: 150px;
  border-radius: 20px;

  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;

  background: #f3f3f4;
  color: #12192b;
  gap: 10px;

  cursor: pointer;
}

.content_mobile {
  height: 40px;
  color: #12192b;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
}

.drop_content {
  width: 160px;
  margin-left: -5px;
  padding: 5px;
  border-radius: 15px;
  background-color: #ffffffa6;
  box-shadow: 0px 30px 60px 0px #7b858e26;
  backdrop-filter: blur(10px);

  .item {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;

    .itemleft {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  .actionItem:hover {
    background-color: #0a9dff1a;
  }
}

.drop_content_mobile {
  background: rgb(87, 91, 104);
  border-radius: 10px;
  overflow: hidden;

  .item {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 0 15px;
    cursor: pointer;
    color: white;
    .itemleft {
      display: none;
    }
  }

  .mobile_hItem {
    background: rgb(54, 62, 79);
  }
}
