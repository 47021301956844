.optionsPane {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}

.title {
  color: #12192b;
  text-align: center;

  font-family: "Ak-Regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.desc {
  align-self: stretch;
  color: #656a75;

  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.subscriptionButtonContainer {
  width: 100%;
}

.bottomToolbar {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.poweredBy {
  width: 110.294px;
  height: 25px;
  flex-shrink: 0;
  border: 1.5px #acaeb5 solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.termsLink {
  color: #0a9dff;
  cursor: pointer;
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.privacyLink {
  color: #0a9dff;
  cursor: pointer;
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.checkSwitch {
  display: flex;
  background: rgba(2, 12, 38, 0.05);
  padding: 10px;
  border-radius: 10px;
}
.checkTip {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #626170;
  margin-left: 10px;
  display: flex;
  label {
    font-size: 13px;
    font-weight: 700;
    margin-right: 6px;
  }
}
