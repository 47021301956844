.cotWrapper {
  background: #eaedf2;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 15vh 2vw 0;
}

.placeholder {
  color: var(--grayscale-gray-40, #b0b0b7);

  /* Body Large */
  font-family: Ak-regular;
  font-size: 16px;
  line-height: 22px; /* 137.5% */
  padding: 10px;
}

.logout {
  color: #12192b;
  font-family: Ak-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  position: absolute;
  top: 5vh;
  right: 5vw;
}

.linkToButton {
  display: flex;
  width: 223px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--primary-primary-50, #0a9dff);
  color: var(--white-white-100, #fff);

  /* H200 */
  font-family: Ak-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.linkToButton img {
  padding: 0 5px;
}

.linkToButtonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.logo {
  position: fixed;
  top: 15px;
  left: 15px;
}
.spinBox {
  display: flex;
  justify-content: center;
}
