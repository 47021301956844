.item {
  border-bottom: 0.5px solid #d9d9d9;
  margin-top: 14px;
  padding-bottom: 14px;
  h3 {
    color: #12192b;
    font-family: Ak-Medium;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  p {
    color: #656a75;
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 0;
  }
}
