@import "../../../../styles/variables.scss";

.cellContent {
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 10px;

  .template__image {
    width: 120px;
    height: 80px;
    background: #ffffff59;
    border-radius: 10px;
    border: 1px;
    margin-right: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .title {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #12192b;
    flex: 0.333;
  }

  .rightIcon {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 60px;
  }
}

.cellSelect {
  box-shadow: $boxShadowHover;
  background: #ffffff59;
  z-index: 15;
}

.cellContent:hover {
  box-shadow: $boxShadowHover;
  background: #ffffff59;
}

.dropDown_content {
  display: flex;
  width: 220px;
  padding: var(--spacing-extra-small, 5px) 0px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 15px;
  background: var(--white-65, rgba(255, 255, 255, 0.65));

  /* Shadow L [Blur] */
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
  cursor: pointer;

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .dropdownItem {
    display: flex;
    padding: 15px var(--spacing-spacing-medium, 20px);
    align-items: center;
    gap: 20px;
    align-self: stretch;

    &__icon {
      display: flex;
    }

    &__text {
      display: flex;

      color: var(--dark-65, rgba(18, 25, 43, 0.65));
      /* Body */
      font-family: Ak-Regular;
      font-size: 15px;

      font-weight: 400;
      line-height: 20px; /* 133.333% */
    }
  }
}
