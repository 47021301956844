.home-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f4f7fb;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}

.home-top {
  padding: 15px 25px;
}

/* .avatar-box {
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.05));
  overflow: hidden;
}
.user-avatar {
  width: 100%;
  height: 100%;
} */
.home-back-img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
.back-img {
  width: 180px;
  height: 180px;
}
.bottom-img-box {
  position: absolute;
  width: 180px;
  height: 180px;
  bottom: -130px;
  z-index: -1;
}
.img-reverse {
  width: 100%;
  transform: rotateX(180deg);
}
.img-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(244, 247, 251, 0.5) 0%, #f4f7fb 55.64%);
}
.top-text {
  position: relative;
  margin-top: 165px;
  text-align: center;
  font-family: "sf-light";
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.364px;
  color: rgba(255, 255, 255, 0);
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/top-text-backimg.png") no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  background-position: -25px 5px;
  background-size: 100% 100%;
}
.top-text span {
  font-family: sf-Regular;
  font-weight: 500;
}
.home-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 15px;
}
.home-bottom :global(.home-input) {
  bottom: 0;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 25px;
}
.bottom-text {
  font-family: "ak-regular";
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #7d8fa1;
  margin: 20px 0;
  text-align: center;
}
.bottom-list {
  position: relative;
  flex: 1;
}
.thought-list {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.thought-list::-webkit-scrollbar {
  display: none;
}
