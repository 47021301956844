.maskBox {
  background: rgba(18, 25, 43, 0.2);
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.myAccount {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
}
.content {
  width: 720px;
  height: 720px;
  border-radius: 20px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(80px);
  position: relative;
}

.sidebar {
  display: flex;
  height: 100%;
}

.sidebarHeader {
  display: flex;
  width: 200px;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #acaeb510;
}
.menuHeaderList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.line {
  background: rgba(18, 25, 43, 0.1);
  padding: 0.5px 0;
  line-height: 1px;
  width: 100%;
  margin-top: 5px;
}
.textItem {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  cursor: pointer;
  color: #12192b;
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  transition: 0.3s;
}
.textItem:hover {
  background: rgba(10, 157, 255, 0.1);
}
.selected {
  font-weight: 500;
  background: rgba(10, 157, 255, 0.1);
}

.sidebarMenu {
  background: #fff;
  flex: 1;
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.topBox {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.titleBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.titleBox .title {
  color: #12192b;
  font-family: "Ak-Regular";
  font-size: 17px;
  font-weight: 500;
  line-height: 150%;
}
.titleBox .subTitle {
  color: rgba(18, 25, 43, 0.65);
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
}
.topBox img {
  position: relative;
  right: -10px;
  cursor: pointer;
}

.assemblyContent {
  height: 100%;
  width: 100%;
}
.alert {
  //background-color: red;
  min-width: 300px;
  min-height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
