.imgBox {
  border-radius: 20px;
  overflow: hidden;
  border: 0.5px solid var(--Dark-10, rgba(18, 25, 43, 0.1));
  img {
    object-fit: cover;
    // 抗img锯齿和模糊
    image-rendering: -webkit-optimize-contrast;
  }
}

.c1Img,
.generatedImg {
  width: 100%;
  height: 225px;
  left: 0;
  top: 0;
  img {
    width: 100%;
    height: 100%;
  }
  .errorImg img {
    transform: translateY(-100%);
  }
}
.c1Img.shrinked {
  position: absolute;
}
.c2CarouselErrorImg {
  height: 100%;
}
.c3Img {
  width: 50%;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.c3Img.expanded {
  max-height: 225px;
}
.c4Img {
  width: 20%;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.c4Img.expanded {
  max-height: 225px;
}
.profileCardImg {
  width: 80px;
  padding: 10px 0;
  border: none;
  img {
    width: 100%;
    height: 80px;
    border-radius: 50%;
  }
}
.topicWithRelatedPeoplePersonInfoImg {
  border: none;
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 24px;
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 0.6;
  }
}
.topicWithRelatedPeopleSignificanceImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 44px;
  min-height: 250px;
  .errorImg {
    aspect-ratio: 0.6;
  }
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 0.6;
  }
}
.errorImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 157, 255, 0.35);
  .errorImg {
    aspect-ratio: 0.6;
  }
  img {
    width: 30px;
    height: 30px;
  }
}
