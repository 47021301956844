$padWidth: 580px;

.chatInput {
  position: relative;
  width: 100%;
  margin: 40px 0 0;
  transition: background 0.1s, border-radius 0.1s, bottom 0.1s;
  label {
    display: inline-block;
  }
  label,
  .labelInput {
    font-family: var(--fontFamily, "ak-regular");
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: var(--shareTitleColor, "#12192b");
    padding: 0 0 10px;
    min-height: 25px;
  }
  .labelInput {
    background: transparent;
    outline: none;
    border: none;
  }
  .inputBar {
    color: var(--inputColor, #12192b);
    border: none;
    transition: all 0.25s;
    padding: var(--inputPadding, 4px 11px);
    border-radius: var(--inputBorderRadius, 0);
    background: var(--inputBackground, transparent);
    border: var(--inputBorder);
    border-bottom: var(--inputBorderBottom);
    font-size: 20px;
    // border-left: 5px solid var(--inputBarBorderColor,'#12192B1A');
  }

  .inputBar::-webkit-scrollbar {
    width: 5px;
    background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
  }
  .inputBar::-webkit-scrollbar-thumb {
    -webkit-box-shadow: node; /* 滚动条轨道阴影 */
    border-radius: 10px;
    background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
  }
  .hightLightBorderLeft {
    // border-left: var(--hightLightBorderLeft, 5px solid #0A9DFF);
  }
  .hideBorderLeft {
    border-left: 0px solid transparent;
    padding-left: var(--inputPaddingLeft, 0px);
    caret-color: #0a9dff;
  }
  input {
    color: var(--shareTitleColor, "#12192b");
    font-family: var(--fontFamily, "Ak-Regular");
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  input::-webkit-input-placeholder {
    font-family: var(--fontFamily, "ak-regular");
    color: var(--inputPlaceholderColor, "rgba(18, 25, 43, 0.35)");
  }
  .leftLine {
    width: var(--inputLeftLineWidth, 0);
    height: auto;
    border-radius: var(--inputLeftLineBorderRadius, 0);
    background: var(--inputLeftLineBackground, "none");
  }
  .pressEnter {
    font-family: var(--fontFamily, "ak-regular");
    font-size: 16px;
    line-height: 25px;
    text-align: right;
    color: #12192b59;
    text-align: left;
    margin-top: 15px;
    span {
      padding: 0 10px;
      border-radius: 5px;
      background: #12192b0d;
      color: #12192ba6;
    }
  }
}
.chatStickyBottom {
  position: sticky;
  bottom: 20px;
  border-radius: 25px;
  background: var(--uploadLoadingWrapBackground, "#fff");
  padding: 20px;
  backdrop-filter: blur(60px);
  width: calc(100% + 30px);
  margin-left: -15px;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.15);
}
// .canvasChatInput.chatStickyBottom {
//   width: calc(100% + 70px);
//   margin-left: -35px;
// }
@mixin mobileStyle {
  label {
    font-size: 20px;
    line-height: 20px;
  }
  input {
    font-size: 20px;
    line-height: 20px;
  }
}

.canvasChatInput.chatInput {
  @include mobileStyle;
}

@media only screen and (max-width: $padWidth) {
  .chatInput {
    @include mobileStyle;
  }
}

.priceText {
  margin-top: 10px;
}
