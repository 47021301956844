.Feed-Top-Skeleton {
  padding: 20px;
  margin: 0 25px;
}
.Feed-Top-Skeleton :global(.ant-skeleton-button) {
  height: 20px;
}
.Feed-Top-Skeleton .Feed-Top-Third {
  width: 35%;
}
.Feed-Top-Box {
  display: flex;
  margin-bottom: 10px;
}
.Feed-Top-Box .Feed-Top-First {
  width: 52%;
  margin-right: 10px;
}
.Feed-Top-Box .Feed-Top-Second,
.Feed-Top-Skeleton .Feed-Top-Third {
  width: 35%;
}
.Feed-Content-Skeleton:global(.ant-skeleton) {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px;
  margin: 0 25px 10px;
  width: calc(100% - 50px);
}
.Feed-Content-Skeleton :global(.ant-skeleton-title) {
  height: 20px;
}
.Feed-Content-Skeleton :global(.ant-skeleton-content .ant-skeleton-paragraph) {
  height: 10px;
  margin-top: 10px;
}
