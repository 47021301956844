@import "../AISaas/AISaasLayout/vars.scss";

:global(.react-flow__panel).callerBotPane {
  top: 95px !important;
  left: calc((100% - 910px) / 2) !important;
  right: calc((100% - 910px) / 2) !important;
  z-index: 5;
  background-color: rgba(234, 236, 240, 0.1);
  display: flex;
  justify-content: center;
  position: absolute;
  width: 910px;
  height: calc(100% - 135px);
  transition: width 0.1s;

  &.padding20 {
    width: calc(100% - 560px);
    left: 20px !important;
  }
  &.width500 {
    width: 500px !important;
  }
  &.left0 {
    left: 20px !important;
  }
}

:global(.react-flow__panel).callerBotPreviewPane {
  top: 95px !important;
  right: 0 !important;
  z-index: 5;
  background-color: rgba(234, 236, 240, 0.1);
  display: flex;
  justify-content: center;
  position: absolute;
  width: 540px;
  height: calc(100% - 115px);
}

:global(.react-flow__panel).callerBotPane.silder {
  width: $panel-wrap-width;
}

:global(.react-flow__panel).coverSettingPane {
  width: 800px;
}

@media only screen and (max-width: 767px) {
  :global(.react-flow__panel).nodePreviewPane {
    width: 100%;
  }
}

.callerBotWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.1s, height 0.1s;
  &.width950 {
    width: 950px;
    height: calc(100% - 25px);
  }
}

.makeItRealLoadingPanel {
  position: fixed;
  border-radius: var(--Radius-large, 25px);
  border: var(--Stroke-Card, 0.5px) solid var(--Light-65, rgba(255, 255, 255, 0.65));
  background: var(--Light-65, rgba(255, 255, 255, 0.65));
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(60px);
  width: 560px;
  height: 224px;
  flex-shrink: 0;
  z-index: 10000;
  top: calc(50% - 112px);
  left: calc(50% - 280px);
  overflow: hidden;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(142, 239, 234, 0.35) 0%,
      rgba(91, 200, 236, 0.35) 25%,
      rgba(160, 147, 244, 0.35) 50%,
      rgba(242, 134, 209, 0.35) 75%,
      rgba(250, 145, 145, 0.35) 100%
    );
    filter: blur(80px);
    position: absolute;
    left: 0;
    top: 0;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.65) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    left: 0;
    top: 0;
  }

  .buildingYourApp {
    background: linear-gradient(90deg, #00a0df 0%, #3771fb 50%, #904bfb 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    /* Subheader */
    font-family: Ak-regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 25.5px */
  }
}

.loadingInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.imagica {
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 110px;
  box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  box-sizing: content-box;
  mix-blend-mode: screen;
  background: linear-gradient(214deg, rgb(172 124 211 / 50%) 19.94%, rgba(255, 255, 255, 0) 72.15%);
  mix-blend-mode: screen;
  z-index: 10;
  position: relative;
  padding: 10px;
}

.imagicaWrapper {
  padding: 10px;
  box-sizing: content-box;
  background: linear-gradient(214deg, rgb(225 204 240 / 50%) 19.94%, rgba(255, 255, 255, 0) 72.15%);
  position: relative;
  z-index: 1;
  border-radius: 110px;
}
