.mapBoxInner {
  padding: 20px;
  width: 100%;
  min-width: 215px;
  height: 290px;
  // min-height: 290px;
  // height: inherit;
  // position: absolute;
  // top: 0;
  // left: 0;
}
.inner {
  width: 100%;
  height: 100%;
}
.mapBoxInner :global(.mapboxgl-control-container) {
  display: none;
}
.mapBoxInner :global(.mapboxgl-canvas-container) {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.imgMapbox {
  width: 18px;
  height: 22px;
}
