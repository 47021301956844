.chargeYourCustomers {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 20px;
  background: #fff;
  margin-bottom: 17px;
}

.sectionTitle {
  color: #acaeb5;
  text-align: center;
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.loading {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
