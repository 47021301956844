.promptBar {
  height: 25px;
  position: relative;
}

.promptBar > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  padding-left: 5%;
  align-items: center;
  border-radius: 0px 15px 15px 0px;
  border: 0.5px solid var(--White-65, rgba(255, 255, 255, 0.65));
  background: rgba(255, 187, 84, 1);

  /* Shadow M [Blur] */
  box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  backdrop-filter: blur(20px);
  cursor: pointer;

  color: #fff;
  font-family: Ak-Regular;
  font-size: 10px;

  font-weight: 500;
  line-height: 20px; /* 200% */
  line-height: 25px;
  text-align: center;
}
