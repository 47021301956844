.Retry {
  margin: 25px;
  height: calc(100% - 120px);
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 15px;
}
.Retry .Right-Btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}
.Retry .Right-Btns :global(.ant-spin) {
  color: #fff;
}
.Retry .Post-Btn {
  min-width: 100px;
  max-height: 40px;
  background: #5182ff;
  border-radius: 25px;
  padding: 10px 20px 10px 15px;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.Retry .Post-Btn:first-child {
  margin-left: 0;
}
.Retry .Post-Btn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Retry :global(.ant-result-success .ant-result-icon > .anticon) {
  color: #5182ff;
}
.Retry span svg {
  color: #5182ff;
}
