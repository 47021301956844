.sign-in-box {
  height: var(--appSignUpHeight, 50px);
  padding: var(--appSignUpPadding, 10px 25px);
  border-radius: var(--appSignUpBorderRadius, 50px);
  background: var(--appSignUpBackgroundColor, #12192b);
  color: var(--appSignUpTextColor, #fff);

  font-family: var(--appSignUpFontFamily, "Ak-Regular");
  font-weight: var(--appSignUpFontWeight, 500);
  font-size: var(--appSignUpFontSize, 20px);
  line-height: var(--appSignUpLineHeight, 30px);

  // position: absolute;
  // top: var(--appSignUpPositionTop, -33px);
  // right: var(--appSignUpPositionRight, -43px);
  width: max-content;

  cursor: pointer;
}
