.ai-saas {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  margin: 0 auto;
  position: relative;
  align-items: center;
}
.saas-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  // user-select: none;
  /* overflow: visible; */
}

.saas-login {
  width: 53.33px;
  height: 40px;
  position: absolute;
  top: 35px;
  left: 35px;
}

.saas-content::-webkit-scrollbar {
  display: none;
}

.moddle-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.moddle-box.top-margin {
  margin-top: 31px;
}
.moddle-box-bgColor {
  background: #fff;
}
.add-input {
  border-radius: 2px;
  border: none;
  font-size: 16px;
  padding: 13px 10px;
  box-shadow: 0px 0px 0px 1px #0000001f;
  width: calc(100% - 62px);
  box-sizing: revert;
  margin: 20px 21px 10px;
  font-family: var(--fontFamily, "Ak-Regular");
}
.moddle-box .moddle-box-bgColor :global(.ant-input-clear-icon) {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}

.moddle-box :global(.CardFrame) {
  position: relative;
}

.max-width-box {
  max-width: 1000px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.output-list {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  flex: 1;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  min-height: 100px;
  position: absolute;
  transition: 0.3s;
}
.output-list::-webkit-scrollbar {
  display: none;
}
.output-list :global(.Editor-UI) {
  z-index: 1;
}
.start-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 46px;
  cursor: pointer;
}
.get-started {
  width: 215px;
  height: 54px;
  font-size: 20px;
  background: #fff;
  line-height: 54px;
  border-radius: 30px;
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, 0.6));
  display: flex;
  justify-content: center;
  align-items: center;
}
.get-started .iconfont-start {
  font-size: 22px;
  font-weight: 900;
  margin-left: 10px;
}

.edit-outline-icon {
  cursor: pointer;
}
.edit-placeholder {
  position: absolute;
  width: 16px;
  right: -30px;
  top: 20px;
  color: var(--saasTitleColor, "#12192B");
}

.footer-brain-studios {
  width: 100%;
  font-family: var(--fontFamily, "Ak-Regular");

  font-size: 20px;
  height: 50px;
  line-height: 40px;
  text-align: center;
  color: var(--footerBrainStudiosColor, "rgba(18, 25, 43, 0.65)");
}
.bottom-prompt {
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer-brain-studios span {
  text-decoration: underline;
  cursor: pointer;
}

.output-top {
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  top: 30px;
  z-index: 100;
  width: 750px;
  height: 90px;
  padding-bottom: 40px;
}
.output-index {
  z-index: 10;
}
.saas-home-bg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--homeIconBgcolor, "#fff");
  backdrop-filter: var(--homeInconFilter, "none");
  cursor: pointer;
  border: 0.5px solid var(--inputBoxBgc, "#fff");
  box-shadow: 0px 10px 30px rgba(123, 133, 142, 0.15);
  margin-left: 20px;
}
.saas-home-bg img {
  width: 100%;
  height: 100%;
  padding: 15px;
}
.number-bg,
.arrow-bg {
  /* position: absolute;
  top: 0;
  right: 0; */
  border: 0.5px solid var(--inputBoxBgc, "#fff");
  box-shadow: 0px 10px 30px rgba(123, 133, 142, 0.15);
}
.number-bg span {
  display: inline-block;
  width: 50px;
  line-height: 50px;
  color: var(--rightTopNumberBg, "#12192B");
  font-size: 20px;
  font-family: var(--fontFamily, "Ak-Regular");
  text-align: center;
}
.arrow-bg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.saas-input-content {
  position: initial;
  z-index: 2;
  margin-top: 20px;
  width: 750px;
}
.saas-input-content.top-margin {
  margin-top: -50px;
  top: 30px;
}
.input-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 30px 80px 30px 20px;
  width: 100%;
  background: var(--inputBoxBgc, "#fff");
  border: 0.5px solid var(--inputBoxBgc, "#fff");
  box-shadow: 0px 10px 30px rgba(123, 133, 142, 0.15);
  backdrop-filter: var(--nodeBackdropFilter, none);
  border-radius: 30px;
  transition: 0.3s;
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

/* 缩小 input */
.top-suction.input-box {
  /* padding: 5px 80px 5px 10px; */
  background: var(--topSuctionBgc, "rgba(255, 255, 255, 0.65)");
  border: var(--inputBorder, 0.5px solid var(--topSuctionBorder, "rgba(255, 255, 255, 0.65)"));
  border-bottom: var(--inputBorderBottom, 0.5px solid var(--topSuctionBorder, "rgba(255, 255, 255, 0.65)"));
  // padding: var(--inputPadding, 30px 80px 30px 20px);
  padding: var(--inputPadding, 20px 20px 20px 20px);
  box-shadow: var(--inputBoxShadow, 0px 4px 24px rgba(0, 0, 0, 0.13));
  border-radius: var(--inputBorderRadius, 30px);
  min-height: 50px;
  height: auto;
  display: flex;
  align-items: center;
  transition: 0.3s;
  backdrop-filter: var(--nodeBackdropFilter, none);
  margin: 0 auto !important;
  width: 100%;
}
.top-suction.input-box-default-padding {
  padding: 5px 80px 5px 10px;
}
.top-suction.input-box-reset-padding {
  padding: 5px 80px 5px 22px;
}
.display-box {
  opacity: 1;
}
.hide-box {
  opacity: 0;
}
// .top-suction .saas-input {
//   height: 30px !important;
//   display: inline-block;
//   white-space: nowrap;
//   overflow: hidden !important;
//   text-overflow:ellipsis;
// }

.input-title-border {
  border: 1px dashed #0a9dff;
  background: rgba(10, 157, 255, 0.1);
}
.input-title-piece {
  left: 0;
  top: 0;
  padding: 4px;
  width: 100%;
  height: 100%;
  position: absolute;

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--inputTitleColor, "#020C26");
  font-size: 20px;
  user-select: text;
  line-height: 29px !important;
}

.input-title {
  min-height: 30px;
  height: 60px;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--saasTitleColor, "#12192B");
  transition: 0.3s !important;
}

.input-title-textArea-display-zIndex {
  z-index: 5;
}

.input-title::-webkit-scrollbar {
  display: none;
}

.saas-input {
  min-height: 30px;
  height: auto;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--saasTitleColor, "#12192B");
  transition: 0.3s !important;
}
.saas-input:disabled {
  color: var(--saasTitleDisableColor, "#000");
}
.roll-box {
  position: absolute;
  width: 10px;
  height: 0;
  background: rgba(2, 12, 38, 0.1);
  border-radius: 5px;
  top: 100px;
  bottom: 100px;
}
.scroll-box {
  position: absolute;
  width: 10px;
  height: 0;
  left: 0px;
  top: 0;
  background: #ffffff;
  border-radius: 5px;
  transition: 0.3s;
}
.saas-input::-webkit-scrollbar {
  display: none;
}
/* :global(.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon) {
  font-size: 25px;
  right: 6px;
} */

.top-suction .close-bottom {
  top: 10px;
  right: 10px;
}

.other-operate-box {
  position: absolute;
  top: 35px;
  right: 50px;
  transition: 0.3s;
}

.field-name-box {
  width: auto;
  height: auto;
  background-color: aqua;
}

.field-name-text {
  color: #000;
  font-size: 16px;
  font-family: var(--fontFamily, "Ak-Regular");

  line-height: 25px;
}
.field-name-textarea {
  background-color: yellow;
}

.remove-suspension {
  position: initial;
}

.submit-button {
  background: var(--submitButtonBgClr, "#12192B");
  border-radius: 25px;
  height: 50px;
  padding: 5px 30px;
  margin: 47px auto 0;
  width: 180px;

  color: var(--submitButtonColor, "#fff");
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.cannot-select {
  transition: 0.3s;
  background: #d9d9d9;
  color: #00000040;
}

.footer-brain-claimer {
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--footerBrainStudiosColor, "rgba(18, 25, 43, 0.65)");
  text-align: left;
  width: 100%;
  padding: 0 25px 20px 25px;
}

.transparent-storey {
  padding-right: 0;
  .upload-file-box {
    padding: 20px 10px;
  }
}
.convention-padding {
  padding: 20px 10px !important;
}

.saas-placeholder {
  width: 100%;
}

/* 适配间距优化 1920px 1680px 1440px 1280px 1024px 800px 568px */
@media only screen and (min-width: 1921px) {
  .output-list {
    max-width: 830px;
  }
  .input-box {
    max-width: 830px;
  }
}
@media only screen and (min-width: 1680px) and (max-width: 1920px) {
  .output-list {
    max-width: 830px;
  }
  .input-box {
    max-width: 830px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .output-list {
    max-width: 726px;
  }
  .saas-input-content {
    /* width: 666px; */
    width: 646px;
  }
  .output-top {
    width: 646px;
  }
  .reduce-width {
    width: 676px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .output-list {
    max-width: 630px;
  }
  .saas-input-content {
    /* width: 580px; */
    width: 550px;
  }
  .output-top {
    width: 550px;
  }
  .reduce-width {
    width: 580px;
  }
  /* .saas-home-bg {
    margin-right: -10px;
  } */
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .output-list {
    max-width: 630px;
  }
  .saas-input-content {
    /* width: 580px; */
    width: 550px;
  }
  .output-top {
    width: 550px;
  }
  .reduce-width {
    width: 580px;
  }
  /* .saas-home-bg {
    margin-right: -10px;
  } */
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .output-list {
    max-width: 530px;
  }
  .saas-input-content {
    width: 450px;
    /* width: 470px; */
  }
  .output-top {
    width: 450px;
  }
  .reduce-width {
    width: 480px;
  }
  /* .saas-home-bg {
    margin-right: -10px;
  } */
}
@media only screen and (min-width: 568px) and (max-width: 799px) {
  .output-list {
    max-width: 530px;
  }
  .saas-input-content {
    width: 470px;
    max-width: 450px;
  }
  .output-top {
    max-width: 450px;
  }
  .reduce-width {
    width: 480px;
  }
  /* .saas-home-bg {
    margin-right: -10px;
  } */
}
@media only screen and (max-width: 567px) {
  .output-list {
    max-width: 100%;
  }
  .saas-input-content {
    margin-top: 20px;
  }
  .saas-input-content {
    width: 90%;
  }
  .output-top {
    width: calc(100% - 80px);
  }
  .reduce-width {
    width: 100%;
  }
  .submit-button {
    width: 100%;
  }
}
.show-output {
  margin-top: 40px;
}
.show-output .submit-button {
  margin-top: 20px;
  margin-bottom: 20px;
}

:global(.project-app) .saas-login {
  top: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
:global(.project-app) .input-box {
  padding: 20px 80px 20px 10px;
  /* width: 95%; */
  margin: 0 auto 20px;
}
:global(.project-app) .upload-file-box {
  padding: 0;
}
:global(.project-app) .saas-input {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}
:global(.project-app) .enter-bottom {
  right: 15px;
  top: 16px;
}
:global(.project-app) .moddle-box,
:global(.project-app) .max-width-box,
:global(.project-app) .output-list {
  height: 100%;
  width: 100%;
}
:global(.project-app) .moddle-box {
  margin-top: 0;
}
:global(.project-app) .output-top {
  width: calc(100% - 80px);
  z-index: 100;
}
:global(.project-app) .saas-input-content.show-output {
  margin: 30px auto 0;
  top: 100px;
}
:global(.project-app) .saas-input-content.top-margin {
  margin: -50px auto 0px;
  top: 30px;
}
:global(.project-app) .saas-input-content {
  margin-top: 20px;
  width: 83%;
}
:global(.project-app) .reduce-width {
  width: 100%;
}

:global(.project-app) .bottom-prompt {
  position: static;
}
:global(.project-app) .saas-login {
  display: none;
}
:global(.project-app) .top-suction.input-box {
  width: 100%;
  max-width: 480px;
  padding: 8px 70px 8px 10px;
}
:global(.project-app) .submit-button {
  width: 100%;
  margin-top: 20px;
}
