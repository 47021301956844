.custom-node-content {
  position: relative;
  /* height: 100%; */
  width: 100%;
  /* filter: drop-shadow(0px 20px 80px rgb(93 122 152 / 20%)); */

  border-radius: 25px;
  transition: box-shadow 0.3s, background-color 1s;
}

.custom-node-content:hover {
  box-shadow: 0 5px 40px 0 rgba(113, 144, 175, 0.503);
  background-color: #fff;
}

.custom_node_toolBar_expander {
  height: 60px;
  width: 100%;
  position: absolute;
  top: -60px;

  .toolBar_expander_content {
    height: 60px;
    width: 100%;
    position: absolute;
    top: 60px;
  }
}

.content-hover-expander {
  display: none;
  position: absolute;
  width: 100%;
  height: 60px;
  top: -60px;
  opacity: 0;
}

.custom-node-content:hover .content-hover-expander {
  display: block;
}

.custom-node-content :global(.react-flow__resize-control.line) {
  z-index: 5;
}
.custom-node-content :global(.react-flow__resize-control.line.top.custom-noderesizer-line) {
  border-top-width: 5px;
}
.custom-node-content :global(.react-flow__resize-control.line.right.custom-noderesizer-line) {
  border-right-width: 5px;
}
.custom-node-content :global(.react-flow__resize-control.line.bottom.custom-noderesizer-line) {
  border-bottom-width: 5px;
}
.custom-node-content :global(.react-flow__resize-control.line.left.custom-noderesizer-line) {
  border-left-width: 5px;
}
.custom-node-content :global(.react-flow__resize-control.handle.custom-noderesizer-handle) {
  z-index: 5;
  width: 16px;
  height: 16px;
  border: none;
}

.shares-buy {
  min-width: 200px;
  max-width: 400px;
  min-height: 300px;
  max-height: 500px;
  position: relative;
}
/* .custom-node-content:hover {
  outline: 3px solid rgb(98 153 249);
  box-shadow: -100px -100px 0 -95px rgb(98 153 249),
    100px -100px 0 -95px rgb(98 153 249), 100px 100px 0 -95px rgb(98 153 249),
    -100px 100px 0 -95px rgb(98 153 249);
} */
/* :global(.react-flow__node.selected) .custom-node-content */
/* .custom-node-content.check-node {
  outline: 3px solid #e84e4e;
  box-shadow: -100px -100px 0 -95px #e84e4e, 100px -100px 0 -95px #e84e4e,
    100px 100px 0 -95px #e84e4e, -100px 100px 0 -95px #e84e4e;
} */
.selected-highlight-border {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: 3px solid rgb(98 153 249);
  /* STUD-1429 */
  z-index: -10;
}
.selected-highlight-border-text {
  outline: 1px solid rgb(98 153 249);
  /* STUD-1429 */
  z-index: -10;
}
.selected-highlight-border span {
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid rgb(98 153 249);
  background: #fff;
  border-radius: 4px;
  z-index: 2;
}
.top-left-block {
  left: -8px;
  top: -8px;
}
.top-right-block {
  right: -8px;
  top: -8px;
}
.bottom-right-block {
  right: -8px;
  bottom: -8px;
}
.bottom-left-block {
  left: -8px;
  bottom: -8px;
}

.custom-node-toolbar {
  display: none;
}

.custom-handle-box {
  position: absolute;
  z-index: 5;
}
.custom-handle-box-top {
  width: 100%;
  height: 30px;
  top: -15px;
}
.custom-handle-box-right {
  width: 38px;
  height: 38px;
  /* right: -23px; */
  right: -17px;
  top: calc(50% - 19px);
}
.custom-handle-box-right-child {
  pointer-events: none;
}
.custom-handle-box-bottom {
  width: 100%;
  height: 30px;
  bottom: -15px;
}
.custom-handle-box-left {
  width: 30px;
  height: 100%;
  left: -15px;
  top: 0;
}
.custom-handle-box-side-target {
  /* height: calc(100% + 50px); 这里屏蔽是因为高度多了会导致handle的加号以及连接点下移，需要考虑新的方式 */
  height: 100%;
}
.custom-handle-box-side-target-right {
  height: 38px;
}
.hide-default {
  display: none;
}
.custom-handle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  border: 0;
  opacity: 0;
}
.custom-handle-top {
  transform: none;
}
.custom-handle-right {
  transform: none;
  /* to override react-flow default behavior */
  cursor: pointer !important;
}
.custom-handle-bottom {
  transform: none;
}
.custom-handle-left {
  transform: none;
}
.custom-handle-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(153 153 153 / 10%);
  opacity: 1;
}
.custom-handle-drop span {
  font-size: 12px;
  color: #555;
}
.custom-handle-right span {
  transform: rotate(90deg);
  white-space: nowrap;
}
.custom-handle-left span {
  transform: rotate(-90deg);
  white-space: nowrap;
}
.default-bot-box {
  position: absolute;
  width: 15px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.custom-handle-box-top .default-bot-box {
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.custom-handle-box-right .default-bot-box {
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-handle-box-bottom .default-bot-box {
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.custom-handle-box-left .default-bot-box {
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}

.edge-add-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 2px 15px rgb(93 122 152 / 20%));
  border-radius: 50%;
  /* width: 30px; */
  width: 24px;
  /* height: 30px; */
  height: 24px;
  font-weight: bold;
  background-color: #ffffff;
  color: #12192b;
  cursor: pointer;
  transform: scale(0);
  transition: all 0.25s;
  z-index: 1;
}

.edge-add-icon path {
  transition: all 0.25s;
}

.edge-add-icon:hover {
  background-color: #1f9ffc;
  color: #fff;
}
.edge-add-icon:hover path {
  stroke: white;
}

.custom-handle-box:hover .edge-add-icon {
  background-color: #1f9ffc;
  color: #fff;
}

.custom-handle-box:hover .edge-add-icon path {
  stroke: white;
}

.add-edge-back-view {
  /* 之前写的是30px，但是左边会有少量空白，能透出后面的色彩，造成有一条线的错觉，经调试，发现是29.8px及以下,就不会出现这条线 */
  width: 29.8px;
  height: 112px;
  background-color: transparent;
  position: absolute;
  top: calc(50% - 56px);
  right: 0px;
  /* transform: translateX(0px); */
  transform: translateX(0px) scale(0.6);
  transition: all 0.5s;
}

.focused-display .add-edge-back-view,
.custom-node-content:hover .add-edge-back-view {
  /* transform: translateX(29.8px); */
  transform: translateX(21.5px) scale(0.6);
  filter: drop-shadow(0 5px 40px rgba(113, 144, 175, 0.503));
}
.guide-disable .add-edge-back-view {
  /* transform: translateX(0px); */
  transform: translateX(0px) scale(0.6);
}

.focused-display .edge-add-icon,
.default-bot-box:hover .edge-add-icon,
.custom-node-content:hover .directionRight .edge-add-icon {
  transform: scale(1);
}
.custom-handle-box-top .edge-add-icon {
  bottom: 30px;
}
.custom-handle-box-right .edge-add-icon {
  left: -8px;
}
.custom-handle-box-bottom .edge-add-icon {
  top: 30px;
}
.custom-handle-box-left .edge-add-icon {
  right: 30px;
}
.preview-box {
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: transparent;
  display: none;
  position: relative;
}
.custom-handle-box-right:hover .preview-box {
  opacity: 0.5;
  display: flex;
}
.custom-handle-box-top .preview-box {
  top: -77px;
}
.custom-handle-box-right .preview-box {
  left: 80px;
}
.custom-handle-box-bottom .preview-box {
  bottom: -77px;
}
.custom-handle-box-left .preview-box {
  right: 85px;
}

.line-input {
  width: 180px;
  left: 0;
  position: relative;
  background: transparent;
  color: #86909c;
  font-size: 14px;
  line-height: 1.5715;
}
.line-input-bottom,
.line-input-top {
  padding: 4px 11px;
}
.line-input-left {
  padding: 4px 40px 4px 11px;
}
.line-input-right {
  padding: 4px 11px 4px 40px;
}

.delete-box {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.run-all-button {
  font-size: 12px;
  padding: 0 2px;
  float: right;
  margin: 2px 2px 2px 0;
  display: none;
}
.show-return-all {
  display: block;
}
.code-box {
  display: flex;
  flex-direction: column;
  min-height: 190px;
  max-height: 380px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #f8f8f8;
  margin: 10px;
}
.code-parser {
  flex: 1;
  overflow: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.code-parser :global(pre) {
  overflow: hidden;
}
.code-box footer {
  margin-top: 10px;
}
:global(.highlight) {
  outline: 3px solid rgb(98 153 249);
  box-shadow: -100px -100px 0 -95px rgb(98 153 249), 100px -100px 0 -95px rgb(98 153 249),
    100px 100px 0 -95px rgb(98 153 249), -100px 100px 0 -95px rgb(98 153 249);
}
:global(.lowBright) {
  opacity: 0.5;
}

.custom-handle-box :global(.lft-hover-list) {
  position: absolute;
  width: 190px;
  text-align: right;
}
.custom-handle-box :global(.lft-hover-label) {
  background: #000;
  border-radius: 25px;
  color: #fff;
  padding: 8px 10px;
  margin-bottom: 5px;
  display: inline-block;
  text-align: left;
  cursor: pointer;
}
.custom-handle-box-top :global(.lft-hover-list) {
  left: -160px;
  bottom: 55px;
}
.custom-handle-box-right :global(.lft-hover-list) {
  right: -160px;
  top: 55px;
}
.custom-handle-box-bottom :global(.lft-hover-list) {
  left: -160px;
  top: 55px;
}
.custom-handle-box-left :global(.lft-hover-list) {
  left: -160px;
  top: 55px;
}

.design-text {
  width: 100%;
  height: 100%;
  min-width: 285px;
  min-height: 250px;
  background: #fbfbfb;
  border: 0.5px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 20px 80px rgba(93, 122, 152, 0.2);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.design-text .input-mask {
  height: calc(100% - 22px);
  margin: 11px 15px;
}
.text-title {
  font-weight: 400;
  font-size: 13px;
  color: rgba(2, 12, 38, 0.35);
  margin: 10px 5px;
  padding: 0 10px 0 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copy-content img,
.delete-content img {
  width: 100%;
  height: 100%;
}
.text-content {
  min-width: 275px;
  min-height: 205px;
  background: #ffffff;
  border-radius: 10px;
  height: calc(100% - 50px);
  margin: 5px;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-wrap;
  position: inherit;
}
.copy-content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  padding: 13px;
  z-index: 12;
}
.text-content textarea {
  padding: 0;
  background-color: #fff;
}
.text-textArea {
  height: 100%;
  padding: 11px 15px;
  min-height: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.max-textarea-height {
  max-height: 300px;
}
.custom-api {
  background-color: #fff;
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  overflow: auto;
  border-radius: 5px;
  white-space: pre;
}
.customnode-chatbox {
  height: 100%;
}
.text-textArea::-webkit-scrollbar,
.text-content::-webkit-scrollbar,
.custom-api::-webkit-scrollbar,
.input-mask::-webkit-scrollbar {
  display: none;
}

.guide-disable .default-bot-box:hover .edge-add-icon {
  transform: scale(0);
}
.guide-disable .custom-handle-box-right .default-bot-box .guide-show-addicon {
  transform: scale(1);
  background-color: #6299f9;
  color: #fff;
}

.add-to-app-btn {
  padding: 0px 10px !important;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  background: #0a9dff;
  width: calc(100% - 20px);
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  box-shadow: 0px 0px 4px 2px rgba(10, 157, 255, 0.15);
}
.add-to-app-btn:hover,
.add-to-app-btn:focus {
  color: #fff;
  background-color: #0495f5;
}
.node-dom-ref-box {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.ash-shedding::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  z-index: 1000;
  opacity: 0.2;
  cursor: not-allowed;
  border-radius: 30px;
}
.custom-node-warning {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.groupCollapse {
  position: absolute;
  height: 20px;
  width: 100%;
  z-index: -11;
}
.groupCollapseInnerOne {
  position: absolute;
  width: calc(100% - 20px);
  height: 50px;
  bottom: 10px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  background: #fcfcfc;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.groupCollapseInnerTwo {
  position: absolute;
  width: calc(100% - 50px);
  height: 50px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  background: #f8f8f8;
  z-index: 0;
}
:global(.react-flow__node-customNode):hover {
  z-index: 20001 !important; /* edge的z-index == 20000, hover时应该z-index最大 */
}

.tooltipRejectTip :global(.ant-popover) {
  left: -4% !important;
}
