.gridItemCell {
  width: 45vw;
  background: #ffffff59;
  border: 1px solid #ffffff59;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 3vw;
  margin-bottom: 10px;
  position: relative;
  // user-select: none;
  touch-action: pan-y;
  .img {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 76.12%;
    background: rgba(255, 255, 255, 0.65);
    border-radius: 10px;
    user-select: none;
    & > * {
      width: 100%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      user-select: none;
    }

    .imgLayer {
      position: absolute;
      width: 100%;
      height: 100%;
      user-select: none;
    }
  }

  .des {
    width: 100%;
    margin-top: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      flex: 1;
      margin-left: 10px;
      font-family: "Ak-Regular";
      font-size: 15px;
      line-height: 20px;
      text-align: left;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      user-select: none;
    }

    .more {
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.upIndex {
  z-index: 12;
}

downIndex {
  z-index: 8;
}
