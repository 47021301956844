.ugc-thought {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ugc-thought :global(.virtuoso-box) {
  padding: 0 25px;
}
.ugc-thought :global(.virtuoso-box::-webkit-scrollbar) {
  display: none;
}
.ugc-thought :global(.virtuoso-box > div) {
  width: calc(100% - 50px) !important;
}
.save-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.save-box span {
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  color: #5182ff;
  cursor: pointer;
}
.save-box img {
  width: 17px;
  cursor: pointer;
}
