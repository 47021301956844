:global(.home-navbar-menu) {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
}

:global(.ant-menu-horizontal > .ant-menu-submenu.home-navbar-user-avatar) {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* .navber-imagica :global(.ant-menu-horizontal > .ant-menu-submenu.home-navbar-user-avatar) {
  /* 比 disabled-overlay 层级高 */
/* z-index: 11;
}  */

:global(.ant-menu-horizontal > .ant-menu-item.save-controls) {
  position: absolute;
  right: 340px;
  top: 50%;
  transform: translateY(-50%);
}

:global(.ant-menu-horizontal > .ant-menu-item.publish-controls) {
  position: absolute;
  right: 82px;
  top: 50%;
  transform: translateY(-50%);
}

:global(.ant-menu-horizontal > .ant-menu-item.share-controls) {
  position: absolute;
  right: 230px;
  top: 50%;
  transform: translateY(-50%);
}

:global(.ant-menu-horizontal > .ant-menu-item.func-status-controls) {
  right: 252px;
}

.nav_bar_view {
  display: flex;
}

.leftmenu {
  flex: 0.4;
  height: 60px;
  background-color: transparent;
  min-width: 280px;
}

.rightmenu {
  height: 60px;
  background-color: transparent;
  flex: 0.6;
  display: flex;
  flex-direction: row-reverse;
}

.brain-logo {
  width: 60px;
  height: 60px;
}

.brain-logo img {
  width: 100%;
  height: 100%;
}

.log-out {
  top: unset;
}

.logout-pop {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.developer-mode {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:global(.home-navbar-logout) {
  width: 190px;
  margin: 10px 0;
}

.navber-back {
  width: 100%;
  height: 80px;
  /* background-color: #fff;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Background.png");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed; */
}

.navber-back :global(.ant-menu-horizontal) {
  border-bottom: none;
}

/* .navber-imagica {
  width: 100%;
  height: 80px;
  background-color: #fff;
  background-image: url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/step_bg.png');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
} */

.home-navber-box {
  position: absolute;
  right: 20px;
  height: 60px;
  left: 20px;
  top: 20px;
  background: #f2f3f4;
  border-radius: 30px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
}

.disbaled-overlay {
  position: absolute;
  right: 20px;
  height: 60px;
  left: 20px;
  top: 20px;
  background-color: #000;
  border-radius: 9999px;
  opacity: 0.2;
  cursor: not-allowed;
  z-index: 10;
}

.block-box {
  cursor: pointer;
  margin: 0 10px;
}

.block-box-center {
  cursor: pointer;
  margin: 35px;
}

.block-box img {
  width: 20px;
  height: 20px;
  /* filter: drop-shadow(#000 0 0);
  -webkit-filter: drop-shadow(#000 0 0); */
}

.block-box .icon-text {
  margin-left: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #12192b;
  font-family: "Ak-Regular";
  /* font-family: 'Ak-Regular'; */
}

.block-box .icon-text-center {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #12192b;
  font-family: "Ak-Regular";
  /* font-family: 'Ak-Regular'; */
  /* STUD-992 进入项目固定最大宽度 */
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-grey {
  color: #b3b7be !important;
}

.space_line {
  height: 60px;
  display: flex;
  align-items: center;
}

.division {
  height: 30px;
  border-left: 1px solid rgba(2, 12, 38, 0.1);
}

.block-box,
.right-box,
.left-box {
  display: flex;
  align-items: center;
}

.base_content {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.run-all-box {
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 90px; */
  color: #0495f5;
  background: rgba(10, 157, 255, 0.1);
  border: none;
  box-shadow: none;
  /* padding: 4px 12px 4px 6px; */
  font-size: 13px;
  gap: 5px;
  height: 40px;
}

.round-button-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  .round-button {
    border-radius: 30px;
  }
}

.share-box {
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0495f5;
  background: rgba(10, 157, 255, 0.1);
  border: none;
  box-shadow: none;
  font-size: 13px;
  height: 40px;
}

.block-box .share-box :global(.ant-btn-loading-icon) {
  display: flex !important; /* 使loading（转圈svg的上层span）垂直居中 */
}

.text-box {
  margin: 0 0 0 0px;
}

.text-box img {
  width: 16px;
}

.text-box .icon-text {
  color: #b3b7be;
  max-width: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.text-box .icon-text::-webkit-scrollbar {
  display: none;
}

.hover-box {
  transition: 0.3s ease-out;
  padding: 0 20px;
  border-radius: 20px;
  height: 40px;
}

.hover-box:hover {
  transition: 0.3s ease-out;
  background: #ffffff;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
}

.saveBtn {
  /* background: #ffffff; */
  background: rgba(255, 255, 255, 0.65);
  padding: 0 20px;
  border-radius: 20px;
  height: 40px;
}
.saveBtn:hover {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 7px 2px rgba(2, 12, 38, 0.03);
  transition: 0.3s ease-out;
}
:global(.select-text) {
  color: #12192b;
}

:global(.select-text) .icon-text {
  color: #12192b;
}

:global(.disable-publish) {
  background-color: #b3b7be !important;
}

:global(.disable-publish) .icon-text {
  color: #fff !important;
}

:global(.not-optional) {
  user-select: none;
  color: #b3b7be;
  cursor: not-allowed;
  pointer-events: none;
}

:global(.not-optional) .nav-bar-button {
  background-color: #b3b7be !important;
}

:global(.not-optional) .icon-text {
  color: #b3b7be;
}

:global(.ant-menu-item) {
  padding: 0 !important;
}

.divider-box {
  display: flex;
  align-items: center;
  height: 100%;
}

:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item::after),
:global(.ant-menu-horizontal:not(.ant-menu-dark) > .antmenu-item-active::after) {
  display: none;
}

/* 优化收起后样式 */
:global(.ant-menu-submenu-popup > .ant-menu) {
  padding: 10px;
}

:global(.ant-menu-vertical.ant-menu-sub > .ant-menu-item) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:global(.ant-menu-horizontal > .ant-menu-submenu::after),
:global(.ant-menu-submenu-popup) .division {
  display: none;
}

:global(.ant-menu-submenu-popup) > .select-text {
  box-shadow: none;
}

:global(.ant-menu-title-content) {
  width: 100%;
  font-family: "Ak-Regular";
}

.publish-box {
  /* width: 107px; */
  height: 40px;
  background: #f7f7f8;
  box-shadow: 0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03);
  border-radius: 20px;
  padding: 8px 15px;
}

.publish-box img {
  width: 16px;
  height: 16px;
}

.publish-box .icon-text {
  margin-left: 5px;
  font-family: "SF-Regular";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #898893;
}

.publish-highlight {
  font-size: 13px;
  /* background: #1890ff; */
  background: rgba(10, 157, 255, 1);
  box-shadow: 0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03);
}
.publish-highlight:hover {
  background: linear-gradient(0deg, #0a9dff, #0a9dff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  transition: 0.3s ease-out;
}
.publish-highlight .icon-text {
  color: #ffffff;
}

.publish-prompt {
  padding: 25px;
  width: 300px;
  border-radius: 30px;
  position: absolute;
  left: -60%;
  top: 75px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03);
  filter: drop-shadow(0px -5px 4px #eee);
}

.default-bot-box {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  transform: rotate(45deg);
  z-index: -1;
  top: -2px;
  left: calc(50% - 40px / 2 - 0.5px);
}

.publish-content {
  margin-top: 20px;
  font-family: "Ak-Regular";

  color: #12192b;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-line;
}

.content-title {
  font-family: "Ak-Regular";
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 15px;
}

.content-subTitle .content-title {
  font-size: 15px;
}

.publish-bottom {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dismiss-bottom {
  width: 115.38px;
  height: 38.46px;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 32.0513px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: rgba(18, 25, 43, 0.65);
  cursor: pointer;
}

.tryIt-bottom {
  width: 115.38px;
  height: 38.46px;
  background: #05be75;
  border-radius: 32.0513px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
}

.feedback {
  position: absolute !important;
  right: 156px !important;
  display: none !important;
  align-items: center !important;
  height: 100% !important;
}

.feedback_show {
  display: flex !important;
}

.feedback_position {
  right: 420px !important;
}

@keyframes showMenu {
  from {
    top: -200px;
  }

  to {
    top: 20px;
  }
}

.navComponent {
  background: #d2d5de;
}

.menuComponent {
  animation-name: showMenu;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: rgba(255, 255, 255, 0.35);
}

.logo {
  margin: 0 30px 0 35px;
  width: 20px;
  height: 20px;
}

.menuItemNewProject {
  display: none !important;
}

.menuItemProfile {
  display: flex !important;
  align-items: center !important;
  position: absolute !important;
  height: 100% !important;
  right: 0 !important;
  top: 0 !important;
}

.reSetRunBtn {
  right: 105px !important;
}

.default-bot-img {
  width: 100%;
}
