.centerBox {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  //border-bottom: 1px solid #dadde1;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.fileName {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  min-height: 44px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.scroll {
  overflow: scroll;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid;
  /* @include themeify {
    border-color: themed(pdfPreviewBg);
    background: themed(xlsxScrollBg);
  } */
  border-color: var(--pdfPreviewBg, #fff);
  background: var(--xlsxScrollBg, rgba(255, 255, 255, 0.2));
}

.scroll::-webkit-scrollbar {
  width: 0;
  height: 5px;
}

/* 隐藏滚动条阴影 */
.scroll::-webkit-scrollbar-track {
  /* @include themeify {
    background-color: themed(xlsxScrollbarTrack);
  } */
  background-color: var(--xlsxScrollbarTrack, #eeeeee);
  border-radius: 5px;
}

/* 隐藏滚动条按钮 */
.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(2, 12, 38, 0.1);
  border-radius: 10px;
}

table {
  text-align: left;
  table-layout: fixed;
  border-collapse: collapse;
}

table thead {
}

table thead tr th {
  padding: 10px;
  height: 40px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  min-width: 60px;
  font-family: var(--fontFamily, "Ak-Regular");
  /* @include themeify {
    background-color: themed(xlsxThBg);
    color: themed(xlsxThTextColor)
  } */
  background-color: var(--xlsxThBg, #eeeeee);
  color: var(--xlsxThTextColor, #12192b);
}

table tbody {
}

table tbody tr td {
  padding: 10px;
  min-width: 60px;
  border-bottom: 1px solid;
  text-wrap: nowrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  height: 40px;
  font-family: var(--fontFamily, "Ak-Regular");
  /* @include themeify {
    border-color: themed(xlsxTdBorder);
  } */
  border-color: var(--xlsxTdBorder, #dadde1);
}

.table_select {
  font-family: Ak-Regular;
}

.tableLoader {
  min-width: 250px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  .loading {
    font-size: 15px;
    color: #aaaaaa;
    height: 40px;
    line-height: 40px;
    padding-left: 5px;
  }

  .line {
    background-color: #eeeeee;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
}

.pageControl {
  margin: 10px auto 0 auto;
  display: flex;
  align-items: center;

  &Index {
    margin: 0 15px;
  }
}
