.AiSaasOutputList {
  position: relative;
}
.moddle-box {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.max-width-box {
  max-width: 1000px;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.output-list {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  min-height: 100px;
  transition: 0.3s;
  padding: 0 20px;
}

.groupThought {
  margin: 20px 0;
  > * {
    margin-bottom: 20px;
  }
}
.outputlabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0 20px 5px;
  margin-bottom: 0;
  color: var(--inputTitleColor, "#020C26");
}
@media screen and (min-width: 567px) {
  .output-list {
    padding: 0 40px;
  }
}

/* 适配间距优化 1920px 1680px 1440px 1280px 1024px 800px 568px */
@media only screen and (min-width: 1921px) {
  .output-list {
    max-width: 830px;
  }
}
@media only screen and (min-width: 1680px) and (max-width: 1920px) {
  .output-list {
    max-width: 830px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .output-list {
    max-width: 726px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .output-list {
    max-width: 630px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .output-list {
    max-width: 630px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .output-list {
    max-width: 530px;
  }
}
@media only screen and (min-width: 568px) and (max-width: 799px) {
  .output-list {
    max-width: 530px;
  }
}
@media only screen and (max-width: 567px) {
  .output-list {
    max-width: 100%;
  }
}
