.ShoppingDetail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  /* padding: 0 5px; */
}

.inner {
  border-radius: 10px;
  height: 100%;
}

.backNav {
  display: flex;
  justify-content: flex-start;
}

.backNav div {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  color: var(--shoppingItemInfoColor, rgba(2, 12, 38, 0.35));
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  font-family: var(--fontFamily, AK-Regular);
}

.backNav img {
  padding-right: 10px;
  width: 15px;
}

.shoppingInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 10px;
  padding: 20px;
}

.imgWrapper img {
  object-fit: contain;
  width: 70%;
}

.desc {
  font-family: var(--fontFamily, AK-Regular);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--shoppingItemInfoColor, rgba(18, 25, 43, 0.65));
}

.buyNowButton {
  width: 151px;
  height: 33px;
  background: #eaeaeb;
  border-radius: 39px;

  font-weight: 600;
  font-size: 13px;
  line-height: 33px;
  text-align: center;
  cursor: pointer;
  color: #6a6c6f;
}

:global(.dark) .inner {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
:global(.dark) .ShoppingDetail {
  z-index: 5;
  background: rgba(0, 0, 0, 0.8);
}
:global(.dark) .backNav div {
  color: #ffffff;
}
:global(.dark) .buyNowButton {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}
