.passwordBox {
  width: 100%;
  height: 100%;
}

.textPrompt {
  color: #12192b;
  font-family: "Ak-Regular";
  font-size: 16px;

  font-weight: 400;
  line-height: 20px;
}

// .buttonBox {
//   display: flex;
//   height: 40px;
//   padding: 5px 30px;
//   justify-content: center;
//   align-items: center;
//   gap: 15px;
//   border-radius: 25px;
//   background: #0a9dff;
//   box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
//   color: #fff;
//   text-align: center;
//   font-family: "Ak-Regular";
//   font-size: 15px;

//   font-weight: 500;
//   line-height: 20px;
//   width: 115px;
//   cursor: pointer;
//   float: right;
//   margin-top: 20px;
// }
.pwResetBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 115px;
  height: 40px;
  padding: 5px 30px;
  background: rgba(10, 157, 255, 1);
  box-shadow: 0px 2px 8px 2px rgba(2, 12, 38, 0);
  color: rgba(255, 255, 255, 1);
  margin-left: auto;
  margin-top: 20px;
  border-radius: 25px;
  font-family: "Ak-Regular";
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
.pwResetBtn:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(0deg, #76bcea, #73b8e6),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  transition: 0.3s ease-out;
}
.pwResetBtn:active {
  color: rgba(255, 255, 255, 1);
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.25);
  background: rgba(53, 143, 203, 1);
  transition: 0.3s ease-out;
}
