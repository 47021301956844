.error-stripe {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-stripe :global(.ant-btn-primary) {
  margin-top: 20px;
}
