/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
@import "~antd/dist/antd.css";
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  line-height: 1;
}
body {
  line-height: inherit;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@font-face {
  font-family: "Ak-bold";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/Ak-bold.woff2") format("woff2");
}
@font-face {
  font-family: "Ak-Light";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/Ak-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Ak-Medium";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/Ak-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Ak-Regular";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/Ak-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "SF-Bold";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/SF-Pro-Display-Bold.otf") format("opentype");
}
@font-face {
  font-family: "SF-Medium";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/SF-Pro-Display-Medium.otf") format("opentype");
}
@font-face {
  font-family: "SF-Regular";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/SF-Pro-Display-Regular.otf") format("opentype");
}
@font-face {
  font-family: "SF-Light";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/SF-Pro-Display-Light.otf") format("opentype");
}
@font-face {
  font-family: "AktivGrotesk";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/AktivGrotesk-Regular.otf") format("opentype");
}
@font-face {
  font-family: "AktivGrotesk-medium";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/AktivGrotesk-Medium.otf") format("opentype");
}
@font-face {
  font-family: "AktivGrotesk-light";
  src: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/font/AktivGrotesk-Light.otf") format("opentype");
}
@font-face {
  font-family: "Lora";
  src: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Lora-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PlayfairDisplay";
  src: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/PlayfairDisplay-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/fonts/Roboto-Regular.ttf") format("truetype");
}

html {
  /* font-size: 1em; */
  font-size: 4.102564102564102vw;
  font-family: ak-regular, Roboto !important;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-family: ak-regular, Roboto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282828;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (min-width: 390px) {
  html {
    font-size: 16px;
  }
}
* {
  box-sizing: border-box;
}

button:hover {
  cursor: pointer;
}

.route-box {
  position: absolute;
  width: 100%;
  height: 100%;
}
/* .Hidden {
  display: none;
} */

/* .IPhone-Hidden-Bottom {
  grid-template-rows: 0fr 1fr;
} */

.Bottom-Menu-Button-Holder {
  min-height: 1.2em;
  display: block;
  margin: 0 auto;
  height: 1em;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.Next-Button:hover,
.Back-Button:hover,
.Top-Down-Button:hover {
  background-color: #99c5d0;
  color: white;
}

.Next-Button,
.Back-Button,
.Top-Down-Button {
  background-color: #338ba1;
  color: #e3e5ef;
  border: 0;
}

/* Input */

.Input {
  border-radius: 3em;
  background-color: #d1e5ea;
  width: 80%;
  display: block;
  margin: 0 auto;
  min-height: 2.6em;
  max-height: 2.6em;
  z-index: 4;
  margin-top: 1.1em;
}

.Input-Holder {
  min-height: 5vh;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.Input-Holder p {
  border: solid red 0.01em;
}

.HomeInputForm {
  display: block;
  width: 100%;
  height: 46%;
  margin: auto 0;
}

.HomeInputQuery {
  display: block;
  border-radius: 0.5em;
  text-align: center;
  border: solid black 0.001em;
  margin: auto 0 auto 2%;
  width: 88%;
}

.Microphone {
  background-image: url("./media/microphone.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 66%;
  width: 90%;
  display: block;
  margin: auto 0 auto 10%;
}

.Microphone:hover {
  opacity: 0.3;
  cursor: pointer;
}

.Microphone img {
  height: auto;
}

.SubmitButton {
  display: none !important;
}

.CardFrameReason {
  padding: 0.2em 0.5em;
}

.CardFrameReason p {
  font-size: 0.8em;
  line-height: 1.4em;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.CardFrameReason p::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.CardHomeStockTop {
  /* height: 7em; */
  overflow-y: scroll;
  padding-left: 15px;
}

.CardHomeStockTop::-webkit-scrollbar {
  display: none;
}

.CardHomeStockContent,
.CardHomeStockVolumeContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;
}
.CardHomeStockVolumeContent {
  margin-bottom: 40px;
}
.CardHomeStockSymbol {
  color: rgba(2, 12, 38, 0.35);
}
.CardHomeStockSymbol,
.CardHomeStockVolume {
  font-weight: 800;
  font-size: 14px;
  font-family: var(--fontFamily, "Ak-Regular");
  /* margin-bottom: 10px; */
}
.CardHomeStockPrice {
  margin-bottom: 20px !important;
}

.CardHomeStockName,
.CardHomeStockPrice {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  color: var(--stockNamePrice, #22232f);
  margin-bottom: 2px;
}

.Large .CardHomeStockName,
.Large .CardHomeStockPrice {
  font-size: 20px;
}
.Large .CardHomeStockSymbol,
.Large .CardHomeStockVolume {
  font-size: 17px;
}
.Large .CardHomeStockVolumeNum {
  font-size: 18px;
}
.Large .CardHomeStockTradeBtn span {
  font-size: 16px;
}

.Small .CardHomeStockName,
.Small .CardHomeStockPrice {
  font-size: 14px;
}
.Small .CardHomeStockSymbol,
.Small .CardHomeStockVolume {
  font-size: 11px;
}
.Small .CardHomeStockVolumeNum {
  font-size: 12px;
}
.Small .CardHomeStockTradeBtn span {
  font-size: 10px;
}

.CardHomeStockVolume {
  /* font-size: 0.7em; */
  /* font-weight: 700;
  line-height: 1.2em; */
  /* color: '#00C805' */
}

.CardHoeStockInfo {
  margin: 25px 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.CardHomeStockVolumeNum {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  color: #00d289;
  margin-bottom: 20px;
  /* color: '#00C805' */
}

.CardHomeStockBottomFixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid #777; */
  padding: 10px 0 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  /* height: 10px; */
}

.showPreviewBtn {
  padding: 10px 0 40px;
}

.CardHomeStockTradeBtn {
  font-size: 15px;
  font-weight: bold;
  right: 5px;
  bottom: 5px;
  color: #fff;
  background: var(--stockTradeBtnBg, #0a9dff);
  width: calc(100% - 40px);
  padding: 0px 10px !important;
  box-shadow: var(--stockTradeBtnBoxShadow, 0px 8px 16px 2px rgba(10, 157, 255, 0.15));
}
.CardHomeStockDoneBtn {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  background: #0a9dff;
  box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
}
.CardHomeStockTradeBtn:hover,
.CardHomeStockDoneBtn:hover,
.CardHomeStockTradeBtn:focus,
.CardHomeStockDoneBtn:focus {
  color: #fff;
  background-color: #0495f5;
}
.CardHomeStockTradeBtnGroup {
  width: calc(100% - 40px);
  position: absolute;
  left: -4px;
  bottom: 15px;
}
/* .CardHomeStockTradeOptionsBox {
  position: absolute;
  height: 40px;
  right: 0;
  bottom: 120px;
}
.CardHomeStockSellBox {
  position: absolute;
  height: 40px;
  right: 0;
  bottom: 80px;
}
.CardHomeStockBuyBox {
  position: absolute;
  height: 40px;
  right: 0;
  bottom: 40px;
} */
.CardHomeStockTradeOptionsBtn {
  right: 0;
  bottom: 64px;
  width: 100% !important;
}
.CardHomeStockTradeBtnGroup .CardHomeStockTradeBtn span,
.CardHomeStockTradeBtn span {
  font-size: 13px;
}
.CardHomeStockSellBtn {
  right: 0;
  bottom: 60px;
  width: 100% !important;
}
.CardHomeStockBuyBtn {
  right: 0;
  bottom: 55px;
  width: 100% !important;
}
.CardHomeStockCancelBtn.ant-btn-round.ant-btn-icon-only {
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  color: var(--stockCancelBtnColor, #0495f5);
  background-color: var(--stockCancelBtnBg, #fff);
  border: var(--stockCancelBtnBorder, 1px solid #0495f5);
  position: absolute;
  bottom: 8px;
}
.CardHomeStockCancelBtn:hover,
.CardHomeStockCancelBtn:focus {
  color: #fff;
  background-color: #0495f5;
  border: 1px solid #0495f5;
}
.CardHomeImplication p {
  background-color: white;
  padding: 0.2em 0.5em;
  font-size: 1em;
  line-height: 1.6em;
  text-align: center;
}

.CardHomeOutfit h4 {
  font-size: 0.85em;
  line-height: 1.6em;
  text-align: center;
  font-weight: 500;
  min-height: 4em;
}

.CardHomeOutfit p {
  font-size: 0.75em;
  line-height: 1.6em;
  text-align: center;
  background-color: #7cd0c0;
  color: #f0f0f0;
  border-radius: 0.5em;
}

.CardHomeOutfit p {
  margin-top: 1em;
}

.CardHomeInfo {
  background-color: white;
  padding: 0.5em;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.CardFocusList,
.CardHomeList {
  background-color: white;
  padding: 0.5em;
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.CardFocusFood img {
  width: 100%;
}

.CardFocusFood p {
  margin-top: -2em;
}

.CardFocusOutfit img {
  display: block;
  margin: auto;
  max-height: 10em;
  margin-bottom: 1em;
}

.CardFocusOutfit p {
  margin-top: 0;
}

.CardHomeList {
  grid-template-columns: 1fr 5fr;
}

.CardFocusList {
  grid-template-columns: 1fr;
  text-align: center;
}

.CardFocusList p,
.CardHomeList p {
  font-size: 0.9em;
  line-height: 1.6em;
}

.CardHomeList h2 {
  display: block;
  margin: auto 0;
  font-size: 2em;
}

.CardHomeInfo1col {
  grid-template-columns: 1fr;
}

.CardHomeInfo p {
  border-right: solid black 0.01em;
  font-size: 0.9em;
  line-height: 1.6em;
  text-align: right;
  padding-right: 1em;
}

.CardHomeInfo1col p {
  text-align: center;
  border-right: 0;
}

.CardHomeInfo h4 {
  font-size: 0.8em;
  line-height: 1.4em;
  color: #006e8a;
  font-weight: 500;
  display: block;
  margin: auto;
  padding: 0 1em;
}

.CardHomeInfo .Hidden {
  display: none;
}

.CardHomeGoogle {
  color: black;
  text-decoration: none;
}

.CardHomeGoogle p {
  display: block;
  font-size: 1.1em;
  text-align: center;
  padding: 2em 0;
}

/* .Domains {
  display: grid;
  grid-template-columns: 1fr 3fr;
  min-height: 2em;
  max-height: 2em;
  margin: 0.5em -0.1em 0.8em -3%;
} */

.DomainsThought {
  padding: 0 0.5em;
  border-right: solid black 0.1em;
  border-left: solid black 0.1em;
  border-radius: 0.5em;
  margin-right: 3%;
  font-size: 0.7em;
  line-height: 1.4em;
}

.DomainsList {
  max-width: 96%;
  white-space: nowrap;
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.DomainsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.DomainsThought img {
  display: block;
  margin: auto;
  height: 2.4em;
}

.DomainsThought .Domain {
  padding: 0.5em 1em 0.67em 1em;
  margin: 0;
}

.DomainsThought .SelectedDomain {
  padding: 0.5em 1em 0.67em 1em;
  margin: 0;
}

.SelectedDomain,
.Domain {
  background-color: black;
  color: #f8f8f8;
  font-size: 0.9em;
  border-radius: 0.5em;
  margin: 2%;
  padding: 0.33em 1em 0.33em 1em;
}

.SelectedDomain {
  opacity: 0.3;
}

/* .SelectedDomain:hover,
.Domain:hover {
  opacity: 0.3;
  cursor: pointer;
} */

.Content {
  /* overflow-y: scroll; */
  overflow: auto;
  padding: 0 0 100px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
.Studio-UI .Content {
  padding: 10px;
}
/*  */
.Content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Content Frame */

.ContentFrameReason p {
  border: solid black 0.01em;
  background-color: white;
  color: #282828;
  padding: 0.3em 0.5em;
  font-size: 0.8em;
  line-height: 1.8em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.CardHomeOutfit img {
  width: 100%;
  object-fit: cover;
}

.ExpandButton {
  border: 0;
  border-left: solid black 0.01em;
  border-right: solid black 0.01em;
  background-color: #99aaae;
  color: white;
  text-align: center;
  line-height: 1.5em;
  width: 99%;
  display: block;
  margin: 0 auto;
}

.ContractButton {
  background-color: #e6eaeb;
  color: black;
  text-align: center;
  line-height: 1.5em;
  border: 0;
  width: 100%;
  display: block;
  margin: 0 auto;
}

/* Card Result */

.CardResult {
  border: solid black 0.01em;
  border-radius: 0.5em;
  height: auto;
  margin: 1em 0.5em;
  min-width: 95%;
  max-width: 95%;
}

.CardResult:hover {
  opacity: 0.3;
  cursor: pointer;
}

.CardResultContent {
  width: 100%;
  padding: 1%;
}

.CardResultReason {
  padding: 0.2em 0.5em;
}

.CardResultReason p {
  font-size: 0.8em;
  line-height: 1.4em;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.CardResultReason p::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Top Menu */

.Back-Button {
  border-top-left-radius: 40em;
  border-top-right-radius: 40em;
  width: 100%;
}

.Next-Button {
  border: solid black 0.01em;
  width: 100%;
}

/* Focus Cards */

.CardFocusVideo {
  border: solid black 0.01em;
  min-height: 2em;
}

.CardFocusImage {
  background-color: white;
  max-height: 15em;
  margin: 0.5em;
  display: block;
  padding: 0.5em;
}

.CardFocusImage img {
  display: block;
  max-height: 14em;
  max-width: 100%;
  margin: auto;
}

/* Reactions */

.ReactionsArea {
  margin: 1.5em 0.5em 1em 0.5em;
  min-height: 74vh;
  max-height: 74vh;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.ReactionsArea::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ReactionsArea .CardHomeThought {
  background-color: white;
  min-height: 2.5em;
  margin-top: 1em;
  border-radius: 0.67em;
}

.BuiltQuery {
  color: white;
}

/* Reaction Bubble */

.ReactionBubble p:hover {
  opacity: 0.3;
  cursor: pointer;
}

/* Chain of Thoughts */

/* .Left-Section, */
.Right-Section {
  background-color: white;
  margin: 5vh 3vw;
}
/* .Left-Section h2, */
.Right-Section h2 {
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 2em;
}

/* route select for studio */
.Route-Select {
  padding: 15px 0px 0px 15px;
  height: 100px;
}

.Home-Button {
  position: absolute;
  right: 55px;
  top: 4px;
  height: 36px;
  line-height: 20px;
  z-index: 1;
}
.api-content-description {
  margin-top: 20px;
  font-size: 15px;
  line-height: 18px;
  white-space: pre-wrap;
}

.log-out {
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 10;
}

.log-out .icon-sign_out {
  font-size: 20px;
  color: #bfbfbf;
}
.permission-denied {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.permission-denied p {
  font-family: ak-regular;
}
.ant-modal-content {
  font-family: ak-regular;
}
.custom-editor-msg {
  position: relative;
  right: 20px;
  top: 80px;
  text-align: right;
}
.custom-editor-msg .ant-message-notice-content {
  min-width: 20%;
  max-width: 100%;
  padding: 0;
  border-radius: 10px;
}
.custom-editor-msg .ant-alert {
  width: 100%;
  padding: 10px 10px 10px 15px;
  border-radius: 10px;
  box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  backdrop-filter: blur(20px);
  border: none;
}
.custom-editor-msg .anticon {
  top: 0;
  color: #fff;
  margin-right: 15px;
  font-size: 20px;
}
.custom-editor-msg .ant-alert-message {
  color: #fff;
  font-size: 13px;
  font-family: ak-regular;
  line-height: 20px;
  text-align: left;
}
.custom-editor-msg .ant-alert-close-icon {
  margin-left: 15px;
}
.custom-editor-msg .anticon-close {
  margin-right: 0;
}
.custom-editor-msg .anticon-close:hover {
  color: #fff;
}
.custom-editor-msg .icon-guanbi {
  display: inline-block;
  color: #fff;
  margin-top: 3px;
}
.custom-editor-msg .sucess-icon {
  width: 20px;
  margin-right: 15px;
}
.custom-editor-msg .close-icon {
  width: 20px;
}
.custom-editor-msg .ant-alert-success {
  background: #05be75;
}
.custom-editor-msg .ant-alert-error {
  background: #ff4d4f;
}
.custom-editor-msg .ant-alert-info {
  background: #1677ff;
}
.custom-editor-msg .ant-alert-warning {
  background: #faad14;
}
.custom-editor-msg a {
  display: inline;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .custom-editor-msg {
    position: relative;
    margin: 0 auto;
    right: 0;
    text-align: center; /* center alert box */
  }
}

.dangerous-html-dot {
  /* display: inline-block; */
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: var(--askImagicaDotTextColor, #12192b);
  font-size: 12px;
  text-align: center;
  background: var(--askImagicaDotBackground, rgba(10, 157, 255, 0.25));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.dark .dangerous-html-dot {
  background: rgb(10, 157, 255) !important;
}

/*antd组件内 ResizeObserver loop limit exceeded报错隐藏 */
#webpack-dev-server-client-overlay {
  display: none !important;
}
