.generatedImageBox {
  position: relative;
  width: 100%;
  min-height: 225px;
  // padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  overflow: hidden;
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  .regenerateImg,
  .downloadImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .regenerateImg {
    margin-right: 10px;
  }
}
.generatedImageBox:not(:first-child) {
  margin-top: 10px;
}
