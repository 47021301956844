@import "../../../../styles/variables.scss";

.template {
  display: flex;
  // width: 230px;
  width: 100%;
  position: relative;
  height: 200px;
  padding: var(--spacing-spacing-small, 10px);
  flex-direction: column;
  justify-content: flex-start;
  // align-items: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: var(--radius-medium, 20px);
  border: 1px solid var(--white-35, rgba(255, 255, 255, 0.35));
  background-color: rgba(255, 255, 255, 0.35);
  box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  transition: box-shadow 0.3s, background-color 1s;
  cursor: pointer;

  &:hover {
    box-shadow: $boxShadowHover;
    background-color: #fff;
  }

  &__image {
    position: relative;
    display: flex;
    // width: 210px;
    width: 100%;
    justify-content: center;
    height: 118px;
    padding: 20px 50px;
    background-color: #f1f2f5;
    transition: background-color 0.3s;
    border-radius: 10px;

    &:hover {
      background-color: #e6eff5;
    }
  }

  &__description {
    display: flex;
    width: 100%;
  }
}

.templateDescription {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0px var(--spacing-spacing-small, 10px);
  align-items: center;
  gap: var(--spacing-spacing-small, 10px);
  flex-shrink: 0;
  align-self: stretch;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;

    overflow: hidden;
    color: var(--dark, #12192b);
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }

  &__icon {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 4.5px 0px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.dropdown {
  display: flex;
  width: 220px;
  padding: var(--spacing-extra-small, 5px) 0px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 15px;
  background: var(--white-65, rgba(255, 255, 255, 0.65));

  /* Shadow L [Blur] */
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
}

.dropdownItem {
  display: flex;
  padding: 15px var(--spacing-spacing-medium, 20px);
  align-items: center;
  gap: 20px;
  align-self: stretch;

  &__icon {
    display: flex;
  }

  &__text {
    display: flex;

    color: var(--dark-65, rgba(18, 25, 43, 0.65));
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }
}

.image {
  display: inline-flex;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-small, 10px);
}

.icon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  &_add {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    fill: var(--blue, #0a9dff);
    cursor: pointer;
  }
}

.loading {
  position: absolute;
  z-index: 200;
  // width: 230px;
  width: 100%;
  // height: 200px;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.templateTooltip {
  left: 50% !important;
  transform: translateX(-90%) !important;
}
.templateTooltip :global(.ant-popover-arrow) {
  left: 80% !important;
  transform: translateY(100%) translateX(80%) !important;
}
