.Studio-UI .ContentFrameResultsGrid {
  margin-top: 20px;
}

/* studio food start */
.Studio-UI .CardHomeFood {
  margin: 20px 20px 0;
}
/* food end */

/* studio outfit start */
.Studio-UI .outfit .ContentFrameResultsGrid {
  padding: 20px 25px;
}
/* outfit end */
