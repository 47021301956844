.custom-node {
  position: relative;
  z-index: 1;
  width: 100%;
  height: calc(100% - 17px);
  font-family: var(--fontFamily, "Ak-Regular");
  padding-top: 60px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: var(--textboxBg, #fff);
}

.custom-node textarea {
  outline: none;
  border: none;
  resize: none;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: none !important;
  background-color: #fff;

  font-size: 15px;
  line-height: 20px;

  font-weight: 400;
  font-family: var(--fontFamily, "Ak-Regular");
}

.text-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 15px;
  gap: 5px;
  position: absolute;
  height: 40px;
  left: 10px;
  right: 10px;
  top: 10px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
}
.text-title {
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
}

.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
}

.text-content {
  width: calc(100% - 30px);
  min-height: 160px;
  border-radius: 10px;
  height: calc(100% - 10px);
  margin: 0 10px;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-wrap;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--askBrainTextColor, #12192b);

  // 这个不能去掉，是因为const onNodeClick，判断是否选中有个逻辑，判断activeElement.className 是否为undefined
  .html_content {
    padding-top: 0;
  }
}
.text-default-padding {
  /* padding: 0 0 15px; */
  /* 调整滚动条与文字的间距，因为自定义的滚动条宽度为5px，右边给出5px */
  padding: 0 5px 15px 0;
}
.text-reset-padding {
  padding: 15px 15px 35px 15px;
}

.text-content * {
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;
  line-height: 25px;
}

.copy-content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  padding: 12px;
  z-index: 12;
}
.copy-content img,
.delete-content img {
  width: 100%;
  height: 100%;
}

.input-mask {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  position: absolute;
  z-index: 10;
  word-break: break-word;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.previewing {
  height: calc(100% - 70px);
}

.text-textArea {
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.max-textarea-height {
  max-height: 300px;
}
.input-mask.hidden-mask {
  z-index: -1;
}

.alternate-text {
  font-size: 15px;
  line-height: 20px;

  font-weight: 400;
  font-family: var(--fontFamily, "Ak-Regular");
  white-space: pre-wrap;
}
.alternate-text.place-color {
  color: #12192b;
}
.text-textArea::-webkit-scrollbar,
.text-content::-webkit-scrollbar,
.custom-api::-webkit-scrollbar,
.custom-node::-webkit-scrollbar,
.custom-node textarea::-webkit-scrollbar,
.input-mask::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.text-textArea::-webkit-scrollbar-thumb,
.text-content::-webkit-scrollbar-thumb,
.custom-api::-webkit-scrollbar-thumb,
.custom-node::-webkit-scrollbar-thumb,
.custom-node textarea::-webkit-scrollbar-thumb,
.input-mask::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}
.text-textArea::-webkit-scrollbar-track,
.text-content::-webkit-scrollbar-track,
.custom-api::-webkit-scrollbar-track,
.custom-node::-webkit-scrollbar-track,
.custom-node textarea::-webkit-scrollbar-track,
.input-mask::-webkit-scrollbar-track {
  -webkit-box-shadow: none; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.guide-textnode-disable.custom-node {
  /* 调整滚动条与文字间距，文字两边间距要相等，这里不添加右边的间距的10px，添加到内层text-default-padding */
  padding: 18px 10px 20px 20px;
}
.guide-textnode-disable .text-box {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}
.guide-textnode-disable .text-content {
  width: 100%;
  margin: 0;
  /* position: relative; */
  /* bottom: 0; */
}
.guide-textnode-disable .text-textArea {
  max-height: unset;
}

.link-box {
  margin-top: 20px;
}

.link-item {
  display: flex;
  height: 30px;
  margin-bottom: 5px;
  padding: 5px 15px 5px 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 25px;
  background: rgba(10, 157, 255, 0.25);
}

.link-item-saas {
  border: 1px solid var(--askLinkBorder, "rgba(2, 12, 38, 0.05)") !important;
}

.link-item-col {
  min-width: 50%;
  max-width: 100%;
}

.item-index {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: rgba(18, 25, 43, 1);
  font-size: 12px;
  /* text-align: center; */
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-index-saas {
  background: var(--rightTopNumberBg, "rgba(10, 157, 255, 0.25)") !important;
}

.item-logo {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.item-title {
  color: var(--saasTitleColor, "#12192B");
  text-align: center;
  /* Caption */
  font-size: 13px;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  line-height: 20px;
  padding: 0px;
  white-space: nowrap; /* 长文本不换行 */
  overflow: hidden; /* 隐藏超出范围的部分 */
  text-overflow: ellipsis;
}
.target {
  border-radius: 30px 30px 0 0;
  border-bottom: 0;
}
