.white-frame {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
.white-frame .customJsFuncBox {
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  position: relative;
}
.customJsFuncBoxSpace {
  padding: 10px;
}
.stockInfoResetSpace {
  padding: unset;
}
.white-frame .type-title {
  color: rgba(2, 12, 38, 0.35);
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  padding: 10px 10px 10px 15px;
  height: 40px;
  line-height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.white-frame .customJsFunc-content {
  border-radius: 20px;
  height: calc(100% - 50px);
  width: 100%;
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.customJsFunc-content::-webkit-scrollbar {
  display: none;
}

.customJsIframe {
  height: 100%;
  width: 100%;
}

.custom-node {
  position: relative;
  z-index: 1;
  min-width: 200px;
  /* max-width: 400px; */
  min-height: 260px;
  /* max-height: 400px; */
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.stockInfo {
  min-width: 250px;
  /* min-height: 215px; */
  min-height: 155px; /* stockInfo没有按钮情况下的高度 */
}
.default-resize {
  width: 300px;
  height: 400px;
}
.shares-buy {
  min-width: 200px;
  max-width: 400px;
  min-height: 260px;
  max-height: 500px;
  position: relative;
}
.custom-node-sharebuy {
  position: relative;
  padding-top: 10px;
}
.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100;
}
.delete-content img {
  width: 100%;
  height: 100%;
}
.target {
  border-radius: 30px 30px 0 0;
  border-bottom: 0;
}

.StockWithShareBuy {
  min-height: 135px;

  &.shareBuy {
    min-height: 350px;
  }
}
