.divider-box {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.button {
  display: flex;
  align-items: center;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    min-width: 90px;
    font-size: 13px;
    // background: rgba(10, 157, 255, 1);
    box-shadow: 0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.03);
    color: white;
    height: 40px;
    border-radius: 20px;
    // padding-left: 20px;
    // padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }
}
.conentOthersStyle {
  background: rgba(10, 157, 255, 1);
  padding-left: 20px;
  padding-right: 20px;
}
.disableBox {
  background-color: #dddddd !important;
  border-color: #dcdee2 !important;
  color: white !important;
  padding: 0;
  font-weight: 600;
  cursor: no-drop !important;
}
.conentCursorPointer {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}
.conentCursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
  padding-left: 20px;
  padding-right: 20px;
}
