.deleteAccountModal :global(.ant-modal) {
  width: 400px !important;
  height: 220px !important;
}
.deleteAccountModal :global(.ant-modal-content) {
  background: white;
  border-radius: 20px;
  box-shadow: unset;
}
.deleteAccountModal :global(.ant-modal-body) {
  padding: 0;
}
.deleteAccountModal :global(.ant-modal-footer) {
  display: none;
}

.deleteConfirmModal :global(.ant-modal) {
  width: 400px !important;
  height: 306px !important;
}
.deleteConfirmModal :global(.ant-modal-content) {
  background: white;
  border-radius: 20px;
  box-shadow: unset;
}
.deleteConfirmModal :global(.ant-modal-body) {
  padding: 0;
}
.deleteConfirmModal :global(.ant-modal-footer) {
  display: none;
}

.mobileClsModal :global(.ant-modal) {
  width: calc(100% - 40px) !important;
  .container {
    width: 100%;
    .title {
      height: auto;
    }
  }
  .sureDelete {
    margin-left: 10px;
  }
}

.container {
  position: absolute;
  border-radius: 20px;
  padding: 20px;
  gap: 30px;
  background-color: white;
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .textContent {
    position: relative;
    width: 100%;
  }
  .title {
    width: 100%;
    font-family: Ak-Regular;
    font-weight: 500;
    font-size: 17px;
    line-height: 25.5px;
    color: rgba(18, 25, 43, 1);
  }
  .subContent {
    margin-top: 20px;
    width: 100%;
    font-family: Ak-Regular;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(18, 25, 43, 0.65);
    .subTitle1 {
      width: 100%;
    }
    .subTitle2 {
      width: 100%;
      .disableText {
        color: rgba(10, 157, 255, 1);
      }
    }
  }
  .bottomContainer {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Ak-Regular;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    .neverMind {
      height: 100%;
      background: rgba(18, 25, 43, 1);
      border-radius: 25px;
      padding: 5px 20px;
      text-align: center;
      width: 117px;
      color: white;
      border: none;
    }
    .sureDelete {
      border: none;
      color: rgba(255, 51, 92, 1);
      width: 233px;
      background: rgba(255, 51, 92, 0.1);
      border-radius: 25px;
      padding: 5px 20px;
      text-align: center;
      height: 100%;
    }
  }
}
