.white-frame {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: relative;
}
.white-frame .customJsFunc-box {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}

.white-frame .type-title {
  color: rgba(2, 12, 38, 0.35);
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  padding: 10px 10px 10px 15px;
  height: 40px;
  line-height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.white-frame .customJsFunc-content {
  margin-top: 10px;
  min-width: 300px;
  min-height: 300px;
  height: calc(100% - 50px);
  width: 100%;
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.customJsFunc-content::-webkit-scrollbar {
  display: none;
}

.customJsIframe {
  height: 100%;
  width: 100%;
}

.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}
.delete-content img {
  width: 100%;
  height: 100%;
}
