@import "../AISaasLayout/vars.scss";

.AISaas-wrap {
  :global(.simplebar-content-wrapper) {
    height: 100% !important;
  }
  :global(.simplebar-content) {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  :global(.simplebar-scrollbar.simplebar-visible:before) {
    opacity: 1;
    left: 0;
    right: 0;
    background: #020c261a;
  }
  :global(.simplebar-scrollbar:before) {
    left: 0;
    right: 0;
    background: #020c261a;
    border-radius: 5px;
  }
  :global(.simplebar-mouse-entered .simplebar-scrollbar:before) {
    opacity: 1;
  }
  :global(.simplebar-track.simplebar-vertical) {
    width: 10px;
    margin-right: 5px;
  }
}

.AISaas-main {
  top: 10px;
  padding-left: $padding-gap + $silder-diff-width;
  margin-right: $padding-gap;
  overflow: hidden auto;
  height: calc(100% - 40px);
  *:focus-visible {
    outline: none;
  }

  // FIXME: 所有子节点 sortableItem 边距
  [data-testid="SortableItem"] {
    // padding: $padding-gap;
    padding: 0 $padding-gap;
  }
}

.AISaas-main-mobile {
  top: 0;
  padding-left: 15px;
  margin-right: 15px;
}

.AISaas-background {
  position: absolute;
  top: 10px;
  bottom: 20px;
  left: $padding-gap + $silder-diff-width;
  right: $padding-gap;

  border-radius: 15px;
  background: var(--background);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  user-select: none;
  background-clip: content-box;
}

.AISaas-background-mobile {
  top: 0;
  left: 15px;
  right: 15px;
}

.preview-btn-group {
  /* margin: 15px 0; */
  /* stud-1046 */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  user-select: none;
}

.preview-btn-add-content {
  height: 40px;
  padding: 5px 30px;
  background-color: #05be75;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  border-radius: 25px;
  cursor: pointer;
}

.preview-btn-done {
  height: 40px;
  padding: 5px 30px;
  background: #cc295f;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.AISaas-logo {
  position: relative;
  top: 25px;
  left: 25px;
  svg path {
    fill: var(--aiLogoBrightColor, "#12192B");
  }
}

.logoBoxImg {
  max-width: 90px;
  max-height: 90px;
  object-fit: cover;
  border-radius: 10px;
}

.footer-brain-claimer {
  margin: 0 auto 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--footerBrainStudiosColor, "rgba(18, 25, 43, 0.65)");
  text-align: left;
  width: 100%;
  padding: 0 25px 0px 25px;
}

.footer-brain-studios {
  width: 100%;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 20px;
  height: 50px;
  line-height: 40px;
  text-align: center;
  color: var(--footerBrainStudiosColor, "rgba(18, 25, 43, 0.65)");
}

.aiSaas-preview-row {
  padding-bottom: 10px;
}

.bottomAddToContent {
  margin-bottom: 20px;
}
.AISaasContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  // 添加默认字体
  font-family: var(--fontFamily, "Ak-Regular");
}

.logoLoading {
  left: 0;
  top: 0;
  font-size: 30px;
  // color: #fff; 默认logo的主题变量也在这里加一个
}

.aiSaasContent {
  // 内部内容相对于该元素
  position: relative;
  max-width: 100%;
  min-height: inherit;
  // 如果父节点是 flex cloumn, 则默认让该元素就充满高度
  flex: 1;
  display: flex;
  flex-direction: column;
  .aiSaasAppFooterUrl {
    padding: 20px 20px;
    flex-wrap: wrap;
  }
}
