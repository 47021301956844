.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // width: 75%;
}

.leftCornersBox {
  position: relative;
  width: 24px;
  flex-shrink: 0;
  display: flex;
}

.leftTopCorners {
  border-radius: 20px 0 0 0;
  /* @include themeify {
    background: themed(contentBg);
  } */
  background: var(--contentBg, rgba(2, 12, 38, 0.05));
  width: 20px;
  height: calc(100% - 20px);
  margin-left: 4px;
}
.lftTopCorners {
  border-radius: 20px 0 0 0;
  background-color: var(--contentBg, rgba(2, 12, 38, 0.05));
  width: 20px;
  height: 100%;
}
.showRadiusBottomLft {
  border-bottom-left-radius: 20px;
}
.chatPubBubbleCorner {
  width: 10px;
  height: 10px;
  margin-top: auto;
}
.lftMsgMask {
  background: radial-gradient(
    circle at -5% 0%,
    transparent 60%,
    transparent 65%,
    var(--contentBg, rgba(2, 12, 38, 0.05)) 35%
  );
  border-bottom-left-radius: 5px;
}
.leftBottomCorners {
  border-radius: 0 0 0 20px;
  /* @include themeify {
    background: themed(contentBg);
  } */
  background: var(--contentBg, rgba(2, 12, 38, 0.05));
  width: 20px;
  height: 20px;
  margin-left: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftMessageMask {
  position: absolute;
  bottom: 0;
  left: 0;
}

.cornerMark {
  path {
    /* @include themeify {
      fill: themed(svgColor);
      fill-opacity: themed(svgOpacity);
    } */
    fill: var(--svgColor, #020c26);
    fill-opacity: var(--svgOpacity, 0.05);
  }
}

.rightCorners {
  border-radius: 0 20px 20px 0;
  /* @include themeify {
    background: themed(contentBg);
  } */
  background: var(--contentBg, rgba(2, 12, 38, 0.05));
  width: 25px;
  margin-right: 5px;
  flex-shrink: 0;
}

.messageContainer {
  /* @include themeify {
    background: themed(contentBg);
  } */
  background: var(--contentBg, rgba(2, 12, 38, 0.05));
  padding: 10px 0 10px 0;
  cursor: pointer;
}

.error {
  /* @include themeify {
    color: themed(errorColor);
  } */
  color: var(--errorColor, #b92c2c);
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;

  font-weight: 400;
  line-height: 20px; /* 133.333% */
}

.message {
  /* @include themeify {
    color: themed(messageColor);
  } */
  color: var(--messageColor, #12192b);
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
}
