.ThinkingInput {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 10px;
  right: 10px;
  bottom: 15px;
  height: 50px;
  /* z-index: 9; */
}
.home-icon {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  flex-shrink: 0;
  margin-right: 10px;
  cursor: pointer;
}
.home-icon img {
  width: 50%;
  height: 50%;
  margin-top: 12px;
}
.ThinkingInput .FeedSearchQuery {
  flex: 1;
  height: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  padding: 10px;
  outline: none;
  border: none;
  font-size: 15px;
  font-family: "ak-regular";
  z-index: 3;
}

.ThinkingInput .FeedSearchQuery::-webkit-input-placeholder {
  font-weight: 400;
  line-height: 20px;
  color: #7d8fa1;
}
