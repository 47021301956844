.feedback-tips-title {
  padding-left: 10px;
  margin-bottom: 10px;
}
.feedback-tips {
  width: 240px;
  padding: 10px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
}
