.blank {
  color: #ccc;
  font-size: 32px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.blankV2 {
  margin-top: 10px;
}
