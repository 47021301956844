.content {
  width: 100%;
  height: 100%;
}

.HtmlContainer {
  width: 100%;
  height: 100%;
}
.newHtmlContainer {
  width: 100%;
  height: calc(100% - 40px);
}
.errorMessage {
  text-align: left;
  line-height: 28px;
  a {
    justify-content: flex-start;
  }
}
.backHistoryBtn {
  margin-bottom: 5px;
}
