.alert {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  position: relative;
  width: 400px;
  padding: 25px 30px;
  border-radius: 20px;
  background-color: white;

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .tips {
    margin-top: 25px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    color: #626170;
  }

  .info_column {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-left: 10px;
    padding-right: 10px;
    .info {
      color: #626170;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      display: flex;

      span {
        font-size: 7px;
        font-weight: 800;
        margin-right: 5px;
      }
    }
  }

  .bottom {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    justify-content: space-between;

    .cancel_button {
      height: 30px;
      padding-left: 40px;
      padding-right: 40px;
      background-color: #f3f3f4;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #898893;
      cursor: pointer;
    }

    .downgrade_button {
      height: 30px;
      background: #ffbb00;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      width: 187px;
    }
  }
}
