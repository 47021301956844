.tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 50px;
  background-color: rgba(255, 255, 255, 1);
  left: -10px;
  right: -10px;
  top: -65px;
  box-shadow: 0px 20px 80px 0px rgba(93, 122, 152, 0.2);
  border-radius: 25px;
  /* overflow: hidden; */
  padding: 10px;
  /* width: 100%; */
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
}

.select-box {
  position: relative;
  /* width: 50%; */
  flex: 1;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-bar :global(.ant-select) {
  width: 100%;
  z-index: 2;
  font-family: "Ak-Regular";
}
.tool-bar :global(.ant-select-selector) {
  background: #000;
}
.tool-bar :global(.ant-select-selection-item) {
  color: #fff;
}
.select-down-arrow {
  position: absolute;
  color: #eee;
  right: 0;
  font-size: 10px;
  z-index: 1;
}

.slice-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 1;
  border-radius: 0 5px 5px 0;
  overflow: hidden;
}
.slice-icon-box :global(.icon-line-sliceqiepian) {
  width: 100%;
  height: 100%;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-left: 1px solid #eee;
  background: #9320f7;
  cursor: pointer;
}
:global(.hidden-input) {
  opacity: 0;
}
.guide-diable-selectbox {
  opacity: 0.5;
}
.tool-bar-list-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.tool-bar-item {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  background: rgba(2, 12, 38, 0.05);
  position: relative;
  cursor: pointer;
}

.tool-bar-item:first-child {
  margin-left: -5px;
}
.tool-bar-item:last-child {
  margin-right: -5px;
}
.tool-bar-item:not(:last-child) {
  margin-right: 10px;
}
.tool-bar-item-republic {
  width: 20px;
  height: 20px;
  margin: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* margin: 0 0 20px; */
}
.tool-bar-item-sta-add {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/send_to_app.svg");
}
.tool-bar-item-sta-remove {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/stopNormal.svg");
}
.optSplit {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/split_1.svg");
}
.optShrink {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/shrink.svg");
}
.optCollapse {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/collapse.svg");
}
.optExpand {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/expand.svg");
}
.optCopy {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/copy.svg");
}
.optCopyTxt {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/copyText.svg");
}
.optDelete {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/bin.svg");
}
.optEye {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/eye.png");
  background-size: 20px;
  background-position: 0 4px;
}
.optEyeClose {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/eyeClose.png");
  background-size: 20px;
  background-position: 0 4px;
}
.optDetail {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/node-info.svg");
  background-position: -2px;
}
.optBrush {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/brush.png");
}
.optBlueCollapse {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/collapse.png");
  background-size: 20px;
}
.optBlueExpand {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/expand.png");
  background-size: 20px;
}
.optMessage {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/messages.png");
  background-size: 20px;
}
.tool-bar-item:hover {
  /*background: rgba(10, 157, 255, 1);*/
  background-color: var(--back-color);
}
.tool-bar-item:hover .tool-bar-item-sta-add {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/send_to_app_hover.svg");
}
.tool-bar-item:hover .tool-bar-item-sta-remove {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/stop.svg");
}
.tool-bar-item:hover .optSplit {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverSplit.svg");
}
.tool-bar-item:hover .optShrink {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverShrink.svg");
}
.tool-bar-item:hover .optCollapse {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverCollapse.svg");
}
.tool-bar-item:hover .optExpand {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverExpand.svg");
}
.tool-bar-item:hover .optCopy {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverCopy.svg");
}
.tool-bar-item:hover .optCopyTxt {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverCopyText.svg");
}
.tool-bar-item:hover .optDelete {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverBin.svg");
}
.tool-bar-item:hover .optEye {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverEyeClose.png");
}
.tool-bar-item:hover .optEyeClose {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverEye.png");
}
.tool-bar-item:hover .optBrush {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hoverBrush.png");
}
.tool-bar-item:hover .optDetail {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/node-hover-info.svg");
  background-position: -2px;
}

.tool-tip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: max-content;
  background-color: rgba(255, 255, 255, 1);
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  font-family: "Ak-Regular";
  font-weight: bold;
  color: rgba(18, 25, 43, 1);
  opacity: 0;
  transition: opacity 0.5s;
}
.tool-bar-item:hover .tool-tip {
  opacity: 1;
}

.disableOptBlueCollapse {
  cursor: no-drop;
}
.canvasPopover :global(.ant-popover-inner-content) {
  font-size: 13px;
}
