.container {
  border-radius: 10px;
  border-style: none;
  background: rgba(5, 190, 117, 0.1);
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 20px 15px;
  .text {
    color: #12192b;
    font-family: Ak-Regular;
    font-size: 13px;

    font-weight: 400;
    line-height: 20px;
    padding-right: 10px;
  }
  .button {
    margin-top: 5px;
    display: inline-block;
    width: 120px;
    height: 30px;
    color: #fff;
    /* Caption [M] */
    font-family: Ak-Regular;
    font-size: 13px;

    font-weight: 500;
    border-radius: 25px;
    background: #05be75;
    border-style: none;
  }
}
