.reportBugContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 10px;
}

.reportBugBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  color: #05be75;
  border-radius: 20px;
  height: 40px;
}

.reportBugBtn:hover {
  background-color: rgba(5, 190, 117, 0.1);
  color: #07ab69;
}

:global(#birdeatsbug-sdk)[mobile-style="true"] {
  right: unset;
  top: 0px;

  :global(.preview-screen) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  :global(.introduction-screen) {
    margin-top: 70px;
    margin-left: 15%;
  }
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
