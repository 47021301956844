@import "../AISaas/AISaasLayout/vars.scss";

:global(.react-flow__panel).nodePreviewPane {
  display: flex;
  flex-direction: column;
  top: 95px;
  height: calc(100% - 120px);
  width: 460px;
  right: 25px;
  transition: all 0.2s ease-in-out;
  &.coverHomeNavBar {
    z-index: 11;
  }
}

:global(.react-flow__panel).nodePreviewPaneCollapse {
  height: 60px;
}

:global(.react-flow__panel).nodePreviewPane.silder {
  width: $panel-wrap-width;
}

:global(.react-flow__panel).coverSettingPane {
  width: 800px;
}

// @media only screen and (max-width: 767px) {
//   :global(.react-flow__panel).nodePreviewPane {
//     width: 100%;
//   }
// }

.previewPane {
  width: 100%;
  height: 100%;
  position: relative;

  & > div {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    width: $panel-width;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 25px;
    box-shadow: 0 0 5px #eee;
    transition: all 0.3s ease-in-out;
  }

  .closePreviewApp {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
  }

  .paneIn {
    animation: showContent 0.3s ease-in-out forwards;
  }

  .paneOut {
    animation: hideContent 0.3s ease-in-out forwards;
  }

  .historyContent {
    visibility: visible;
  }

  .publishContent {
    visibility: visible;
  }

  .previewContent {
    visibility: visible;
  }

  .hidePublishContent {
    display: none;
  }
}

.previewPaneMobile {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(226, 228, 234);

  & > div {
    width: 100%;
    visibility: visible;
    box-shadow: none;
    transition: none;
  }
}

.coverSettingPane .previewPane .previewBox {
  width: 800px;
}

@keyframes showContent {
  0% {
    opacity: 0;
    visibility: visible;
    transform: translateX(calc(100% + 25px));
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes hideContent {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  99% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(calc(100% + 25px));
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(0);
  }
}

:global(.react-flow__panel).paneZIndex {
  z-index: 6;
}
