@import "../../../../styles/variables.scss";

.gridHeaderLft {
  grid-area: a;
  // border: 1px solid #ffffff;
}
.gridHeaderRgt {
  grid-area: b;
  position: relative;
  // border: 1px solid #ffffff;
}

.slider {
  display: flex;
  width: 730px;
  height: 230px;
  align-items: flex-start;

  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  transition: 0.3s box-shadow;

  &:hover {
    box-shadow: $boxShadowHover;
  }

  &__image {
    display: flex;
    width: 307px;
    height: 230px;
    z-index: 1;
  }

  &__content {
    display: flex;
    padding: var(--spacing-spacing-medium, 20px);
    align-items: flex-start;
    gap: var(--spacing-spacing-large, 30px);
    flex: 1 0 0;
    align-self: stretch;
  }
}
.sliderContainer {
  height: 100%;
  border-radius: 20px;
  background: #fff;
  transition: 0.3s box-shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    box-shadow: $boxShadowHover;
  }
  .sliderBox {
    display: flex;
    .sliderImgsBox {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(230px, 100%));
      grid-template-rows: repeat(auto-fill, minmax(230px, 230px));
      .imgsCarouselBox {
        position: relative;
        z-index: 1;
        padding: 10px;
        .imgsCarouselBg {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px;
        }
        .imgsCarouselTitle {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: ak-medium;
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: center;
          color: #12192b;
        }
      }
    }
    .sliderTxtsBox {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(242px, 100%));
      overflow: hidden;
      .txtsCarouselBox {
        padding: 20px 20px 0;
        .txtCarouselDescriptionBox {
          color: #12192b;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 20px;
          .txtCarouselTitle {
            font-family: "ak-medium";
            font-size: 17px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
          }
          .txtCarouselDescription {
            width: 90%;
            // width: 100%;
            font-family: "ak-regular";
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
      // .sliderContentControlerBox{
      // }
    }
  }
}
.isShowSliderControler {
  display: block;
  padding: 10px 20px;
}
.isShowLongSliderControler {
  display: none;
  padding: 10px 20px;
}
// antd走马灯宽高百分比
.antdCarouselInit :global(.ant-carousel) {
  width: 100%;
  height: 100%;
}
.antdCarouselInit :global(.ant-carousel .slick-slider) {
  width: 100%;
  height: 100%;
}
.antdCarouselInit :global(.slick-list) {
  width: 100%;
  height: 100% !important;
}
.antdCarouselInit :global(.slick-track) {
  width: 100%;
  height: 100%;
}
.antdCarouselInit :global(.slick-slide) {
  width: 100%;
  height: 100%;
}
.antdCarouselInit :global(.slick-slide div) {
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 980px) and (max-width: 1479px) {
  .sliderImgsBox {
    flex: 0.5 !important;
    grid-template-columns: repeat(auto-fill, minmax(230px, 230px)) !important;
  }
  .sliderTxtsBox {
    flex: unset !important;
  }
  .sliderBox .sliderTxtsSafariBox {
    display: flex !important;
    flex-direction: column;
  }
}
@media only screen and (min-width: 718px) and (max-width: 979px) {
  .sliderImgsBox {
    grid-template-columns: repeat(auto-fill, minmax(230px, 100%)) !important;
    grid-template-rows: repeat(auto-fill, minmax(230px, 100%)) !important;
  }
  .txtCarouselActionBox {
    // display: flex;
    // flex: .3;
    flex-wrap: wrap !important;
    // align-items: center;
    flex-direction: column;
    justify-content: end;
    gap: 20px;
    align-items: flex-start !important;
  }
  .isShowSliderControler {
    display: none;
  }
  .isShowLongSliderControler {
    display: block;
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  .sliderImgsBox {
    display: none !important;
  }
  .txtCarouselActionBox {
    flex-wrap: wrap !important;
    width: 70%;
  }
  .isShowSliderControler {
    display: none;
  }
  .isShowLongSliderControler {
    display: block;
  }
}
@media only screen and (max-width: 309px) {
  .sliderImgsBox {
    display: none !important;
  }
  .txtCarouselActionBox {
    flex-wrap: wrap !important;
  }
  .isShowSliderControler {
    display: none;
  }
  .isShowLongSliderControler {
    display: block;
  }
}

.sliderImage {
  display: flex;
  width: 307px;
  height: 230px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: relative;

  &__image {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__text {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px 75px 0px 77px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    color: var(--dark, #12192b);
    text-align: center;

    /* H4 */
    font-family: Ak-Regular;
    font-size: 24px;

    font-weight: 500;
    line-height: 30px; /* 125% */
  }
}

.sliderContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  &__carousel {
    display: flex;
    padding: 0px var(--spacing-spacing-small, 10px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-spacing-medium, 20px);
    width: 363px;
    height: 160px;
  }

  &__control {
    display: flex;
    width: 100%;
  }
}

.sliderInfo {
  display: flex;
  padding: 0px var(--spacing-spacing-small, 10px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-spacing-medium, 20px);
  align-self: stretch;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-spacing-small, 10px);
    align-self: stretch;

    overflow: hidden;
    color: var(--dark, #12192b);
    text-overflow: ellipsis;
    /* Subheader */
    font-family: Ak-Regular;
    font-size: 17px;

    font-weight: 500;
    line-height: 150%; /* 25.5px */
  }

  // &__description {
  // }
}

.sliderActions {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing-extra-small, 5px);
  align-self: stretch;

  &__watch {
    display: flex;
  }

  &__getTemplate {
    display: flex;
  }
}

.watchIcon {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 20px;
  background: var(--links-color-1, #0a9dff);
}

.carousel {
  width: 363px;
  height: 150px;
}

.sliderContentItem {
  display: flex !important;
  height: 155px !important;
  position: relative;
  padding: 0px var(--spacing-spacing-small, 10px);
  flex-direction: column;
  gap: var(--spacing-spacing-medium, 20px);
  align-self: stretch;
  padding: 0px;

  &__description {
    display: flex;
    padding: 0px var(--spacing-spacing-small, 10px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-spacing-medium, 20px);
    align-self: stretch;
    padding-bottom: 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: var(--spacing-spacing-medium, 20px);
    position: absolute;
    bottom: 0px;
  }
}

.text {
  display: inline-flex;

  &_title {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-spacing-small, 10px);
    align-self: stretch;

    overflow: hidden;
    color: var(--dark, #12192b);
    text-overflow: ellipsis;
    /* Subheader */
    font-family: Ak-Regular;
    font-size: 17px;

    font-weight: 500;
    line-height: 150%; /* 25.5px */
  }

  &_description {
    align-self: stretch;

    color: var(--dark, #12192b);
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }

  &_buttonBlack {
    overflow: hidden;
    color: var(--white, #fff);
    text-overflow: ellipsis;
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    // line-height: 20px; /* 133.333% */
    line-height: 30px;
  }

  &_buttonNone {
    overflow: hidden;
    color: var(--dark-65, rgba(18, 25, 43, 0.65));
    text-overflow: ellipsis;
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    // line-height: 20px; /* 133.333% */
    line-height: 30px;
  }
}

.image {
  display: flex;

  width: 100%;
  height: 100%;
}

.icon {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.txtCarouselActionBox {
  display: flex;
  align-items: center;
  flex: 0.3;
}
.button {
  display: inline-flex;
  // height: 40px;
  // padding: 0px 20px 0px var(--spacing-extra-small, 5px);
  padding: 6px 20px 6px 5px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer !important;

  &_black {
    background: var(--dark, #12192b);
    margin-right: 20px;
  }

  &_none {
    background: transparent;
  }

  &__text {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    clear: both;
  }

  &__icon {
    display: flex;
  }
}
