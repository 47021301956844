.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.checkbox {
  cursor: pointer;
}

.plain {
  color: rgba(18, 25, 43, 0.65);
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.link {
  color: #0a9dff;
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.agree {
  display: flex;
  flex: 1;
}
