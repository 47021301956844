.wrap {
  position: fixed;
  font-family: "Ak-Regular";
  width: 100%;
  height: 100%;
  /* background: #fff; */
  /* background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Background.png"); */
  /* background-size: cover;
  background-position: center center;
  background-attachment: fixed; */
}

:global(#birdeatsbug-sdk) {
  right: 100px;
  top: 80px;
}

:global(#birdeatsbug-default-button) {
  display: none !important;
}

.wrap :global(.react-flow__attribution) {
  display: none;
}
