.IdeaPage {
  width: 240px;
  min-height: 500px;
  box-shadow: 0 0 5px #eee;
  border-radius: 10px;
  position: absolute;
  right: 20px;
  top: 15%;
  bottom: 15%;
  padding: 10px;
  z-index: 9;
  box-sizing: content-box;
  background: #fff;
}
.IdeaListBox {
  width: 100%;
  position: relative;
  margin-top: 10px;
  overflow: auto;
  flex: 1;
}
.IdeaPage :global(.ant-spin-nested-loading .ant-spin) {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: none;
}
.right-arrow {
  flex: 1;
}
.IdeaPage :global(.ant-spin-nested-loading) {
  height: 100%;
}
.IdeaPage :global(.ant-spin-container) {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.IdeaPage :global(.icon-fanhui),
.IdeaPage :global(.icon-shuaxin) {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  font-size: 30px;
  cursor: pointer;
  color: #40a9ff;
}
.left-arrow {
  flex: 1;
  text-align: right;
}
.icon-forward {
  transform: rotateY(180deg);
}
.IdeaPage :global(.icon-shuaxin) {
  margin-left: 10px;
}
.shrink-dashed {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
.shrink-dashed:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #40a9ff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.refresh-loading {
  animation: rotateAni 1s infinite linear;
}
@keyframes rotateAni {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
.IdeaListBox::-webkit-scrollbar {
  display: none;
}

.ideaBox {
  margin-bottom: 15px;
  cursor: pointer;
}
.ideaBox:hover {
  background-color: #eee;
}

.title {
  font-size: 18px;
  line-height: 20px;
  color: #000;
  margin-bottom: 15px;
  color: #434141;
}

.content {
  font-size: 14px;
  line-height: 20px;
  color: #443343;
}
