.pitch-desk {
  width: 100%;
  background: #f4f7fb;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px;
}
:global(.Studio-UI .Feed) {
  background: #f4f7fb;
}
.h3-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #22232f;
  font-weight: 800;
  padding: 0 25px;
}

.pitch-desk h3 {
  font-family: "Ak-Regular";

  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  width: inherit;
  color: #0a0a0a;
}
.pitch-desk .icon-right {
  flex-shrink: 0;
  margin-left: 10px;
  color: #22232f;
  font-weight: 800;
}
.pitch-desk .content {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
  margin: 13px 0;
  padding: 0 25px;
}
.pitch-desk .slide-box {
  font-family: "Ak-Regular";
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 15px;
  margin: 10px 25px 0;
}

.slide-box .img {
  border: 0.5px solid rgba(34, 35, 47, 0.1);
  border-radius: 20px 20px 0px 0px;
}
.big-content {
  font-family: "Ak-Regular";
  width: 100%;
  min-height: 100px;
  max-height: 330px;
  background: #fff;
  font-size: 15px;
  border-radius: 20px 20px 0 0;
  border-bottom: 0.5px solid rgba(34, 35, 47, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
  color: #22232f;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.inslide-box {
  overflow-y: auto;
  padding: 0 20px;
}
.inslide-box::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.inslide-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(34, 35, 47, 0.1);
}
.inslide-box::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(50, 50, 50, 0.1);
  background: rgba(34, 35, 47, 0.1);
}
.deck_bg {
  opacity: 0.2;
  position: absolute;
  width: 340px;
  height: 100%;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
}
.big-content label {
  font-family: "Ak-Regular";
  font-weight: 700;
  line-height: 20px;
}
.big-content p {
  line-height: 20px;
}

.big-content .mar-top {
  margin-top: 20px;
}
.scroll-list {
  width: 100%;
  background: #fff;
  padding: 0 10px;
}
.scroll-in_list {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 12px;
}
.scroll-in_list::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
.scroll-in_list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(34, 35, 47, 0.1);
}
.scroll-in_list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(50, 50, 50, 0.1);
  background: rgba(34, 35, 47, 0.1);
}

.scroll-list .scroll-in_list div {
  min-width: 120px;
  height: 60px;
  background: #fff;
  border: 0.5px solid rgba(34, 35, 47, 0.1);
  border-radius: 10px;
  color: #3f3f50;
  font-size: 11px;
  line-height: 15px;
  margin-left: 10px;
  padding: 5px 10px;
  margin-top: 0.9375rem;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll-list .scroll-in_list div:first-child {
  margin-left: 0;
}
.scroll-list div::-webkit-scrollbar {
  width: 0;
}
.scroll-list div label {
  font-family: "Ak-Regular";
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
}
.scroll-list div p {
  font-family: "Ak-Regular";
}
.scroll-list .scroll-in_list .selected-item {
  border: 1.5px solid #5182ff;
}
