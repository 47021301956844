.planBody {
  margin-top: 24px;
  padding: 24px;
  gap: 24px;
  border-radius: 44px;
  background: #fff;
}

.planHeader {
  color: var(--text-default, #12192b);
  h2 {
    margin-bottom: 18px;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.planDailyBox {
  margin-top: 24px;
  color: #12192b;
  .title {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 30px;
  }
  .description {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
  }
}
