$pcWidth: 1180px;
$padWidth: 580px;

.chatItem {
  margin: 20px 0;
  position: relative;
  button {
    height: 40px;
    padding: 5px 20px;
    outline: none;
    background: var(--buttonBackground, "rgba(18, 25, 43, 0.05)");
    font-family: var(--fontFamily, "Ak-regular");
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background: var(--buttonBackground, "rgba(18, 25, 43, 0.05)");
    color: var(--buttonColor, "rgba(18, 25, 43, 0.65)");
    border: var(--buttonBorder, 0);
    border-radius: var(--buttonRadius, 25px);
  }
}
.chatInfo {
  margin-bottom: 20px;
}
.chatValue {
  padding: 15px 25px;
  border-radius: 25px 25px 5px 25px;
  margin: 0 0 5px;
  background: var(--toolTipBackground, "rgba(255, 255, 255, 0.65)");
  color: var(--valueColor, "#12192b");
  font-family: var(--fontFamily, "ak-regular");
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: fit-content;
}
.errorHint {
  color: #b92c2c;
  line-height: 30px;
}
.generateAnimation {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: -55px;
}
.rightItem {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .chatValue {
    // max-width: calc(100% - 100px);
    padding: 15px 25px;
  }
}
.leftItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .chatValue {
    background: transparent;
    font-family: var(--fontFamily, "Ak-Regular");
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--shareTitleColor, "#12192b");
  }
}
.chatTime {
  width: 100%;
  font-family: var(--fontFamily, "ak-regular");
  font-size: 16px;
  line-height: 25px;
  color: var(--uploadLimitSizeColor, "#12192b59");
  display: inline-block;
  text-align: right;
}

.btns {
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 10px;
  }
}

@media only screen and (min-width: $padWidth) and (max-width: $pcWidth) {
  .chatItem {
    margin: 20px 0;
  }
}

@mixin mobileStyle {
  .chatItem {
    margin: 0 0 10px;
  }
  .leftItem .chatValue {
    font-size: 13px;
    line-height: 20px;
  }
  .rightItem .chatValue {
    font-size: 13px;
    line-height: 20px;
  }
  .errorHint {
    line-height: 20px;
  }
  .chatTime {
    font-size: 13px;
    line-height: 20px;
  }
  .generateAnimation {
    left: 0;
  }
  .chatInfo {
    margin: 0px;
  }
  .indent {
    margin-left: 70px;
  }
  .rightItem .chatInfo {
    width: unset;
  }
}
.canvasChatItem {
  @include mobileStyle;
}
@media only screen and (max-width: $padWidth) {
  .btns {
    button {
      padding: 5px 10px;
      font-size: 12px;
    }
  }
  @include mobileStyle;
}
