.icon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &_preview {
    width: 45.724px;
    height: 30px;
    fill: linear-gradient(134deg, #ff42c0 0%, #fa5e7b 50%, #ffab73 100%);
  }

  &_button {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
