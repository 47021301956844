.logoButton {
  display: inline-flex;
  width: 40px;
  height: 60px;
  overflow: hidden;
  position: relative;
  margin-left: 20px;
  margin-right: 10px;
  // cursor: pointer;
  .content {
    width: 40px;
    height: 100%;
    position: absolute;
    // top: -10px;
    display: flex;
    justify-content: center;
  }
  .logoImage {
    height: 20px;
  }
}
.logoButton.isListPage {
  cursor: auto;
}
.houseBox {
  height: 100%;
  display: flex;
  align-items: center;
}
.isShowLogoBtn {
  display: flex;
}
.isShowHomeTopToolBarMenu {
  display: none;
}
@media only screen and (min-width: 718px) and (max-width: 979px) {
  .isShowLogoBtn {
    display: none;
  }
  .isShowHomeTopToolBarMenu {
    display: block;
    align-self: center;
  }
  .logoButton.isListPage {
    cursor: pointer;
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  .isShowLogoBtn {
    display: none;
  }
  .isShowHomeTopToolBarMenu {
    display: block;
    align-self: center;
  }
  .logoButton.isListPage {
    cursor: pointer;
  }
}
@media only screen and (max-width: 309px) {
  .isShowLogoBtn {
    display: none;
  }
  .isShowHomeTopToolBarMenu {
    display: block;
    align-self: center;
  }
  .logoButton.isListPage {
    cursor: pointer;
  }
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
