.AISaasShare {
  background: #fff;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 10 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  font-family: var(--fontFamily, "Ak-Regular");
}

// .account {
//   top: 30px;
//   right: 70px;
//   position: absolute;
// }

.AISaasShare::-webkit-scrollbar {
  width: 10px;
  background-color: #020c261a; /* 滚动条整体背景，一般被覆盖着 */
}

.AISaasShare::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: white; /* 滚动条整体背景，一般被覆盖着 */
}

.AISaasShare::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
.saas-content {
  background-image: var(
    --background,
    url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/aiSaasBackground2x.png")
  );
  background-color: var(--renderingTransitionColors, #ecf2fc);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  // user-select: none;
}

// @media (min-width: 768px) and (max-width: 1920px) {
//   .account {
//     right: 50px;
//   }
// }

// @media screen and (max-width: 768px) {
//   .account {
//     top: 20px;
//     right: 20px;
//   }
// }
