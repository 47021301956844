.CardImplication {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 20px;
  padding: 15px;
  margin: 22px 20px;
}
.CardImplication .title {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #676e7a;
}
