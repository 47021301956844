.slider {
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
  gap: var(--spacing-extra-small, 5px);
  align-self: stretch;
  cursor: pointer;

  &__controls {
    display: flex;
    height: 100%;
    flex: 1;
  }

  &__button {
    display: flex;
    width: 35px;
    align-items: center;
    gap: 15px;
    height: 100%;

    &_left {
      display: flex;
      justify-content: flex-start;
    }

    &_right {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.sliderControls {
  display: flex;
  align-items: center;
  gap: var(--spacing-extra-small, 5px);
  align-self: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  width: 300px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__control {
    display: flex;
    height: 100%;
    align-items: center;
    align-self: stretch;

    &:hover > .sliderControl {
      height: 10px;
      background-color: rgba(181, 181, 181, 0.65);
    }
  }
}
.sliderControlBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sliderControlLftArrow,
  .sliderControlRgtArrow {
    width: 35px;
    height: 20px;
  }
  .sliderControlRgtArrow {
    display: flex;
    justify-content: end;
  }
  div {
    display: flex;
    gap: 5px;
    align-self: center;
    width: 100%;
  }
}

.sliderControlBlocks {
  display: flex;
  // width: 39px;
  height: 3px;
  border-radius: 2px;
  background-color: #e6e7e9;
  transition: height 0.3s, background-color 0.3s;

  &_active {
    background-color: #656a75;
  }

  &:hover {
    // height: 20px;
    padding: 4px 0;
    background-color: #e6e7e9;
    border-radius: 5px;
  }
}
