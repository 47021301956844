@import "../AISaas/AISaasLayout/vars.scss";

:global(.react-flow__panel).designPreviewPane {
  display: flex;
  flex-direction: column;
  top: 95px !important;
  bottom: 25px;
  width: 460px;
  z-index: 5;
  right: 25px !important;
}

:global(.react-flow__panel).designPreviewPane.silder {
  width: $panel-wrap-width;
}

:global(.react-flow__panel).coverSettingPane {
  width: 800px;
}

@media only screen and (max-width: 767px) {
  :global(.react-flow__panel).designPreviewPane {
    width: 100%;
  }
}

.previewPane {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  position: relative;

  background: transparent;
  background-color: #fff;

  .closePreviewApp {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
  }

  .paneIn {
    animation: showContent 0.3s ease-in-out forwards;
  }

  .paneOut {
    animation: hideContent 0.3s ease-in-out forwards;
  }

  .historyContent {
    visibility: visible;
  }

  .publishContent {
    visibility: visible;
  }

  .previewContent {
    visibility: visible;
  }

  .hidePublishContent {
    display: none;
  }
}

.previewLayout {
  height: 100%;
  padding: 25px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-size: cover;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg");
}

.title {
  color: #12192b;
  text-align: center;
  font-family: AktivGrotesk-Medium;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
}

.description {
  color: #656a75;
  text-align: center;
  font-family: AktivGrotesk-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.mode {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.modeText {
  padding-right: 10px;
  color: rgba(18, 25, 43, 0.65);
  font-family: AktivGrotesk-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.modeImgWrapper {
  margin-top: 20px;
  height: calc(100% - 110px);
  overflow-y: scroll;
}

.modeImg {
  width: 100%;
  height: auto;
}
