.loadingWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-color: #fff;
  z-index: 2;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
