.profile {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profile__image {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.profile__arrow {
  display: flex;
  width: 20px;
  height: 20px;

  transform: rotate(90deg);
}

.profile__arrow_open {
  transform: rotate(270deg);
}

.dropdown {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-left: 10px;
}

.dropdown_content {
  display: flex;
  width: 250px;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background: #fff;
  /* background: rgba(243, 244, 245, 1); */
  box-shadow: 0 0 5px rgb(209, 209, 209);
  /* box-shadow: 0px 3px 10px 0px rgba(55, 69, 108, 0.15); */
  margin-left: -50px;
}

.dropdown__item {
  display: flex;
  /* height: 60px; */
  height: 40px;
  /* padding: 0px 5px; */
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
  align-self: stretch;
  cursor: pointer;
}

.dropdown__container {
  display: flex;
  /* padding: 20px; */
  padding: 5px 20px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.dropdown__container:hover {
  /* border-radius: 10px; */
  background: rgba(10, 157, 255, 0.1);
}

.dropdownItem {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 30px;
}

.dropdownItem__image {
  width: 20px;
  height: 20px;
}

.dropdownItem__text {
  color: #12192b;
  font-family: Ak-Regular;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.dropdownItemLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.dropdownTitle {
  font-family: "Ak-Regular";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: rgba(18, 25, 43, 1);
  padding: 5px 20px;
  gap: 10px;
  height: 30px;
}
.dropdownSwitchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdownSwitchBox:hover {
  cursor: default;
}
.dropdownIconBox {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #12192b;
  /* svg path,
  svg circle {
    stroke: #676e7f;
  }

  .mzd path {
    fill: black;
  } */
}
.dropdown :global(.ant-dropdown) {
  left: -130px !important;
  top: 60px !important;
}

.dropdown_content_mobile {
  background: rgba(83, 87, 100);
  .dropdownTitle {
    display: none;
    height: 0;
  }
  .dropdownItem__text {
    color: #fff;
  }
  .dropdown__container:hover,
  .dropdown__container:active,
  .dropdown__container:focus {
    background: rgba(2, 12, 38, 0.35);
  }

  .dropdownIconBox {
    color: #fff;
  }

  /* .dropdownIconBox {
    svg path,
    svg circle {
      stroke: #fff;
    }
    .mzd path {
      fill: #fff;
    }
  } */
}

.ablyStatus {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
