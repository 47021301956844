.card {
  position: relative;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  font: {
    size: 13px;
    family: Ak-Regular;
    weight: 500;
  }
  line-height: 20px;

  .title {
    color: #acaeb5;
    font-weight: 600;
  }

  .item {
    margin-top: 20px;

    &Title {
      color: #12192b;
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      p {
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    &Desc {
      margin-top: 5px;
      color: #656a75;
      font-weight: 400;
    }

    &ActionBar {
      margin-top: 10px;
      background-color: #f3f3f4;
      border-radius: 10px;
      padding: 15px 20px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &Title {
        color: #656a75;
        font-weight: 400;
      }

      &Btn {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &:last-child .itemActionBar {
      padding-right: 15px;
    }
  }
  .inputContainerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    border-style: none;
    //background: #f3f3f4;
    margin-top: 5px;
  }
  .buttonStyle {
    font-family: Ak-Regular;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;

    line-height: 19px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    border-style: none;
    height: 20px;
    margin-right: 10px;
  }
  .inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    border-style: none;
    background: #f3f3f4;
    margin-top: 5px;
    .input {
      padding: 15px 10px;
      background-color: transparent;

      color: #12192b;
      font-family: Ak-Regular;
      font-size: 13px;

      font-weight: 400;
      line-height: 20px;
    }
    .inputLinkContainer {
      padding: 15px 0 15px 20px;
      flex: 1;
      overflow: hidden;
      margin-right: 20px;
    }
    .inputLink {
      background-color: transparent;

      font-family: Ak-Regular;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #0a9dff;
      text-decoration: underline;
      text-decoration-color: #0a9dff;
      display: inline;
    }
    .input::placeholder {
      color: #acaeb5;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }

    .saveButton {
      @extend .buttonStyle;
      background: transparent;
      color: #0a9dff;
    }
    .saveButtonDisable {
      @extend .buttonStyle;
      //background: transparent;
      color: white;
      border-radius: 5px;
      background: #acaeb5;
    }
  }

  .validatingButton {
    @extend .buttonStyle;
    color: #fff;
    background: #ef930a;
    margin-right: 0;
  }
  .connectedButton {
    @extend .buttonStyle;
    color: #fff;
    background: #05be75;
    cursor: default;
    margin-right: 0;
  }
  .stepContainer {
    margin-top: 10px;
    .stepStyle {
      color: #656a75;
      /* Caption [M] */
      font-family: Ak-Regular;
      font-size: 13px;

      font-weight: 500;
      line-height: 20px; /* 153.846% */
    }
  }
  .notPublishText {
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    align-self: stretch;
    color: rgba(18, 25, 43, 0.65);
    font-family: "Ak-Regular";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 10px;
    background: rgba(10, 157, 255, 0.1);
    border-style: none;
  }
  .errorText {
    color: #ff335c;
    display: flex;
    padding: 0 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    align-self: stretch;

    font-family: "Ak-Regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.alert {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
