.MapImgLIst {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 5px;
  position: relative;
}
.MapImgLIst::-webkit-scrollbar {
  display: none;
}
.MapImgLIst .ImgItem {
  width: 80px;
  height: 80px;
  margin-right: 5px;
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
}
.MapImgLIst .ImgItem img {
  width: 100%;
  height: 100%;
}
