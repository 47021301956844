.example {
  background-color: #a4a7b1;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  text-align: center;
}

.title {
  font-size: 40px;
  color: #ff4d4f;
}
