.nodeCovering {
  min-width: 100% !important;
  min-height: 100% !important;
  position: relative;
  user-select: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  overflow: hidden;
  background: var(--nodeWrapperBgColor, #fff);
  box-shadow: var(--nodeWrapperShadow, none);
  backdrop-filter: var(--nodeBackdropFilter, none);
  /* padding: 8px 10px 10px; */
  /* gap: 12px; */
  gap: 15px;
}
.nodeWrapPadding {
  padding: 10px 10px 10px;
}
.stockInfoResetSpace {
  padding: unset;
}
.darkNodeWrapper {
  background-color: rgba(28, 28, 32, 0.8);
}

.lightNodeWrapper {
  background-color: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
}

.nodeCovering .nodeContent {
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  /* margin-top: 8px; */
  justify-content: center;
}
/* .nodeWrapPadding > div {
  margin-top: 12px;
} */
.stockInfoResetSpace > div {
  margin: unset;
}
.bordered {
  border: var(--nodeWrapperBorder, 0.5px solid rgba(255, 255, 255, 0.35));
  background: var(--nodeWrapperBgColor, #fff);
  padding: 10px;
  border-radius: 30px;
}

.TitleBar {
  font-weight: 400;
  font-size: 13px;
  color: rgba(2, 12, 38, 0.35);
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 10px 10px 15px;
  height: 40px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
}

.deleteContent {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.scrollContent {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
}

.scrollList {
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
}
.scrollList::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
.scrollList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}
.scrollList::-webkit-scrollbar-track {
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.loadMore {
  display: flex;
  justify-content: center;
  width: 100%;
  /* padding: 20px 0; */
  padding: 20px 0 0;
}

.loadMore div {
  cursor: pointer;
  width: 95px;
  height: 30px;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: rgba(2, 12, 38, 0.65);
}

.deleteContent {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
}
.deleteContent img {
  width: 100%;
  height: 100%;
}

:global(.dark) .nodeCovering,
:global(.dark) .scrollContent {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
:global(.dark) .video-item-content span {
  color: #ffffff;
}
:global(.dark) .loadMore div {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.nodeContent {
  flex: 1;
  box-sizing: border-box;
}
/* .nodeContentSpace {
  padding-top: 15px;
} */
.nodeUnsetPaddingTop {
  padding-top: unset;
}

.nodeUnsetMarginTop {
  margin-top: unset !important;
}

.fitNodeContent {
  width: 100% !important;
}

.reasonIcon {
  position: absolute;
  right: 15px;
  top: 12px;
}

.reasonPopover :global(.ant-popover-inner-content) {
  text-align: left;
}

.nodeCovering > .nodeText {
  flex: 1;
}

.overFlowVisible {
  overflow: visible !important;
}
