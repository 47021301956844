.dropdown {
}

.overlayDropdown {
  padding: 0;

  & .ant-popover {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }

  & :global(.ant-popover-inner-content) {
    padding: 0 !important;
  }
}
