.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: auto;
}

.chatContent {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  margin-bottom: 10px;
}

.chatMessageLoading {
  margin-bottom: 10px;
}

.chatInptContainer {
  width: 100%;
  flex-shrink: 0;
}

.chatContent::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.chatContent::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}

.chatContent::-webkit-scrollbar-track {
  -webkit-box-shadow: none; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
