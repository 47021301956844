.avatar-box {
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.05));
  overflow: hidden;
}
.user-avatar {
  width: 100%;
  height: 100%;
}
