.customJSFunction-box {
  position: fixed;
  top: 80px;
  z-index: 10;
}
.occlusion {
  box-shadow: 0 0 0 20px #d2d5de;
  transition: 1s;
}
.title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  font-family: "Ak-Regular";
  width: 300px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: #1f1f1f;
  font-family: "Ak-Regular";
  width: 300px;
}

:global(.ant-drawer-bottom > .ant-drawer-content-wrapper) {
  top: 0;
  height: 100% !important;
  box-shadow: none;
}

.input-box {
  width: 300px;
}
.input-box:first-child {
  margin-bottom: 10px;
}
:global(.ant-drawer-body) {
  padding: 0;
}

.content-box {
  position: relative;
  background-color: #444;
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.preview-code,
.code-box {
  color: #fff;
  font-size: 12px;
  font-family: "Ak-Regular";
  height: 49%;
  background-color: #f4f4f4;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.preview-code {
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  margin-bottom: 90px;
}

.code-box {
  padding: 0 5px;
  background-color: #444;
  display: flex;
  justify-content: space-around;
}
.code-editor {
  flex: 1 1;
  height: 100%;
  background-color: #444;
  padding: 5px;
  margin: 0;
  max-width: 33.3%;
}

.code-title {
  padding: 8px 15px;
  width: max-content;
  background: #f4f4f4;
  color: #444;
  font-weight: 700;
  font-size: 14px;
}

.CodeEditor {
  background: var(--codeEditorBg, #f4f4f4);
  font-size: 14px;
  tab-size: 4;
  color: #333;
  font-family: "Consolas";
  line-height: 18px;
  min-height: 100%;
}
.CodeEditor pre {
  overflow: hidden;
}
.code-text-box {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 26px);
}
.preview-code::-webkit-scrollbar,
.code-text-box::-webkit-scrollbar {
  display: none;
}

:global(.data-empty) {
  color: #ccc;
}

.example {
  position: fixed;
  background-color: #00000017;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tool-bar-box {
  width: 100%;
  position: absolute;
  bottom: 1%;
  display: flex;
  justify-content: center;
}

.bottom-tool-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 5px 5px -8px #000;
  border-radius: 10px;
}
.bottom-tool-bar :global(.icon-bianliang),
.bottom-tool-bar :global(.icon-i-info) {
  padding: 5px;
  font-size: 25px;
  cursor: pointer;
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.bottom-tool-bar :global(.icon-bianliang):hover,
.bottom-tool-bar :global(.icon-i-info):hover {
  background: #eee;
}

.chatBoxWrap {
  position: fixed;
  top: 80px;
  right: 0;
  width: 35%;
  padding: 10px;
}
