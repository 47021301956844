.box {
  position: relative;
  width: 100%;
  margin-top: 24px;
  .colorLabel {
    color: var(--Text-Description, rgba(18, 25, 43, 0.65));
    /* Nat 2.0/Body */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .colorValue {
    color: var(--Text-Default, #12192b);
    /* Nat 2.0/Body */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .circleBox {
    margin-top: 8px;
    .circle {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      overflow: hidden;
      border-style: none;
      margin-right: 8px;
      position: relative;
      img {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
