.switchBarWrapper {
  text-align: center;
}
.switchBar {
  display: inline-flex;
  padding: var(--Natural-2-Gap-Medium, 8px) var(--Natural-2-Gap-Large, 16px);
  align-items: flex-start;
  gap: var(--Natural-2-Gap-Large, 16px);
  border-radius: var(--Natural-2-Radius-Main, 44px);
  border: var(--Stroke-Card, 0.5px) solid var(--Stroke-Stroke-Faded, rgba(255, 255, 255, 0.35));
  background: var(--Surfaces-Faded-Easy, rgba(255, 255, 255, 0.35));
  margin: 0 auto;
  margin-bottom: 5px;
}
.item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(18, 25, 43, 0.1);
  &:hover {
    background-color: rgba(18, 25, 43, 0.65);
  }
}

.active {
  background-color: rgba(18, 25, 43, 0.65);
}
