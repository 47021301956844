.loadingWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  // left: 0;
  // top: 0;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingWrapper .lottieLoader {
  width: 80px !important;
  height: 80px !important;
  // margin-bottom: 20px;
}
.loadingText {
  font-size: 15px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
}
:global(.dark) .loadingText {
  color: #ffffff;
}
