.container {
  display: flex;
  flex-direction: column;
}
.loadingContainer {
  width: 100%;
  height: 100%;
}

.loadingWrapper {
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingText {
  width: 100%;
  color: #000;
  text-align: center;
  margin: 31px 0;
  color: #b7b7b7;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}
