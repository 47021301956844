.searchBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 40px;
  border-radius: 25px;
  margin: 0 15px;
  width: auto;
  border-radius: 25px;
  border: 1px solid var(--shoppingSearchBarBorderColor, rgba(2, 12, 38, 0.05));
}

.searchBar .img-box {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 15px;
  overflow: hidden;
}
.searchBar .img-box img {
  width: 100%;
  height: 100%;
  filter: var(--shoppingSearchIconBrightness, brightness(0));
}

.searchBar input {
  background: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--shoppingSearchBarInputColor, rgba(2, 12, 38, 0.35));
}
.scrollContent {
  position: relative;
  /* padding: 0 5px; */
  box-sizing: border-box;
  background: unset !important;
  /* border-radius: unset !important; */
  border-radius: 30px;
  overflow: hidden;
}

.inner {
  /* background: #fff; */
  border-radius: 10px;
  height: 100%;
  position: relative;
  padding-top: 15px;
}

.scrollList {
  padding: 0 25px;
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--shoppingItemInfoSpanColor, #12192b);
  padding: 10px 15px;
}

.ShoppingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 10px;
  cursor: pointer;

  border-radius: 10px;

  background: var(--shoppingItemBg, #fff);
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
}

.shoppingItemImgWrapper {
  padding: 5px;
  box-sizing: border-box;
  flex: 1 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShoppingItem img {
  object-fit: contain;
  max-width: 70px;
  max-height: 70px;
}

.ShoppingItemInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  overflow: hidden;
  height: 80px;
}

.ShoppingItemInfo div {
  font-family: var(--fontFamily, Inter);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: var(--shoppingItemInfoColor, rgba(2, 12, 38, 0.35));
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ShoppingItemInfo span {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--shoppingItemInfoSpanColor, #12192b);
}

.target {
  border-radius: 30px 30px 0 0;
  border-bottom: 0;
}

.target-inner {
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
}

.hideShoppingList {
  opacity: 0;
}

/* :global(.dark) .inner, */
:global(.dark) .ShoppingItem {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
:global(.dark) .searchBar img {
  overflow: hidden;
  transform: translateX(30px);
  filter: drop-shadow(#ffffff -30px 0px 0px);
}
:global(.dark) .title,
:global(.dark) .searchBar input {
  color: #ffffff;
}
:global(.dark) .ShoppingItemInfo div,
:global(.dark) .ShoppingItemInfo span {
  color: #ffffff;
}
