.Feed {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background-color: #f0f6f8;
  border: solid black 0.01em;
  min-height: 100%;
  max-height: 100%;
  border-radius: 2.7em;
  display: grid;
  grid-template-rows: 1fr 5fr;
  grid-template-columns: 1fr; */
}
/* .Feed-Focus {
  display: flex;
  flex-direction: column;
  background-color: #f0f6f8;
} */
.Feed-DomainsList {
  position: relative;
  max-width: 96%;
  white-space: nowrap;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.Feed-DomainsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.Content.thought {
  flex: 1;
}
.Feed .Top-Menu-Focus {
  background: #ffffff;
}
.Feed .Top-Menu {
  background: #fff;
  padding: 0 25px 20px;
}
.Feed .Feed-Thought-Filter p {
  font-family: "Ak-Regular";
  font-size: 18px;
  line-height: 25px;
  color: #22232f;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}
.Feed .Feed-Thought-Filter .tap-to-edit {
  cursor: pointer;
  flex: 1;
}

.Feed .Feed-Thought-Filter .tap-to-edit span {
  font-family: "Ak-regular";

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7d8fa1;
}

.Feed-Hidden {
  display: none;
}
.Feed-Filters {
  display: flex;
  overflow: auto;
  margin-top: 10px;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
}
.Feed-Filters::-webkit-scrollbar {
  display: none;
}
.Feed-Thought-Filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Feed-Drag-Box {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.Condensed-Content {
  padding: 20px 0;
}
.Feed-Domains {
  margin: 0 0 0 -3%;
}
.Feed-Domain-Skeleton {
  display: flex;
  align-items: center;
  margin-right: 4%;
}
.Feed-Top-Skeleton {
  padding: 20px;
  margin: 0 25px;
}
.Feed-Top-Skeleton .ant-skeleton-button {
  height: 20px;
}
.Feed-Top-Box {
  display: flex;
  margin-bottom: 10px;
}
.Feed-Top-Box .Feed-Top-First {
  width: 52%;
  margin-right: 10px;
}
.Feed-Top-Box .Feed-Top-Second,
.Feed-Top-Skeleton .Feed-Top-Third {
  width: 35%;
}
.Feed-Content-Skeleton.ant-skeleton {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px;
  margin: 0 25px 10px;
  width: calc(100% - 50px);
}
.Feed-Content-Skeleton .ant-skeleton-title {
  height: 20px;
}
.Feed-Content-Skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  height: 10px;
  margin-top: 10px;
}
.refresh-box {
  position: absolute;
  bottom: -1rem;
  left: 0.75rem;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.feed-edit-query {
  flex: 1;
  height: 100%;
  position: relative;
  background: #ffffff;
  /* box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05); */
  border-radius: 40px;
  padding: 10px;
  outline: none;
  border: 1px solid #eee;
  font-size: 15px;
  font-family: "ak-regular";
  z-index: 3;
}
.feed-edit-query::-webkit-input-placeholder {
  font-weight: 400;
  line-height: 20px;
  color: #7d8fa1;
}
