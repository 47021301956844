.commandItem {
  border-radius: var(--radius-small, 10px);
  margin-top: 10px;

  cursor: pointer;
  display: flex;
  width: 100%;
  min-height: 70px;
  padding: var(--spacing-spacing-small, 10px);
  align-items: center;
  gap: var(--spacing-spacing-medium, 20px);
  flex-shrink: 0;
}

.commandItem:hover {
  background: rgba(10, 157, 255, 0.1);
}

.commandItem .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.commandItem .content {
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1 0 0;
}

.commandItem .content .title {
  color: var(--Dark, #12192b);
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.commandItem .content .desc {
  color: var(--Dark-35, #acaeb5);
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.commandItem .content .score {
  color: #0a9dff;
  font-family: Ak-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
}
