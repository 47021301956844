.previewOutput {
  border-radius: 20px;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;

  [data-node-type="midjourney"],
  [data-node-type="midjourneyV2"],
  [data-node-type="askBrain"],
  [data-node-type="text"] {
    // 覆盖nodes 组件外层的边距
    padding: 0;

    [data-testid="NodesContent"] {
      margin-top: 0;
    }
  }

  [data-node-type="askBrain"],
  [data-node-type="text"] {
    background: transparent;
  }
}

.previewOutputText {
  background: var(--textboxBg, #fff);
  color: var(--textboxColor, #000);
  box-shadow: var(--nodeWrapperShadow);
  backdrop-filter: var(--nodeBackdropFilter, none);
  font-size: 1em;
  line-height: 1.6em;
  // padding: 0.2em 0.5em;
  padding: 5px 5px;
  // margin: 15px 25px;
  border-radius: 10px;

  // 覆盖 shareBuy 组件样式
  [data-testid="ShareBuy"] {
    position: static;
  }
}
