.c2Box {
  position: relative;
  width: 100%;
  min-height: 160px;
  // max-height: 220px;
  display: flex;
  // padding: 5px;
  margin-top: 10px;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  background: var(--textboxBg, #fff);
  :global(.ant-carousel) {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }
  :global(.slick-slider) {
    height: 100%;
    :global(.slick-list) {
      height: 100%;
      :global(.slick-track) {
        height: 100%;
        :global(.slick-slide) {
          height: 100%;
          & > div {
            position: relative;
            height: 100%;
          }
        }
      }
    }
  }
}
.textBox {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
}
