.embedBox {
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  // box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  margin: 20px 0;
}

.title {
  color: #acaeb5;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.embedSizeBox {
  margin-top: 20px;
}

.blackTitle {
  color: #12192b;
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
}
.remindTitle {
  color: #656a75;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}

.centerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploadCenterBox {
  margin: 10px 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  display: flex;
  width: 90px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}
.logoBoxImg {
  max-width: 90px;
  max-height: 90px;
  object-fit: cover;
}
.uploadTxtBox {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
}
.uploadTxtBox :global(.ant-upload.ant-upload-drag) {
  background-color: #fff;
  border-radius: 10px;
}
.uploadTxt {
  color: #0a9dff;
  text-align: center;
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  height: 58px;
  line-height: 58px;
  display: flex;
  justify-content: center;
}

.bottomPromptBox {
  border-radius: 10px;
  background: #f3f3f4;
  display: flex;
  min-width: 85px;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  margin: 10px 0 20px;
}

.testPrompt {
  flex: 1;
  color: #656a75;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}
.copyrightTitle {
  color: rgba(18, 25, 43, 0.65);
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.copyrightInput {
  border-radius: 10px;
  background: rgba(2, 12, 38, 0.05);
  line-height: 20px;
  padding: 15px 20px;

  color: rgba(18, 25, 43, 0.35);
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  input {
    background-color: transparent;
    &::placeholder {
      font-size: 12px;
    }
  }

  border: none;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    border: none;
  }
}
