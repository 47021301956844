.title {
  color: var(--Dark, #12192b);
  text-align: center;
  /* Subheader */
  font-family: AK-Regular;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 25.5px */
}
.message {
  margin-top: 10px;
  color: rgba(18, 25, 43, 0.65);
  text-align: center;
  /* Caption */
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0 20px;
  word-wrap: break-word;
}

.content {
  min-width: 260px;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
}
.bottomContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  //gap: 10px;
  align-self: stretch;
  .cancel {
    display: flex;
    height: 40px;
    min-width: 100px;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: rgba(2, 12, 38, 0.05);

    color: rgba(18, 25, 43, 0.65);
    text-align: center;
    /* Body [M] */
    font-family: Ak-regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
    border-style: none;
  }
  .confirm {
    margin-left: 15px;
    color: var(--Dark-65, rgba(18, 25, 43, 0.65));
    text-align: center;
    display: flex;
    height: 40px;
    min-width: 100px;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: #ff335c;
    border-style: none;

    /* Body [M] */
    color: white;
    text-align: center;
    /* Body [M] */
    font-family: Ak-regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
  }
}
