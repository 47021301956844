.title {
  font-family: "Ak-Regular";
  color: #12192b;
  font-size: 20px;

  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 440px;
}

.link {
  cursor: pointer;
  color: #4a87ff;
}

.wrapperUnPublished {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0.65) 100%),
    linear-gradient(72deg, #757f9a 0%, #d7dde8 100%);
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  background-color: #f8f8f8;
  width: 720px;
  border-radius: 25px;
  padding: 40px 0px 60px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box img {
  margin-bottom: 40px;
}

@media screen and (max-width: 480px) {
  .box {
    width: 100%;
    padding: 30px;
  }
  .title {
    font-size: 16px;
  }
}
