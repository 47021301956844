.container {
  border-radius: 44px;
  border: 0.5px solid rgba(255, 255, 255, 0.35);
  background: rgba(255, 255, 255, 0.35);
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  align-self: stretch;
  min-width: 280px;
}
.upperLayer {
  display: flex;
  align-items: center;
  gap: 44px;
  height: 84px;
}
.weatherImage {
  width: 72px;
  height: 72px;
}
.weatherInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.temperature {
  color: #12192b;
  text-align: right;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

.description {
  color: rgba(18, 25, 43, 0.65);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.middleLayer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow: auto;
}

.middleLayer::-webkit-scrollbar {
  display: none;
}

.weatherItem {
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(18, 25, 43, 0.05);
  flex: 1;
  min-width: 105px;
}

.icon {
  width: 16px;
  height: 16px;
}

.name {
  color: rgba(18, 25, 43, 0.65);
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.value {
  margin-top: 4px;
  color: #12192b;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.lowerLayer {
  align-self: stretch;
  color: #12192b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
