.loader {
  z-index: 40;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #12192b1a;
  backdrop-filter: blur(10px);

  .content {
    width: 70%;
    max-width: 300px;
    height: 200px;
    background: #f8f8f8;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 40px 0px #7190af1a;
    .loaderSize {
      transform: scale(1.2);
    }
  }
}
