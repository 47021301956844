.planMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.planTablePopup {
  //max-width: 975px;
  margin: auto;

  :global {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      border-radius: 20px;
    }
  }
}

.planTablePopup.full {
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 100px);
  top: 50%;
  transform: translateY(-50%);

  :global {
    .ant-modal-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      // 为了 loading 有位置
      min-height: 200px;
      max-height: 100%;
    }

    .ant-modal-content {
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.popupHeader {
  display: flex;
  align-items: center;
  padding: 20px;
}

.popupBody {
  position: relative;
  overflow: auto;
  flex: 1;
  background: #d7d9e0;
  height: calc(100% - 60px);
  -webkit-overflow-scrolling: touch;
}

.tableMain {
  //min-width: 800px;
}

.loadingLayer {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  cursor: pointer;
}

.backIcon {
  margin-right: 15px;
}

.title {
  flex: 1;
}

.planPopupWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  //&::after {
  //  position: absolute;
  //  content: "";
  //  width: 100%;
  //  height: 0;
  //  bottom: 0;
  //  left: 0;
  //  background-image: linear-gradient(rgba(255, 255, 255, 30%) 0%, #ffffff 65%);
  //  z-index: 2;
  //  border-bottom-left-radius: 40px;
  //}
  //
  //&.bottomFade::after {
  //  height: 45px;
  //}
}

@media screen and (min-width: 980px) {
  .planTablePopup.full {
    max-width: 80%;
  }
}

@media screen and (min-width: 1204px) {
  .planTablePopup.full {
    max-width: 1040px;
  }
}
