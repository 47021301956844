.submit-button {
  border: var(--submitButtonBorder);
  background: var(--submitButtonBgClr, "#12192B");
  color: var(--submitButtonColor, "#fff");
  border-radius: var(--submitButtonBorderRadius, 25px);
  padding: var(--inputPadding, 5px 30px);
  height: 50px;
  padding-left: 35px;
  // margin: 28px;
  margin: 20px;
  width: auto;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.cannot-select {
  transition: 0.3s;
  background: #d9d9d9;
  color: #00000040;
}
.saas-loading {
  background-color: #d9d9d930;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.inputAddToContent {
  margin-bottom: 20px;
}
