.header {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 120px;
  .label {
    //styleName: Body [M];
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #12192ba6;
    flex: 1;

    span {
      margin-left: -120px;
    }
  }
}
