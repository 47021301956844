.jira-box {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin: 20px 25px 0 25px;
  padding: 15px 20px;
}
.jira-box .icon-right {
  position: relative;
  z-index: 1;
  color: #22232f;
  transition: all 0.3s;
  display: block;
  padding: 10px;
  margin: -10px;
  cursor: pointer;
}
.jira-box .icon-rotate {
  transform: rotate(90deg);
  transition: all 0.3s;
}
.head-box {
  display: flex;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  font-family: "Ak-Regular";
  font-weight: 500;
  justify-content: space-between;
  align-items: flex-start;
}
.jira-content {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #3f3f50;
  margin-top: 6px;
  white-space: pre-wrap;
}
.over-title {
  width: calc(100% - 40px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.over-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.jira-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  font-family: "Ak-Regular";
  color: #959fb1;
  margin-top: 16px;
}
.jira-footer img {
  width: 19px;
  height: 19px;
  margin: 0 5px;
}
.jira-footer p {
  font-weight: 400;
  font-size: 12.4202px;
  line-height: 19px;
  display: flex;
  align-items: center;
  background: #06e278;
  padding: 2px 11px;
  border-radius: 28.662px;
  color: #ffffff;
}
.circle-item {
  width: 19px;
  height: 19px;
  border-radius: 50%;
}
.f-left {
  display: flex;
  align-items: center;
}
