@mixin content {
  height: auto;
  background: #fff;
  padding: 10px;
  border-radius: 30px;
  box-sizing: border-box;
}

.reject-content {
  width: 250px;
  @include content;
}

.reject-text-content {
  width: 100%;
  @include content;
}

.reject-box {
  background: rgba(204, 41, 95, 0.05);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  box-sizing: border-box;
}
.reject-box .textContent {
  max-height: 500px;
  overflow-y: scroll;
}
.textContent::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  /* 滚动条整体背景，一般被覆盖着 */
}

.textContent::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1);
  /* 滚动条整体背景，一般被覆盖着 */
}

.textContent::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent;
  /* 滚动条整体背景，一般被覆盖着 */
}

.reject-box p {
  word-break: break-word;
  font-family: ak-regular;
  font-size: 15px;
  line-height: 20px;
  color: #cc295f;
}
.btn-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  width: 80px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
  border-radius: 25px;
  font-family: ak-regular;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #12192b;
  margin-top: 30px;
}
