.userAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.menuWrap {
  width: 200px;
  padding: 10px;
  cursor: pointer;
  border-radius: 15px;
  background: #ebebed;
  box-shadow: 0px 16px 24px -2px rgba(58, 57, 76, 0.12), 0px 2px 12px 0px rgba(58, 57, 76, 0.1);
}

.menuItem {
  width: 100%;
  padding: 10px;
  color: #12192b;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--fontFamily, Ak-Regular);
}

.payContainer {
  width: auto !important;
  :global(.ant-modal-content) {
    border-radius: 20px;
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
}

.subscribeWrap {
  width: 380px;
  padding: 5px;
  background: #fff;
  border-radius: 30px;

  .titleWrap {
    padding: 30px;
    border-radius: 25px;
    background: linear-gradient(61deg, #4776e6 -50.57%, #b88bff 103.64%);
    font-family: var(--fontFamily, Ak-Regular);
    text-align: center;
  }

  .title {
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    line-height: 30px;
  }

  .subTitle {
    margin-top: 10px;
    font-family: var(--fontFamily, Ak-Regular);
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-size: 16px;
    line-height: 25px;
  }

  .btn {
    color: #fff;
    margin: 0 auto 55px;
    display: block;
    font-family: var(--fontFamily, Ak-Regular);
    font-weight: 500;
    min-width: 160px;
    height: 50px;
    padding: 5px 40px;
    border-radius: 25px;
    background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
    span {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .link {
    margin-top: 10px;
    color: #b0b0b7;
    font-size: 14px;
    line-height: 30px;
    padding: 0 8px;
    white-space: nowrap;
    display: flex;
    .stripe {
      font-weight: 800;
      margin-left: 5px;
    }
    .line {
      padding: 0 8px;
    }
    .privacy {
      margin-left: 5px;
    }
  }
}

.unsubscribe {
  width: 380px;
  padding: 5px;
  background: #fff;
  border-radius: 30px;

  .titleWrap {
    padding: 30px;
    border-radius: 25px;
    background: linear-gradient(61deg, #4776e6 -50.57%, #b88bff 103.64%);
    font-family: var(--fontFamily, Ak-Regular);
    text-align: center;
  }

  .header {
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    line-height: 30px;
  }

  .title {
    color: #fff;
    text-align: center;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }

  .subTitle {
    margin-top: 10px;
    font-family: var(--fontFamily, Ak-Regular);
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-size: 16px;
    line-height: 25px;
  }

  .price {
    color: #12192b;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 68px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -0.68px;
  }

  .month {
    color: #12192b;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  .unSubBtn {
    display: flex;
    margin: 0 auto 55px;
    width: 160px;
    height: 50px;
    padding: 5px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: var(--Static-Blue-10, rgba(10, 157, 255, 0.1));
    border: 0;
    span {
      color: #0a9dff;
      text-align: center;
      font-family: var(--fontFamily, Ak-Regular);
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .cancelAt {
    color: #12192b;
    text-align: center;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 16px;
    line-height: 25px;
    padding: 0 25px;
    .date {
      font-weight: 600;
    }
  }

  .resubBtn {
    display: flex;
    width: 160px;
    margin: 30px auto 55px;
    height: 50px;
    padding: 5px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
    span {
      color: #fff;
      text-align: center;
      font-family: var(--fontFamily, Ak-Regular);
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
  }
}

.per {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0 30px;
  .money {
    font-family: var(--fontFamily, Ak-Regular);
    color: #12192b;
    text-align: center;
    font-size: 68px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -0.68px;
  }
  .unit {
    font-family: var(--fontFamily, Ak-Regular);
    color: #12192b;
    font-size: 16px;
    line-height: 25px;
  }
  .days {
    color: #12192b;
    text-align: center;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 68px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -0.68px;
  }
  .daysUnit {
    color: #12192b;
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-left: 5px;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.resubscribe {
  cursor: pointer;
  color: #0a9dff;
  min-width: 77px;
  text-align: center;
}

.loginContainer {
  // width: auto !important;
  :global(.ant-modal-content) {
    border-radius: 20px;
  }
}

.modelBtn {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
}

.modalButtonNormal {
  color: #12192ba6;
  background: #020c260d;
  font-size: 15px;
  font-family: var(--fontFamily, Ak-Regular);
  font-weight: 500;
  border: none;
  width: 110px;
}

.modalButtonDanger {
  font-size: 15px;
  font-family: var(--fontFamily, Ak-Regular);
  font-weight: 500;
  border: none;
  width: 130px;
  margin-left: 20px;
}

.notLogIn {
  position: relative;
  top: auto;
  right: auto;
}
