.article {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  gap: 4px;
  &.reverse {
    flex-direction: row-reverse;
  }
  .leftBox {
    width: 50%;
    border-radius: 44px;
  }
  .rightBox {
    width: 50%;
    padding: 24px;
    border-radius: 44px;
    overflow: hidden;
    color: #12192b;
    background-color: #fff;
    .title {
      display: -webkit-box;
      margin-bottom: 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      overflow: hidden;
    }
    .description {
      display: -webkit-box;
      margin-bottom: 8px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
    }
    .timestamp {
      color: rgba(18, 25, 43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }
  }
}
