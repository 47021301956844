.navber_back {
  width: 100%;
  height: 80px;
}
.homeNavBarBackgroud {
  background: #d2d5de;
}

.home_navber_box {
  position: absolute;
  // right: 20px;
  right: 25px;
  height: 60px;
  // left: 20px;
  left: 25px;
  top: 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: #e1e3e8;
}

.home_navber_box :global(.ant-menu-horizontal) {
  border-bottom: none;
}
.home_navber_box :global(.ant-menu-horizontal) {
  border-bottom: none;
}

.leftmenu {
  flex: 0.4;
  height: 60px;
  background-color: transparent;
  min-width: 280px;
}

.rightmenu {
  height: 60px;
  background-color: transparent;
  flex: 0.6;
  display: flex;
  flex-direction: row-reverse;
  min-width: 200px;
}

.space_line {
  height: 60px;
  display: flex;
  align-items: center;
}

.division {
  height: 30px;
  border-left: 1px solid rgba(2, 12, 38, 0.1);
}
