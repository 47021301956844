.AiSaasOutputList {
  position: relative;
  flex: 1;
}
.output-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AiSaasOutputList {
  [data-testid="SortableItem"] {
    border-top: 1px dashed transparent;
    border-bottom: 1px dashed transparent;
    &:hover {
      border-top: 1px dashed #0a9dff;
      border-bottom: 1px dashed #0a9dff;
    }
  }
}

.AiSaasOutputListMobile {
  [data-testid="SortableItem"] {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    &:hover {
      border-top: 2px solid #0a9dff;
      border-bottom: 2px solid #0a9dff;
    }
  }
}
