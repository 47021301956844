.Filter-Item {
  height: 30px;
  background: rgba(97, 158, 251, 0.75);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 12px;
  margin-right: 10px;
}
.Filter-Item p {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;

  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.Filter-Item .icon-guanbi {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  color: #677a8e;
  font-size: 12px;
  margin-left: 8px;
  cursor: pointer;
}
