.customAPIFunction-box {
  position: fixed;
  top: 80px;
  z-index: 10;
}
.occlusion {
  box-shadow: 0 0 0 20px #d2d5de;
  transition: 1s;
}
.title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  font-family: "Ak-Regular";
  width: 300px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  color: #1f1f1f;
  font-family: "Ak-Regular";
  width: 300px;
}
:global(.ant-drawer-bottom > .ant-drawer-content-wrapper) {
  top: 0;
  height: 100% !important;
}
:global(.ant-drawer-body) {
  padding: 0;
}
.data-empty {
  color: #ccc;
}
.input-box {
  width: 300px;
  display: block;
}
.input-box:first-child {
  margin-bottom: 10px;
}
.content-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tool-bar-box {
  width: 100%;
  position: absolute;
  bottom: 1%;
  display: flex;
  justify-content: center;
}

.bottom-tool-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 5px 5px -8px #000;
  border-radius: 10px;
}
.bottom-tool-bar :global(.icon-bianliang),
.bottom-tool-bar :global(.icon-i-info) {
  padding: 5px;
  font-size: 25px;
  cursor: pointer;
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.bottom-tool-bar :global(.icon-bianliang):hover,
.bottom-tool-bar :global(.icon-i-info):hover {
  background: #eee;
}
