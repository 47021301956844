.input-title-box {
  color: var(--saasTitleColor, "#12192B");
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  min-height: 41px;
  min-width: 200px;
  position: relative;
  border: 1px dashed #0000;
  z-index: 2;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  left: -2px;
  width: 100%;
}

@media only screen and (min-width: 568px) and (max-width: 799px) {
  .input-title-box {
    font-size: 16px;
  }
}

@media only screen and (max-width: 567px) {
  .input-title-box {
    font-size: 16px;
  }
}
