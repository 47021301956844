.settingPage {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: inherit;
  overflow: hidden auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    display: flex;
    position: relative;
    top: 25px;
    left: 20px;
    min-height: 50px;
  }

  .backButton {
    width: 20px;
    cursor: pointer;
  }

  .title {
    font-size: 17px;
    font-family: Ak-Regular;
    font-weight: 500;
    position: absolute;
    left: calc(50% - 10px);
    transform: translateX(-50%);
  }

  .dangerZone {
    margin-top: 50px;
    margin-bottom: 30px;

    .dangerZoneTitle {
      margin-left: 20px;
      font-size: 17px;
      font-family: Ak-Regular;
      font-weight: 500;
      color: #12192b;
    }

    .dangerZoneDesc {
      margin-top: 5px;
      margin-left: 20px;
      font-size: 15px;
      font-family: Ak-Regular;
      font-weight: 400;
      line-height: 20px;
      color: #656a75;
    }

    .dangerZoneButton {
      margin-top: 20px;
      margin-left: 20px;
      width: fit-content;
      padding: 5px 20px;
      font-size: 15px;
      font-family: Ak-Regular;
      font-weight: 500;
      color: #ff335c;
      border: 1px solid #ff335c;
      border-radius: 20px;
    }

    .buttonDisabled {
      padding: 8px 20px;
      background: #d9d9d9;
      color: #00000040;
      border: none;
    }

    .dangerZoneButton:hover {
      cursor: pointer;
    }
    .disabledUnPublish,
    .disabledUnPublish:hover {
      cursor: not-allowed;
      background-color: #f7f7f7;
      border-color: #dcdee2;
      color: #acaeb5;
    }
  }
}

.modalWrap {
  :global(.ant-modal-content) {
    border-radius: 20px;
    box-shadow: 0px 20px 80px 0px #5d7a9833;
  }

  .modalIcon {
    color: #ff335c;
    font-size: 20px;
    vertical-align: middle;
  }

  .modalTitle {
    vertical-align: middle;
    margin-left: 10px;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 500;
    color: #12192b;
  }

  .modalSubtitle {
    margin-top: 15px;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 400;
    color: #12192b;
  }

  .modalFooter {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  [ant-click-animating-without-extra-node="true"]::after {
    display: none;
  }

  .modalButtonNormal {
    color: #12192ba6;
    background: #020c260d;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 500;
    border: none;
    width: 110px;
  }

  .modalButtonDanger {
    color: #fff;
    background: #ff335c;
    font-size: 15px;
    font-family: Ak-Regular;
    font-weight: 500;
    border: none;
    width: 130px;
  }
}
