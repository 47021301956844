@import "./vars.scss";

.layout {
  width: 460px;
  height: 100%;
  position: relative;
  flex-shrink: 0;
}

.layoutMobile {
  width: 100%;
  .layout-background {
    width: 100%;
  }
  .layout-content {
    width: 100%;
  }
}

.layout-background {
  width: $panel-width;
  position: absolute;
  z-index: 1;

  top: 0;
  bottom: 0;
  right: 0;
  // border-radius: $panel-round;
  background-color: inherit;
  // box-shadow: $panel-shadow;
}

.layout-content {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: $panel-wrap-width;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: $panel-round;
}

.header,
.footer {
  position: relative;
  display: flex;
  padding-left: $silder-width - $padding-gap;
}

.headerMobile {
  height: 0;
  display: none;
}

.v3Header {
  transition: all 0.3s ease-in-out;
}

.publishV3Header {
  height: 100px;
}

// v3 相关的class 修改，直接使用新的class 名称
.coverSettingContent .publishV3Header {
  height: 60px;
}

.header-inner,
.footer-inner {
  width: 100%;
}

.body {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
