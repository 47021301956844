.container {
  position: relative;
  background-color: #fff;
  padding: 20px;
}

.title {
  font-size: 17px;
  font-family: Ak-Regular;
  font-weight: 500;
}

.themeBody {
  padding: 20px 0px;
}

.themeItem {
  padding: 15px;
  border-radius: 15px !important;
  height: auto !important;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.themeItemTitle {
  margin-bottom: 10px;
  color: #12192b;
  font-weight: 500;
  line-height: 30px;
}

.themeSeleted {
  border-color: #40a9ff;
}
