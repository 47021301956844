.BrowerSuggestionTip-wrap {
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(123, 133, 142, 0.15);
  padding: 25px 30px;
  border-radius: 30px;
  min-width: 530px;
  color: #12192b;

  $icon-size: 60px;

  :global(.ant-notification-notice-with-icon) {
    display: flex;
  }

  :global(.ant-notification-notice-icon) {
    margin-left: 0;
    align-self: center;
  }

  :global(.ant-notification-notice-close) {
    top: 25px;
    right: 25px;
    max-width: 20px;
    max-height: 20px;
  }

  :global(.ant-notification-notice-message) {
    display: none;
  }

  :global(.ant-notification-notice-description) {
    margin-left: $icon-size + 30px;
    font-family: "Ak-Regular";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    max-width: 360px;
  }

  a {
    display: inline;
    color: #40a9ff;

    &:hover {
      text-decoration: underline;
      color: #40a9ff;
    }
  }

  .title {
    font-weight: 700;
    line-height: 150%;
    font-size: 17px;
    margin-bottom: 8px;
  }

  [data-testid="CloseIcon"] {
    max-width: 20px;
  }
}

@media screen and (max-width: 530px) {
  .BrowerSuggestionTip-wrap {
    min-width: auto;

    :global(.ant-notification-notice-with-icon) {
      flex-direction: column;
    }

    :global(.ant-notification-notice-icon) {
      position: static;
    }

    :global(.ant-notification-notice-description) {
      margin-left: 0;
      max-width: 100%;
    }
  }
}
