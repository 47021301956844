// @import "../../base/style/Common.scss";

$preview-width: 70px;

.container {
  border-radius: 20px;
  // border: 1px #020C260D solid;
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // padding: 10px;
  /* @include themeify {
    background: themed(pdfPreviewBg);
  } */
  background: var(--pdfPreviewBg, #fff);
}

.pdfContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.previewBox {
  // width: 70px;
  height: 100%;
  display: flex;
  flex-direction: column; /* 垂直布局 */
}

.pdfPreview {
  // $scroll-bar-color: rgba(255, 255, 255, 0.15);
  $scroll-bar-color: #e7e8ea;

  height: 100%;
  // 留出四周最大2px 的边框距离
  min-width: $preview-width + 4px;
  overflow: hidden auto; /* 显示滚动条 */
  // 滚动条与内容见的间距
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--pdfPreviewScrollBarColor, $scroll-bar-color);
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }
}

.pdfPreview > div:not(:first-child) {
  margin: 5px 0 0 0;
}

.pdfPreviewItem {
  width: $preview-width + 4px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  // 显示出圆角
  overflow: hidden;
  // padding: 5px;
  /* @include themeify {
    background: themed(pdfPreviewItem);
  } */
  background: var(--pdfPreviewItem, transprent);
}
.pdfPreviewItemSelected {
  border: 2px solid #0a9dff;
}
.pdfPreviewItemUnselected {
  padding: 1px;
  border: 1px solid #020c260d;
}

.contentBox {
  margin: 0 0 0 10px;
  width: calc(100% - 80px);
  border-radius: 10px;
  border: solid 1px #020c260d;
  background-color: #7190af03;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfLoading {
  width: "100%";
  height: "100%";
  background-color: "#fff";
}

.pdfDocument {
  margin: 10px;
}

.pdfPage {
  background-color: transparent;
  border-radius: 15px;
}

.pdfPage canvas {
  border-radius: 10px;
}

.pdfExportAndFullscreen {
  width: 100px;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 10px;
  /* z-index: 10px; */
}

.pdfExportAndFullscreen > div {
  margin: 5px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #020c260d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfPagePane {
  background-color: #fff;
  box-shadow: 0 8px 16px 2px rgba(2, 12, 38, 0.05);
  border-radius: 25px;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.pdfPagePane > div {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfPagePaneHidden {
  opacity: 0;
}

.pdfPagePaneDisplay {
  opacity: 1;
}

.pdfPagePane > div {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfPrev,
.pdfNext {
  height: 40px;
  width: 40px;
}

.pdfPageNum {
  line-height: 40px;
  font-size: 13px;
}
