.cp-box {
  display: flex;
  align-items: center;
  padding-right: 15px;
  border-radius: 25px;
  background: #f3f3f4;
  cursor: pointer;
  height: 40px;

  &:hover {
    background: #bde3ff;
  }

  &.active {
    background: #0a9dff;
    .txt {
      color: #fff;
    }
  }

  .icon {
    border-radius: 50%;
    position: relative;
    width: 40px;
    height: 40px;
    overflow: clip;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      box-shadow: 0 0 18px 1.2000000476837158px #f7acd980 inset, 0 15px 45px -7.5px #a3167b33;
    }
  }

  .txt {
    font-family: "Ak-Regular";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #12192ba6;
    cursor: pointer;
    margin-left: 10px;
    letter-spacing: 0em;
    padding-right: 5px;
  }

  .close {
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    background: rgba(2, 12, 38, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    &:active {
      background: rgba(18, 25, 43, 0.65);
    }
  }
}

.cp-box-mobile {
  background: var(--talkBuilderBg, #12192b);

  .txt {
    color: var(--talkBuilderText, #fff);
    white-space: nowrap;
    padding-right: 0;
  }
}
