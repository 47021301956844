.Appearance {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
}

.AppearanceMobile {
  height: 100%;
  padding: 0;
  border-radius: 0;
  background: rgb(226, 228, 234);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.title {
  font-family: "Ak-Regular";
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #acaeb5;
  text-align: left;
}
