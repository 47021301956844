.newsVideoBox {
  position: relative;
  width: 100%;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 44px;
  overflow: hidden;
  background-color: #fff;
  .backIcon {
    background-color: rgba(255, 255, 255, 0.35);
    position: absolute;
    width: 36px;
    height: 36px;
    left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 10px;
    cursor: pointer;
  }
  .video {
    position: relative;
    width: 100%;
    height: 210px;
    border-top-left-radius: 44px;
    border-top-right-radius: 44px;
    overflow: hidden;
  }
  .videoMask {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .videoInfo {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .videoTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    align-self: stretch;
    overflow: hidden;
    color: #12192b;
    text-overflow: ellipsis;
    max-width: 350px;

    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px; /* 137.5% */
  }
  .videoBrand {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border: 0.5px solid rgba(18, 25, 43, 0.1);
      border-radius: 12px;
    }
    span {
      color: rgba(18, 25, 43, 0.6);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

.newsVideoBox:not(:first-child) {
  margin-top: 10px;
}
