.log-in-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  font-family: "Ak-Regular";
  /* font-size: 1.5vw; */
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  width: 100%;
  height: 4vw;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
  /* box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.07); */
  box-shadow: 0px 8px 16px 2px rgba(18, 25, 43, 0.05);
  /* color: rgba(0, 0, 0, 0.54); */
  color: rgba(18, 25, 43, 0.65);
  text-align: center;
  line-height: 30px;
  border: none;
}
.log-in-button:hover,
.log-in-button:focus,
.log-in-button:active {
  background: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 0.54);
}
.google-icon {
  width: 24px;
  margin-right: 1.5vw;
}
@media screen and (min-width: 1920px) {
  .log-in-button {
    font-size: 20px !important;
    font-family: "Ak-Regular";
    font-weight: 400;
    line-height: 30px !important;
    /* width: 487px; */
    height: 60px !important;
    padding: 5px 30px !important;
  }
  .google-icon {
    width: 24px;
    margin-right: 20px;
  }
}
@media screen and (min-width: 720px) {
  .log-in-button {
    padding: 5px 20px;
    /* font-size: 16px; */
    font-size: 20px;
    line-height: 30px;
    height: 45px;
  }
  .google-icon {
    width: 24px;
    margin-right: 16px;
  }
}
/* 文字大小最小限制 */
@media screen and (max-width: 720px) {
  .log-in-button {
    padding: 5px 20px;
    /* font-size: 16px; */
    font-size: 20px;
    line-height: 30px;
    height: 45px;
  }
  .google-icon {
    width: 24px;
    margin-right: 16px;
  }
}
