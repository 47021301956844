.designBox {
  position: absolute;
  top: -80px;
  left: -28px;
  width: calc(100% + 56px);
  height: calc(100% + 160px);
  padding: 20px;
  z-index: 100;
  border-radius: 25px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  background: rgba(18, 25, 43, 0.1);
  backdrop-filter: blur(20px);
}

.header {
  display: flex;
  justify-content: space-between;
  color: rgba(18, 25, 43, 0.35);
  line-height: 20px;
}

.closeIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-size: cover;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg");
}

.carousel {
  margin-top: 60px;
  :global(.slick-center .slide) {
    height: 160px;
    margin-top: 20px;
    transition: height margin 1s;
  }

  :global(.slide) {
    margin: 0 5%;
    width: 90% !important;
    height: 200px;
    border-radius: 20px;
    border: 0.5px solid rgba(18, 25, 43, 0.1);
    background: #fff;
  }
}

.carouselToolbox {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.dots {
  bottom: -85px !important;
  :global(li) {
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px;
    background-color: rgba(18, 25, 43, 0.2);
  }
  :global(li button) {
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;
    background-color: rgba(18, 25, 43, 0.2);
  }
  :global(li.slick-active button) {
    background-color: rgba(18, 25, 43, 0.65) !important;
  }
}

.selectedSlide {
  width: 90%;
  margin: 0 5%;
  height: 280px;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  background: #fff;
  transition: display 1s;
}

.buttonGroup {
  text-align: center;
  margin-top: 60px;
}

.wrapWord {
  margin: 10px 0;
  font-size: 15px;
  color: #12192b;
}

.button {
  height: 40px;
  padding: 10px 30px;
  border-radius: 25px;
  font-size: 15px;
  line-height: 20px;
}

.buttonApply {
  width: 153px;
  color: #fff !important;
}

.buttonApplyAble {
  background: rgba(18, 25, 43, 1);
  border-color: rgba(18, 25, 43, 1) !important;
}

.buttonApplyDisable {
  background-color: rgba(18, 25, 43, 0.35) !important;
  border-color: rgba(18, 25, 43, 0.35) !important;
}

.buttonCreate {
  width: 230px;
  background: rgba(18, 25, 43, 0.9);
  border-color: rgba(18, 25, 43, 0.35) !important;
}

.button:hover {
  background: rgba(18, 25, 43, 1);
  border-color: rgba(18, 25, 43, 1) !important;
}
