.playBtn {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(18, 25, 43, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.newsVideoPlayIcon {
  position: absolute;
  bottom: 24px;
  left: 24px;
}
.newsVideoListPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
