.upgradeContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  height: 100%;
}

.upgradeBox {
  color: #8e79ff;
  font-size: 13px;
  font-weight: 500;
  font-family: "AK-Regular";
  line-height: 20px;
  padding: 10px 15px;
  border-radius: 20px;
}

.upgradeBox:hover {
  background-color: #8e79ff;
  color: #fff;
}

.upgradePopoverBox :global(div.ant-popover-inner-content) {
  padding: 0 !important;
}

.upgradeTipBox {
  // padding: 10px 20px;
  padding: 10px 0;
  font-size: 15px;
  line-height: 20px;
  font-family: "AK-Regular";
  min-width: 250px;
  text-align: left;
}

.tipTitle {
  // padding: 5px 0;
  padding: 5px 20px;
  // font-weight: 500;
  font-weight: 600;
  color: #12192b;
}

.tipContentBox {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: #8e79ff;
  // padding: 0 20px;
  padding: 5px 20px;
}

.tipContent {
  margin-left: 10px;
}
.tipContentBox:hover {
  cursor: pointer;
  background: rgba(10, 157, 255, 0.1);
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
