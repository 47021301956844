.BlueprintDetail {
  width: 100%;
  border-radius: 0px 15px 15px 15px;
  border: 0.5px solid var(--Dark-5, rgba(2, 12, 38, 0.05));
  background: rgba(2, 12, 38, 0.02);
}

.toolPiece {
  margin-top: 10px;
  border-radius: 15px;
}

.content {
  padding: 0 10px 10px;
}

.toolPiece .content {
  padding: 10px 10px;
}

.desc {
  padding-top: 15px;
  color: #acaeb5;
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.executionMsg {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  color: #acaeb5;
  font-family: Ak-Regular;
  font-size: 11px;

  font-weight: 400;
  line-height: 16px; /* 145.455% */
}

.msgStrong {
  padding-right: 5px;
  color: #12192b;
  font-family: Ak-Regular;
  font-weight: 500;
}

.canvas {
  overflow-y: scroll;
  width: 100%;
  height: 144px;
  min-width: 250px;
  max-width: 400px;
  min-height: 100px;
  padding: 5px;
  justify-content: center;
  align-items: center;

  border: 0.5px solid rgba(18, 25, 43, 0.05);
  background: var(--White, #fff);

  color: var(--Dark, #12192b);
  font-family: Ak-Regular;
  font-size: 10px;

  font-weight: 400;
  line-height: 14px; /* 140% */
  white-space: pre-line;
}

.label {
  color: var(--Dark, #12192b);
  font-family: Ak-Regular;
  font-size: 11px;

  font-weight: 500;
  line-height: 20px; /* 181.818% */
  padding: 10px 0;
  width: 60px;
}
.info {
  display: flex;
  justify-content: flex-start;
  line-height: 20px; /* 181.818% */
  padding: 10px 0;
  font-size: 11px;
  font-weight: 500;
}
.infoLabel {
  width: 105px;
  color: var(--Dark, #12192b);
  font-family: Ak-Regular;
}

.textArea {
  border: 0.5px solid rgba(18, 25, 43, 0.05);
  background: var(--White, #fff);
  color: #acaeb5;
  font-family: Ak-Regular;
  font-size: 11px;

  font-weight: 400;
  line-height: 16px; /* 145.455% */
}

.loadingBlueprint {
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-spacing-small, 10px);
  border-radius: 0px 0px 15px 15px;
  border: 0.5px solid var(--Dark-5, rgba(2, 12, 38, 0.05));
  background: rgba(2, 12, 38, 0.02);
}

.loadingText {
  color: var(--Dark-35, #acaeb5);
  text-align: center;
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.item {
  display: flex;
  justify-content: flex-start;
}

.itemContent {
  flex: 1;
  color: #acaeb5;
  font-family: Ak-Regular;
  font-size: 11px;

  font-weight: 400;
  line-height: 16px; /* 145.455% */
  padding: 10px 0;
}

.buttonGroup {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 0 30px;
}
