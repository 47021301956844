.headerView {
  width: 100%;
  height: 55px;
  padding-top: 15px;
  margin-bottom: 10px;
  .contents {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #12192b;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 15px;
    }

    .rightView {
      display: flex;
      margin-right: 15px;
    }
  }
}
