$inputColor: #12192b;
// $placeholderColor: #a9a9a9;
$placeholderColor: #acaeb5;
.custom-node {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: var(--fontFamily, "Ak-Regular");
  -webkit-overflow-scrolling: touch;
  // padding-bottom: 8px;
  padding-bottom: 15px;
}

.textNodeScrollZone {
  flex: 1;
  // padding: 1px 0;
  display: flex;
  // overflow: hidden;
  // y轴需要scroll,否则build ui/view text button会被遮挡
  overflow: hidden auto;
  position: relative;
}
// 隐藏y轴滚动条
.textNodeScrollZone::-webkit-scrollbar {
  display: none;
}
.textNodeScroll {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.textNodeScroll::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  /* 滚动条整体背景，一般被覆盖着 */
}

.textNodeScroll::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node;
  /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1);
  /* 滚动条整体背景，一般被覆盖着 */
}

.textNodeScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent;
  /* 滚动条整体背景，一般被覆盖着 */
}

// 滚动条滚动到底部且未到最后一行显示模糊块
.showNodeBlur {
  position: absolute;
  content: "";
  width: 90%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 85%);
  transition: background-image 1s;
  z-index: 2;
  border-bottom-left-radius: 40px;
}

// 滚动底部文字模糊效果，没有feedback时模糊块高度
.nodeDefaultBlurHgt {
  height: 60px;
}

// 滚动底部文字模糊效果，有feedback时模糊块高度等于本身高度60px加feedback的高度34px
.nodeFeedbackBlurHgt {
  height: 94px;
}

.reservedButtonHeight {
  margin-bottom: 50px;
}

.target {
  border-radius: 30px 30px 0 0;
  border-bottom: 0;
}

.custom-node textarea:global(.ant-input) {
  outline: none;
  border: none;
  resize: none;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: none !important;
  overflow: hidden;

  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-family: var(--fontFamily, "Ak-Regular");
}

.text-content {
  width: calc(100% - 30px);
  border-radius: 10px;
  // height: calc(100% - 20px);
  height: calc(100% - 16px);
  margin: 0 10px;
  // padding: 15px;
  // padding: 12px 15px 0;
  // font-size: 13px;
  // line-height: 20px;
  white-space: pre-wrap;
  position: absolute;
  bottom: 10px;
}
.textDefaultPadding {
  // padding: 12px 15px 0;
  padding: 0 10px 0;
}
.textResetPadding {
  padding: 15px 15px 0;
}
.input-mask {
  width: calc(100% - 20px); // 20px: 父视图左右两边padding各10px
  position: absolute;
  // top: 12px;
  z-index: 10;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 12px); // 12px: 父视图的padding-top
}

.previewing {
  height: calc(100% - 70px);
}

.text-textArea {
  // 将底部空间剩余出来，可进入拖拽，100% 会将剩余内容充满导致无法拖动
  // height: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.addBottom {
  padding-bottom: 50px;
}

.text-textArea::-webkit-scrollbar {
  display: none;
}

.max-textarea-height {
  max-height: 300px;
}

.input-mask.hidden-mask {
  z-index: -1;
}

.alternate-text {
  // font-size: 15px;
  // line-height: 20px;

  // font-weight: 400;
  // font-family: var(--fontFamily, "Ak-Regular");
  user-select: text;
  white-space: pre-wrap;
}

.alternate-text.place-color {
  color: $placeholderColor;
}

.show-text-textArea::-webkit-scrollbar,
.text-content::-webkit-scrollbar,
.custom-api::-webkit-scrollbar,
.custom-node::-webkit-scrollbar,
.custom-node textarea::-webkit-scrollbar,
.input-mask::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  /* 滚动条整体背景，一般被覆盖着 */
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.show-text-textArea::-webkit-scrollbar-thumb,
.text-content::-webkit-scrollbar-thumb,
.custom-api::-webkit-scrollbar-thumb,
.custom-node::-webkit-scrollbar-thumb,
.custom-node textarea::-webkit-scrollbar-thumb,
.input-mask::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node;
  /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1);
  /* 滚动条整体背景，一般被覆盖着 */
}

.show-text-textArea::-webkit-scrollbar-track,
.text-content::-webkit-scrollbar-track,
.custom-api::-webkit-scrollbar-track,
.custom-node::-webkit-scrollbar-track,
.custom-node textarea::-webkit-scrollbar-track,
.input-mask::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent;
  /* 滚动条整体背景，一般被覆盖着 */
}

.guide-textnode-disable .text-content {
  width: 100%;
  margin: 0;
  position: relative;
  bottom: 0;
}

.guide-textnode-disable .text-textArea {
  max-height: unset;
}

textarea:not(:focus) {
  cursor: pointer !important;
}

.input-in-blueprint {
  position: relative;
}

// 该 class 统一一个地方修改 text node 颜色等样式
// 方便被外界覆盖
.colorMode1 {
  &.topShadow::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 40px;
    top: 0;
    left: 0;
    transition: background-image 1s;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 85%);
    z-index: 2;
  }

  &.bottomShadow::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
    transition: background-image 1s;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 85%);
    z-index: 2;
  }

  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-family: var(--fontFamily, "Ak-Regular");

  .custom-node {
    background: #ffffff;

    textarea::placeholder {
      color: $placeholderColor;
    }
  }

  .custom-node textarea:global(.ant-input) {
    background-color: #fff;
  }

  .text-content {
    background: #ffffff;
  }
}

// 只读时的一些样式
.textNodeScrollZone.readOnlyText {
  .input-mask {
    position: static;
    height: auto;
  }
}

.textNodeScrollZone {
  flex-direction: column;
}

.viewBuilder {
  width: 100%;
}
