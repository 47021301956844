.popoverWrap {
  padding-top: 0;
  text-align: left;
  // FIXME: popver 会向左偏移
  left: -80px !important;
  top: 60px !important;
}
.popoverWrap :global(div.ant-popover-inner-content) {
  padding: 0 !important;
}

.popoverContent {
  // width: 220px;
  min-width: 250px;
  min-height: 80px;
  text-align: left;
  padding: 10px 0;
}

.popoverHead {
  // padding: 15px 20px;
  color: rgba(18, 25, 43, 1);
}

.popverHeadTitle {
  // margin-bottom: 10px;
  padding: 5px 20px;
  // font-family: Aktiv Grotesk;
  font-size: 15px;
  // font-weight: 500;
  font-weight: 600;
  line-height: 20px;
  // letter-spacing: 0em;
  // text-align: left;
  min-height: 30px;
  display: flex;
  align-items: center;
}
.popverHeadDetail {
  padding: 5px 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.popverHeadDetailsBox {
  padding: 0px 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.popverErrorMsg {
  color: rgb(222, 28, 34);
  padding: 10px 20px 20px;
}

.popverHeadContent {
  //styleName: Body;
  // font-family: Aktiv Grotesk;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.popoverActionList {
  padding: 5px 0;
  border-top: 1px solid rgba(2, 12, 38, 0.05);
  margin-top: 5px;
}

.actionItem {
  padding: 0 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  color: rgba(18, 25, 43, 1);
}

.actionItem:disabled:hover {
  background-color: #0a9dff1a;
}

.actionItem:hover {
  background-color: #0a9dff1a;
}

.actionLabel {
  margin-left: 10px;
  color: inherit;
}

.popoverTrigger {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.triggerButton {
  padding: 10px 15px;
  border-radius: 9999px;
  min-height: 40px;
  min-width: 60px;
  display: inline-flex;
  align-items: center;
}
