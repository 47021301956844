.CardHomeOutfit {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(144, 147, 169, 0.08);
  border-radius: 20px;
}

.CardHomeOutfit .outfitName {
  margin: 15px 5px;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #0a0a0a;
  min-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;
}
.CardHomeOutfit .outfitPrice {
  margin: 0 0 0 5px;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #0a0a0a;
}

.CardHomeOutfit .imgBox {
  max-width: 145px;
  height: 145px;
}

.CardHomeOutfit .imgBox img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
