.fotInput-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  min-height: 55vh;
  max-height: 55vh;
  overflow-y: scroll;
}

.fotInput-wrapper::-webkit-scrollbar {
  display: none;
}

.fotInputBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.startAnimation {
  overflow: hidden;
  border-radius: 15px;
  height: 90px;
}

.fotInputBox > div {
  position: relative;
}

.superposition-box {
  width: 95%;
  background: rgba(255, 255, 255, 0.35);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  height: 30px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -10px;
  z-index: -3;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
}

.fotInputBox :global(.ant-popover) {
  width: 213px;
}
.fotInputBox :global(.ant-popover-inner) {
  border-radius: 15px;
}

:global(.ant-input-affix-wrapper-textarea-with-clear-btn) {
  background: none;
}

div:global(.ant-popover-inner-content) {
  padding: 15px 20px !important;
  text-align: center;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: rgba(18, 25, 43, 0.65);
}

.imagic-logo {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 10px;
  top: 10px;
}

.ash-shedding {
  background-color: rgba(255, 255, 255, 0.35);
}

.VirtualAssistantWrapper {
  cursor: pointer;
  position: absolute;
  left: -80px;
  cursor: pointer;
}

.retryMask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.65);
  top: 0;
  z-index: 5;
}
