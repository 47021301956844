.c4Box {
  position: relative;
  width: 100%;
  min-height: 160px;
  display: flex;
  padding: 5px;
  margin-top: 10px;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  background: var(--textboxBg, #fff);
}
.textBox {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 80%;
}
