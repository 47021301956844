.headerWrap {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.8vw;

  font: {
    weight: 400;
    family: Ak-Regular;
    size: 13px;
  }
  color: #12192b;
  line-height: 20px;

  .logo {
    width: 6vw;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .projectBtn {
    padding: 15.5px 10px;
  }

  .headerAvatar {
    margin: 0;
    :global(.ant-dropdown) {
      right: 3.8vw !important;
      left: unset !important;
    }
  }
}
