.creatorRoot {
  // react flow 默认层级
  $default-reactflow-zindex: 5;

  [data-creator="NavBar"] {
    // 由于有auto imagica和 thinkapp，在build的过程中都需要在最上层，所以层级提高
    z-index: 10;
  }

  [data-creator="Canvas"] {
    z-index: 1;
  }

  [data-creator="Toolbar"] {
    z-index: 4;
  }

  [data-creator="PreviewApp"] {
    z-index: 6;
  }

  [data-creator="PreviewAppChat"] {
    z-index: 3;
  }

  [data-creator="RightMenu"] {
    // pagenavbar 有 z-index: 6 !important;
    z-index: 7;
  }

  [data-creator="Help"] {
    z-index: 12;
  }

  // auto imagica 背景层 < navbar
  [data-creator="CallorBotPaneConverConvas"] {
    z-index: $default-reactflow-zindex;
  }

  // auto imagica 聊天框层级
  [data-creator="CallerBotPane"] {
    z-index: $default-reactflow-zindex;
  }

  [data-creator="OnboardingInputMask"] {
    z-index: 4;
  }

  [data-creator="OnboardingInput"] {
    z-index: 10;
  }
}
