.divider-box {
  display: flex;
  align-items: center;
  height: 100%;
}
.block-box {
  cursor: pointer;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: rgba(10, 157, 255, 0.1);
}
.block-box:hover {
  background-color: rgba(10, 157, 255, 0.3);
}

.block-box.not-optional {
  user-select: none;
  // color: #b3b7be;
  cursor: not-allowed;
  pointer-events: none;
}
.block-box.noBackground,
.block-box.noBackground:hover {
  background-color: transparent;
  cursor: not-allowed;
}
.popoverContent {
  // width: 184px;
  // padding: 15px 20px;
  width: 250px;
  padding: 10px 0px;
  font-size: 15px;
  line-height: 20px;
  font-family: "AK-Regular";
  min-width: 100px;
  text-align: left;
}
.popupTip {
  // font-weight: 500;
  font-weight: 600;
  color: #12192b;
  // margin-bottom: 10px;
  padding: 5px 20px;
  line-height: 20px;
  min-height: 30px;
}
.popupContent {
  color: rgba(18, 25, 43, 1);
  padding: 5px 20px;
  min-height: 40px;
}
.runallTip {
  left: -85px !important;
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
