@import "../mixin.scss";

.planCell {
  display: flex;
  flex-direction: column;
  position: relative;
}

.planBody {
  position: relative;
  height: 121px;
  background-size: auto;
  background-repeat: round;
  padding: 35px 0 10px 10px;
}
.gridLine {
  position: absolute;
  width: 10px;
  left: -3px;
  height: 100%;
  background: #d7d9e0;
  top: 0;
}

.planFoot {
  height: 70px;
  padding: 0 px2em(10px) 0 px2em(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(18, 25, 43, 0.05);
  flex-direction: column;
  color: #12192b;
}

.planName {
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  margin-bottom: px2em(20px);
}

.badgeList {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  .badge {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
}

.priceRow {
  margin: -15px 0 -8px 0;
  .priceBlock {
    display: inline-flex;
  }

  .priceMonthValue {
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
  }

  .unit {
    font-size: px2em(18px);
    font-weight: 700;
    line-height: 28px;
    margin-left: 4px;
    vertical-align: top;
  }
}

.noPriceRowMar {
  margin-top: -5px;
}

.priceAnnualRow {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  .unit {
    margin-left: 1px;
  }
}

.footButton {
  position: relative;
  width: 86px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: px2em(5px) px2em(20px);
  border-radius: 25px;

  color: #8f79ff;
  text-shadow: 0px 0px 3.5px rgba(255, 255, 255, 0.35);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  /* 153.846% */
  background-size: cover;
  background-repeat: no-repeat;

  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.hidden {
  visibility: hidden;
}

.loadingBox {
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnLoading {
  font-size: px2em(26px);
}

.currentPlanButton {
  //styleName: Caption [M];
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #8e79ff;
}

.cancelTipBox {
  line-height: 16px;
  text-align: center;

  .cancelTip {
    font-family: Ak-Regular;
    font-size: px2em(13px);
    font-weight: 500;
    color: rgba(18, 25, 43, 0.65);
  }
}

.disableBtn {
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.05);
  filter: opacity(0.5);
  cursor: not-allowed;
}
.mount {
  margin-right: 2px;
}
