.a-modal-confirm-wrapper :global(.ant-modal-content) {
  border-radius: 15px;
}

.a-modal-confirm-wrapper :global(.ant-modal-body) {
  padding: 30px;
}

.a-modal-confirm-wrapper :global(.ant-modal-close-x) {
  width: 42px;
  height: 42px;
  line-height: 42px;
}

.a-modal-confirm-wrapper :global(.ant-btn) {
  /* width: 163px; */
  padding: 5px 20px;
  font-size: 15px;
}

.a-modal-confirm-wrapper :global(.ant-btn-primary) {
  font-weight: 500;
  background: #0a9dff;
  box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
}

.a-modal-confirm-wrapper :global(.ant-btn):disabled {
  font-weight: 500;
  color: rgba(2, 12, 38, 0.35);
  border: none;
  background: rgba(2, 12, 38, 0.05);
  box-shadow: none;
}

.a-modal-confirm-wrapper :global(.ant-btn-default) {
  font-weight: 500;
  color: rgba(18, 25, 43, 0.65);
  border: none;
  background: rgba(2, 12, 38, 0.05);
}

.modal-title {
  color: #12192b;
  font-size: 17px;
  text-align: center;
  line-height: 25px;
  font-family: "Ak-Regular";
  font-weight: 800;
}

.modal-sub-title {
  margin: 10px 0 30px;
  color: #12192b;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  font-family: "Ak-Regular";
  white-space: pre-line;
}

.modal-icon {
  margin: 25px 0;
  display: flex;
  justify-content: center;
}

.modal-input {
  margin-bottom: 25px;
  border-radius: 6px;
}

.modal-textarea {
  margin-bottom: 25px;
  padding: 15px 20px;
  border-radius: 10px;
  background: rgba(2, 12, 38, 0.05);
  color: #12192b;
  border: none;
  /* Body */
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
}

.a-modal-btn-dangerous {
  background-color: #ff335c !important;
  border-color: #ff335c !important;
}

.modal-custom-footer {
  display: flex;
  justify-content: space-between;
}

.modal-custom-footer-btn-0 {
  margin-right: 7.5px;
  flex: 1;
}

.modal-custom-footer-btn-1 {
  margin-left: 7.5px;
  flex: 1;
}
