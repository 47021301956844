.CardHomeGoogle {
  margin: 20px 25px 10px;
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 15px 20px;
}

.CardHomeGoogle a {
  display: flex;

  font-size: 15px;
  line-height: 20px;

  align-items: center;
  justify-content: space-between;
  font-family: "Ak-Regular";
}

.CardHomeGoogle .title {
  display: flex;
  align-items: center;
  color: #22232f;
  margin-bottom: 5px;
  font-weight: 700;
  word-break: break-all;
}

.CardHomeGoogle .subheading {
  font-weight: 400;
  font-size: 13px;
  color: #7d8fa1;
}

.CardHomeGoogle .icon-right {
  font-weight: 700;
  color: #22232f;
}
