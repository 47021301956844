.mapBox {
  position: relative;
  margin-top: 10px;
  background: var(--textboxBg, #fff);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.02), 0px 10px 15px -3px rgba(0, 0, 0, 0.01);
  border: 0.5px solid rgba(18, 25, 43, 0.1);
}
.mapLink {
  display: inline-block;
  margin-bottom: 10px;
}
.mapBox :global(.mapboxgl-map) {
  border-radius: 15px;
  overflow: hidden;
}
.mapBox :global(.mapboxgl-control-container) {
  display: none;
}
.mapAddr {
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.mapTravelTime {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
