.post-body-param-evaluated {
  background-color: white !important;
  color: black !important;
}

.beautify-btn {
  position: absolute;
  right: 20px;
  top: 1px;
}

.select-type-btn {
  position: absolute;
  right: 108px;
  top: 6px;
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1890ff;
}

.moreActionsBox {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0px;
  width: 90px;
  padding: 5px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
}
.boxItem {
  height: 50px;
  padding: 15px 25px;
  color: #656a75;
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.boxItem:hover {
  background: rgba(10, 157, 255, 0.1);
}
