.mask_content {
  position: relative;
}

.transparentContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.MaskContentMobile {
  flex: 0 0 calc(50% - 5px);
  box-sizing: border-box;
}

.mask_view {
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 255, 255, 0.3);
  z-index: 10;
  top: 0px;
  transition: all 0.3s linear;
  // border-radius: 10px;

  .vip_view {
    background-color: #8f79ff;
    border-radius: 5px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    top: 20px;
    opacity: 1;
    transition: all 0.2s linear;

    span {
      color: white;
      font-size: 13px;
    }
  }

  .small_vip_view {
    background-color: #8f79ff;
    border-radius: 5px;
    height: 20px;
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 0.2s linear;

    span {
      color: white;
      font-size: 13px;
    }
  }

  .mask_view_content {
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 100%;

    .title {
      max-width: 260px;
      color: #656a75;
      font-size: 15px;
      font-family: Ak-Regular;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }

    .button {
      margin-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #8f79ff;
      display: flex;
      height: 40px;
      align-items: center;
      border-radius: 20px;
      cursor: pointer;

      span {
        margin-left: 10px;
        color: white;
        font-family: Ak-Regular;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .small_button {
      padding-left: 15px;
      padding-right: 15px;
      background-color: #8f79ff;
      display: flex;
      height: 40px;
      align-items: center;
      border-radius: 20px;
      color: white;
      font-family: Ak-Regular;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

.mask_view:hover {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  transition: all 0.3s linear;
  .vip_view {
    opacity: 0;
    transition: all 0.3s linear;
  }

  .small_vip_view {
    display: none;
    opacity: 0;
    transition: all 0.3s linear;
  }

  .mask_view_content {
    opacity: 1;
    transition: all 0.3s linear;
  }
}

.loadingMask {
  background-color: rgba(255, 255, 255, 0.6);
}
