.chatPanel {
  z-index: 300;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
  border-radius: 15px;
  background: linear-gradient(
    90deg,
    rgba(142, 239, 234, 1) 0%,
    rgba(91, 200, 236, 1) 25%,
    rgba(160, 147, 244, 1) 50%,
    rgba(242, 134, 209, 1) 75%,
    rgba(250, 145, 145, 1) 100%
  );
  margin-bottom: 5px;
  position: relative;
  padding: 15px;
  display: flex;
  align-items: center;
  // border: 1px solid rgba(255, 255, 255, 0.35);
  justify-content: flex-start;
  overflow: hidden;
}

.chatPanel > img {
  position: absolute;
  top: 16px;
  right: 13px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.mask {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.7) 25%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0.7) 75%,
    rgba(255, 255, 255, 0.7) 100%
  );
  // background:rgba(255, 255, 255, 0);
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0.5) 0%,
  //   rgba(255, 255, 255, 0.495676) 6.67%,
  //   rgba(255, 255, 255, 0.482245) 13.33%,
  //   rgba(255, 255, 255, 0.45917) 20%,
  //   rgba(255, 255, 255, 0.426294) 26.67%,
  //   rgba(255, 255, 255, 0.384113) 33.33%,
  //   rgba(255, 255, 255, 0.334058) 40%,
  //   rgba(255, 255, 255, 0.278654) 46.67%,
  //   rgba(255, 255, 255, 0.221346) 53.33%,
  //   rgba(255, 255, 255, 0.165942) 60%,
  //   rgba(255, 255, 255, 0.115887) 66.67%,
  //   rgba(255, 255, 255, 0.0737057) 73.33%,
  //   rgba(255, 255, 255, 0.0408299) 80%,
  //   rgba(255, 255, 255, 0.017755) 86.67%,
  //   rgba(255, 255, 255, 0.0043236) 93.33%,
  //   rgba(255, 255, 255, 0) 100%
  // );
}

.startMotionComplete {
  z-index: 100;
  background: linear-gradient(
    90deg,
    rgba(142, 239, 234, 1) 0%,
    rgba(91, 200, 236, 1) 25%,
    rgba(160, 147, 244, 1) 50%,
    rgba(242, 134, 209, 1) 75%,
    rgba(250, 145, 145, 1) 100%
  ) !important;
}

.textContent {
  font-family: "Ak-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #12192b;
  margin-left: 20px;
  flex: 0.9;
}

.loadingText {
}

.progress {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.35);
}
