.previewHeaderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 20px 0 30px;
  height: 100%;
}

.title {
  margin-left: 5px;
  font-family: "Ak-Regular";
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #12192b;
}

.setting {
  display: flex;
  align-items: center;

  &Bar {
    margin-left: 10px;
    display: flex;
    border-radius: 30px;
    background-color: #12192b1a;
    padding: 1.5px 2px;
    gap: 5px;

    & > span {
      width: 37px;
      height: 37px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;

      &:hover:not(.selected) {
        background-color: #12192b1a;
      }
    }

    .selected {
      background-color: #0a9dff;
    }
  }

  .settingIcon {
    position: relative;
  }
}

.popover,
.featuresPopover {
  :global(.ant-tooltip-inner) {
    padding: 15px 20px;
    border-radius: 15px;
    color: #f8f8f8;
    line-height: 20px;
    font-weight: 500;
    font-family: Ak-Regular;
    font-size: 15px;
    background-color: #12192b;
  }
  :global(.ant-tooltip-arrow-content) {
    background-color: #12192b;
  }
}
.popover {
  top: -68px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.featuresPopover {
  top: -68px !important;
  right: 0 !important;
}

.buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  flex: 1;
  cursor: pointer;
  height: 40px;
  width: 90px;
  padding: 5px 10px;
  border-radius: 35px;
  background-color: white;
  color: #676e7f;
  text-align: center;
  font-family: "Ak-Regular";
  font-weight: 400;
  line-height: 20px;
  &:hover {
    background: #020c260d;
    color: #676e7f;
  }
}

.size {
  margin-left: 5px;
}
.size:hover {
  cursor: pointer;
}
