.HomeMobile {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(226, 228, 234);

  .content {
    height: calc(100% - 60px);
  }

  .project {
    width: 100%;
    height: 100%;
  }

  .menu {
    height: 50px;
    font-family: Ak-Regular;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 0 6.36vw;
    position: relative;
    z-index: 3;
  }

  .container {
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
  }

  .hasInputContainer {
    height: 100%;
  }

  .previewContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    background: rgb(209, 214, 223);
  }

  .addNewProject {
    position: fixed;
    right: 6vw;
    bottom: 20px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menuKey {
    margin-right: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 1.2vw;
    }
  }
}

.menuContainer {
  :global(.ant-dropdown-menu) {
    border-radius: 10px;
    background: rgba(83, 87, 100);
  }
  :global(.ant-dropdown-menu-item) {
    color: #fff;
  }
  :global(.ant-dropdown-menu-item.ant-dropdown-menu-item-active) {
    background-color: rgba(2, 12, 38, 0.35);
  }
  :global(.ant-dropdown-menu-item-selected) {
    color: #fff;
    background-color: rgba(2, 12, 38, 0.35);
    border-radius: 10px;
  }
}

.showChat {
  position: relative;
  z-index: 3;
}

.overlay {
  background: #535764;
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014, 0 9px 28px 8px #0000000d;

  .overlayLi {
    clear: both;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0 12px;
    transition: all 0.3s;
  }

  .overlayLiSelected {
    background: #020c2659;
  }
}
