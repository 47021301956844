.divider_box {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  margin-left: 10px;
}

.linksAndCodesBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  gap: 10px;
  flex: 1;
  cursor: pointer;
  height: 40px;
  padding: 0px;
  border-radius: 25px;
  background: white;
  color: #12192b;
  text-align: center;
  font-family: "Ak-Regular";

  font-weight: 500;
  line-height: 20px;
  flex: none;
  padding: 0 15px;
}
.originBox {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.orLine {
  width: 1px;
  height: 20px;
  background: #c2e7ff;
}
.copyLinkTxt {
  margin-left: 10px;
}
.urlInput {
  flex: 1 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #ebebed;
  background: #fff;
  justify-content: space-between;
  cursor: pointer;
}
.urlText {
  flex: 0.9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
  line-height: 16px;
  color: #12192b;
  font-family: "sf-regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}

.urlCodes {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #ebebed;
  background: #fff;
  cursor: pointer;
}

.QRCodeModeBox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  max-width: 400px;
  right: 160px;
  z-index: 11;
}

.maskBox {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.65);
  width: 100%;
  height: 100%;
  border-radius: 25px;
  box-shadow: 0 30px 60px 0 #7b858e26;
  z-index: 30;
}

.QRCodeBox {
  margin: 80px 20px 0;
  position: relative;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0 20px 40px 0 #37456c26;
  overflow: hidden; // 为了触发BFC, 解决子元素的margin撑不开的问题

  .codeTitle {
    text-align: center;
    padding-top: 25px;
  }
}

.QRCodeBox .crossBox {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.QRCodeBox .codeBox {
  margin: 10px 20px 0;
}

.QRCodeBox .codeText {
  color: rgba(18, 25, 43, 0.65);
  text-align: center;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}

.clickable {
  color: #0a9dff;
}
.copyLink {
  display: flex;
  align-items: center;
}
