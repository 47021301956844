.response-box {
  padding: 10px;
  flex: 1;
  overflow: hidden;
}
.response-box :global(.ant-tabs-content-holder) {
  overflow: auto;
}
.response-box :global(.ant-tabs-content-holder)::-webkit-scrollbar {
  display: none;
}
.response-status {
  display: flex;
}
.response-status span:first-child {
  font-size: 14px;
  font-family: ak-light;
}
.response-status span:last-child {
  font-size: 14px;
  font-family: sf-regular;
}
.response-status p:not(:last-child) {
  margin-right: 10px;
}
.ok-status {
  color: #52c41a;
}
.failed-status {
  color: #ff4d4f;
}
