@import "./mixin.scss";

.planTable {
  --plan-table-root-size: 16px;
}

@media screen and (min-width: 390px) {
  .planTable {
    --plan-table-root-size: 10px;
  }
}

@media screen and (min-width: 650px) {
  .planTable {
    --plan-table-root-size: 12px;
  }
}

@media screen and (min-width: 950px) {
  .planTable {
    --plan-table-root-size: 13px;
  }
}

@media screen and (min-width: 1204px) {
  .planTable {
    --plan-table-root-size: 15px;
  }
}

.planTable {
  font-size: var(--plan-table-root-size, 10px);
}

.columns {
  display: flex;

  .column {
    position: relative;
    width: 25%;
    border: 1px solid rgba(18, 25, 43, 0.05);
    border-top: none;

    &:last-child {
      border-right: 1px solid rgba(18, 25, 43, 0.05);
      border-bottom-right-radius: 20px;
    }
    > .cell {
      height: 70px;
      border-left: none;
      overflow: hidden;

      &:first-child {
        height: 195px;
        max-height: 195px;
        position: sticky;
        top: 0;
        background: #d7d9e0;
      }

      &.multiLine {
        height: px2em(60px);
      }

      border-bottom: 1px solid rgba(18, 25, 43, 0.05);
    }
  }
}
