.build-layer-view {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.main-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 100px;
  bottom: 0;
}
