.prompt-for-updates .modal-footer {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}
.prompt-for-updates .modal-footer :global(.ant-btn) {
  outline: none;
  border: none;
}
.prompt-for-updates .modal-title {
  color: #12192b;
  font-family: "Ak-regular";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.prompt-for-updates .modal-content {
  padding: 10px 0;
  color: #12192b;
  font-family: "Ak-Regular";
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 15px;
}
.prompt-for-updates .text-bold {
  color: #0a9dff;
  display: contents;
}
.prompt-for-updates .button-box {
  border-radius: 25px;
  height: 40px;
  padding: 5px 30px;
}
