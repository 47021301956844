.node-stock,
section.empty-node-stock {
  min-width: 260px;
  min-height: 200px;
}
section.empty-node-stock {
  margin: 0;
}
.hiddenContent {
  opacity: 0;
}

/* .preview {
  margin: 20px 0;
}

.share {
  margin: 20px 25px;
  width: calc(100% - 50px) !important;
} */
