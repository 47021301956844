.recipeCardRecipe {
  padding: 0 25px 15px 0;
  margin-left: 25px;
  border-bottom: 0.5px solid rgba(34, 35, 47, 0.1);
}

.recipeCardRecipe img {
  width: 100%;
  height: auto;
  padding: 20px 0 0 0;
}
.recipeCardRecipe div {
  font-family: "ak-regular";
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
}
.recipeCardRecipe .recipeCook-oma {
  font-weight: 700;
  padding: 20px 0 5px;
}
