.toolbar-main {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 60px;
  top: 5px;
  z-index: 999;
  transform: translate(-50%, -100%);
  top: 0;
  left: 50%;
}
.toolbar-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 5px;
  border-radius: 25px;
  background: #e8e8e8;
  border: 0.5px solid #020c260d;
  box-shadow: 0px 20px 80px 0px rgba(93, 122, 152, 0.2);
}
.cp-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border-radius: 25px;
  background: #f3f3f4;
  cursor: pointer;
  min-width: 40px;
  position: relative;
  overflow: hidden;

  path {
    stroke: var(--toolbarIcon, #fff);
    fill: var(--toolbarIcon, #fff);
  }
}

.cp-box-no-fill path {
  fill: none !important;
}

.popover-box {
  position: relative;
  margin-left: 10px;
}
.popover-box:first-child {
  margin-left: 0;
}
// 使用css的filter改变svg背景色和字体色
.cp-box img {
  width: 20px;
  height: 20px;
  // width: 50%;
  // height: 50%;
  // position: absolute;
  // left: -30px;
  // top: 10px;
  // filter: drop-shadow(#12192b 40px 0);
}

.cp-box:hover {
  background: #bde3ff;
}
.cp-box:hover img {
  filter: drop-shadow(#12192ba6 40px 0);
}

.cp-box:active {
  background: #0a9dff;
}

.cp-box:active img {
  filter: drop-shadow(white 40px 0);
}

.high-light-bar {
  background: #0a9dff;
}
.high-light-border {
  outline: 2px solid #0a9dff;
}
.disabled-bar {
  opacity: 0.4;
  cursor: not-allowed;
}

.textPopover {
  left: 50% !important;
  transform: translateX(-50%) !important;
  :global(.ant-popover-inner) {
    background-color: var(--tooltipBgColor, #fff);
  }
  :global(.ant-popover-arrow-content) {
    background-color: var(--tooltipBgColor, #fff);
    --antd-arrow-background-color: var(--tooltipBgColor, #fff);
  }
}

.tooltipContainer {
  white-space: nowrap;
  color: var(--tooltipColor, #fff);
}

:global {
  .font-style-dropdown {
    width: 100%;
    border-radius: 15px;
    padding: 5px;
    overflow: hidden;
    background: #fff;
    top: 60px !important;
    left: 0 !important;
    box-shadow: 0px 20px 80px 0px rgba(93, 122, 152, 0.2);

    .ant-dropdown-menu {
      box-shadow: none;
    }
    .ant-dropdown-menu-item {
      border-radius: 10px;
      padding: 10px 15px;
    }
    .ant-dropdown-menu-item:hover {
      background: rgba(10, 157, 255, 0.1);
    }
  }
}

.cp-box.disable {
  opacity: 0.4;
  cursor: not-allowed !important;
  &:hover {
    outline: none !important;
  }
}

.toolbar-box-mobile {
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -100%;
  background: var(--toolbarBg, rgb(80, 84, 96));
  border-radius: 25px;

  .mobileTop {
    display: flex;
    justify-content: space-between;
    & > div:not(:last-child) {
      margin-right: 2.7vw;
    }
  }

  .mobileBottom {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    & > div:not(:last-child) {
      margin-right: 2.7vw;
    }
  }

  .cp-box {
    background: var(--talkBuilderBg, #12192b);
  }
}

.toolbar-box-one-line {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  .mobileTop {
    margin-right: 2.7vw;
  }
  .mobileBottom {
    margin-top: 0;
  }
}

.rect {
  width: 20px;
  height: 20px;
  border: 2px solid var(--toolbarIcon, #fff);
  border-radius: 4px;
}
