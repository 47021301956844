.topicWithRelatedPeopleWrapper {
  width: 100%;
  header {
    padding: 24px;
    border-radius: 44px;
    margin-bottom: 8px;
    background: #fff;
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: rgba(18, 25, 43, 1);
    margin-bottom: 8px;
  }
  .summary {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(18, 25, 43, 1);
  }
  .personInfo {
    display: flex;
    align-items: center;
    padding: 24px;
    border-radius: 44px;
    background: #fff;
    margin-bottom: 8px;
  }
  .personDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .personName {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: rgba(18, 25, 43, 1);
    margin-bottom: 8px;
  }
  .personLifeSpan {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(18, 25, 43, 0.6);
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .empty {
    background: #fff;
    border-radius: 44px;
    padding: 24px 0;
    margin: 0;
  }
}
