.ContentFrameResultsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 0 20px;
}
.Editor-UI.custom-node {
  height: 100%;
  overflow: hidden;
}
.Editor-UI.custom-node .ContentFrameResults,
.Editor-UI.custom-node .CardFrame,
.Editor-UI.custom-node .CardFrameContent,
.Editor-UI.custom-node .CardHome,
.Editor-UI.custom-node .CardHomeCode,
.Editor-UI.custom-node .ant-spin-nested-loading,
.Editor-UI.custom-node .ant-spin-container {
  height: 100%;
  overflow: hidden;
}
.Editor-UI.custom-node .CardHomeStock,
.Editor-UI.custom-node .CardHomeImage {
  /* height: calc(100% - 40px); */
}
.Editor-UI.custom-node.default-resize .CardHomeImage img {
  width: 200px;
  height: 200px;
}
.Editor-UI.custom-node .Code {
  height: calc(100% - 50px);
  overflow: auto;
}
.Editor-UI.custom-node.default-resize .Code {
  width: 200px;
  height: 200px;
  overflow: auto;
}
.Editor-UI.custom-node .Code::-webkit-scrollbar {
  display: none;
}
