.white-frame {
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--textboxBg, #fff);
}

.white-frame .image-box {
  width: 100%;
  height: 100%;
  position: relative;
}

.white-frame .type-title {
  color: rgba(2, 12, 38, 0.35);
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  padding: 10px 10px 10px 15px;
  height: 40px;
  line-height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.white-frame .image-content {
  height: 100%;
  width: 100%;
  position: relative;
  /* 增加了 caption 排列解决像素问题 */
  display: flex;
  flex-direction: column;
}

.white-frame .image-content img {
  width: 100% !important;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  min-height: 250px;
}

.white-frame .default-resize img {
  width: 230px;
  height: 225px;
}

.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.delete-content img {
  width: 100%;
  height: 100%;
}

.white-frame .default-describe-resize {
  width: 265px;
}

.image-describe {
  min-height: 50px;
  margin-top: 10px;
  padding: 15px;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #12192b;
  height: auto;
}

.target {
  overflow: hidden;
  border-bottom: 0;
  /* margin-top: -18px; */
}

.timeoutQuery {
  position: absolute;
  bottom: 0px;
  right: 20px;
}

.img-preview-modal :global(.ant-modal) {
  top: 110px;
  width: 100% !important;
  max-width: 780px !important;
  padding-bottom: 0;
}

.img-preview-modal :global(.ant-modal-content) {
  background-color: unset;
  border-radius: 0 none;
  box-shadow: unset;
}

.img-preview-modal :global(.ant-modal-body) {
  padding: 0;
}

.img-preview-modal :global(.ant-modal-footer) {
  display: none;
}

.img-preview-modal :global(.ant-modal-close-x) {
  color: #12192b;
  font-size: 14px;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  margin: 20px 20px 0 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}

.previewImg {
  /* width: 780px;
  height: 780px; */
  border-radius: 20px;
  width: 100%;
  max-height: 780px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
/* 来自 cardHome image 组件 image-describe */
.imageCaption {
  padding: 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--imgTextColor, #12192b);
  height: auto;
  /* 为了保证多个换行符能解析成功 */
  white-space: pre-wrap;
}
