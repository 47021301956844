.disappears {
  display: none;
}

.saas-input {
  min-height: 30px;
  height: 60px;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 16px;
  line-height: 25px !important;
  color: var(--saasTitleColor, "#12192B");
  transition: 0.3s !important;
  padding: 0 !important;
}
.saas-input:disabled {
  color: var(--saasTitleDisableColor, "#000");
}

.top-suction .saas-input {
  height: 30px !important;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.saas-input::-webkit-scrollbar {
  display: none;
}

.saas-placeholder {
  width: 100%;
}
