.content {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.button {
  width: 180px;
  height: 40px;
  border-radius: 20px;
  border: 1px;
  gap: 10px;
  background: #8f79ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: white;
  }
}
