.hover-border {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px dashed #0a9dff;
  background: rgba(10, 157, 255, 0.1);
  display: flex;
  align-items: center;
  z-index: 10;
  margin-right: 5px;
  opacity: 1;
}

.disappears {
  display: none;
}

.right-box {
  position: absolute;
  width: 35px;
  right: -35px;
}

.right-box-menu {
  width: 30px;
  margin-left: 5px;
  background: #fff;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
  border-radius: 10px;
  padding: 5px;
  min-height: 75px;
}
.right-box-menu .icon-button {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.icon-button:last-child {
  margin-bottom: 0;
}
.input-box .right-box-menu {
  padding-top: 10px;
}

// input delate
.close-bottom {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 10;
  background: var(--inputBoxBottom, "#fff");
  border-radius: 30px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/close.png");
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.dark-cross {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/darkModeCross2x.png");
}

// input enter
.enter-bottom {
  cursor: pointer;
  position: absolute;
  right: 30px;
  padding: 15px 20px;
  width: 60px;
  height: 40px;
  background: var(--inputBoxEnterBgc, "rgba(2, 12, 38, 0.05)");
  border-radius: 30px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/enter2x.png");
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.saas-input-content .top-suction .enter-bottom {
  right: 5px;
  top: 5px;
}

:global(.project-app) .enter-bottom {
  right: 15px;
  top: 16px;
}

.dark-enter {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/darkModeEnter2x.png");
}

// input loading
.saas-loading {
  background-color: #d9d9d930;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
