.scrollContent {
  width: 100%;
  height: 100%;
  /* background: #ffffff; */
  border-radius: 10px;
  overflow-x: visible;
  padding: 10px;
  font-family: var(--fontFamily, "Ak-Regular");
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}
@media screen and (max-width: 1280px) {
  .sharePage .info {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 768px) {
  .sharePage.scrollContent {
    padding: 20px 40px;
    font-size: 16px;
    line-height: 25px;
    flex-direction: column;
  }
  .sharePage .keywordContent {
    height: 50px;
    margin-bottom: 30px;
  }

  .sharePage .keywordContent div {
    width: 50px;
    height: 50px;
  }
  .sharePage .keywordContent div img {
    width: 25px;
    height: 25px;
  }
  .sharePage .info {
    font-size: 16px;
  }

  .sharePage .itemTimeLabel {
    font-size: 13px;
    line-height: 20px;
  }

  .sharePage .scrollList {
    flex-direction: column;
    gap: 15px;
    margin-right: 0;
    padding-right: 0;
    margin-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .sharePage.scrollContent {
    padding: 20px;
    font-size: 13px;
    line-height: 20px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    /* height: 100% !important; */
  }
  .sharePage.scrollContent > div {
    flex: 1;
  }
  .sharePage.scrollContent > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sharePage .keywordContent {
    height: 30px;
    margin-bottom: 20px;
  }
  .sharePage .keywordContent div {
    width: 30px;
    height: 30px;
  }
  .sharePage .keywordContent div img {
    width: 15px;
    height: 15px;
  }
  .sharePage .videoListContainer {
    margin-top: unset;
  }
  .sharePage .scrollList {
    /* margin-top: 50px; */
    margin-top: -30px;
    padding-top: 30px;
  }
  .sharePage .videoListResetHgt {
    height: calc(100% + 30px) !important;
  }
  .videoPlayerResetHgt {
    flex: 1;
  }
  .sharePage .info {
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  .sharePage .info::-webkit-scrollbar {
    height: 0;
    width: 5px;
    background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
  }
  .sharePage .info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
  }
  .sharePage .info::-webkit-scrollbar-track {
    background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
  }
}

@media screen and (min-width: 1441px) {
  .sharePage .itemTitle {
    -webkit-line-clamp: 3 !important;
    color: var(--saasTitleColor, "#12192B");
  }
}

.target {
  border-bottom: 0;
}
:global(.ai-saas) .scrollContent,
:global(.thinking-app) .scrollContent {
  border-radius: 0 30px 30px 30px;
}
.keywordContent {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #12192B; */
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  height: 30px;
}
.keywordContent div {
  width: 30px;
  height: 30px;
  border: 0.75px solid #020c260d;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--videoKeywordContentBg, #fff);
}
.keywordContent div img {
  width: 15px;
  height: 15px;
}
.keywordContent p {
  font-size: 16px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: var(--videoKeywordContentColor, #12192b);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.info {
  font-size: 13px;
  line-height: 20px;
  color: var(--videoItemContentColor, #12192b);
  /* margin-bottom: 15px; */
  font-weight: 400;
}

.title {
  font-weight: 500;
}

.description {
}

.showMore {
  cursor: pointer;
  color: var(--videoShowMoreColor, #acaeb5);
}

.scrollList {
  /*overflow-x: scroll;*/
  /*overflow-y: hidden;*/
  overflow: auto;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-right: -20px;
  margin-bottom: 15px;
  margin-top: 0;
  padding-right: 20px;
}
.scrollList::-webkit-scrollbar {
  height: 0;
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
.scrollList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}
.scrollList::-webkit-scrollbar-track {
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.item {
  position: relative;
  flex-shrink: 0;
  overflow-x: visible;
  cursor: pointer;
  z-index: 0;
}

.sharePage .item {
  display: flex;
  gap: 15px;
  align-items: center;
}

.sharePage .itemTitle {
  font-weight: 500;
  overflow: hidden;
  max-height: 100%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: var(--saasTitleColor, "#12192B");
}

.item img {
  border-radius: 10px;
  height: 100%;
}

.itemTimeLabel {
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 0 5px;
  line-height: 20px;
  background-color: var(--videoItemDurationBg, #acaeb5);
  border-radius: 15px;
  color: white;
}

.currentVideoWrapper {
  border-radius: 15px;
  background-color: var(--videoSelectedItemBg, #e7e8ea);
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  z-index: -1;
}

.textShowLess {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.noPagination {
  display: none;
}
.pagination > div {
  min-width: 73px;
  height: 40px;
  background-color: var(--videoPaginationBg, #f3f3f4);
  border-radius: 25px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--videoItemContentColor, #12192b);
}

.pagination > div:not(:nth-child(2)) {
  cursor: pointer;
}

.pagination .of {
  margin: 0 3px;
}

.videoListContainer {
  margin-top: auto;
}
