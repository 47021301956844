.content {
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #12192b;

  .input {
    height: 40px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .title {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    align-items: center;
  }
}

.menu_box {
  border-radius: 15px;
  top: -4px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  .point {
    cursor: no-drop;
    user-select: none;
    opacity: 0.5;
  }

  .menu_group_title {
    padding-left: 10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #12192b;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .menu_item {
    display: flex;
    align-items: center;
    height: 30px;
    min-width: 190px;
    padding-left: 10px;
    padding-right: 10px;

    span {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

.overlayWrap {
  .line {
    margin: 0;
  }

  :global(.ant-dropdown-menu-item:hover) {
    background-color: #0a9dff1a;
  }
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
