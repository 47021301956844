.oneProjectOneAppModal {
  width: auto !important;
  :global(.ant-modal-content) {
    border-radius: 20px;
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
}

.oneProjectOneApp {
  padding: 30px;
  width: 500px;
  font-family: "Ak-Regular";

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .headerTitle {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }

  .title {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .content {
    font-size: 15px;
    line-height: 20px;
  }

  .hint {
    font-size: 13px;
    line-height: 20px;
    color: rgba(222, 28, 34, 1);
  }

  .appList {
    margin-top: 10px;
    max-height: 390px;
    overflow-y: auto;
    margin-right: -10px;
    padding-right: 10px;

    :global(.ant-checkbox-group) {
      width: 100%;
    }
  }

  .btn {
    text-align: center;
    margin-top: 35px;
  }
  .gotIt {
    width: 92px;
    height: 40px;
    border-radius: 20px;
  }
}
