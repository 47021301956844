.tool-bar-item {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  background: rgba(2, 12, 38, 0.05);
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.tool-bar-item-republic {
  width: 20px;
  height: 20px;
  margin: 0;
}

.tool-bar-item:hover {
  background-color: var(--back-color);
}

.tool-bar-item-sta-add {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/send_to_app.svg");
}
.tool-bar-item-sta-remove {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/stopNormal.svg");
}

.tool-bar-item:hover .tool-bar-item-sta-add {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/send_to_app_hover.svg");
}
.tool-bar-item:hover .tool-bar-item-sta-remove {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/stop.svg");
}

.tool-tip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: max-content;
  background-color: rgba(255, 255, 255, 1);
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: bold;
  color: rgba(18, 25, 43, 1);
  opacity: 0;
  transition: opacity 0.5s;
}
.tool-bar-item:hover .tool-tip {
  opacity: 1;
}
