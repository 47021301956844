@import "../../../../styles/variables.scss";

.template {
  display: flex;
  // width: 230px;
  width: 100%;
  position: relative;
  height: 200px;
  padding: var(--spacing-spacing-small, 10px);
  flex-direction: column;
  justify-content: flex-start;
  // align-items: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: var(--radius-medium, 20px);
  background: var(--white, #fff);
  // box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  transition: box-shadow 0.3s, background-color 1s;
  cursor: pointer;
  &:hover {
    box-shadow: $boxShadowHover;
    background-color: #fff;
  }

  &__image {
    display: flex;
    align-items: center;
    // width: 210px;
    width: 100%;
    justify-content: center;
    height: 118px;
    border-radius: var(--radius-small, 10px);
    background-color: #f2f3f4;
    //padding: 20px 50px;
    overflow: hidden;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e6eff5;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__description {
    display: flex;
    width: 100%;
  }
}

.templateDescription {
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  // align-items: flex-start;
  align-items: center;
  width: 100%;
  gap: 2px;
  align-self: stretch;

  &__text {
    display: inline-block;
    width: 190px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--dark, #12192b);
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px;
  }

  &__datetime {
    width: 190px;
    flex: 1 0 0;

    color: var(--dark-35, rgba(2, 12, 38, 0.35));
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }
}

.dropdown {
  display: flex;
  width: 220px;
  padding: var(--spacing-extra-small, 5px) 0px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 15px;
  background: var(--white-65, rgba(255, 255, 255, 0.65));

  /* Shadow L [Blur] */
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
  cursor: pointer;

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
}

.dropdownItem {
  display: flex;
  padding: 15px var(--spacing-spacing-medium, 20px);
  align-items: center;
  gap: 20px;
  align-self: stretch;

  &__icon {
    display: flex;
  }

  &__text {
    display: flex;

    color: var(--dark-65, rgba(18, 25, 43, 0.65));
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }
}

.image {
  display: inline-flex;
  width: 117px;
  height: 102px;
  object-fit: cover;
  border-radius: var(--radius-small, 10px);
}

.template .input {
  display: flex;

  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;

  color: rgba(18, 25, 43, 0.65);
  font-family: Ak-Regular;
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
}

.loading {
  position: absolute;
  z-index: 200;
  // width: 230px;
  width: 100%;
  // height: 200px;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.templateToolTipsBox :global(.ant-popover) {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-18%, 80%) !important;
}
