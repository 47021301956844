.UnStructured {
  width: 100%;
  height: 100%;
  min-width: 230px;
  min-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15px 0;
  font-size: 13px;
  word-wrap: anywhere;

  font-weight: 400;
  line-height: 20px;
}
.reservedButtonHeight {
  margin-bottom: 50px;
}
.UnStructured::-webkit-scrollbar {
  width: 5px;
  // background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
.UnStructured::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}
.UnStructured::-webkit-scrollbar-track {
  // background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
