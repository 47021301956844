.logic-layer {
  max-width: 600px;
  width: 50%;
  max-width: 600px;
  height: 100%;
  padding: 50px 0 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  line-height: 16px;
}

.logic-input {
  margin-top: 50px;
  width: 100%;
  border-radius: 30px;
  border: none;
  background-color: #f2f2f2;
  padding: 15px 20px;
  margin-bottom: 20px;
}

.logic-input:focus {
  outline: none;
}

.logic-input::-webkit-input-placeholder {
  color: #000;
  font-weight: 700;
}
.logic-input:-moz-placeholder {
  color: #000;
  font-weight: 700;
}
.logic-input::-moz-placeholder {
  color: #000;
  font-weight: 700;
}
.logic-input:-ms-input-placeholder {
  color: #000;
  font-weight: 700;
}

.logic-api-box {
  padding: 10px;
}
.api-child-box {
  background-color: #f2f2f2;
  margin-top: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.api-list {
  overflow: auto;
}
.api-title {
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
}
.api-child-title {
  color: #000;
  font-weight: 700;
  flex: 1;
  margin-right: 10px;
}
.api-child-description {
  font-size: 14px;
  flex: 1;
  line-height: 16px;
}

.api-content-description {
  margin-top: 20px;
  font-size: 15px;
  line-height: 18px;
}
