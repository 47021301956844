.maskBox {
  background: rgba(18, 25, 43, 0.2);
  position: absolute;
  z-index: 2;
  inset: 0;
}

.doneBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;

  :global {
    .ant-skeleton-active .ant-skeleton-title::after,
    .ant-skeleton-active .ant-skeleton-paragraph > li::after,
    .ant-skeleton-active .ant-skeleton-avatar::after,
    .ant-skeleton-active .ant-skeleton-button::after,
    .ant-skeleton-active .ant-skeleton-input::after,
    .ant-skeleton-active .ant-skeleton-image::after {
      background: linear-gradient(
        90deg,
        rgba(190, 190, 190, 0.5) 25%,
        rgba(129, 129, 129, 0.64) 37%,
        rgba(190, 190, 190, 0.5) 63%
      );
    }
  }
}

.doneContent {
  width: 400px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;

  .header {
    color: #fff;
    background-color: #12192b;
    background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/upgrade_success_bg@2x.jpg");
    background-repeat: round;

    .header_conetnt {
      gap: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      backdrop-filter: blur(20px);
    }

    .tips {
      font-family: "Ak-Regular";
      font-size: 17px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 340px;
    }

    .tips_loading {
      width: 200px;
    }

    .title {
      font-family: "Ak-Regular";
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 340px;
    }

    .title_loading {
      width: 320px;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 30px;

    .tips {
      font-family: "Ak-Regular";
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #12192b;
      margin-bottom: 20px;
    }

    .item_view {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .item {
        font-family: "Ak-Regular";
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #12192b;

        span {
          margin-left: 10px;
        }
      }

      .item_loading {
        width: 240px;
        // background-color: rgba(225, 225, 225, 1);
      }
    }

    .button {
      margin-top: 20px;
      width: 100px;
      height: 40px;
      padding: 5px, 30px, 5px, 30px;
      border-radius: 25px;
      gap: 15px;
      font-family: "Ak-Regular";
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8f79ff;
      color: white;
      cursor: pointer;
    }
  }
}

.loading_line {
  height: 20px;
  width: 100%;
  // background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.column_space {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tips {
  .tips_loading {
    width: 240px;
  }
}
