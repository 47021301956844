.logoButton {
  display: inline-flex;
  width: 80px;
  height: 60px;
  overflow: hidden;
  position: relative;
  .content {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -10px;
    display: flex;
    justify-content: center;
  }
  & svg {
    cursor: pointer;
  }
}

.isShowLogoBtn {
  display: block;
}
.isShowHomeTopToolBarMenu {
  display: none;
}
@media only screen and (min-width: 718px) and (max-width: 979px) {
  .isShowLogoBtn {
    display: none;
  }
  .isShowHomeTopToolBarMenu {
    display: block;
    align-self: center;
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  .isShowLogoBtn {
    display: none;
  }
  .isShowHomeTopToolBarMenu {
    display: block;
    align-self: center;
  }
}
@media only screen and (max-width: 309px) {
  .isShowLogoBtn {
    display: none;
  }
  .isShowHomeTopToolBarMenu {
    display: block;
    align-self: center;
  }
}
