.cover {
  background: rgba(18, 25, 43, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  :global(.ant-modal) {
    top: auto;
  }
}
.content {
  background-color: #ffffffcc;
  width: 540px;
  height: 500px;
  padding: 30px;
  border-radius: 20px;
  font-family: "Ak-Regular";

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .closeBtn {
    position: absolute;
    top: 30px;
    right: 5px;
    z-index: 10;
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .item_view {
    width: 100%;
    height: 180px;
    padding: 20px;
    border-radius: 20px;
    margin-top: 30px;
    background-color: white;
    display: flex;
    flex-direction: row;
    transition: all 0.3s;
    cursor: pointer;
    justify-content: space-between;

    &:hover {
      box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
    }

    .left_view {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .label_title {
        // font-family: AktivGrotesk-Medium;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #12192b;
        font-weight: 600;
      }

      .label_info {
        // font-family: AktivGrotesk-Regular;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 15px;
        color: #656a75;
      }

      .label_tip {
        // font-family: AktivGrotesk-Medium;
        display: flex;

        .label_tip_box {
          line-height: 20px;
          text-align: left;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 5px;
          background: #05be7533;
          color: #05be75;
          font-size: 13px;
          font-weight: 400;
        }

        .label_tip_box_blue {
          background: #0a9dff33;
          color: #0a9dff;
        }
      }
    }

    .right_view {
      margin-left: 20px;
      width: 200px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .item_view_disable {
    display: none;
    height: 0;
  }
}

.contentMobile {
  width: 100%;
  height: 300px;
  .closeBtn {
    right: 30px;
  }
}
