.tableItemCell {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 3.8vw;
  margin-top: 10px;
  position: relative;
  touch-action: pan-y;

  .img {
    width: 23vw;
    height: 15vw;
    margin-right: 5vw;
    border-radius: 10px;
    background: #ffffff59;
    border: 1px solid #ffffff59;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
    img {
      width: 100%;
    }
    .imgLayer {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .name {
    flex: 1;
    font-family: "Ak-Regular";
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }

  .more {
    padding: 2.5vw;
  }
}
