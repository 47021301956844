.white-frame {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
.white-frame .chat-box {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}
.white-frame .type-title {
  color: rgba(2, 12, 38, 0.35);
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  padding: 10px 10px 10px 15px;
  height: 40px;
  line-height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.white-frame .chatbox-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: calc(100% - 45px);
  /* min-width: 400px; */
  min-height: 370px;
  width: 100%;
  padding: 15px 5px;
  background-color: #fff;
}
.white-frame .max-chatbox {
  max-width: 500px;
  max-height: 372px;
}
.chatbox-list {
  width: 100%;
  flex: 1;
  margin-bottom: 5px;
  height: calc(100% - 85px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.chatbox-list::-webkit-scrollbar {
  display: none;
}
.chatbox-item {
  display: flex;
  padding: 15px 5px;
  user-select: text;
}
.chatbox-assistant-item {
  background: #f9f9f9;
}
.chatbox-type {
  min-width: 75px;
  font-family: var(--fontFamily, "Ak-Regular");
  color: #999;
  margin-right: 10px;
  line-height: 25px;
}
.chatbox-text {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 500;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 25px;
}
.chatbox-text pre {
  background: #000;
  position: relative;
  white-space: break-spaces;
  word-wrap: break-word;
}
.chatbox-text code {
  color: #ccc;
}

.copy {
  position: absolute;
  top: 0;
  right: 5px;
}

.copy span {
  color: #ccc;
  cursor: pointer;
}

.chatbox-footer {
  width: 100%;
  text-align: center;
}
.chatbox-footer button {
  margin-bottom: 10px;
}
.preview-code {
  margin: 25px;
  height: calc(100% - 50px);
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 15px;
  padding: 5px;
}
.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.delete-content img {
  width: 100%;
  height: 100%;
}

:global(.dark) .white-frame,
:global(.dark) .chatbox-assistant-item {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

:global(.dark) .white-frame .chatbox-content {
  background: none;
}

:global(.dark .ant-btn-default) {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}
:global(.dark .ant-btn-default[disabled]) {
  color: #ffffff52;
}

:global(.dark .ant-btn-default:hover) {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

:global(.dark .ant-btn-default[disabled]:hover) {
  color: #ffffff52;
}

:global(.dark .ant-input-affix-wrapper) {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}
:global(.dark .ant-input-affix-wrapper:hover) {
  border-color: #fff;
  box-shadow: none;
}
:global(.dark .ant-input) {
  color: #ffffff;
  background: none;
  border: none;
  box-shadow: none;
}
:global(.dark .ant-input-affix-wrapper .anticon svg) {
  fill: #fff;
}
