.shareLocation {
  padding: 10px;
  > p {
    padding-bottom: 10px;
  }
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    > button {
      border-radius: 15px;
    }
  }
}
