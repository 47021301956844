$shadow: 0px 30px 60px rgba(113, 144, 175, 0.15);
$hover-shadow: 0px 2px 12px rgba(58, 57, 76, 0.2), 0px 16px 24px -2px rgba(58, 57, 76, 0.12);

.myProject {
  // padding: 66px 103px;
  padding: 66px 0 0 0;
  width: 100%;
  position: relative;
}
.create-h3 {
  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  user-select: none;
}
.new-project {
  position: relative;
  width: 160px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px 32px 32px 0;
  font-family: "Ak-Regular";

  font-weight: 500;
  line-height: 16px;
  padding: 18px 12px;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.3s;

  font-size: 15px;
  color: #12192b;
  background: rgba(255, 255, 255, 0.35);
  border: 0.5px solid #ffffff;
  box-shadow: unset;
  backdrop-filter: blur(20px);
  border-radius: 15px;
  box-shadow: $shadow;
}

.new-project:hover {
  box-shadow: $hover-shadow;
}

.blackContent {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.blackContent .blankIcon {
  font-size: 30px;
  margin-right: 10px;
}

.watchExample .new-project {
  box-shadow: unset;
}

.function-box {
  font-size: 15px;
  color: #12192b;
  background: rgba(255, 255, 255, 0.35);
  border: 0.5px solid #ffffff;
  box-shadow: 50px 30px 60px rgba(113, 144, 175, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 15px;
}
.new-project:hover {
  box-shadow: 0px 2px 12px rgba(58, 57, 76, 0.1), 0px 16px 24px -2px rgba(58, 57, 76, 0.12);
}
.video-title {
  font-family: ak-Regular;
  font-weight: 800;
  font-size: 17px;
  line-height: 150%;
  color: #000000;
  margin: 0;
}
.player-box {
  width: 213px;
  height: 120px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
}
.play-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fillet-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.fillet-box img {
  width: 100%;
  height: 100%;
}

.project-box {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-height: 100px;
  max-height: 540px;
  overflow: auto;
  user-select: none;
}
.project-box::-webkit-scrollbar {
  display: none;
}

.fullScreenScroll {
  max-height: unset;
  overflow: unset;
}

.span-icon {
  position: absolute;
  right: 12px;
  bottom: 18px;
}

.clickable-title {
  width: 100%;
  height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* stud-992 */
.clickable-title .clickable-inner {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  /* 纯数字时需要换行 */
  word-break: break-word;
}

.skeleton-new-project :global(.ant-skeleton-button) {
  width: 160px;
  height: 120px;
  border-radius: 10px;
  padding: 18px 12px;
  margin: 32px 32px 32px 0;
}
.confirm-modal-reset :global(.ant-modal) {
  top: 50%;
  left: 50%;
  margin-top: -105px;
  margin-left: -200px;
}
.confirm-modal-reset :global(.ant-modal-content) {
  border-radius: 15px;
  width: 400px;
}
.confirm-modal-reset :global(.ant-modal-confirm .ant-modal-body) {
  padding: 30px;
}
.confirm-modal-reset :global(.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon) {
  color: #ff335c;
  margin-right: 10px;
}
.confirm-modal-reset :global(.ant-modal-confirm-body .ant-modal-confirm-title) {
  font-family: "Ak-Regular";
  font-weight: 800;

  font-size: 15px;
  color: rgba(18, 25, 43, 1);
}
.confirm-modal-reset
  :global(.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content) {
  word-break: break-all;
  /* margin: 15px 0 34px; */
  line-height: 20px;
  color: rgba(18, 25, 43, 1);
  font-weight: 400;
  font-size: 15px;
  font-family: "Ak-Regular";
}
.confirm-modal-reset :global(.ant-modal-confirm .ant-modal-confirm-btns) {
  text-align: unset;
  display: flex;
  justify-content: space-between;
}
.confirm-modal-reset :global(.ant-btn) {
  border-radius: 25px;
  padding: 5px, 30px, 5px, 30px;
  background: #020c260d;
  border: 0 none;
  font-family: "Ak-Regular";
  font-size: 15px;
  color: rgba(18, 25, 43, 0.65);
  /* width: 109px; */
  height: 40px;
  flex: 1;
}
.confirm-modal-reset :global(.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn) {
  border-radius: 25px;
  background-color: #ff335c;
  padding: 5px, 30px, 5px, 30px;
  border: 0 none;
  font-family: "Ak-Regular";
  font-size: 15px;
  color: #ffffff;
}

.newProject-row {
  --gap-y: 30px;
  --gxp-x: 15px;
  --height: 170px;

  display: flex;
  align-items: center;
  min-height: 220px;
  width: 100%;

  .new-project {
    margin: 0;
    width: 200px;
    height: var(--height);
  }

  .newProject-split {
    margin-left: 30px;
    margin-right: var(--gxp-x);
    width: 3px;
    background-color: #fff;
    height: var(--height);
  }

  .TemplateListWrap {
    flex: 1;
    white-space: nowrap;
    overflow: auto;
  }

  .TemplateList {
    padding-top: var(--gap-y);
    padding-bottom: var(--gap-y);
    padding-left: var(--gxp-x);
    padding-right: 56px;
    width: 100%;
  }
}

.projectTemplateList {
  width: 100%;
}

.newProject {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 103px;
  margin: 0;

  &_videoExamples {
    padding: 0 103px;
    margin: 0;
  }

  &_startNewProject {
    // background: rgba(78, 78, 78, 0.3);
    padding: 20px 0 20px 103px;
    margin: -50px 0 0 0;
  }

  &_projects {
    margin-top: -40px;
  }

  &__title {
    display: flex;
    width: 100%;

    &_videoExamples {
      padding: 0 20px 0 0;
    }
  }

  &__templates {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
}

.newProjectTemplates {
  display: flex;
  width: 100%;

  &__new {
    display: flex;
    margin-right: 20px;
  }

  &__templates {
    display: flex;
    flex: 1;
    width: 100%;

    &_main {
      width: 0px;
      padding-right: 103px;
    }

    &_full {
      flex: auto;
    }

    &_fullWrap {
      flex: auto;
      height: auto;
      flex-wrap: wrap;
    }
  }
}

.title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Ak-Regular;
  font-size: 32px;

  font-weight: 500;
  line-height: 48px;
}

.newProjectTemplatesScrollbar {
  width: 100%;
}

.projectTemplates {
  display: flex;
  gap: 20px;
}

.scrollbarOfLeftArrow {
  display: none;
}

@keyframes showMain {
  from {
    top: 1000px;
  }
  to {
    top: 0px;
  }
}

.main {
  display: flex;
  width: 100%;
  gap: 50px;
  padding: 50px 0 0 0;

  position: relative;
  z-index: 100;

  animation-name: showMain;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &__sidebar {
    display: flex;
    padding: 0 0 0 25px;
    width: 295px;
  }

  &__content {
    // display: flex;
    width: calc(100% - 295px - 50px - 25px);
    height: 95vh;
  }
}
.isVisibleSmallScreenSideBar {
  visibility: visible;
}
@media only screen and (min-width: 718px) and (max-width: 979px) {
  // .main__sidebar {
  //   display: none !important;
  // }
  .main .isVisibleSmallScreenSideBar {
    visibility: hidden;
  }
  .main__sidebar {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 999;
  }
  .main__content {
    width: 100% !important;
    // padding-left: 50px !important;
    padding-left: 25px !important;
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  // .main__sidebar {
  //   display: none !important;
  // }
  .main .isVisibleSmallScreenSideBar {
    visibility: hidden;
  }
  .main__sidebar {
    position: fixed;
    top: 95px;
    z-index: 999;
    width: -webkit-fill-available;
    padding: 0 25px;
  }
  .main__content {
    width: 100% !important;
    // padding-left: 50px !important;
    padding-left: 25px !important;
  }
}
@media only screen and (max-width: 309px) {
  // .main__sidebar {
  //   display: none !important;
  // }
  .main .isVisibleSmallScreenSideBar {
    visibility: hidden;
  }
  .main__sidebar {
    position: fixed;
    top: 95px;
    z-index: 999;
    width: -webkit-fill-available;
    padding: 0 25px;
  }
  .main__content {
    width: 100% !important;
    // padding-left: 50px !important;
    padding-left: 25px !important;
  }
}
.gridHomeRgtBox {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(230px, 1fr));
  justify-content: center;
  align-content: center;
  grid-template-areas:
    "a a b b"
    "c c c c";
  padding-right: 50px;
}
.gridHeaderLft {
  grid-area: a;
  // border: 1px solid #ffffff;
}
.gridHeaderRgt {
  grid-area: b;
  position: relative;
  // border: 1px solid #ffffff;
}
.gridMainContentBox {
  grid-area: c;
  // border: 1px solid #ffffff;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-spacing-large, 30px);

  &__header {
    display: flex;
    width: 100%;
  }

  &__view {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex: 1;

    padding: 0 0 50px 0;
  }
}

.contentHeader {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: var(--spacing-spacing-medium, 20px);

  &__slider {
    display: flex;
  }

  &__preview {
    display: flex;
    position: relative;
    width: calc(100% - 730px - 20px);
    height: 230px;
  }
}

.view {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-spacing-small, 10px);
  align-self: stretch;
  position: relative;
  top: 0px;

  &__title {
    display: flex;
    padding: var(--spacing-spacing-small, 10px) 0px;
    align-items: center;
    align-self: stretch;

    .name {
      color: var(--dark, #12192b);
      font-family: Ak-Regular;
      font-size: 24px;

      font-weight: 500;
      line-height: 30px; /* 125% */
    }

    .input {
      width: 20%;
      min-width: 200px;
      margin-left: 15px;
      border-radius: 10px;
      padding: 10px;
      font-family: Ak-Regular;
      font-size: 13px;
    }
  }

  &__content {
    // display: flex;
    // align-items: flex-start;
    // align-content: flex-start;
    // min-width: 100%;
    // height: 100%;
    gap: 20px;
    // align-self: stretch;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    width: 100%;
    overflow: unset !important;
  }
}

:global(.infinite-scroll-component__outerdiv) {
  width: 100%;
}

.notify {
  .ant-notification-notice-close {
    top: 0px;
    right: 15px;
  }

  :global(.ant-notification-notice-close-x) {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

@media only screen and (min-width: 718px) and (max-width: 979px) {
  .gridHomeRgtBox {
    padding-right: 25px;
  }
  .sidebarMask {
    position: absolute;
    background: rgb(255, 255, 255, 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1000vh;
    z-index: 999;
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  .gridHomeRgtBox {
    padding-right: 25px;
  }
  .sidebarMask {
    position: absolute;
    background: rgb(255, 255, 255, 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1000vh;
    z-index: 999;
  }
}
@media only screen and (max-width: 309px) {
  .gridHomeRgtBox {
    padding-right: 25px;
  }
  .sidebarMask {
    position: absolute;
    background: rgb(255, 255, 255, 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1000vh;
    z-index: 999;
  }
}
