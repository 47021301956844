.itemBox {
  background-color: white;
  border-radius: 50px;
  border-style: none;

  .headerBox {
    position: relative;
    border-radius: 44px;
    overflow: hidden;
    .headerContentBox {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border: 0;
      padding: 24px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .title {
        overflow: hidden;
        color: var(--Text-Default-Inverted, #fff);
        text-align: center;
        word-break: break-all;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        /* Nat 2.0/H3 */
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 125% */
      }
      .date {
        color: var(--Text-Default-Inverted, #fff);
        text-align: center;
        /* Nat 2.0/Subhead */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }
      .toolBox {
        margin-top: 12px;
        display: flex;
        height: 40px;
        padding: 0px 20px;

        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 32px;
        background: rgba(255, 255, 255, 0.3);
        // backdrop-filter: blur(32px);
        .line {
          width: 1px;
          height: 16px;
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  .bottomBox {
    padding: 20px;
    .content {
      color: var(--Text-Default, #12192b);
      /* Nat 2.0/Body */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .newsAgencyBox {
      margin-top: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .agencyName {
        overflow: hidden;
        color: rgba(18, 25, 43, 0.65);
        text-overflow: ellipsis;
        /* Nat 2.0/Body */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}
