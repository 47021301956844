.CardHomeShopping {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(144, 147, 169, 0.08);
  border-radius: 15px;
  margin: 0px 10px 25px 0;
}
:global(.Studio-UI) .CardHomeShopping {
  margin: 0px 10px 25px;
}

.CardHomeShopping img {
  display: block;
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border-radius: 10px;
  object-fit: cover;
}

.CardHomeShopping article {
  padding: 10px 10px;
}

.CardHomeShopping .shoppingTitle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 400;
  height: 60px;
  line-height: 20px;
  margin: 15px 0 15px;
  word-break: break-all;
  overflow: hidden;
}

.CardHomeShopping .NADefaults {
  color: #ccc;
  font-weight: 500;
  font-size: 14px;
}
