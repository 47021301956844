.outputWrapper {
  padding: 20px 0;
  height: calc(100% - 80px);
  .outputBox {
    padding: 10px 4px;
    &.selected {
      border: 1px solid blue;
    }
  }
  img {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }
  .outputTitle {
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
}
