.thought-item {
  background: rgba(255, 255, 255, 0.65);
  background-blend-mode: soft-light;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin: 10px 0;
  overflow: hidden;
}
.item-top {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 15px 15px;
  padding: 20px;
}
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.title-box p {
  width: 85%;
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
  margin-bottom: 6px;
}
.title-box :global(.icon-right) {
  flex-shrink: 0;
  font-weight: bold;
}
.save-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.save-box span {
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  color: #5182ff;
  cursor: pointer;
}
.save-box img {
  width: 17px;
  cursor: pointer;
}
.thumb-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.thumb-box img {
  width: 30px;
  margin-right: 10px;
}
.thumb-box span {
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  color: #5182ff;
}
.thumb-box .thumbed-text {
  color: #7d8fa1;
}
.item-bottom {
  padding: 30px 20px;
}
.item-bottom p span {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 10px 20px;
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
  display: inline-block;
  word-break: break-word;
}
.item-bottom p:not(:last-child) {
  margin-bottom: 10px;
}
.thought-box {
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.subthought-box {
  padding: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.thought-title {
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
}
.thought-created {
  font-family: "Ak-Regular";
  font-size: 13px;
  line-height: 20px;
  color: #7d8fa1;
  margin: 10px 0;
}
.thought-content {
  font-family: "Ak-Regular";
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 10px 20px;
}
.subthought-title {
  font-family: "Ak-Regular";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7d8fa1;
}
.subthoughts-content {
  padding: 20px;
}
.top-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px;
}
.add-icon {
  font-size: 29px;
  margin-right: 15px;
  flex-shrink: 0;
}
.top-input input {
  outline: none;
  border: none;
  flex: 1;
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  color: #7d8fa1;
}
