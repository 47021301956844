.layer-search {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.layer-search :global(.ant-input-affix-wrapper-lg) {
  padding: 12px 11px;
  border-radius: 5px;
}
.build-thinking-btn {
  position: absolute;
  margin: 40px 0;
  left: 50%;
  transform: translateX(-50%);
}
.layer-btn {
  border-radius: 30px;
  padding: auto 25px;
  padding-left: 35px;
  padding-right: 35px;
  border: none;
  box-shadow: 0 3px 4px #ccc;
}
