.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  border-radius: 10px;
  border: 1px solid var(--Dark-5, rgba(2, 12, 38, 0.05));

  padding: 10px;

  .input {
    height: 50px;

    /* Dark 5 */

    background: rgba(2, 12, 38, 0.05);
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 15px;
  }
}

.label {
  width: 100%;
  display: block;
  text-align: left;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* Dark 65 */

  color: rgba(18, 25, 43, 0.65);
  padding-top: 15px;
  padding-bottom: 5px;
}

.add {
  display: flex;
  height: 30px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 25px;
  background: var(--Blue, #0a9dff);
}

.cancel {
  border-radius: 25px;
  background: rgba(2, 12, 38, 0.05);
  margin-right: 10px;
}

.title {
  color: var(--Dark, var(--Dark-100, #12192b));
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 100%;
}

.buttonGroup {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  padding-top: 10px;
}

.contactAdd {
  cursor: pointer;
  height: 80px;

  border-radius: 15px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 60px;
  border: 1px dashed rgba(10, 157, 255, 1);
}

.contactAdd .addImage {
  margin: 0 25px;
  width: 20px;
}

.uploadTextBox {
  font-size: 13px;
  text-align: left;
}

.uploadTextBox .uploadDescription {
  color: rgba(172, 174, 181, 1);
}
