.ratingBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  .ratingImg {
  }
  .ratingNumber {
    padding: 0 5px;
    color: rgba(18, 25, 43, 0.65);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .reviews {
    color: rgba(18, 25, 43, 0.65);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .restaurantRecommendationRating {
    color: rgba(18, 25, 43, 0.6);
    font-weight: 500;
  }
}
