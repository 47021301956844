@import "./mixin.scss";

.planTable {
  --plan-table-root-size: 16px;
}

@media screen and (min-width: 390px) {
  .planTable {
    --plan-table-root-size: 10px;
  }
}

@media screen and (min-width: 650px) {
  .planTable {
    --plan-table-root-size: 12px;
  }
}

@media screen and (min-width: 950px) {
  .planTable {
    --plan-table-root-size: 13px;
  }
}

@media screen and (min-width: 1204px) {
  .planTable {
    --plan-table-root-size: 15px;
  }
}

.planTable {
  font-size: var(--plan-table-root-size, 10px);
}

.columns {
  display: flex;

  .column {
    position: relative;
    flex: 1 1 px2em(260px);
    border: 1px solid rgba(18, 25, 43, 0.05);
    border-top: none;

    &:first-child {
      position: sticky;
      left: 0;
      background-color: #fff;
      z-index: 10;
      border-right: 1px solid rgba(18, 25, 43, 0.05);
    }

    &:last-child {
      border-right: 1px solid rgba(18, 25, 43, 0.05);
      border-bottom-right-radius: 20px;
    }

    &[data-highlight-border="true"] {
      border-color: rgba(143, 121, 255, 1);
      border-width: 2px;
      > .cell {
        &:first-child {
          border-top-color: rgba(143, 121, 255, 1);
          border-width: 2px;
        }
      }
    }

    > .cell {
      height: 50px;
      border-left: none;

      &:first-child {
        height: 215px;
        max-height: 215px;
        position: sticky;
        top: 0;
        background-color: #fff;
        border-top: 2px solid rgba(18, 25, 43, 0.05);
      }

      &.multiLine {
        height: px2em(60px);
      }

      border-bottom: 1px solid rgba(18, 25, 43, 0.05);
    }
  }
}
