@import "../mixin.scss";

.planCell {
  display: flex;
  flex-direction: column;
  position: relative;
}

.planBody {
  min-height: 145px;
  padding: px2em(20px) px2em(20px) 0 px2em(20px);
}

.planFoot {
  height: 70px;
  padding: 0 px2em(10px) 0 px2em(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(18, 25, 43, 0.05);
  flex-direction: column;
}

.planName {
  font-size: px2em(17px);
  font-weight: 500;
  line-height: 26px;

  margin-bottom: px2em(20px);
}

.badgeList {
  position: absolute;
  right: 0;
  top: 0;

  .badge {
    border-bottom-left-radius: 10px;
    padding: px2em(5px) px2em(10);
  }
}

.priceRow {
  .priceBlock {
    display: inline-flex;
  }

  .priceMonthValue {
    font-size: px2em(32px);
    font-weight: 700;
    line-height: 45px;
  }

  .unit {
    font-size: px2em(18px);
    font-weight: 700;
    line-height: 28px;
    margin-left: 4px;
    vertical-align: top;
  }
}

.priceAnnualRow {
  font-size: px2em(15px);
  font-weight: 400;
  line-height: 20px;

  .unit {
    margin-left: 1px;
  }
}

.footButton {
  position: relative;
  min-width: 157px;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: px2em(5px) px2em(20px);
  border-radius: 25px;

  color: #8f79ff;
  text-shadow: 0px 0px 3.5px rgba(255, 255, 255, 0.35);
  font-size: px2em(13px);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 153.846% */
  background-size: cover;
  background-repeat: no-repeat;

  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.hidden {
  visibility: hidden;
}

.loadingBox {
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnLoading {
  font-size: px2em(26px);
}

.currentPlanButton {
  padding: 0 px2em(5px);
  border-radius: 5px;
  background: rgba(242, 123, 44, 1);
  color: #fff;
  /* Caption [M] */
  font-family: Ak-Regular;
  font-size: px2em(13px);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.cancelTipBox {
  line-height: 16px;
  text-align: center;

  .cancelTip {
    font-family: Ak-Regular;
    font-size: px2em(13px);
    font-weight: 500;
    color: rgba(18, 25, 43, 0.65);
  }
}

.disableBtn {
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.05);
  filter: opacity(0.5);
  cursor: not-allowed;
}
