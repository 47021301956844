.news-feed {
  border-radius: 44px;
  align-self: stretch;
  max-width: 600px;
  .news-card {
    position: relative;
  }
  .news-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 268px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 24px;
    gap: 10px;
    .likeBox {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      border-radius: 32px;
      position: absolute;
      right: 85px;
      top: 24px;
      background: linear-gradient(0deg, rgba(18, 25, 43, 0.1), rgba(18, 25, 43, 0.1)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
      img {
        width: 20px;
      }
    }
    .shareBox {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      border-radius: 32px;
      position: absolute;
      right: 24px;
      top: 24px;
      background: linear-gradient(0deg, rgba(18, 25, 43, 0.1), rgba(18, 25, 43, 0.1)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
      img {
        width: 20px;
      }
    }
  }
  .imgBox {
    min-width: 328px;
    min-height: 328px;
    object-fit: cover;
    border-top-left-radius: 44px;
    border-top-right-radius: 44px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
  }
  .category {
    background: #8f79ff;
    color: #fff;
    padding: 0px 12px;
    gap: 10px;
    border-radius: 20px;
    width: max-content;
    font-weight: 500;
  }
  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .restaurantType {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .buttonBox {
    padding: 24px;
    border-radius: 44px;
    background: #fff;
    margin-top: -60px;
    position: relative;
    margin-bottom: 5px;
    .preview {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgba(18, 25, 43, 1);
    }
    .sourceBox {
      display: flex;
      margin-top: 15px;
      align-items: center;
      gap: 10px;
    }
    .source {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgba(18, 25, 43, 0.6);
    }
  }
}
