.previewPage {
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
  background-color: #f7f8fa;
  border-radius: inherit;
  display: flex;
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .previewLoading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border-radius: 25px;
    background: rgba(2, 12, 38, 0.05);
    backdrop-filter: blur(10px);
    .loading {
      width: 30%;
    }
  }

  .previewLoadingHide {
    display: none;
  }
}
.newPreviewPage {
  background-color: #f9fafc;
}

.previewPageMobile {
  background: rgb(226, 228, 234);
}

.leftBackBox {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
