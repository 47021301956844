.listBody {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  .news {
    width: calc(50% - 5px);
    height: 234px;
    border-radius: 44px;
    .newsBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      height: 100%;
      border-radius: 44px;
      background-color: #fff;
      .title {
        flex: 1;
        color: #12192b;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
      }
      .timestamp {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        .categoryTag {
          width: 24px;
          height: 24px;
          border-radius: 12px;
        }
        .categoryText {
          color: rgba(18, 25, 43, 0.6);
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
