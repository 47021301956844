.food .ContentFrameResultsGrid {
  display: block;
}

.CardHomeFood {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}

.CardHomeFood img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.CardHomeFood .cardFood-middle {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CardHomeFood .cardFood-left {
  flex: 1;
}

.CardHomeFood .cardFood-descreption {
  text-align: left;
  background: radial-gradient(
        49.73% 79.17% at 72.17% 12.5%,
        rgba(98, 206, 245, 0.75) 0%,
        rgba(102, 136, 255, 0.75) 50%,
        rgba(130, 53, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #7d8fa1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  mix-blend-mode: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 0 20px 20px 20px;
}
.CardHomeFood .cardFood-left .FoodTitle {
  font-family: "Ak-Regular";

  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
}

.CardHomeFood .cardFood-left .SubInformation {
  color: #22232f;
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  margin-top: 5px;
}

.CardHomeFood .cardFood-right {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  width: 98px;
  color: #22232f;
  background: #e7edf4;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
