.title {
  color: var(--Dark, var(--dark-100, #12192b));
  text-align: center;
  font-family: Ak-Medium;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
}

.desc {
  color: var(--Dark-65, #656a75);
  text-align: center;
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}
