.footer-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-box :global(.anticon-plus-circle) {
  font-size: 30px;
  cursor: pointer;
}
.header-table :global(.ant-empty-normal) {
  margin: 0;
}
