.beautifyImage[src=""],
.beautifyImage:not([src]) {
  opacity: 0;
}

.blankNode {
  height: 160px;

  &:global(.ant-skeleton-element .ant-skeleton-image) {
    width: 100%;
    height: 100%;
  }
}

.hideImgContent {
  opacity: 0;
}
