.header {
  width: 100%;
  height: 60px;
  background: #e5e7eb;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #12192b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
