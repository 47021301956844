.tooltipBox {
  width: 580px;
  padding: 15px 25px;
  border-radius: 25px;
  background: var(--toolTipBoxBg, "rgba(255, 255, 255, 0.65)");
  box-shadow: var(--toolTipBoxShadow, "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)");
  color: var(--shareTitleColor, "#12192b");
  font-family: var(--fontFamily, "ak-regular");
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin: 20px 0;
  span {
    color: var(--hintTextColor, #0a9dff);
    font-weight: 500;
    cursor: pointer;
  }
}

.chatTooltip {
  margin-top: 10px;
  .info {
    color: var(--shareTitleColor, "#12192b");
    font-family: var(--fontFamily, "ak-regular");
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }
  .link {
    color: #0a9dff;
    font-family: var(--fontFamily, "ak-regular");
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    cursor: pointer;
  }
}

.previewTooltip {
  width: 100%;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .tooltipBox {
    width: 100%;
  }
}
