.content-box {
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 15px;
  padding: 5px;
  // position: relative;
  top: 0;
  z-index: 0;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
}

.bottom-background {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  border-radius: 15px;
  overflow: hidden;
}

.assistant-title {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 15px 0 0 15px;
  color: rgba(2, 12, 38, 0.35);
}

.optional-text {
  width: 100%;
  min-height: 40px;
  padding: 10px 15px;
  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #12192b;
  cursor: pointer;
  transition: 0.3s;
}

.optional-text:hover {
  background: rgba(10, 157, 255, 0.1);
  border-radius: 10px;
}

.motion {
  position: absolute;
  width: 100%;
}

.starter-prompts-box {
  max-height: 210px;
  overflow: auto;
}
