.head {
}

.row {
  display: flex;
  padding: var(--spacing-m, 15px) var(--spacing-l, 20px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  margin-top: 5px;

  .rowInfo {
    flex: 1;
    max-width: 320px;
  }
}

.row {
  font-size: 13px;
  line-height: 20px;
  position: relative;
  border-radius: var(--radius-m, 15px);
  background: var(--dark-5, rgba(18, 25, 43, 0.05));

  .rowTitle {
    color: var(--dark-100, #12192b);

    /* Caption [M] */
    font-family: Ak-Regular;
    // font-size: 13px;
    font-style: normal;
    font-weight: 500;
    // line-height: 20px;
    /* 153.846% */
  }

  .rowContent {
    color: var(--Dark-65, rgba(18, 25, 43, 0.65));

    /* Caption */
    font-family: Ak-Regular;
    // font-size: 13px;
    font-style: normal;
    font-weight: 400;
    // line-height: 20px;
    /* 153.846% */
    margin-top: 5px;
  }
}

.cancelTip {
  display: inline-block;
  border-radius: 25px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  font-family: Ak-Regular;
  padding: 5px 15px;
  background-color: rgba(255, 51, 92, 0.1);
  color: #ff335c;
  margin-top: 5px;
}

.rowHead {
  background: var(--purple-10, rgba(143, 121, 255, 0.1));
}

.subTitle {
  color: var(--Dark-65, rgba(18, 25, 43, 0.65));

  /* Caption */
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 153.846% */
}

.rowAction > * {
  cursor: pointer;
}

.seePlanButton {
  // layout
  display: flex;
  height: 30px;
  padding: 5px var(--spacing-l, 20px);
  justify-content: center;
  align-items: center;
  gap: 15px;

  border-radius: 25px;
  background: var(--surface-subscription, #8f79ff);

  // style
  color: var(--light-100, #fff);
  text-align: center;

  /* Caption [M] */
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 153.846% */

  &:hover {
    color: var(--light-100, #fff);
  }
}

.priceRow {
  color: var(--Dark-65, rgba(18, 25, 43, 0.65));

  /* Caption */
  font-family: Ak-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  /* 153.846% */
  .split {
    margin: 0 2px;
  }
}

.poninter {
  cursor: pointer;
}

.loadingBox {
  position: absolute;
  inset: 0;
  background: #eee;
  border-radius: 20px;
}

.skeleton {
  max-width: 20%;
}
