.unsupported-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.unsupported-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.65);
  top: 0;
  z-index: 5;
}

.unsupported-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 280px;
  height: 280px;
  margin-top: -140px;
  margin-left: -150px;
  padding: 30px;
  border-radius: 30px;
  z-index: 6;
  border: 1px solid var(--white-35, rgba(255, 255, 255, 0.35));
  text-align: center;
}

.lottie-bg {
  width: 220px;
  height: 220px;
  background: linear-gradient(
    90deg,
    rgba(142, 239, 234, 0.2) 0%,
    rgba(91, 200, 236, 0.2) 25%,
    rgba(160, 147, 244, 0.2) 50%,
    rgba(242, 134, 209, 0.2) 75%,
    rgba(250, 145, 145, 0.2) 100%
  );
}

.lottie-bg2 {
  width: 280px;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  border-bottom: coral;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 5px 40px 0px rgba(113, 144, 175, 0.1);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.lottie-animation {
  width: 280px;
  height: 280px;
  position: absolute;
  top: -60px;
  left: 0px;
}

.unsupported-action {
  padding: 0 30px;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.unsupported-message {
  color: #12192b;
  text-align: center;
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
}

.unsupported-button {
  margin: 0 auto;
  display: flex;
  color: #fff;
  font-size: 13px;
  width: 160px;
  height: 30px;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-radius: 25px;
  background: #12192b;
  font-family: "Ak-Regular";
}
