.uploadLoading {
  margin: 15px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.wrap {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--spacing-spacing-small, 10px);
  border: 1px solid var(--dark-5, rgba(2, 12, 38, 0.05));
  /* @include themeify {
    background: themed(uploadingWrapBg);
  } */
  background: var(--uploadingWrapBg, #fff);
}

.infoError {
  border: 1px solid;
  /* @include themeify {
    border-color: themed(uploadingError);
  } */
  border-color: var(--uploadingError, #ff335c);
}
.infoSuccess {
  border: 1px solid;
  /* @include themeify {
    border-color: themed(uploadingSuccess);
  } */
  border-color: var(--uploadingSuccess, #0a9dff);
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .infoLeft {
    display: flex;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}

.fileName {
  color: var(--dark, #12192b);
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  /* @include themeify {
    color: themed(uploadingFilename);
  } */
  color: var(--uploadingFilename, #12192b);
}

.fileSize {
  color: var(--dark-35, rgba(2, 12, 38, 0.35));
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
  /* @include themeify {
    color: themed(uploadingFilesize);
  } */
  color: var(--uploadingFilesize, rgba(2, 12, 38, 0.35));
}

.operation {
  display: flex;
  align-items: center;
  // gap: 10px;
  flex-shrink: 0;
}

.operation > span {
  width: 1px;
  height: 14px;
  background: var(--dark-10, rgba(2, 12, 38, 0.1));
}

.operation > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.progressBar {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: var(--blue-10, rgba(10, 157, 255, 0.1));
}

.progressBar > div {
  width: 100px;
  height: 4px;
  border-radius: 2px;
  background: var(--blue, #0a9dff);
}

.errorMsg {
  padding-top: 10px;
  /* Caption */
  font-size: 13px;

  font-weight: 400;
  line-height: 20px; /* 153.846% */
  /* @include themeify {
    color: themed(uploadingError);
  } */
  color: var(--uploadingError, #ff335c);
}
.indexerResult {
  font-size: 13px;

  font-weight: 400;
  line-height: 20px; /* 153.846% */
  position: relative;
  padding: 10px 0 20px;
}
.irTitle {
  color: #0a9dff;
  margin-bottom: 8px;
}
.LoadingSty {
  height: 50px;
  margin-top: -15px;
}

.uploadStatus {
  color: var(--Dark-65, #656a75);
  font-size: 15px;

  font-weight: 400;
  line-height: 20px; /* 133.333% */
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.uploadStatus > img {
  cursor: pointer;
}

.validating {
  background: #ddff0a;
  > div {
    background: #ddff0a;
  }
}

.space {
  border-bottom: 1px solid rgba(48, 48, 50, 0.1);
  padding-bottom: 10px;
}
