.food-content {
  min-width: 295px;
  min-height: 510px;
  background: #ffffff;
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 30px;
  height: 100%;
}
.food-box {
  position: relative;
  overflow: auto;
  height: calc(100% - 70px);
  margin: 10px;
}
.food-box::-webkit-scrollbar {
  display: none;
}
.food-box p {
  margin: 0;
}
p.food-title {
  font-family: var(--fontFamily, "ak-Regular");
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #12192b;
  margin: 15px;
}
.store-box {
  height: 100%;
  margin: 15px 0 0;
  :global(.ant-spin-nested-loading) {
    height: calc(100% - 80px);
  }
}
.store-detail {
  display: flex;
  align-items: center;
  margin: 0 15px;
}
.store-img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  object-fit: cover;
}
.store-info p:first-child {
  font-family: var(--fontFamily, "ak-Regular");
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #12192b;
}
.store-info p:last-child {
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
}
.food-list {
  display: flex;
  overflow: auto;
  width: 100%;
}
.food-list::-webkit-scrollbar {
  display: none;
}
.food-item {
  width: 100px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
  border-radius: 15px;
  text-align: center;
  padding-bottom: 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.food-item:not(:last-child) {
  margin-right: 10px;
}
.food-img {
  margin: 5px 5px 10px;
  width: calc(100% - 10px);
  height: calc(50% - 10px);
  object-fit: cover;
  border-radius: 10px;
}
p.food-price {
  text-align: left;
  margin: 0 10px 5px;
  font-family: var(--fontFamily, "ak-Regular");
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #12192b;
}
p.food-name {
  text-align: left;
  margin: 0 10px 0;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;
  line-height: 20px;
  color: #12192b;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.food-loading {
  position: relative;
  min-height: 410px;
}
.foodVirtualList {
  padding: 15px 15px 25px;
}
.restaurantVirtualList::-webkit-scrollbar,
.foodVirtualList::-webkit-scrollbar {
  display: none;
}
