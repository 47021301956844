.MapToolTip {
  position: absolute;
  width: calc(100% - 20px);
  height: auto;
  top: 10px;
  left: 10px;
  border-radius: 10px;
  padding: 10px 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.MapToolTip img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.MapToolTip div {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}
.MapToolTip p {
  margin: 0;
}
.MapToolTip span {
  color: #0a9dff;
  cursor: pointer;
}
