$silder-width: 30px;
$silder-panel-gap: 5px;
$silder-gap-width: 15px;
$panel-bg-color: rgba(255, 255, 255, 0.65);
$panel-round: 25px;
$panel-shadow-color: #888;
$panel-shadow-size: 5px;
$panel-shadow: 0 0 $panel-shadow-size $panel-shadow-color;
$padding-gap: 20px;
$panel-width: 460px;

$silder-diff-width: $silder-width - $padding-gap + 10px;
$panel-wrap-width: $panel-width + $silder-diff-width;
