.nodeCovering {
  min-width: 100%;
  min-height: 100%;
  background: #fbfbfb;
  border: 0.5px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 20px 80px rgb(93 122 152 / 20%);
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  user-select: none;
}

.TitleBar {
  font-weight: 400;
  font-size: 13px;
  color: rgba(2, 12, 38, 0.35);
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 10px 10px 15px;
  height: 40px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
}

.deleteContent {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.scrollContent {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
}

.scrollList {
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.video-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-width: 100%;
  cursor: pointer;
}

.active {
  background: #cccccc5e;
}

.video-image {
  flex: 1;
  max-width: 170px;
}

.video-image img {
  flex: 1;
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 15px;
  min-width: 150px;
  width: 50%;
  margin-right: 15px;
}

.video-item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.video-item-content div {
  font-size: 13px;
  font-weight: 400;
}

.video-item-content span {
  color: rgba(2, 12, 38, 0.65);
  font-weight: 400;
  font-size: 12px;
}

.loadMore {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.loadMore div {
  cursor: pointer;
  width: 95px;
  height: 30px;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: rgba(2, 12, 38, 0.65);
}

.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}
.delete-content img {
  width: 100%;
  height: 100%;
}

:global(.dark) .nodeCovering,
:global(.dark) .scrollContent {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
:global(.dark) .video-item-content span {
  color: #ffffff;
}
:global(.dark) .loadMore div {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}
