@import "../../../../styles/variables.scss";

@keyframes animation {
  from {
    top: var(--from);
  }
  to {
    top: var(--to);
  }
}

.sidebar {
  position: fixed;
  display: flex;
  width: 270px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-spacing-small, 10px);
  border-radius: 15px;

  &__active {
    display: flex;
    position: absolute;
    // top: 190px;
    // top: 210px;
    width: 270px;
    height: 60px;
    padding: var(--spacing-spacing-medium, 20px) var(--spacing-spacing-large, 30px) var(--spacing-spacing-medium, 20px)
      var(--spacing-spacing-medium, 20px);
    gap: var(--spacing-spacing-medium, 20px);
    cursor: pointer;
    border-radius: 20px;
    border: 0.5px solid var(--white-35, rgba(255, 255, 255, 0.35));
    background: var(--white-35, rgba(255, 255, 255, 0.35));
    z-index: 1;

    &_animation {
      animation-name: animation;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    position: relative;
    top: 0;
    z-index: 2;
  }
}

.sidebarItem {
  display: flex;
  width: 100%;
  padding: var(--spacing-spacing-medium, 20px) var(--spacing-spacing-large, 30px) var(--spacing-spacing-medium, 20px)
    var(--spacing-spacing-medium, 20px);
  align-items: flex-start;
  gap: var(--spacing-spacing-medium, 20px);
  align-self: stretch;

  border-radius: var(--radius-medium, 20px);
  cursor: pointer;

  &_new {
    border-radius: var(--radius-medium, 20px);
    background: var(--links-color-1, #0a9dff);
  }

  &_active {
    border-radius: 20px;
    border: 0.5px solid var(--white-35, rgba(255, 255, 255, 0.35));
    background: var(--white-35, rgba(255, 255, 255, 0.35));
  }

  &__icon {
    display: flex;
    border-radius: var(--radius-medium, 20px);
  }

  &__text {
    display: flex;
  }
}

.text {
  display: inline-flex;

  &_sidebarItem {
    color: var(--dark, #12192b);
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px;
  }

  &_sidebarItemNew {
    color: var(--white, #fff);
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 500;
    line-height: 20px;
  }
}

.icon {
  display: inline-flex;

  width: 20px;
  height: 20px;
}

@media only screen and (min-width: 718px) and (max-width: 979px) {
  .sidebar {
    background: #ffffff;
    width: Fixed (270px);
    padding: 10px;
    border-radius: 25px;
    border: 1px solid rgba(248, 248, 248, 1);
    gap: 10px;
    box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  }
  .sidebar .sidebar__active {
    width: -webkit-fill-available;
    left: 0;
    margin: 10px 10px;
    background: var(--blue-10, rgba(10, 157, 255, 0.1));
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  .sidebar {
    position: unset;
    background: #ffffff;
    width: -webkit-fill-available;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid rgba(248, 248, 248, 1);
    gap: 10px;
    box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  }
  .sidebar .sidebar__active {
    width: -webkit-fill-available;
    left: 0;
    margin: 10px 35px;
    background: var(--blue-10, rgba(10, 157, 255, 0.1));
  }
}
@media only screen and (max-width: 309px) {
  .sidebar {
    position: unset;
    background: #ffffff;
    width: -webkit-fill-available;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid rgba(248, 248, 248, 1);
    gap: 10px;
    box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  }
  .sidebar .sidebar__active {
    width: -webkit-fill-available;
    left: 0;
    margin: 10px 35px;
    background: var(--blue-10, rgba(10, 157, 255, 0.1));
  }
}
