.AppView {
  height: 100%;
  padding: 50px 0 0 20px;
  overflow: hidden;
  width: 50%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}
.app-bottom-box {
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}

.text {
  font-size: 14px;
  line-height: 16px;
}

.app-box {
  position: relative;
  width: 390px;
  height: calc(100% - 50px);
  border-radius: 10px;
  margin-top: 50px;
  overflow: hidden;
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  float: left;
  /* min-width: 380px; */
}

.Top-Menu {
  background: #fff;
  padding: 15px 20px;
}

.input-box {
  position: relative;
}
.app-refresh-box {
  width: 25px;
  height: 25px;
  position: absolute;
  background-color: #5cbf9b;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  font-weight: bold;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

.button-box {
  display: flex;
  flex-direction: row;
  padding-top: 25px;
}
.app-button {
  margin-right: 10px;
  background: rgba(104, 237, 255, 0.2);

  padding: inherit;
  border: none;
  border: 0.5px solid #68edff;
  box-shadow: inset 0px 0px 10px rgba(128, 210, 235, 0.3);
  border-radius: 20px;
  padding: 5px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.template-button {
  background: #22232f;
  color: #fff;

  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;

  height: 40px;
  line-height: 20px;
  padding: 10px 20px;
  align-items: flex-start;
  opacity: inherit;
  border-radius: 20px;
}

.content-box {
  padding: 30px;
  background-color: #f4f7fb;
  overflow: auto;
  height: 100%;
}

.answer-box {
  background-color: #d9d9d9;
  padding: 20px;
}

.invisible-translator-layer {
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px dashed #000;
}

.product-search-results {
  width: 100%;
  height: auto;
  grid-gap: 15px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.results-box {
  width: 155px;
  height: 155px;
  background-color: #d9d9d9;
}

.example-output {
  float: left;
  margin: 3.125rem 20px 0;
  width: 40%;
  max-with: 200px;
  height: calc(100% - 100px);
}

.text-code {
  position: relative;
  /* min-width: 200px; */
  min-height: 200px;
  max-height: 100%;
  overflow: auto;
  white-space: pre;
  padding: 10px;
  background-color: #d9d9d9;
  font-size: 14px;
  line-height: 18px;
}
