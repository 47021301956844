.custom-new-edge {
  position: relative;
}
.edge-hidden {
  display: none;
}
.custom-edge-box {
  display: flex;
  justify-content: center;
  pointer-events: all;
  text-align: center;
  user-select: none;
}
.ai-text-span {
  position: absolute;
  opacity: 0;
  white-space: nowrap;
  padding: 9px 11px;
  font-size: 14px;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid;
}
.ai-textArea-wrap {
  position: relative;
}
textarea.ai-textArea {
  resize: none;
  max-width: 200px;
  min-width: 140px;
  width: 100%;
  /* 覆盖 style 不能滚动的样式 */
  overflow-y: auto !important;
  background: rgb(255, 255, 255);
  border: 0 solid rgba(255, 255, 255, 0.65);
  border-radius: 20px;
  box-shadow: 0 5px 40px rgba(113, 144, 175, 0.1);
  -webkit-overflow-scrolling: touch;
}
textarea.ai-textArea:global(.ant-input:focus),
textarea.ai-textArea:global(.ant-input:hover) {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
textarea.ai-textArea:global(.ant-input) {
  padding: 9px 11px;
}
.ai-textArea::-webkit-scrollbar {
  display: none;
}
.line-button-box {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-background {
  display: flex;
  width: 180px;
  height: 0;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.17);
  backdrop-filter: blur(80px);
  top: -20px;
}
.flow-line-button {
  width: 77px;
  height: 30px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  font-family: ak-regular;
  font-weight: 500;
  line-height: 20px;
  background-color: #0a9dff;

  &-draggingNewLine_hover {
    transform: scale(1.1, 1.1);
    transition: 0.2s ease-out;
  }
}
.flow-line-button:global(.ant-btn-round) {
  border-radius: 32px;
}
.flow-line-p {
  color: rgba(18, 25, 43, 0.65);
  font-family: ak-regular;
  font-size: 13px;
  line-height: 20px;
  width: 150px;
  position: absolute;
  top: 30px;
  padding: 10px 5px 5px;
  max-width: 200px;
  word-break: break-word;
  user-select: none;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-weight: 400;
}
.all-line-text {
  overflow: visible;
  -webkit-line-clamp: unset;
}
.not-optional {
  user-select: none;
}
.edge-handle {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 0;
  border: 0;
  opacity: 0;
}
.edge-handle:global(.react-flow__handle-left) {
  left: 0;
}
.edge-handle:global(.react-flow__handle-bottom) {
  bottom: 0;
}
.edge-handle:global(.react-flow__handle-top) {
  top: 0;
}
.edge-handle:global(.react-flow__handle-right) {
  right: 0;
}

.edge-handle-box {
  position: absolute;
  z-index: 5;
}

.edge-handle-box-top {
  width: 6px;
  height: 6px;
  transform: translateX(-50%);
  left: 50%;
  top: -10px;
}
.edge-handle-box-right {
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  top: 50%;
  right: -10px;
}
.edge-handle-box-bottom {
  width: 6px;
  height: 6px;
  transform: translateX(-50%);
  left: 50%;
  bottom: -10px;
}
.edge-handle-box-left {
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  top: 50%;
  left: -15px;
}
.custom-edge-handle-drop-left-draggingNewLine_hover {
  width: 10px;
  height: 10px;
  transition: 0.2s ease-out;
}

.guide-disable-edge .line-button-box {
  opacity: 0.5;
  cursor: not-allowed;
}
.guide-disable-edge .line-button-box button {
  cursor: not-allowed;
}

.custom-edge-handle-drop-left {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1890ff;
  opacity: 1;
  border-radius: 50%;
}

.custom-edge-handle-drop {
  display: flex;
  width: 100px;
  height: 30px;
}
