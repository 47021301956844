.shrinkExpandButton {
  text-align: right;
  display: flex;
  margin: 20px 0 0 calc(100% - 85px);
  cursor: pointer;
  color: var(--textColor, rgba(18, 25, 43, 0.65));
}
.shrinkExpandText {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}
.icon {
  width: 20px;
  height: 20px;
  background: var(--textColor, rgba(18, 25, 43, 0.65));
}
.icon.c1Paragraph.shrinked {
  background: #fff;
}
.icon.shrinked {
  -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M14 9L10 13L6 9" stroke="%23fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
  mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M14 9L10 13L6 9" stroke="%23fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
}
.icon.expanded {
  mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6 11L10 7L14 11" stroke="%2312192B" stroke-opacity="0.65" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6 11L10 7L14 11" stroke="%2312192B" stroke-opacity="0.65" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.shrinkExpandButton.c1Paragraph {
  margin-top: 5px;
}
.shrinkExpandText.c1Paragraph.shrinked {
  color: #fff;
}
