/* 验证码显示框 */
.numberBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 每一个验证码输入框的样式 */
.inputItem {
  border-style: none;
  background-color: rgba(2, 12, 38, 0.05);
  outline: none;
  width: 40.5px;
  height: 50px;
  padding: 15px 5px 15px 5px;
  border-radius: 10px;
  font-family: "Ak-Regular";
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(18, 25, 43, 1);
}
.showInputCursor {
  position: relative;
}
.showInputCursor::before {
  content: "";
  height: 40%;
  width: 1px;
  background-color: black;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: cursor 1s infinite;
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 最后一个验证码输入框margin-right为0 */
.inputItem:last-child {
  margin-right: 0px;
}

/* 将input输入框隐藏看不见 */
.inputValue {
  margin-left: -750px;
  position: absolute;
  z-index: -99;
  opacity: 0;
}
