.chat-input-box {
  border-radius: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: row;
  border: 1px solid;
  height: 100%;
  width: 100%;
  /*  @include themeify {
    border-color: themed(chatInputBorder);
  } */
  border-color: var(--chatInputBorder, #020c260d);
}

.chat-input-box .chat-input::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.chat-input-box .chat-input::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}

.chat-input-box .chat-input::-webkit-scrollbar-track {
  -webkit-box-shadow: none; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.chat-input {
  margin: 9px 0 9px 10px;
  padding: 0;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;
  border: none;
  resize: none;
  outline: none;
  line-height: 20px;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    border: none;
  }
  /* @include themeify {
    color: themed(chatInputTextColor);
  } */
  color: var(--chatInputTextColor, #12192b);
  width: calc(100% - 40px);
  background: transparent;
  &::placeholder {
    /* @include themeify {
      color: themed(chatInputTextPlaceholder);
    } */
    color: var(--chatInputTextPlaceholder, rgba(2, 12, 38, 0.35));
  }
}

.chat-input-box:focus {
  border: none;
  resize: none;
  outline: none;
  box-shadow: none !important;
}

.chat-send-box {
  // position: relative;
  width: 40px;
}

.chat-send {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  // position: absolute;
  // bottom: 0;
  // right: 0;
}
.chat-input-box :global(textarea.ant-input) {
  border: 0 none;
}
.chat-input-box :global(textarea.ant-input):hover {
  border: 0 none;
}
