.container {
  padding: 24px;
  border-radius: 44px;
  background: rgba(255, 255, 255, 0.65);
  // height: 482px;
  position: relative;
  .scrollView {
    max-height: 730px;
    // height: calc(100% - 115px);
    width: 100%;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  .title {
    color: #12192b;
    /* Nat 2.0/H3 Rows */

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
  }
  .shoppingItem {
    min-height: 94px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    .right {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: calc(100% - 96px - 24px);
      .itemTitle {
        overflow: hidden;
        color: #12192b;
        text-overflow: ellipsis;
        /* Nat 2.0/Subhead */

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
      }
      .price {
        overflow: hidden;
        color: #12192b;
        text-overflow: ellipsis;
        /* Nat 2.0/Subhead M */

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
    }
  }
}
.container:not(:first-child) {
  margin-top: 10px;
}
.scrollView::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
