.touristAttraction {
  display: flex;
  padding: var(--Natural-2-Padding-V, 24px) var(--Natural-2-Padding-H, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Natural-2-Gap-XLarge, 24px);
  align-self: stretch;
  min-width: 367px;
  border-radius: var(--Natural-2-Radius-Main, 44px);
  background: var(--Surfaces-Default, #fff);
}

.desc {
  color: var(--Text-Default, #12192b);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.title {
  margin-bottom: 14px;
  color: var(--Text-Default, #12192b);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
}

.assetsSource {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--Natural-2-Gap-Medium, 8px);
  align-self: stretch;

  > div {
    overflow: hidden;
    color: var(--Text-Description, rgba(18, 25, 43, 0.65));
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
