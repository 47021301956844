.container {
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  // margin-top: 10px;
  // margin-bottom: 20px;
}

.handleButton {
  display: inline-flex;
  height: 30px;
  padding: 5px 10px 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid;
  cursor: pointer;
  /* @include themeify {
    border-color: themed(handleBtnBorder);
  } */
  border-color: var(--handleBtnBorder, rgba(2, 12, 38, 0.1));
}

.handleButton:not(:first-child) {
  margin-left: 14px;
}

.handleText {
  /* @include themeify {
    color: themed(handleBtnTextColor);
  } */
  color: var(--handleBtnTextColor, rgba(18, 25, 43, 0.65));
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;

  font-weight: 400;
  line-height: 20px; /* 133.333% */
}
