.shareToAppModal {
  position: fixed;
  left: 0;
  margin: 0 5vw;
  bottom: 5vh;
  width: 90%;
  height: 15vh;
  z-index: 100000;
  display: flex;
  /* padding: 0 10%; */
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-medium, 20px);
  background: var(--grayscale-gray-05, #f7f7f8);
  color: var(--grayscale-gray-70, #757481);
  font-family: Ak-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
}

.shareToAppCloseIcon {
  position: absolute;
  top: 5%;
  right: 5%;
}

.shareIcon {
  padding-right: 5%;
}
