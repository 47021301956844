.text {
  display: inline-flex;

  &_nav {
    color: var(--dark, #12192b);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Ak-Regular;
    font-size: 13px;

    font-weight: 400;
    line-height: 20px;
  }

  &_buttonGreen {
    color: var(--green, #05be75);
    text-align: center;
    font-family: Ak-Regular;
    font-size: 13px;

    font-weight: 400;
    line-height: 20px;
  }

  &_sidebar {
    color: var(--dark, #12192b);
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px;
  }

  &_sidebarNew {
    color: var(--white, #fff);
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 500;
    line-height: 20px;
  }

  &_buttonBlack {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--white, #fff);
    text-overflow: ellipsis;
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }

  &_buttonTransparent {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--dark-65, rgba(18, 25, 43, 0.65));
    text-overflow: ellipsis;
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;

    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }
}
