.embedBox {
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  // box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  margin: 20px 0;
}

.title {
  color: #acaeb5;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.embedSizeBox {
  margin: 20px 0;
}

.blackTitle {
  color: #12192b;
  font-family: "Ak-Regular";
  font-size: 15px;

  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}
.centerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputBox {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #f3f3f4;
  flex: 1;
  overflow: hidden;
}
.inputBox .customNumberInput {
  color: #12192b;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 600;
  line-height: 20px;
  height: 50px;
  line-height: 50px;
  padding: 0 4px 0 9px;
  background: #f3f3f4;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}
.inputBox .addon {
  padding: 15px 20px 15px 0px;
  color: #acaeb5;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 500;
  line-height: 20px;
}
.connectorBox {
  color: #acaeb5;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 700;
  line-height: 20px;
  margin: 0 10px;
}

.promptText {
  padding: 15px 20px;
  border-radius: 10px;
  background: rgba(10, 157, 255, 0.1);
  color: rgba(18, 25, 43, 0.65);
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 600;
  line-height: 20px;
}

.tooltipText {
  color: #656a75;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}

.generateBox {
  display: flex;
  padding: 15px 15px 15px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #f3f3f4;
}
.generateLink {
  overflow: hidden;
  color: #acaeb5;
  text-overflow: ellipsis;
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.generateBox img {
  cursor: pointer;
}
