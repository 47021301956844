.sourceBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.title {
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #12192b;
  /* @include themeify {
    color: themed(dragDropTitle);
  } */
  color: var(--dragDropTitle, #12192b);
}

.header {
  padding: 10px;
}

.header .desc {
  // margin-top: 5px;
  font-family: var(--fontFamily, "Ak-Light");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* @include themeify {
    color: themed(dragDropDes);
  } */
  color: var(--dragDropDes, rgba(18, 25, 43, 0.65));
}

.uploadWrapper {
  margin-top: 10px;
}

.SupportFormat {
  display: inline-block;
  font-family: var(--fontFamily, "Ak-Light");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #0a9dff;
}

.dragger:global(.ant-upload.ant-upload-drag) {
  background: rgba(10, 157, 255, 0.1);
  border: 1px dashed rgba(10, 157, 255, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.dragger :global(.ant-upload) {
  padding: unset !important;
}

.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
}

.upload .icon {
  padding-bottom: 25px;
}

.upload .desc {
  font-family: var(--fontFamily, "Ak-Light");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #12192b;
  /* @include themeify {
    color: themed(uploadDes);
  } */
  color: var(--uploadDes, rgba(2, 12, 38, 0.45));
}

.upload .limitSize {
  font-family: var(--fontFamily, "Ak-Light");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  /* @include themeify {
    color: themed(uploadLimitColor);
  } */
  color: var(--uploadLimitColor, rgba(2, 12, 38, 0.45));
}

.linkWrapper .desc {
  font-family: var(--fontFamily, "Ak-Light");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: rgba(18, 25, 43, 0.65);
}

.linkWrapper > ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.linkWrapper > input {
  padding: 15px 20px;
  width: 230px;
  height: 50px;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 10px;
}

.surportWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.surportItem {
  display: inline-block;
  font-family: var(--fontFamily, "Ak-Regular");
  padding: 2px 8px;
  border-radius: 16px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 13px;
  margin: 0 4px 4px;
}
