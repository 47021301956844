.CardHomeDocument {
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(144, 147, 169, 0.08);
  border-radius: 15px;
  margin: 4px 10px 20px 0;
}

.CardHomeDocument .documentTitle {
  line-height: 25px;
  padding-right: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
  overflow: hidden;
  margin-bottom: 10px;
}

.CardHomeDocument .documentBody {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #3f3f50;
  max-height: 200px;
  overflow: auto;
}

.CardHomeDocument .documentBody::-webkit-scrollbar {
  display: none;
}

.CardHomeTask {
  background-color: white;
  padding: 10px 0 10px 15px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  margin: 20px 25px;
  border-radius: 10px;
  overflow: hidden;
}

.CardHomeTask p {
  font-size: 14px;
  line-height: 20px;
}

.CardHomeTask img {
  height: 2em;
  display: block;
  margin: auto;
}

.CardHomeTextbox {
  background: var(--textboxBg, #fff);
  color: var(--textboxColor, #000);
  box-shadow: var(--nodeWrapperShadow);
  backdrop-filter: var(--nodeBackdropFilter, none);
  font-size: 1em;
  line-height: 1.6em;
  padding: 0.2em 0.5em;
  margin: 15px 25px;
  border-radius: 10px;
}
.CardHomeTextbox pre {
  white-space: pre-wrap;
  user-select: text;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}
.CardHomeTextbox pre::-webkit-scrollbar {
  display: none;
}
.ai-saas pre,
.thinking-app pre {
  margin-bottom: 0;
}

.Editor-UI .Large {
  font-size: 1.2em;
}
.Editor-UI .Small {
  font-size: 0.8em;
}

.Editor-UI .CardHomeTextbox {
  min-height: 60px;
  /* padding: 15px 20px; */
  padding: 20px;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
}

.ai-saas .CardHomeTextbox,
.thinking-app .CardHomeTextbox {
  border-radius: 20px;
  margin: 20px 0;
  /* jerry: 手机版本的左右Margin有点过大 */
  /* padding: 10px; */
}

.ai-saas .CardHomeTextbox.share,
.thinking-app .CardHomeTextbox.share {
  /* margin: 20px 25px; */
  margin: 20px 0;
  width: 100% !important;
}

.Editor-UI .noStyle {
  background: none;
  padding: 0;
}

.CardHomeStock {
  padding: 10px;
  font-size: 1em;
  line-height: 1.6em;
  background: var(--stockBg, #fff);
  color: var(--stockColor, #000);
  box-shadow: var(--nodeWrapperShadow, none);
  backdrop-filter: var(--nodeBackdropFilter, none);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  position: relative;
  min-height: 10em;
  /* border-radius: 30px; */
  border-radius: 25px;
  min-width: 215px;
  width: 100%;
  overflow: hidden;
}

.CardHomeStockBox {
  /* background-color: #fff; */
  border-radius: 30px;
  padding-bottom: 60px;
  /* padding: 10px; */
  font-family: var(--fontFamily);
}

.CardHomeStockPanel {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0px 15px;
  background: rgba(10, 157, 255, 0.1);
  border-radius: 20px;
}

.CardHomeStockPanelTitle {
  color: rgba(18, 25, 43, 0.65);
}

.CardHomeStockPanelAction {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.CardHomeStockLogo {
  margin: 0 15px;
}

.CardHomeStockImg {
  max-width: 100%;
  max-height: 40px;
  height: auto;
  width: auto;
  border: 1px solid #f3f3f4;
  border-radius: 36px;
}

.CardHomeFlight {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 15px;
  margin: 20px 25px;
}

.CardHomeVideo {
  margin: 20px 25px;
  width: auto;
}

.CardHomeThought {
  background-color: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #22232f;
  padding: 10px;
  width: auto;
  margin: 20px 25px;
  border-radius: 10px;
}

.CardHomeThought p {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}

.Skeleton-Custom {
  margin: 20px 25px;
  padding-bottom: 20px;
  box-sizing: content-box;
  width: -webkit-fill-available;
  border-bottom: 0.5px solid rgba(34, 35, 47, 0.1);
}
.text-reset-padding {
  padding: 15px 15px 35px 15px;
}
