.myAccountVerifyModal :global(.ant-modal) {
  width: 400px !important;
}
.myAccountVerifyModal :global(.ant-modal-content) {
  background: #f7f7f8;
  border-radius: 20px;
  box-shadow: unset;
}
.myAccountVerifyModal :global(.ant-modal-body) {
  padding: 0;
}
.myAccountVerifyModal :global(.ant-modal-footer) {
  display: none;
}
.myAccountVerifyBox {
  height: 306px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.verifyTitle {
  font-family: "ak-regular";
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.verifyDescribe {
  font-family: "ak-regular";
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12192ba6;
}
.verifyDescribeNum {
  font-family: "ak-regular";
  font-weight: 500;
}
.verifyCodeTitle {
  font-family: "ak-regular";
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 25, 43, 0.65);
}
// .verifyCodeInputBox{
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// .verifyCodeInput{
//   border-style: none;
//   background-color: rgba(2, 12, 38, 0.05);
//   outline: none;
//   width: 40.5px;
//   height: 50px;
//   padding: 15px 5px 15px 5px;
//   border-radius: 10px;
//   font-family: 'ak-medium';
//   font-size: 17px;
//   font-weight: 500;
//   line-height: 26px;
//   letter-spacing: 0em;
//   text-align: center;
//   color: rgba(18, 25, 43, 1);
// }
// .verifyCodeFailInput{
//   border: 1px solid #FF335C;
//   background: #ffffff;
// }
.verifyCodeFailWarn {
  color: rgba(255, 51, 92, 1);
}
.verifyCountDownBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hideConfirmDelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.verifyCountDownLft {
  font-family: "ak-regular";
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(18, 25, 43, 0.35);
  border-style: none;
  background: transparent;
}
.sendAgain {
  @extend .verifyCountDownLft;
  color: #0a9dff;
}
.verifyCountDownRgt {
  width: 158px;
  height: 40px;
  padding: 5px 20px 5px 20px;
  border-radius: 25px;
  gap: 15px;
  border: 0 none;
  color: #fff;
  background: rgba(255, 51, 92, 1);
}
.sendAgainFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  .deleteBtn {
    width: 158px;
    height: 40px;
    padding: 5px 20px 5px 20px;
    border-radius: 25px;
    gap: 15px;
    border: 0 none;
    font-family: "ak-regular";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
  .disableDelete {
    @extend .deleteBtn;
    color: rgba(172, 174, 181, 1);
    background: rgba(2, 12, 38, 0.05);
  }
  .enableDelete {
    @extend .deleteBtn;
    color: rgba(255, 51, 92, 1);
    background: rgba(255, 51, 92, 0.1);
  }
}
