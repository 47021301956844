.L1box {
  position: relative;
  width: 100%;
  // min-height: 160px;
  display: flex;
  padding: 5px;
  // margin-top: 10px;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  > div {
    min-height: 30px;
  }
}
.L1box:not(:first-child) {
  margin-top: 10px;
}
.textBox {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 80%;
}

.L1 {
  width: 100%;
  > div {
    display: flex;
    padding: var(--Bento-Cards-Cards---E-padding-inner, 10px) 0px;
    align-items: center;
    gap: var(--Bento-Cards-Cards---E-gap, 10px);
    align-self: stretch;
    border-bottom: 0.5px solid var(--ViewBuilderL1DividerColor, rgba(18, 25, 43, 0.1));
    justify-content: space-between;
  }
  > div:last-child {
    border-bottom: unset;
  }
}
