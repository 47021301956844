.variable-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.3s;
  height: 50px;
  line-height: 40px;
}
.variable-item:hover {
  background: #1890ff;
  color: #fff;
}
.variable-item:hover :global(.ant-typography-copy) {
  color: #fff;
}

.delete-icon {
  margin-left: 10px;
}
