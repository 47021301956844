.wrap {
  --action-bg-color: rgba(2, 12, 38, 0.05);
  --action-color: rgba(18, 25, 43, 0.65);

  background-color: var(--action-bg-color);
  color: var(--action-color);

  // FIXME: 为了覆盖 tool-bar-item 样式
  // width: 40px;
  // height: 40px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // border-radius: 50%;
  // padding: 10px;
  // position: relative;

  &:hover {
    --action-color: #fff;
    --action-bg-color: #0a9dff;
    background-color: var(--action-bg-color);
    color: var(--action-color);
  }
}

.popover {
  :global(div.ant-popover-inner-content) {
    padding: 20px 20px 10px 20px;
    max-width: 240px;
    font-family: Ak-Regular;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.wrap.added:hover {
  --action-color: #fff;
  --action-bg-color: #ff335c;
  background-color: var(--action-bg-color);
  color: var(--action-color);
}

.wrap.disabled,
.wrap.disabled:hover {
  --action-color: rgba(172, 174, 181, 1);
  --action-bg-color: rgba(243, 243, 244, 1);

  background-color: var(--action-bg-color);
  color: var(--action-color);
}

// tip
// .tool-tip {
//   position: absolute;
//   top: -30px;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   height: 20px;
//   width: max-content;
//   background-color: rgba(255, 255, 255, 1);
//   padding: 0px 10px 0px 10px;
//   border-radius: 10px;
//   text-align: center;
//   line-height: 20px;
//   font-size: 13px;
//   font-family: 'Ak-Regular';
//   font-weight: bold;
//   color: rgba(18, 25, 43, 1);
//   opacity: 0;
//   transition: opacity 0.5s;
// }
// .wrap:hover .tool-tip{
//   opacity: 1;
// }
