.saasHeadManage {
  width: 100%;
  height: auto;
  padding: 0 20px 0 15px;
}

.saas-top {
  text-align: center;
  max-width: 580px;
  margin-top: 90px;
  position: relative;
}

.title-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;
  box-sizing: content-box;
  min-height: 50px;
  &:hover {
    .edit-box {
      border-top: 1px dashed #0a9dff;
      border-bottom: 1px dashed #0a9dff;
    }
    .edit-box-mobile {
      border-top: 2px solid #0a9dff;
      border-bottom: 2px solid #0a9dff;
    }
  }
}

.saas-title {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  word-break: break-word;
  text-align: center;
  color: var(--saasTitleColor, "#12192B");
}

.display-box {
  opacity: 1;
}
.hide-box {
  opacity: 0;
}

.display-zIndex {
  z-index: 5;
}

.saas-top :global(textarea.title-textarea) {
  resize: none;
  width: 100%;
  height: 100%;
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  padding: 4px 5px !important;
  position: absolute;
  top: 10px;
  left: 2px;
}

.saas-subTitle {
  width: 100%;
  white-space: normal;
  word-break: break-all;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: var(--saasTitleColor, "#12192B");
}

.saas-top :global(textarea.subtitle-textarea) {
  resize: none;
  width: 100%;
  height: 100% !important;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 16px;
  padding: 0 5px !important;
  position: absolute;
  top: 10px;
  left: 1px;
  color: var(--saasTitleColor, "#12192B");
  margin: 0;
  text-align: center;
  word-break: break-all;
}

.edit-box {
  position: absolute;
  border-top: 1px dashed transparent;
  border-bottom: 1px dashed transparent;
  top: 0px;
  left: -13px;
  right: -18px;
  bottom: 0px;
  transition: 0.4s;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-box-mobile {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.display-box {
  opacity: 1;
}
.hide-box {
  opacity: 0;
}

.hoverPlaceholder {
  opacity: 0.3;
}

.border-highlight {
  transform: scaleY(1.2);
  border-top: 1px dashed #0a9dff;
  border-bottom: 1px dashed #0a9dff;
}

.border-highlight-mobile {
  transform: scaleY(1.2);
  border-top: 1px solid #0a9dff;
  border-bottom: 1px solid #0a9dff;
}

.title-highlight {
  transform: scale(1.025);
}
