.modal {
  & :global(.ant-modal-content) {
    background-color: transparent;
    box-shadow: none;
  }

  & :global(.ant-modal-body) {
    padding: 0;
  }
}

.wrap {
}
