.custom-group {
  /* background: #DDECF6; */
  background: #e4f3fe;
  /* height: calc(100% + 110px); */
  height: 100%;
  /* border-radius: 15px; */
  border-radius: 40px;
  transition: box-shadow 0.3s, background-color 1s;
  padding-top: 10px;
}

.custom-group:hover {
  box-shadow: 0 5px 40px 0 rgba(113, 144, 175, 0.503);
}

.blueprint {
  background: #b0cfe761;
}

.selected-highlight-border {
  border: none;
  outline: 3px solid rgb(98 153 249);
}

.custom-handle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  border: 0;
  opacity: 0;
}
.custom-handle-top {
  transform: none;
}
.custom-handle-right {
  height: 67px;
  top: calc(50% - 33.5px);
  transform: none;
  z-index: 2;
  /* to override react-flow default behavior */
  cursor: pointer !important;
}
.custom-handle-bottom {
  transform: none;
}
.custom-handle-left {
  transform: none;
}

.custom-handle-box {
  position: absolute;
  z-index: 5;
}
.custom-handle-box-top {
  width: 100%;
  height: 30px;
  top: -15px;
  border-radius: 6px;
  /* background: rgba(2, 12, 38, 0.1); */
  cursor: grab;
}
.custom-handle-box-hover-top {
  width: 100%;
  height: 70px;
  top: -70px;
  border-radius: 6px;
  cursor: grab;
  z-index: 1;
}
.custom-handle-box-top .top-bar-visible {
  background: rgba(2, 12, 38, 0.1);
  height: 15px;
  top: -70px;
  border-radius: 6px;
}
.custom-handle-box-right {
  width: 67px;
  height: 100%;
  right: -52px;
  top: 0px;
  cursor: grab;
}
.custom-handle-box-bottom {
  width: 100%;
  height: 30px;
  bottom: -15px;
  cursor: grab;
}
.custom-handle-box-left {
  width: 67px;
  height: 100%;
  left: -52px;
  cursor: grab;
}

.customGroupTitle {
  position: relative;
  top: -60px;
  padding: 0 50px;
  line-height: 48px;
  color: #020c26;
  font-size: 20px;
}

.groupTitle {
  //styleName: App/SubTitle;
  font-family: Ak-Regular;
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(18, 25, 43, 1);
}

.default-bot-box {
  position: absolute;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.custom-handle-box-top .default-bot-box {
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.custom-handle-box-right .default-bot-box {
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.custom-handle-box-bottom .default-bot-box {
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.custom-handle-box-left .default-bot-box {
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.add-edge-back-view {
  width: 29.8px;
  height: 112px;
  background-color: transparent;
  position: absolute;
  top: calc(50% - 56px);
  right: 52px;
  transform: translateX(0px);
  transition: all 0.5s;
}

.edge-add-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 2px 15px rgb(93 122 152 / 20%));
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  background-color: #ffffff;
  color: #12192b;
  cursor: pointer;
  transform: scale(0);
  transition: all 0.25s;
  z-index: 1;
}
.custom-handle-box-top .edge-add-icon {
  bottom: 30px;
}
.custom-handle-box-right .edge-add-icon {
  left: 7px;
}
.custom-handle-box-bottom .edge-add-icon {
  top: 30px;
}
.custom-handle-box-left .edge-add-icon {
  right: 30px;
}

.edge-add-icon path {
  transition: all 0.25s;
}

.edge-add-icon:hover {
  background-color: #1f9ffc;
  color: #fff;
}

.edge-add-icon:hover path {
  stroke: white;
}

.custom-handle-box-right:hover .edge-add-icon,
.custom-handle-box-hover-top:hover + .custom-handle-box-right .edge-add-icon {
  background-color: #1f9ffc;
  color: #fff;
}

.custom-handle-box:hover .edge-add-icon path,
.custom-handle-box-hover-top:hover + .custom-handle-box-right .edge-add-icon path {
  stroke: white;
}

.custom-group:hover .add-edge-back-view,
.custom-handle-box:hover .add-edge-back-view {
  transform: translateX(29.8px);
}
.custom-group:hover .edge-add-icon,
.custom-handle-box:hover .edge-add-icon {
  transform: scale(1);
}
.custom-handle-box-top .edge-add-icon {
  bottom: 30px;
}
.custom-handle-box-right .edge-add-icon {
  left: 7px;
}
.custom-handle-box-bottom .edge-add-icon {
  top: 30px;
}
.custom-handle-box-left .edge-add-icon {
  right: 30px;
}

.preview-box {
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: transparent;
  display: none;
  opacity: 0;
  z-index: -1;
  transition: all 0.8s;
}
.custom-handle-box-right:hover .preview-box {
  opacity: 0.5;
  display: flex;
}
.custom-handle-box-top .preview-box {
  top: -77px;
}
.custom-handle-box-right .preview-box {
  left: 65px;
}
.custom-handle-box-bottom .preview-box {
  bottom: -77px;
}
.custom-handle-box-left .preview-box {
  right: 65px;
}

.line-input {
  width: 150px;
  position: relative;
  padding: 4px 11px;
  color: #86909c;
  font-size: 14px;
  line-height: 1.5715;
}

.custom-handle-box-left {
  transform: rotateZ(-180deg);
}

:global(.inputTextArea) {
  background: #f69795;
}

.group-node-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.delete-box {
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.delete-box img {
  width: 100%;
  height: 100%;
}
.group-node-box:hover .delete-box {
  display: flex;
}

.guide-group-diable .custom-handle-box-right .add-edge-back-view,
.guide-group-diable .custom-handle-box-left .add-edge-back-view {
  transform: translateX(0px);
}

.guide-group-diable .custom-handle-box-right .edge-add-icon,
.guide-group-diable .custom-handle-box-left .edge-add-icon {
  display: none;
}
.guide-group-diable .custom-handle-box-right .guide-show-addicon {
  display: flex;
  transform: scale(1);
  background-color: #6299f9;
  color: #fff;
}

.custom-group.highlight {
  outline: 3px solid rgb(98 153 249);
  box-shadow: -100px -100px 0 -95px rgb(98 153 249), 100px -100px 0 -95px rgb(98 153 249),
    100px 100px 0 -95px rgb(98 153 249), -100px 100px 0 -95px rgb(98 153 249);
}
.custom-group.lowBright {
  opacity: 0.5;
}

.feedback-container {
  height: 50px;
  display: flex;
  padding: 0 0 0 20px;
  cursor: pointer;
  border-radius: 0 0 30px 30px;
  border-top: 0;
  margin-top: -1px;
  z-index: 2;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.like {
  padding: 6px;
  margin: 3px;
}
.dislike {
  padding: 6px;
  margin: 3px;
}
.like-active {
  filter: invert(11%) sepia(95%) saturate(5491%) hue-rotate(245deg) brightness(91%) contrast(150%);
}
.dislike-active {
  filter: invert(16%) sepia(47%) saturate(5732%) hue-rotate(338deg) brightness(97%) contrast(92%);
}
.like-disable {
  filter: invert(75%) sepia(97%) saturate(0%) hue-rotate(317deg) brightness(102%) contrast(78%);
}
.customGroup {
  position: absolute;
  width: 100%;
  height: 60px;
  top: -60px;
}
.noOthers {
  height: 100%;
}
.noFeedbackCollapseGroup {
  height: calc(100% + 85px);
}
.noCollapseGroup {
  height: calc(100% + 60px);
}
.collapseOrExpand {
  bottom: 50px;
  width: 100%;
  padding: 10px 20px;
  position: absolute;
}
.adjustGroupCollapse {
  bottom: 20px;
}
.collapseOrExpandBtn {
  width: 100%;
  border-radius: 20px;
  background: #fcfcfc;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  cursor: pointer;
}
