.FLight-Top {
  padding: 25px 20px 60px;
  border-radius: 15px 15px 0 0;
}
.FLight-Top.Blue-Background {
  background: linear-gradient(63.8deg, #7889f7 8.4%, #75a2f8 83.41%);
}
.FLight-Top.Orange-Background {
  background: linear-gradient(63.8deg, #d678f7 8.4%, #f97d9b 47.08%, #fc9f82 83.41%);
}
.FLight-AirCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}
.FLight-AirCode img {
  width: 20px;
  height: 20px;
}
.AirCode-From,
.AirCode-To {
  font-family: "Ak-Regular";
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.FLight-Cities {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AirCode-FromCity,
.AirCode-ToCity {
  font-family: "Ak-Regular";
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.5;
}
.FLight-Times {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FLight-Times p {
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}
.FLight-Times p:last-child {
  text-align: right;
}
.FLight-Duration-Cabin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.FLight-Duration-Cabin p {
  padding: 0px 7px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  font-family: "Ak-Regular";
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.FLight-Bottom {
  padding: 0 20px 20px;
}
.FLight-Icon-Price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  position: relative;
  margin-top: -35px;
}
.FLight-Icon-Price .icon-right {
  cursor: pointer;
  color: #22232f;
}
.Price-Left {
  display: flex;
  align-items: center;
}
.Price-Left img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.Price-Left p {
  font-family: "Ak-Regular";
  line-height: 20px;
  color: #22232f;
}
.Price-Left p:first-child {
  font-weight: 700;
  font-size: 15px;
}
.Price-Left p:last-child {
  font-size: 13px;
}
.Flight-Description {
  margin-top: 25px;
}
.Flight-Description p {
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 20px;
  background: radial-gradient(
        49.73% 79.17% at 72.17% 12.5%,
        rgba(98, 206, 245, 0.75) 0%,
        rgba(102, 136, 255, 0.75) 50%,
        rgba(130, 53, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #7d8fa1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
