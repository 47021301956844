.textH {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: inherit;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: 900;
  line-height: 25px; /* 147.059% */
  margin-bottom: 5px;
  // letter-spacing: 1px;
  word-break: break-word;
}
.c1Head.shrinked {
  color: #fff;
}
.c3Head {
  -webkit-line-clamp: 2;
}
