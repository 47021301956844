.settingPage {
  width: 320px;
  height: calc(100% - 30px);
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s linear;
  margin: 10px 20px 20px 0;
  transform: scaleX(0);
}

.settingPageMobile {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  margin: 0;
  transition: none;
  border-radius: 25px;
  transform-origin: right;
  transform: translateX(105%);
  background: rgb(226, 228, 234);
}

.settingPage section {
  height: calc(100% - 50px);
  overflow-y: auto;
  margin-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.settingPageMobile section {
  height: 100%;
  margin-top: 0;
}

.settingPage section::-webkit-scrollbar {
  display: none;
}
.showSettingPage {
  transform: scaleX(1);
  flex-shrink: 0;
}

.showSettingPageMobile {
  transform: translateX(0);
}

.featuresMobile {
  padding: 0 15px;
}
