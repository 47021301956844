.mapBoxInner {
  width: 100%;
  height: 100%;
}
.mapboxCanvas {
  padding: 20px;
  width: calc(100% - 20px);
  min-width: 215px;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}
.mapboxPreview {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.inner {
  width: 100%;
  height: 100%;
}
.mapBoxInner :global(.mapboxgl-control-container) {
  display: none;
}
.mapBoxInner :global(.mapboxgl-canvas-container) {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.imgMapbox {
  width: 18px;
  height: 22px;
}
.restaurantItem div {
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #12192b;
}
.restaurantItem p {
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12192ba6;
  margin: 0;
}
.restaurantItem {
  padding: 10px 0;
  border-bottom: 1px solid #020c260d;
}
.restaurantItem:last-child {
  border: none;
}
.restaurantbox {
  padding: 10px;
  overflow: auto;
}
.mapboxContent {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.mapboxPreview .mapboxContent {
  border-radius: 30px;
}
.mapMarker p {
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  position: relative;
  margin-bottom: 0;
}
.mapMarker p span {
  position: absolute;
  left: -50%;
}
.mapToolBox {
  display: flex;
  flex-direction: column;
  height: 90px;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.mapToolBox span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.xingP span {
  color: #020c2659;
  margin: 0 3px;
}
.previewRestaurantbox {
  padding: 10px;
  overflow: auto;
  background: #fff;
  margin-top: 20px;
  border-radius: 30px;
}
.ShareRestaurantbox {
  overflow-y: auto;
  background: #fff;
  margin-right: 20px;
  overflow-x: hidden;
  width: 50%;
}

.previewRestaurantbox::-webkit-scrollbar,
.restaurantbox::-webkit-scrollbar {
  display: none;
}
.mapboxShare {
  padding: 20px;
  width: 100%;
  height: 500px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65));
  border-radius: 30px;
  display: flex;
}
.ShareMapboxContent {
  width: 50%;
  height: 460px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
