.Job-Description .markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  min-height: 32px;
  max-width: 980px;
  margin: 0 auto;
}
.markdown-body h1 {
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
  margin-bottom: 6px;
}
.markdown-body h2 {
  font-family: "Ak-Regular";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7d8fa1;
  margin-bottom: 19px;
}
.markdown-body p {
  margin: 20px 0 15px;
  font-family: "Ak-Regular";
  font-size: 15px;
  line-height: 25px;
}
.markdown-body strong {
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
}
.markdown-body ul {
  list-style: outside;
  padding-left: 15px;
  margin-bottom: 20px;
}
.markdown-body ul li {
  font-family: "Ak-Regular";
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #3f3f50;
  word-break: break-word;
  margin-left: 10px;
}
.Job-Description {
  margin: 25px;
  height: calc(100% - 120px);
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 15px;
}
.Job-Description .Markdown-Box {
  height: calc(100% - 70px);
  padding: 15px 20px;
  overflow: auto;
}
.Job-Description .Markdown-Box::-webkit-scrollbar {
  display: none;
}
.Job-Description footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  padding: 15px 20px;
  border-top: 0.5px solid rgba(34, 35, 47, 0.1);
}
.Job-Description .Right-Btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Job-Description .Right-Btns :global(.ant-spin) {
  color: #fff;
}
.Job-Description :global(.icon-note) {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #5182ff;
  font-size: 17px;
  color: #5182ff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.Job-Description :global(.icon-duigou) {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #52c41a;
  font-size: 17px;
  color: #52c41a;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.Job-Description .Post-Btn {
  min-width: 100px;
  max-height: 40px;
  background: #5182ff;
  border-radius: 25px;
  padding: 10px 20px 10px 15px;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.Job-Description .Post-Btn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Job-Description :global(.ant-result-success .ant-result-icon > .anticon) {
  color: #5182ff;
}
