@import "../mixin.scss";

.featureCell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  padding: 10px 15px;
  height: 100%;

  .featureName {
    color: #12192b;
    max-width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    word-break: break-word;
  }

  .featureDesc {
    color: rgba(18, 25, 43, 0.35);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.title {
  flex-direction: column;
  align-items: flex-start;
}
