.searchBlueprintsToolPieces {
  position: relative;
  width: 342px;
  border-radius: 20px;
  color: var(--primary-color);
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  background-color: var(--bg-color);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  max-height: inherit;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.searchItem {
  padding: 10px;
  width: 100%;
}

.searchTitle {
  color: var(--Dark-35, #acaeb5);
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 400;
  line-height: 20px; /* 153.846% */
  padding-bottom: 10px;
}

.searchContent {
  border-top: 1px solid rgba(48, 48, 50, 0.1);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.loading > span {
  color: var(--Dark-35, #acaeb5);
  text-align: center;
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 400;
  line-height: 16px; /* 123.077% */
  padding-bottom: 10px;
}
