.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-family: "Ak-Regular";
  font-weight: 600;
  color: #12192b;
  width: 100%;
  height: 40px;
  img {
    cursor: pointer;
  }
}
.SettingTitle {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 10px;
}
.saveLoading {
  position: relative;
  height: 25px;
  width: 25px;
}
