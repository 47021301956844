.StartGuiding-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* FIXME: 有关 homeRoot 中每个弹出的层级都应该有个规则 */
  z-index: 99;
}
.background-mask {
  position: fixed;
  left: 0;
  top: 0;
  font-family: "Ak-Regular";
  width: 100%;
  height: 100%;
  background: #fff;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Background.png");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.content-box {
  position: absolute;
  width: 500px;
  height: 510px;
  left: calc(50% - 500px / 2);
  top: calc(50% - 510px / 2);
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  overflow: hidden;
}
.content-box :global(.icon-shanchu1) {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  right: 22px;
  top: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  z-index: 1;
}
.background-gradient {
  position: absolute;
  height: 270px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.066003) 11.94%,
    rgba(255, 255, 255, 0.132024) 21.59%,
    rgba(255, 255, 255, 0.19808) 29.31%,
    rgba(255, 255, 255, 0.26419) 35.45%,
    rgba(255, 255, 255, 0.33037) 40.37%,
    rgba(255, 255, 255, 0.39664) 44.42%,
    rgba(255, 255, 255, 0.463016) 47.94%,
    rgba(255, 255, 255, 0.529517) 51.31%,
    rgba(255, 255, 255, 0.59616) 54.86%,
    rgba(255, 255, 255, 0.662963) 58.96%,
    rgba(255, 255, 255, 0.729944) 63.96%,
    rgba(255, 255, 255, 0.79712) 70.21%,
    rgba(255, 255, 255, 0.86451) 78.06%,
    rgba(255, 255, 255, 0.93213) 87.87%,
    #ffffff 100%
  );
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 1;
}

.background-rectangle {
  position: absolute;
  height: 270px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: linear-gradient(90deg, #feac5e 0%, #ec8a9e 23.44%, #d863e6 50%, #8377f0 73.44%, #4bc0c8 100%);
  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 0;
}

.subject-matter-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;
  width: 337px;
  height: 290px;
  z-index: 3;
  margin: 60px auto 0;
  user-select: none;
}

.icon-box {
  position: relative;
  width: 100px;
  height: 100px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px 20px 80px rgba(93, 122, 152, 0.2);
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.icon-box img {
  width: 60px;
  height: 60px;
}

.lottie-animation {
  width: 200px;
  height: 200px;
  position: absolute;
  top: -50%;
  left: -50%;
}

.characters-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 465px;
  height: 150px;
}
.title {
  width: 360px;
  height: 90px;
  font-family: "Ak-Regular";

  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #12192b;
  white-space: pre-wrap;
}

.subTitle {
  height: auto;
  width: 100%;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #12192b;
  white-space: pre-wrap;
}

.button {
  position: absolute !important;
  margin: 60px auto;
  left: 0;
  right: 0;
  padding: 10px 30px;
  width: 200px;
  height: 40px;
  text-align: center;
  color: #ffffff !important;
  background: #12192b !important;
  border-radius: 25px;
  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border: none;
}

.exit-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;
  isolation: isolate;
  position: absolute;
  width: 420px;
  height: 200px;
  left: calc(50% - 420px / 2);
  top: calc(50% - 200px / 2);
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  overflow: hidden;
  user-select: none;
}
.exit-box .background-gradient,
.exit-box .background-rectangle {
  top: -180px;
}

.exitTitle {
  width: 300px;
  height: 60px;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #12192b;
  z-index: 1;
}
.exit-box .button {
  z-index: 1;
  margin: 30px auto 0;
  bottom: 40px;
  width: 98px;
}
.end-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  gap: 60px;
  isolation: isolate;
  position: absolute;
  width: 500px;
  height: 525px;
  left: calc(50% - 500px / 2);
  top: calc(50% - 525px / 2);
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  overflow: hidden;
}
.end-box .subject-matter-box {
  width: 380px;
  height: 245px;
  margin: 0;
}
.end-box .title {
  height: 45px;
}
.end-box .subTitle {
  width: 380px;
  height: 40px;
}
.end-box .button {
  bottom: 60px;
}
.end-box .auxiliary-button {
  position: absolute;
  bottom: 60px;
  width: 120px;
  height: 40px;
  border-radius: 25px;
  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: rgba(18, 25, 43, 0.65);
}
