.setting-box {
  background-color: #f0f6f8;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
  font-family: "Ak-Regular";
}

.setting-box :global(.icon-guanbi1) {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
}
.setting-content {
  padding-top: 50px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.setting-content::-webkit-scrollbar {
  display: none;
}
.setting-box h6 {
  font-size: 14px;
  color: #b6b2b2;
  padding-left: 16px;
  font-family: "Ak-Regular";
}
.setting-item {
  margin: 6px 0;
  cursor: pointer;
}
.selected-font {
  border: 2px solid #6299f9;
}
.setting-item:hover {
  box-shadow: 0 0 5px #6299f9;
}
