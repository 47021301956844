.container {
  position: relative;
  width: 100%;
  margin-top: 10px;
  .configuration {
    margin: 20px 0 10px;
    color: #12192b;
    font-family: Ak-Regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
  }
  .descriptionTitle {
    color: #656a75;
    /* Caption [M] */
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    word-break: break-word;
    overflow: hidden;
  }
  .description {
    color: rgba(18, 25, 43, 0.35);
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    white-space: pre-wrap;
  }
  .link {
    @extend .description;
    color: #0a9dff;
    text-decoration: underline;
    text-decoration-color: #0a9dff;
    display: inline !important;
  }
  .linkRightIcon {
    margin-left: 5px;
  }
}
.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  &:last-child {
    margin-top: 5px;
  }
  .baseFontStyle {
    color: rgba(18, 25, 43, 0.35);
    /* Caption */
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }
  .title {
    color: rgba(18, 25, 43, 0.65);
    /* Caption */
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }
  .itemDetail {
    position: relative;
    width: calc(100% - 65px);
  }
  .detailBox {
    position: relative;
    display: flex;
    padding: 0 20px;
    overflow: hidden;
    align-items: center;
    gap: 3px;
    align-self: stretch;
    border-radius: 10px;
    border-radius: 10px 10px 0 0;
    background: #f3f3f4;
    border-bottom: 1px solid rgba(18, 25, 43, 0.05);
    .detailLabel {
      width: 50px;
      color: #acaeb5;
      font-family: ak-regular;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .detailText {
      flex: 1;
      padding: 10px 0;
      border: none;
      background: #f3f3f4;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @extend .baseFontStyle;
      position: relative;
      user-select: text;
      color: #12192b;
    }
    img {
      cursor: pointer;
    }
  }
  .detailBox:last-child {
    border-radius: 0 0 10px 10px;
    border-bottom: none;
  }
  .domainDone {
    width: 60px;
    background: rgba(10, 157, 255, 0.1);
    display: flex;
    align-items: center;
    margin-left: 5px;
    border-radius: 10px;
    justify-content: center;
    color: #0a9dff;
    border-style: none;
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }
  .nameContainer {
    overflow: hidden;
  }
  .valueContainer {
    overflow: hidden;
    margin-top: 10px;
  }
}
