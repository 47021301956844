.CardThought {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 15px;
  margin: 0 25px 10px 25px;
  padding: 20px;
}
.CardThought p {
  font-family: "Ak-Regular";

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #22232f;
}
.CardThought .icon-right {
  flex-shrink: 0;
  margin-left: 10px;
  color: #22232f;
  font-weight: 800;
}
