.dropdown {
  width: 170px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown_content {
  display: flex;
  width: 300px;
  padding: var(--spacing-extra-small, 5px) 0px;
  flex-direction: column;
  align-items: flex-start;

  &__item {
    display: flex;
    height: 60px;
    padding: 0px var(--spacing-extra-small, 5px);
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
}

.item {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--radius-small, 10px);
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background-color: var(--blue-10, rgba(10, 157, 255, 0.1));
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.button {
  display: flex;
  height: 40px;
  padding: 15px;
  align-items: center;
  gap: 10px;

  border-radius: 20px;
  background: rgba(5, 190, 117, 0.1);

  outline: none;
  border: none;
}
.button:hover {
  background: rgba(5, 190, 117, 1);
}
.filterIconBox {
  display: inline-flex;

  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.button:hover .filterIconBox {
  .icon {
    position: absolute;
    left: -20px;
    filter: drop-shadow(rgba(255, 255, 255, 1) 20px 0);
    transition: 0.1s ease-out;
  }
}
.button:hover .text {
  &_button {
    color: rgba(255, 255, 255, 1);
    transition: 0.3s ease-out;
  }
}
.text {
  display: inline-flex;
  font-family: Ak-Regular;

  font-weight: 400;
  line-height: 20px;

  &_button {
    color: var(--green, #05be75);
    font-size: 13px;
  }

  &_dropdown {
    color: var(--dark, #12192b);
    font-size: 15px;
  }
}

.dropdown__item {
  width: 100%;
  padding: 0px 5px;
}
