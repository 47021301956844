@import "../../../../../styles/variables.scss";

.quickStart {
  width: 100%;
  // min-width: 300px;
  // max-width: 480px;
  // height: 230px;
  height: 100%;
  border-radius: 20px;
  background: white;
  backdrop-filter: blur(20px);
  transition: box-shadow 0.3s;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: {
    famaily: Ak-Regular;
    size: 15px;
    weight: 400;
  }
  cursor: pointer;

  &:hover {
    box-shadow: $boxShadowHover;
  }

  &-blur {
    position: absolute;
    left: calc(50% - 130px);
    top: calc(50% - 20px - 8px); // from the design of figma, the bottom is 16px more than top
    width: 260px;
    height: 40px;
    border-radius: 25px;
    filter: blur(50px);
    background: linear-gradient(90deg, #9485fa 0%, #fa5eb6 50%, #ff9473 100%);
    z-index: -1;
  }

  &-logo-box {
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 20px;
  }

  &-title {
    font: {
      size: 17px;
      weight: 500;
    }
    color: #12192b;
    margin-top: 10px;
    line-height: 25.5px;
    text-align: center;
  }

  &-actions-bar {
    height: 30px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    // overflow: hidden;
    justify-content: center;
  }

  .action-box {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .action-title {
    margin-left: 10px;
    white-space: nowrap;
  }

  .watchVideo {
    @extend .action-box;

    &-icon {
      margin-left: 5px;
      background: #656a75;
      border-radius: 10px;
      width: 20px;
      height: 20px;
    }

    &-title {
      @extend .action-title;
      color: #656a75;
    }
  }

  .verticalLine {
    width: 1px;
    height: 20px;
    background: #e7e8ea;
    margin-left: 20px;
    margin-right: 20px;
  }

  .getTemplate {
    @extend .action-box;

    &-icon {
      margin-left: 5px;
    }

    &-title {
      @extend .action-title;
      color: #0a9dff;
    }
  }
}
@media only screen and (min-width: 980px) and (max-width: 1479px) {
  .verticalLine {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}
@media only screen and (min-width: 718px) and (max-width: 979px) {
  .quickStart-actions-bar {
    flex-wrap: wrap !important;
    width: 70% !important;
  }
  .verticalLine {
    display: none !important;
  }
}
@media only screen and (min-width: 310px) and (max-width: 717px) {
  .quickStart-actions-bar {
    flex-wrap: wrap !important;
  }
  .verticalLine {
    display: none !important;
  }
}
@media only screen and (max-width: 309px) {
  .quickStart-actions-bar {
    flex-wrap: wrap !important;
  }
  .verticalLine {
    display: none !important;
  }
}
