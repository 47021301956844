.sourceBox {
  position: absolute;
  top: 70px;
  width: 260px;
  padding: 20px;
  border-radius: 20px;
  z-index: 1;
  background: #fff;
  text-align: left;
}

.title {
  font-family: "Ak-Regular";

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #12192b;
  padding-bottom: 25px;
}

.uploadWrapper {
  padding-bottom: 25px;
}

.uploadWrapper .desc {
  font-family: "Ak-Light";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: rgba(18, 25, 43, 0.65);
}

.uploadWrapper > a {
  display: inline-block;
  font-family: "Ak-Light";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #0a9dff;
  padding-bottom: 15px;
}

.dragger:global(.ant-upload.ant-upload-drag) {
  background: rgba(10, 157, 255, 0.1);
  border: 1px dashed rgba(10, 157, 255, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.dragger :global(.ant-upload) {
  padding: unset !important;
}

.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
}

.upload .icon {
  padding-bottom: 25px;
}

.upload .desc {
  font-family: "Ak-Light";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #12192b;
}

.upload .limitSize {
  font-family: "Ak-Light";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: rgba(2, 12, 38, 0.45);
}

.linkWrapper .desc {
  font-family: Ak-Light;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: rgba(18, 25, 43, 0.65);
}

.linkWrapper > ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.linkWrapper > input {
  padding: 15px 20px;
  width: 230px;
  height: 50px;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 10px;
}

.surportWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.surportItem {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 16px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 13px;
  margin: 0 4px 4px;
}

/* 为了解决 reactflow 画布上节点有 scale 缩放，导致计算偏移 */
.wrapperFromScale :global(.ant-upload-list .ant-tooltip) {
  top: 304px !important;
  left: 83px !important;
}
