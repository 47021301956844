.submit-textarea {
  padding: 15px 20px;
  border-radius: 10px;
  background: rgba(2, 12, 38, 0.05);
  border: none;
  font-size: 15px;
  font-family: ak-regular;
  line-height: 20px;
  color: #12192b;

  &::-webkit-input-placeholder {
    color: rgba(2, 12, 38, 0.35);
  }
}

.successModal {
  :global(.ant-modal-footer) {
    justify-content: center;

    [data-testid="confirmModal-discard"] {
      display: none;
    }

    :global(.ant-btn:not(.ant-dropdown-trigger)) {
      max-width: 98px;
      margin: 0;
    }
  }

  .success-title {
    img {
      width: 70px;
      margin-bottom: 35px;
    }
  }
}
