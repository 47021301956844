.FontStyle {
  margin-top: 20px;

  .FontBoxMobile {
    justify-content: flex-start;
    .item_content {
      width: 100%;
      height: auto;
      cursor: pointer;
      flex: 0 0 calc(33.3333% - 10px);
      margin: 0px 5px 10px 5px;
      box-sizing: border-box;
    }
  }
}

.title {
  font-family: "Ak-Regular";
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12192b;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.fontBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item_content {
    width: 85px;
    height: 85px;
    margin-top: 10px;
    cursor: pointer;

    .item_div,
    .mobile_item_div {
      width: 100%;
      height: 100%;
      background: #f3f3f4;
      border: 2px solid transparent;
      text-align: center;
      line-height: 85px;
      border-radius: 10px;
      font-size: 36px;
      color: #656a75;
    }
    .item_div:hover {
      border-color: #0a9dff;
      background: #fcfcfc;
      color: #12192b;
    }
  }
}

.popover {
  :global(.ant-popover-inner) {
    background-color: #12192b;
  }
  :global(.ant-popover-inner-content) {
    color: white !important;
  }
  :global(.ant-popover-arrow-content) {
    background-color: #12192b;
    --antd-arrow-background-color: #12192b;
  }
}

.activeFont {
  border-color: #0a9dff !important;
  background: #fcfcfc !important;
  color: #12192b !important;
}
