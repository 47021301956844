$padWidth: 768px;
$mobileWidth: 320px;

.shareFooterUrl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 70px;

  .info {
    word-break: break-word;
    color: var(--shareFooterUrlInfo, rgba(18, 25, 43, 0.35));
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 16px;
    line-height: 25px;
    & > span {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .url {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    color: var(--shareFooterUrlColor, #fff);
    font-family: var(--fontFamily, Ak-Regular);
    font-size: 16px;
    line-height: 25px;
    span {
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .copyright {
    cursor: pointer;
  }

  .copyright {
    cursor: pointer;
  }
  .imagica {
    cursor: pointer;
    color: var(--shareFooterUrlColor, #fff);
  }
}

@media screen and (max-width: $padWidth) {
  .shareFooterUrl {
    padding: 20px 50px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: $mobileWidth) {
  .shareFooterUrl {
    padding: 20px 20px;
  }
}
