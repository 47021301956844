.loader {
  display: inline-flex;
  position: relative;
  &_full {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
.lottieLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
