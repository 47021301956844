.navbarWrapper {
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .navbar {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    .navItem {
      display: flex;
      padding: 10px 24px;
      justify-content: center;
      white-space: nowrap;
      align-items: center;
      cursor: pointer;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border: 1px solid rgba(18, 25, 43, 0.1);
      &.active {
        background-color: rgba(18, 25, 43, 0.05);
      }
    }
  }
}
