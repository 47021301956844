.c1Box {
  position: relative;
  width: 100%;
  min-height: 225px;
  // padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  overflow: hidden;
}
.c1Box.shrinked {
  height: 225px;
}
.c1Box:not(:first-child) {
  margin-top: 10px;
}
.backGradient {
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(179deg, rgba(39, 39, 39, 0) 1.21%, rgba(39, 39, 39, 0.87) 41.53%, #272727 98.79%);
  position: absolute;
  left: 0;
  right: 0;
  // top: 0;
  height: 65%;
  bottom: 0;
  border-radius: 0 0 20px 20px;
}
.textBox {
  position: relative;
  padding: 10px;
  margin: 5px;
}
.textBox.shrinked {
  margin: 0;
}
