@import "../mixin.scss";

.featureCell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: px2em(15px);
  font-weight: 400;
  line-height: px2em(20px);

  padding: px2em(10px) px2em(30px);
  height: 100%;

  .featureName {
    color: rgba(18, 25, 43, 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .featureDesc {
    color: rgba(18, 25, 43, 0.35);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.title {
  flex-direction: column;
  align-items: flex-start;
}
