.login_c {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}
.login {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  margin-top: 80px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/brain-logo.png");
  background-size: 100% 100%;
}
.login-text {
  margin: 30px auto;
  font-size: 24px;
}
input {
  width: 90%;
  outline: none;
  border: none;
}
.pass-input {
  padding-right: 28px;
  box-sizing: border-box;
}
.icon-yanjing_off {
  color: #f0f0f0;
}
.option,
.reset-pwd {
  color: #c1c1c1;
  font-family: Lator-Semibold;
  font-size: 16px;
  margin-bottom: 20px;
}
.reset-pwd {
  display: block;
  text-align: left;
  width: 90%;
  max-width: 500px;
  margin: 20px auto;
}
.option:hover,
.reset-pwd:hover {
  color: #4492dc;
  cursor: pointer;
}
.error {
  color: red;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -5px;
  font-size: 14px;
}
.input_error {
  color: red;
}
@media only screen and (max-width: 374px) {
  .option {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1023px) {
  .login {
    margin-top: 10px;
  }
  .reset-pwd {
    margin: 20px auto 10px;
  }
}
:global(.weui-input) {
  max-width: 500px;
  border-bottom: 1px solid #c4c4c4;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #707070;
}

:global(.weui-input::-webkit-input-placeholder) {
  color: #f3f3f5;
  opacity: 0.8;
  font-family: Ak-Regular;
  font-weight: 800;
}

:global(.cur-height) {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: relative;
  transition: transform 1s;
}

:global(.user-email-input) {
  width: 90%;
  height: 36px;
}

.number-verification-input {
  width: 500px;
  margin: 0 auto;
}

.input-size {
  font-size: 21px;
  font-weight: 700;
  color: #707070;
}

.get-otp {
  width: 170px;
  height: 46px;
  line-height: 46px;
  margin: 45px auto 55px;
  background-color: #b4b4b4;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
}

.lg-proceed {
  cursor: pointer;
  background-color: #4492dc;
}

.lg-proceed:active {
  background-color: #4492dc;
}

.loading {
  color: #fff;
  display: flex;
  width: 100%;
  z-index: 11;
  height: 100%;
  position: absolute;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: #0000003d;
}

.errorTip {
  color: red;
  font-size: 14px;
}

.otp-box {
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}
.otp-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.otp-bottom-item {
  width: 30px;
  height: 35px;
  border-bottom: 1px solid #c4c4c4;
  margin: 0 10px 0 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #707070;
}

.disable-icon {
  color: #b4b4b4;
  opacity: 0.6;
}

.otp-timer {
  cursor: pointer;
  color: #4492dc;
  margin-right: 5%;
}

/* 伪类实现光标效果 */
.active:after {
  content: "";
  display: inline-block;
  height: 25px;
  width: 1px;
  margin-top: 6px;
  background: #313131;
  animation: 0.8s animate infinite;
}

@keyframes animate {
  100% {
    opacity: 0;
  }
}

.code-input-input {
  opacity: 0;
  width: 65%;
  height: 60px;
  position: absolute;
  left: 5%;
  outline: none;
  color: transparent;
  background: transparent;
  margin-left: -100px;
}
.login-style {
  color: #c1c1c1;
  font-family: Lator-Semibold;
  font-size: 16px;
  margin-bottom: 20px;
}
.sign-box {
  position: relative;
}
.retrieve-text {
  position: absolute;
  top: 12px;
  right: 200px;
}
.sign-up-input {
  padding: 8px 11px 8px 2px;
  max-width: 500px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #707070;
  border-bottom: 1px solid #c4c4c4;
}

.sign-up-input::-webkit-input-placeholder {
  color: #f3f3f5;
  opacity: 0.8;
  font-family: Ak-Regular;
  font-weight: 800;
}
