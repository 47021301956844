@mixin CommonBgColor {
  background-color: #ffffffa6 !important;
}

.commonBackgroundColor {
  @include CommonBgColor;
}

@mixin RowBtn {
  @include CommonBgColor;
  display: block;
  width: 100%;
  height: 40px;
  font-family: AktivGrotesk-Medium;
  color: #12192ba6;
  border: 0.5px solid #ffffff59;
  border-radius: 10px;
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: #e3e5eb;

  font: {
    family: Ak-Regular;
    size: 13px;
    weight: 400;
  }
  color: #12192b;
  line-height: 20px;

  button {
    @include CommonBgColor;
  }

  input {
    @include CommonBgColor;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 60px;
  border-bottom: 1px solid #ffffff59;
}

.content {
  padding: 12px 6.36vw 30px;
  height: calc(100% - 60px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  & > section:not(:first-child) {
    margin-top: 20px;
  }
  & > section:first-child {
    margin-top: 15px;
  }
}

.section {
  &Title {
    font: {
      family: AktivGrotesk-Medium;
      size: 15px;
    }
  }

  &SubTitle {
    color: #12192ba6;
    margin: 5px 0 10px;
  }
}

.emailErrorPromptBottomSpacing {
  height: 35px;

  &_short {
    height: 10px;
  }
}

.normalRowBtn {
  @include RowBtn;
}
button.saveChangesBtn {
  @include RowBtn;
  margin-top: 35px;

  &_emailInvalid {
    margin-top: 10px;
  }
  &_dsiabled {
    background: rgba(2, 12, 38, 0.1) !important;
    color: darkgrey;
    cursor: not-allowed;
  }
}
.deleteAccountBtn,
.logOutBtn {
  @include RowBtn;
  color: #ff335c;
}
button.deleteAccountBtn {
  background-color: unset !important;
  margin-top: 5px;
  border: unset;
  font-family: Ak-Regular;
}
