.tool-bar-box {
  width: 100%;
  position: absolute;
  bottom: 1%;
  display: flex;
  justify-content: center;
}
div:global(.react-flow__panel).bottom-tool-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 5px 5px -8px #000;
  border-radius: 10px;
  position: absolute;
  height: 60px;
  left: 25px;
  bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(113, 144, 175, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 30px;

  width: max-content;
  left: 0;
  right: 0;
  margin: 0 auto !important;
}

div:global(.react-flow__panel).bottom-zoom-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* width: 206px; */
  width: 246px;
  height: 60px;
  left: 25px;
  bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(113, 144, 175, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 15px;
  justify-content: space-between;
}

div:global(.ant-popover-inner-content) {
  /* padding: 5px !important; */
}
.tool-bar-pop-over {
  color: #3a394c;
  font-family: SF-Regular;
  font-weight: 500;
  white-space: nowrap;
}
.bottom-tool-bar :global(.icon-bianliang),
/* .bottom-tool-bar :global(.icon-view), */
.bottom-tool-bar :global(.icon-tianjia),
/* .bottom-tool-bar :global(.icon-yulan-guanbi_preview-close-one), */
.bottom-tool-bar :global(.icon-choose),
.bottom-tool-bar :global(.icon-quanping),
.bottom-tool-bar :global(.icon-suoxiao),
.bottom-tool-bar :global(.icon-fangda) {
  padding: 5px;
  font-size: 25px;
  cursor: pointer;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.bottom-tool-bar :global(.icon-bianliang):hover,
/* .bottom-tool-bar :global(.icon-view):hover, */
.bottom-tool-bar :global(.icon-tianjia):hover,
.bottom-tool-bar :global(.icon-choose):hover,
/* .bottom-tool-bar :global(.icon-yulan-guanbi_preview-close-one):hover, */
.bottom-tool-bar :global(.icon-quanping):hover,
.bottom-tool-bar :global(.icon-suoxiao):hover,
.bottom-tool-bar :global(.icon-fangda):hover {
  background: #eee;
}
.bottom-tool-bar .highlight-mouse:global(.icon-choose) {
  background: #1890ff;
  color: #fff;
}
.bottom-zoom-bar .zoom-in-out {
  /* width: 116px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}
.percentage {
  width: 50px;
  font-size: 13px;
  font-weight: 400;
  /* font-family: SF-Regular; */
  font-family: "Ak-Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.bottom-zoom-bar .zoom-in-out :global(.icon-suoxiao) {
  box-shadow: none;
}
.zoom-input {
  width: 30px;
}
.zoom-input-two-numbers {
  width: 20px;
}
:global(.HomeToolBar_zoom-input__dXGue .ant-input-number-input) {
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  font-family: SF-Regular;
}
.zoom-input :global(.ant-input-number-input) {
  padding: 0;
}
.zoom-input :global(.ant-input-number-handler-wrap) {
  display: none;
}
.help-wrapper {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 60px;
  height: 60px;
  z-index: 1000;
}

.help-hover-wrapper {
  padding-top: 50px;
  position: absolute;
  left: 0;
  top: -50px;
  width: 100%;
  height: calc(100% + 50px);
}

.icon-default {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  position: relative;
}
button.icon-default {
  border: none;
  background: transparent;
}
.icon-report-bug {
  position: absolute;
  left: 90px;
  top: 22px;
  cursor: pointer;
  font-size: 15px;
  color: rgba(18, 25, 43, 0.65);
}
/* .icon-default:hover {
  background-color: #efefef9f;
  border: 1px solid #efefef;
} */
.icon-HW {
  width: 14px;
  height: 22px;
  background-size: cover;
  padding: 10px;
}
.variable-icon {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/variable-Icon.svg");
}
.zoom-top-fit {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/centre2x.png");
  background-size: 100% 100%;
}
.reduce-icon {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/reduce2x.png");
}
.amplify-icon {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/amplify2x.png");
}
.hand-icon {
  background-color: #000;
  display: inline-block;
  -webkit-mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hand-logo.png") no-repeat;
  mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/hand-logo.png") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-55%);
}
.document-icon {
  /* background: url('https://dopniceu5am9m.cloudfront.net/static/assets/230705/document2x.png') no-repeat;
  background-size: 100% 100%; */
  background-color: #000;
  display: inline-block;
  mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/document2x.png") no-repeat;
  -webkit-mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/document2x.png") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.upload-icon {
  background-color: #000;
  display: inline-block;
  mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/upload-file.svg") no-repeat;
  -webkit-mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/upload-file.svg") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.shift-icon {
  background-color: #000;
  display: inline-block;
  mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Icons2x.png") no-repeat;
  -webkit-mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/Icons2x.png") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.advance2x-icon {
  background-color: #000;
  display: inline-block;
  mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/redo2x.png") no-repeat;
  -webkit-mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/redo2x.png") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.drawback-icon {
  background-color: #000;
  display: inline-block;
  mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/undo2x.png") no-repeat;
  -webkit-mask: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/undo2x.png") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.help-icon {
  background: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/help2x.png") no-repeat;
  background-size: contain;
  background-position: top center;
}

.selected {
  background: #0a9dff;
}
.selected .shift-icon {
  background-color: #fff;
}
.selected .hand-icon {
  background-color: #fff;
}
.selected:hover {
  background: #0a9dff;
}
.spacing {
  width: 20px;
}
.not-have .drawback-icon,
.not-have .document-icon,
.not-have .advance2x-icon {
  background-color: #cfcfcf;
  cursor: not-allowed;
}

:global(.ant-popover-inner) {
  border-radius: 15px;
}
.help-menu {
  padding: 0px 25px;
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  border-radius: 10px;
}
.help-menu:hover {
  background-color: rgba(10, 157, 255, 0.1);
}
.box-display {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.help-title {
  flex: 1;
  min-width: 126px;
  width: max-content;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: rgba(18, 25, 43, 0.65);
  gap: 20px;
  text-align: left;
  font-family: "Ak-Regular";
}

.version-menu {
  color: #c4c4c9;
  height: 32px;
  padding: 13px 15px 8px 15px;
  margin-top: 5px;
  border-top: 1px solid #ebebed;
  font-family: "SF-Regular";
  font-weight: bolder;
  font-size: 12px;
}

:global(.supportCommunity) {
  width: 20px;
  height: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/supportCommunity.png");
  background-size: cover;
  margin-right: 20px;
  background-position: center;
}
:global(.externalLink) {
  width: 16px;
  height: 16px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/externalLink2x.png");
  background-size: cover;
  margin-left: 15px;
}
:global(.discordCommunity) {
  width: 20px;
  height: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/discord-community.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
}
:global(.requestafeature) {
  width: 20px;
  height: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/imagica.ai/icons/message.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
}
:global(.startVideo) {
  width: 20px;
  height: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/startVideo.svg");
  background-size: cover;
  background-position: center;
  margin-right: 20px;
}
:global(.userGuide) {
  width: 20px;
  height: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/userGuide.png");
  background-size: cover;
  margin-right: 20px;
  background-position: center;
}
:global(.feature) {
  width: 20px;
  height: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/natural.ai/assets/png/natural.png");
  background-size: cover;
  margin-right: 20px;
  background-position: center;
}

.disbaled-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(80px);
  border-radius: 9999px;
  opacity: 0.2;
  cursor: not-allowed;
  z-index: 10;
}

.help-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  left: 0px;
  top: 0px;
  background: #12192b;
  box-shadow: 0px 30px 60px rgba(113, 144, 175, 0.15);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 10px;
  justify-content: space-between;
}

.cot-tag {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: -90px;
  cursor: pointer;
}

.imagic-logo {
  width: 60px;
  height: 60px;
}

.disabled {
  cursor: not-allowed;
}
