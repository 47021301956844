.shares-box {
  background-color: #f0f6f8;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: var(--fontFamily, "Ak-Regular");
}

.button-group {
  position: absolute;
  width: 170px;
  bottom: 20px;
  right: 20px;
}

.button-group :global(.ant-btn) {
  width: 100%;
  margin: 5px 0;
}

.buyBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.buyBox .buyInput {
  background-color: #f0f6f8;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #22232f;
  font-size: 30px;
  bottom: 84px;
}

.buyInput .buyValue {
  font-size: 90px;
  margin-top: 40px;
  word-break: break-all;
  max-width: 80%;
}
.buyBox :global(.ant-btn) {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.check-cursor {
  border-right: 1px solid #000000;
  padding-left: 10px;
  width: 0;
  height: 67px;
  margin-top: 40px;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: #000000;
  }
  50% {
    border-color: transparent;
  }
}

.loading-box {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0f6f8;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-box {
  height: 350px;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 0.5rem 1rem rgb(144 147 169 / 8%);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.confirm-box .title {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
}

.confirm-box .parameters {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #00000040;
  padding-bottom: 15px;
  align-items: center;
}

.buyBox :global(.icon-guanbi1) {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
}
.custom-node-sharebuy {
  position: relative;
  padding-top: 10px;
}

:global(.globalAiSaasOutputList) {
  .shares-box {
    min-height: 400px;
    border-radius: 20px;
    overflow: hidden;

    .icon-guanbi1 {
      top: -70px;
    }
  }
}
