.SharedCanvasView {
  width: 100%;
  height: 100%;
  background: linear-gradient(72.44deg, #d2d4dc 0%, #f0f2f5 100%), linear-gradient(0deg, #f0f2f5, #f0f2f5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.get-access-container {
  text-align: center;
  padding-top: 15%;
  width: 720px;
  height: 350px;
  padding: 50px 0px 70px 0px;
  border-radius: 25px;
  gap: 50px;
  background: rgba(255, 255, 255, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoImg {
  width: 40px;
  height: 40px;
}
.shareDescription {
  width: 334px;
  font-family: "Ak-Regular";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 400;
  color: #12192b;
}
.shareDescription p {
  margin: 0;
  padding: 0;
}
.shareDescription span {
  font-family: "Ak-Medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.SharedCanvasView :global(.ant-btn) {
  width: 207px;
  height: 50px;
  /* padding: 5px 30px 5px 30px; */
  padding: 15px 30px 15px 30px;
  border-radius: 25px;
  gap: 15px;
  color: #ffffff;
  font-size: 20px;
  border-color: rgba(10, 157, 255, 1);
  background: rgba(10, 157, 255, 1);
  font-family: "Ak-Medium";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
