.personSignificance {
  width: 100%;
  // display: flex;
  // gap: 4px;
  // flex-wrap: wrap;
  column-count: 2;
  column-gap: 4px;
  .significanceBox {
    // flex: 0.5;
    // flex-shrink: 0;
    // min-width: calc(50% - 2px);
    width: 100%;
    margin-bottom: 4px;
    break-inside: avoid;
  }
  .biographyValue {
    padding: 24px;
    border-radius: 44px;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(18, 25, 43, 1);
  }

  .headerBox {
    padding: 24px;
    border-radius: 44px;
    margin-bottom: 8px;
    background: #fff;
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: rgba(18, 25, 43, 1);
    margin-bottom: 8px;
  }
  .summary {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(18, 25, 43, 1);
  }
  .orangeBox {
    .headerBox {
      background-color: #f17b2c;
    }
    .title,
    .summary {
      color: #fff;
    }
  }
  .purpleBox {
    .headerBox {
      background-color: #8f79ff;
    }
    .title,
    .summary {
      color: #fff;
    }
  }
}
