.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.settings,
.privacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.title {
  color: #12192b;
  text-align: left;
  font-family: "Ak-Regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.subtitle {
  color: rgba(18, 25, 43, 0.65);
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.input {
  width: 100%;
  border-radius: 10px;
  background: rgba(2, 12, 38, 0.05);
  line-height: 20px;
  padding: 15px 20px;

  color: rgba(18, 25, 43, 0.35);
  font-family: "Ak-Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  input {
    background-color: transparent;
  }

  border: none;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    border: none;
  }

  .ant-input-prefix {
    width: 21px;
  }
}

.inputWrap {
  display: flex;
  align-items: center;
  width: 100%;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :global {
    .ant-input-clear-icon-has-suffix {
      display: flex;
    }
  }
}
.publishFee {
  color: #656a75;
  font-family: "Ak-Regular";
  font-size: 13px;
  line-height: 20px;
}
