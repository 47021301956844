$icon-active-border-color: rgba(10, 157, 255, 1);
$icon-border-color: transparent;
$bg-color: #fff;
$bg-border-color: rgba(18, 25, 43, 0.1);
$iconCanvas-fill-color: #676e7f;
$iconCanvas-active-fill-color: rgba(10, 157, 255, 1);

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.switcher {
  display: inline-flex;
  align-items: center;

  padding: 3px;

  background-color: $bg-color;
  border-radius: 9999px;
  border: 0.5px solid $bg-border-color;
}

.icon,
.chatIcon {
  display: inline-block;
  line-height: 0;
  font-size: 0;
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  overflow: hidden;

  border: 0.5px solid $icon-border-color;
}

.switcher > .icon + .icon {
  margin-left: 5px;
}

.iconCanvas {
  color: $iconCanvas-fill-color;
}

.icon:hover {
  border-color: $icon-active-border-color;
}

.icon.selected {
  position: relative;
  border-color: $icon-active-border-color;
  background: linear-gradient(0deg, rgba(10, 157, 255, 0.15), rgba(10, 157, 255, 0.15)),
    linear-gradient(0deg, #ffffff, #ffffff);

  &.iconCanvas {
    color: $iconCanvas-active-fill-color;
  }
}

.popoverWrap {
  // top: 95px;
  // left: 194px;
  border-radius: 15px;
  // ant-menu-overflow-item 高度减去 navbar switcher 高度和上下边距
  padding-top: 6px;

  // gap: 10px;
  :global(div.ant-popover-inner-content) {
    // FIXME:
    padding: 0 !important;
  }
}

.popverContent {
  // width: 220px;
  // padding: 15px 20px 15px 20px;
  padding: 10px 0;
  min-width: 220px;
  min-width: 250px;
  min-height: 80px;
  text-align: left;
}

.popverTitle {
  //styleName: Body [M];
  // font-family: Aktiv Grotesk;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 25, 43, 1);
  display: flex;
  align-items: center;
  padding: 5px 20px;
}

.popverSubTitle {
  //styleName: Body;
  // font-family: Aktiv Grotesk;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 25, 43, 1);
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  // margin-top: 10px;
}
.disableAutoImagica {
  background: #12192b1a;
  border: none;
}
.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointNoDrop {
  cursor: no-drop;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
