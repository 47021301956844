.input-box {
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 72px;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px 20px;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
}

.input-box textarea {
  overflow: hidden;
}

.inputMotion {
  position: absolute;
  width: 100%;
  padding: 16.5px 20px;
  box-sizing: border-box;
  left: 0px;
  top: 0px;
}

.inputMotionLoading {
  transition: height 0.3s ease-in-out;
}

.inputMotionLoading :global(.ant-input-clear-icon) {
  display: none;
}

.inputMotionLoading .enter-prompt {
  display: none;
}

.add-input {
  width: 100%;
  color: #12192b;

  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  padding-left: 0;
  caret-color: #0a9dff;
}

.input-box :global(.ant-input) {
  padding: 4px 70px;
  padding-left: 0px;
}

.InputStatusButton {
  cursor: pointer;
  position: absolute;
  z-index: 100;
}

.enter {
  right: 25px;
  width: 60px;
  height: 40px;
  background: var(--inputBoxEnterBgc, "rgba(2, 12, 38, 0.05)");
  border-radius: 30px;
  background-image: url(https://dopniceu5am9m.cloudfront.net/static/assets/230705/enter2x.png);
  background-size: 20px 20px;
  top: calc(50% - 20px);
  background-position: center center;
  background-repeat: no-repeat;
}

.enter .mark {
  background: rgba(2, 12, 38, 0.05);
  border-radius: 5px;
  color: rgba(18, 25, 43, 0.65);
  width: 49px;
  text-align: center;
}

.close {
  top: calc(50% - 15px);
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 10;
  background: var(--inputBoxBottom, "#fff");
  border-radius: 30px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/close.png");
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.suffixInput-box {
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: flex-end;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
}
.input-icon {
  width: 20px;
  height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 5px;
  color: rgba(18, 25, 43, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

:global(.fotInput-popover) {
  width: 213px;
}
:global(.fotInput-popover .ant-popover-inner) {
  border-radius: 15px;
}

.inputBar {
  position: relative;
}

.inputBarMotion {
  position: relative;
  z-index: 100;
}

.historyMotion {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: none;
}

.freeze {
  z-index: 200;
  display: block;
}
