.NodeTypeInput:global(.ant-input) {
  width: 100%;
  min-height: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fafafb;
  border: 0.5px solid rgba(2, 12, 38, 0.0509803922);
  padding: 0px 10px 0px 15px;
  gap: 10px;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  // margin-bottom: -17px;
}

.NodeTypeInput:disabled {
  color: rgba(0, 0, 0, 0.85) !important;
}

.NodeTypeInput:hover,
.NodeTypeInput:focus {
  border: 0.5px solid #020c260d;
  box-shadow: none;
}
