.recipe .CardHomeCookingStep {
  width: 290px;
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4px 10px 20px 0;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.03);
}

.CardHomeCookingStep .signBox {
  width: 50px;
  height: 50px;
  min-width: 50px;
  margin-right: 10px;
  background: #f3f3f6;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #9ba2a9;
}
.CardHomeCookingStep .subscript {
  position: absolute;
  width: 10px;
  height: 20px;
  left: calc(50% - 10px / 2 - 8px);
  top: calc(50% - 20px / 2 - 8px);
}
.CardHomeCookingStep .splitLine {
  position: absolute;
  width: 1px;
  height: 28px;
  left: 24px;
  top: 11px;
  transform: rotate(45deg);
  background: rgba(34, 35, 47, 0.1);
}

.CardHomeCookingStep .total {
  position: absolute;
  width: 10px;
  height: 20px;
  left: calc(50% - 10px / 2 + 8px);
  top: calc(50% - 20px / 2 + 8px);
}

.CardHomeCookingStep .title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
}

.CardHomeCookingStep .reduceText {
  font-size: 12px;
}
