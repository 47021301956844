.container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // gap: 15px;
  width: 100%;
  div {
    font-size: 14px;
    color: #656a75;
    margin-bottom: 15px;
  }
  .text span {
    cursor: pointer;
  }
}

.link {
  color: #0a9dff;
  font-family: "Ak-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  cursor: pointer;
}
.loading {
  text-align: center;
}
