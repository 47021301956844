.AngleContent {
  color: #282828;

  font-size: 18px;
  font-weight: 700;
  font-family: "Ak-Regular";
  background-color: #f0f6f8;
}

.project-app .AngleContent {
  background: none;
}
.ai-saas .AngleContent {
  background-color: #fff;
  padding: 1px;
  border-radius: 10px;
  margin: 20px 25px;
}
.ai-saas .horizontalScrolling .CardFrame {
  border-bottom: none;
}

/* .AngleContent img:hover {
  opacity: 0.3;
  cursor: pointer;
} */

.AngleContent p:hover {
  opacity: 0.3;
  cursor: pointer;
}

.AngleContent .AngleTitle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 25px;
  overflow: hidden;
  margin: 15px 0 15px 25px;
}
.AngleContent .icon-right {
  position: relative;
  z-index: 1;
  color: #22232f;
  transition: all 0.3s;
  display: block;
  padding: 10px;
  line-height: 25px;
  margin: -10px;
  cursor: pointer;
}
.AngleContent .icon-rotate {
  transform: rotate(90deg);
  transition: all 0.3s;
}
.AngleContent .over-angle-tile {
  width: calc(100% - 40px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.AngleContent .AngleTitle p {
  line-height: 25px;
  padding-right: 20px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-height: 50px; */
}

.horizontalScrolling {
  width: 100%;
  overflow: auto;
  display: flex;
  padding: 0 0 0 25px;
  position: relative;
  overflow-y: hidden;
}
.horizontalScrolling .CardFrame {
  border-bottom: 0.5px solid rgba(34, 35, 47, 0.1);
}
.horizontalScrolling::-webkit-scrollbar {
  display: none;
}

/* angle video start */
.AngleContent .angleDescription {
  font-size: 15px;
  line-height: 20px;
  margin: 20px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
/* video end */

/* angle image start */
.AngleContent .CardHomeImage {
  width: 140px;
  height: 105px;
  margin: 0 10px 25px 0;
}
/* image end */

/* angle food start */
.AngleContent .CardHomeFood {
  margin: 0 10px 25px 0;
}
/* food end */
