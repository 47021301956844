.restaurantRecommendationBox {
  position: relative;
  width: calc(100% - 30px);
  min-height: 380px;
  margin: 15px;
  .title {
    color: #12192b;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    word-wrap: break-word;
  }
  .description {
    color: #12192b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    margin: 16px 0;
  }
  .platform {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    p {
      color: rgba(18, 25, 43, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    border-radius: 44px;
    background: #fff;
  }
  .listBox {
    max-height: 730px;
    overflow: auto;
  }
  .listBox::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: flex;
    align-items: center;
  }
  .item:not(:last-child) {
    margin-bottom: 24px;
  }
  .info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .restaurantName {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    align-self: stretch;
    overflow: hidden;
    color: #12192b;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 4px;
  }
  .restaurantType {
    margin-top: 16px;
    color: rgba(18, 25, 43, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
