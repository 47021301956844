.bg_layer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  z-index: 10;
  user-select: none;
}

.content {
  position: absolute;
  z-index: 13;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background: rgb(87, 91, 104);
  overflow: hidden;

  /* Shadow L [Blur] */
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  // backdrop-filter: blur(20px);
  cursor: pointer;

  .content_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .dropdownItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    user-select: none;
    padding: 0 15px;

    &:active,
    &:hover {
      background: rgb(54, 62, 79);
    }

    &__icon {
      display: flex;
    }

    &__text {
      display: flex;
      color: white;
      font-family: Ak-Regular;
      font-size: 13px;
      margin-left: 10px;
    }
  }
}
