.recipe .CardHomeNutrition {
  width: 140px;
  height: 80px;
  background: #ffffff;
  border-radius: 15px;
  padding: 15px 20px;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 4px 10px 20px 0;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.03);
}

.CardHomeNutrition .title {
  font-family: "Ak-Regular";

  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #22232f;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
}

.CardHomeNutrition .price {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #22232f;
}
