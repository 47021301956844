.alert {
  :global {
    .ant-modal-content .ant-modal-body {
      padding: 0;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  position: relative;
  min-width: 400px;
  padding: 25px;
  border-radius: 20px;
  background-color: white;

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .priceinfo {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 20px;

    .price_switch {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .switch {
        // background-color: #E7E8EA;
      }
    }

    .line {
      height: 1px;
      background: #12192b0d;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .today {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 30px;
      margin-bottom: 15px;
      align-items: center;
    }

    span {
      font-weight: 600;
    }
  }
  .payDisClaimer {
    font-size: 11px;
    font-family: Ak-Regular;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    margin-bottom: 15px;
    padding-left: 10px;
  }
  .pay_button {
    background-color: #12192b;
    color: white;
    //styleName: LP/Body [B];
    font-family: Ak-Regular;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
