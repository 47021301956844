.loadingWrapper {
  /* position: absolute; */
  position: relative;
  width: 100%;
  height: 170px;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.improveImgLoading {
  position: absolute;
  background: #fff;
  height: 100%;
}
.loadingWrapper .lottieLoader {
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 20px;
}

.loadingText {
  font-size: 15px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
}
:global(.dark) .loadingText {
  color: #ffffff;
}
