.btnWrapper {
  display: flex;
  justify-content: center;
  margin: 5px 0;
  cursor: pointer;
}

.deleteButton {
  display: flex;
  width: 113px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 15px;

  border-radius: 25px;
  background: rgba(218, 46, 46, 0.66) !important;
  border-color: unset !important;
  color: var(--White, var(--light-100, #fff)) !important;
  text-align: center;
  font-family: Ak-Regular;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
}

.saveButton {
  display: flex;
  width: 113px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 25px;
  background: rgba(5, 190, 117, 0.5);

  color: var(--White, var(--light-100, #fff));
  text-align: center;
  font-family: Ak-Medium;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
}

.editButton {
  display: flex;
  width: 113px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 25px;
  background: rgba(10, 157, 255, 0.5);

  color: var(--White, var(--light-100, #fff));
  text-align: center;
  font-family: Ak-Medium;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
}
