.contentDescription {
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* or 133% */

  background: radial-gradient(
        49.73% 79.17% at 72.17% 12.5%,
        rgba(98, 206, 245, 0.75) 0%,
        rgba(102, 136, 255, 0.75) 50%,
        rgba(130, 53, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #7d8fa1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.Video iframe {
  width: 100%;
  height: 170px;
  border-radius: 15px;
}

.video .AngleTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
  padding-right: 25px;
  font-size: 18px;
  font-weight: 700;
  font-family: "Ak-Regular";
}

.horizontalScrolling .CardHomeVideo {
  margin: 10px 10px 0 0;
  width: 290px;
}

.CardHomeVideo .videoIframeBox {
  width: 100%;
  height: 145px;
  position: relative;
}

.CardHomeVideo .videoIframeBox .videoThumbnail {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.CardHomeVideo .videoPlayBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.CardHomeVideo .videoPlayButton {
  width: 40px;
  height: 40px;
  position: absolute;
  background: rgba(34, 35, 47, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.CardHomeVideo .videoPlayButton .icon-bofangqi-bofang {
  color: #fff;
  font-size: 18px;
  padding-left: 2px;
}

.AngleContent .ContentFrameReason p {
  padding-left: 25px;
  background: none;
  border: none;
  margin-top: 10px;
  background: radial-gradient(
        49.73% 79.17% at 72.17% 12.5%,
        rgba(98, 206, 245, 0.75) 0%,
        rgba(102, 136, 255, 0.75) 50%,
        rgba(130, 53, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #7d8fa1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  -webkit-line-clamp: none;
}

.Studio-UI .video {
  margin-bottom: 25px;
}
