.blueprintMessagePanel {
  position: absolute;
  width: 200px;
  text-align: left;
  left: -10%;
  color: var(--Dark-35, #acaeb5);
  font-family: Ak-Regular;
  font-size: 11px;

  font-weight: 400;
  line-height: 16px;
}
