.VirtualAssistant {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0px 8px 16px 2px rgba(2, 12, 38, 0.05);
}

.VirtualAssistant video {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  border: 5px solid white;
}
