$pcWidth: 1180px;
$mobileWidth: 580px;

.chatAiPage {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .chatAiShareFooterUrl {
    flex-wrap: wrap;
    padding: 20px 40px;
  }
}

.canvasSharePage {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: var(
    --chatBackground,
    linear-gradient(180deg, #f3f5f6 22.4%, #dee1e3 81.25%)
  ); // 背景色为普通不渐变颜色，background-image这里也需要写成写为渐变色格式，确保能生效
  background-repeat: no-repeat;
  background-color: var(--renderingTransitionColors);
  background-size: 100% 100%;
  .shared-logo-box {
    position: relative;
    margin-bottom: 30px;
    left: unset;
    top: unset;
    cursor: default;
  }
}
.shared-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--chatBackground, linear-gradient(180deg, #f3f5f6 22.4%, #dee1e3 81.25%));
  background-repeat: no-repeat;
  background-color: var(--renderingTransitionColors);
  transition: 0.1s;
  background-size: 100% 100%;
  z-index: 9999;
  padding: 0;
  margin: 0;
}
.chatShareBox {
  flex: 1;
  padding: 60px 0 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--fontFamily);
}
.shared-logo-box {
  position: absolute;
  left: 65px;
  top: 30px;
  cursor: pointer;
}
.shared-logo {
  width: 54px;
  height: 40px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/chatAILogo-PC.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  filter: brightness(var(--logoBrightness, 0));
}
.logoBoxImg {
  max-width: 90px;
  max-height: 90px;
  object-fit: cover;
}
.shared-container {
  position: relative;
  align-self: center;
  width: 100%;
  max-width: 880px;
  height: 100%;
}
// padding row 用于盒子内部撑开边距
.paddingRow {
  padding: 0 75px;
}
// margin row 和 paddingRow 边距一样,但是 brainLogo 之前的样式是绝对定位
// padding 无法修改他的位置,所以只能用 margin, 如果 brainLogo 样式重新,可以把 marginRow 去掉
.marginRow {
  margin-left: 75px;
  margin-right: 75px;
}
.shared-assistant {
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/chatAIAssistant-PC.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}
.assistant-align {
  width: 40px;
  height: 40px;
  align-self: flex-start;
  position: absolute;
  left: -70px;
  top: 0;
}
.shared-body-box {
  width: 100%;
  display: flex;
  // min-height: calc(100% - 100px);
  flex-direction: column;
  margin: 0 auto;
  // justify-content: center;
  position: relative;
  min-height: 100%;
  padding-top: 100px;
}
.shared-title-box {
  width: 100%;
  max-width: inherit;
  color: var(--shareTitleColor, "#12192b");
  margin-bottom: 20px;
  position: relative;
  textarea {
    color: var(--shareTitleColor);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  :global(.ant-input[disabled]) {
    color: inherit;
  }
}
.inputBar {
  background: transparent;
  border: none;
  border-left: 5px solid #12192b1a;
}
:global(.ant-input:focus) {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
:global(.ant-input:hover) {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.titleBox {
  // from toolbar position
  position: relative;
  font-family: var(--fontFamily, "ak-regular");
  font-weight: 800;
  min-height: 30px;
}
.shared-title {
  font-family: var(--fontFamily, "ak-regular");
  font-weight: 700;
  font-size: 68px;
  line-height: 75px;
  letter-spacing: -0.68px;
  text-align: left;
  padding: 0;
}
.subTitleBox {
  position: relative;
  margin-top: 20px;
  position: relative;
  // 保证在没有内容的时候也能显示
  min-height: 30px;
}
.hoverBorderWrap {
  border-top: 1px dashed transparent;
  border-bottom: 1px dashed transparent;
  &:hover {
    border-color: #0a9dff;
  }
}
.shared-subtitle {
  font-family: var(--fontFamily, "ak-regular");
  font-weight: 800;
  font-size: 26px;
  line-height: 30px;
  text-align: left;
  padding: 0;
  color: inherit;
}

.chatFooterBrain {
  width: 100%;
  max-width: 880px;
  // margin-top: 10px;
  // padding: 0 75px;
}

.footer {
  position: relative;
  .chatFooterImagica {
    padding: 36px 75px;
  }
  .chatAIFooterText {
    padding-top: 10px;
    height: 35px;
    min-height: 35px;
  }
  .chatFooter {
    font-family: var(--fontFamily, "ak-regular");
    font-size: 16px;
    line-height: 35px;
    text-align: left;
    color: var(--uploadLimitSizeColor, "#12192b59");
    text-align: left;
    // padding-bottom: 15px;
  }
}

.addContentWrap {
  margin-right: -40px;
  margin-left: -40px;
  padding-bottom: 20px;
}

// pad
@media only screen and (min-width: $mobileWidth) and (max-width: $pcWidth) {
  .shared-title-box {
    margin-top: 70px;
  }
  .shared-title {
    font-family: var(--fontFamily, "ak-regular");
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
  }
  .shared-subtitle {
    font-size: 20px;
    font-family: var(--fontFamily, "ak-regular");
    font-weight: 500;
    line-height: 30px;
  }
  .shared-page {
    background-image: var(--chatBackgroundPad);
    transition: 0.1s;
  }
  .assistant-align {
    position: absolute;
    top: -70px;
    left: 0;
  }
}

@mixin mobileStyle {
  .footer {
    left: 0;
    .chatFooter {
      font-size: 13px;
      line-height: 35px;
    }
  }
  .chatShareBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .shared-logo-box {
    position: relative;
    left: unset;
    top: unset;
    margin-bottom: 30px;
  }
  .shared-container {
    flex-direction: column;
    // width: 420px;
    // bsf-6217
    // padding: 0 20px;
  }
  .paddingRow {
    padding: 0 20px;
  }
  .marginRow {
    margin-left: 20px;
    margin-right: 20px;
  }
  .assistant-align {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    position: relative;
    top: unset;
    left: unset;
    // position: absolute;
    // top: -70px;
    // left: 0;
  }
  .shared-title-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .shared-title {
    font-family: var(--fontFamily, "ak-regular");
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
  }
  .shared-subtitle {
    font-family: var(--fontFamily, "ak-regular");
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }

  .chatFooterBrain {
    width: 420px;
    max-width: 100%;
    text-align: left;
    padding: 0;
  }
  .shared-page {
    background-image: var(--chatBackgroundMobile);
    transition: 0.1s;
  }
}
.canvasSharePage {
  @include mobileStyle;

  // .shared-container {
  //   // bsf-6217
  //   // padding: 0 40px;
  // }
  .paddingRow {
    padding: 10px 40px;
  }
  .marginRow {
    margin-left: 40px;
    margin-right: 40px;
  }
}
// mobile
@media only screen and (max-width: $mobileWidth) {
  @include mobileStyle;
}

.account {
  top: 30px;
  right: 70px;
  position: absolute;
}

.chatOutputWrap {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chatWrap {
  min-height: 100%;
  flex: 1;
  display: flex;
}

@media (min-width: 768px) and (max-width: 1920px) {
  .account {
    right: 50px;
  }
  .shared-logo-box {
    left: 45px;
  }
}

@media (min-width: $mobileWidth) and (max-width: 768px) {
  .shared-logo-box {
    top: 20px;
    left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .account {
    top: 20px;
    right: 20px;
  }
}

.hoverPlaceholder {
  opacity: 0.3;
}
