.appItem {
  width: 100%;
  padding: 20px;
  position: relative;
  border-radius: 20px;
  border: 0.5px solid rgba(18, 25, 43, 0.1);
  margin-bottom: 5px;
}

.appName {
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  padding-right: 25px;
}

.appDetail {
  width: 100%;
  position: relative;
  .content {
    line-height: 20px;
    font-size: 15px;
    margin-top: 5px;
    color: rgba(172, 174, 181, 1);
  }
  .contentEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .more {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.publishStatus {
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;
  color: rgba(172, 174, 181, 1);
}

.appCheckbox {
  top: 20px;
  right: 20px;
  position: absolute;
  :global(.ant-checkbox-inner) {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background: rgba(10, 157, 255, 0.1);
    border-color: rgba(10, 157, 255, 1);
  }
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background: rgba(10, 157, 255, 1);
  }
}
