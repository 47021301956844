.raycast {
  --max-height: 400px;
  --primary-color: rgba(2, 12, 38, 0.35);
  --second-color: rgba(18, 25, 43, 1);
  --bg-color: #fcfcfc;
  /* ui */
  /* --bg-color: rgba(123, 133, 142, 0.02); */

  /* position: absolute;
  top: 100%;
  left: 10px;
  margin-top: 10px; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* left: calc(100% + 20px); */
  max-height: var(--max-height);
  cursor: default;
}
@media screen and (min-height: 300px) {
  .raycast {
    --max-height: 240px;
  }
}
@media screen and (min-height: 600px) {
  .raycast {
    --max-height: 345px;
  }
}
@media screen and (min-height: 900px) {
  .raycast {
    --max-height: 420px;
  }
}

.raycast [cmdk-root] {
  width: 342px;
  border-radius: 20px;

  color: var(--primary-color);
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  background-color: var(--bg-color);
  border: 0.5px solid rgba(2, 12, 38, 0.05);

  max-height: inherit;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.raycast [cmdk-root] .commandbar-head {
  flex: 1;
}

.raycast [cmdk-list] {
  /* overflow: auto; */
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  scroll-padding-block-end: 40px;
  transition: 0.1s ease;
  transition-property: height;
}

.raycast [cmdk-group-heading] {
  user-select: none;
  font-size: 12px;
  color: #6f6f6f;
  padding-left: 10px;
  margin-bottom: 10px;

  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.raycast [cmdk-input] {
  border: none;
  font-size: 15px;
  outline: none;
  background: var(--bg-color);
  max-width: 216px;
  color: var(--second-color);
}

.raycast [cmdk-input]::placeholder {
  color: var(--primary-color);
}

.raycast [cmdk-item][aria-selected="true"] {
  background: rgba(10, 157, 255, 0.1);
  color: #12192b;
}

.disableHover [cmdk-item][aria-selected="true"] {
  background: var(--bg-color);
  color: #171717;
}

.commandbar-item-inner {
  display: flex;
  align-items: center;
}

.commandbar-item-icon {
  width: 40px;
  /* STUD-992 函数显示过长 */
  min-width: 40px;
  height: 40px;
  margin-right: 20px;
  /* padding: 8px; */
  border-radius: 5px;
  border: 1px solid rgba(2, 12, 38, 0.1);
  background: #fff;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commandbar-item-icon img {
  max-width: 24px;
}

.raycast [cmdk-item][aria-selected="true"] .commandbar-item-icon {
  border: 1px solid transparent;
}

.disableHover [cmdk-item][aria-selected="true"] .commandbar-item-icon {
  border: 1px solid rgba(2, 12, 38, 0.1);
}

:global(.CustomDropdown_raycast__ghZtB [cmdk-list]) {
  -webkit-overflow-scrolling: touch;
}

:global(.CustomDropdown_raycast__ghZtB [cmdk-list])::-webkit-scrollbar {
  display: none;
}

:global(.CustomDropdown_raycast__ghZtB [cmdk-group-heading]) {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  /* font-family: "Ak-Regular"; */
}

/* commandbar-head */
.commandbar-head {
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid rgba(2, 12, 38, 0.05);
  min-height: 58px;
}

.commandbar-head .searchInput-icon {
  margin-right: 10px;
  padding: 5px;
}

.commandbar-head .searchInput {
  text-overflow: ellipsis;
}

.commandbar-content {
  flex: 1 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

/* commandBar-list */
.commandScroll {
  /* 充满父节点高度 */
  min-height: 100%;
  --scrollbar-size-x: 5px;
  /* --scrollbar-size-y: 120px; */
  --scrollbar-size-color: rgba(2, 12, 38, 0.1);
}

.commandScroll :global(.simplebar-vertical .simplebar-scrollbar) {
  border-radius: 5px;
  cursor: pointer;
}

.commandScroll :global(.simplebar-vertical .simplebar-scrollbar::before) {
  width: var(--scrollbar-size-x);
  background-color: var(--scrollbar-size-color);
}

.raycast [cmdk-list] {
  /* max-height: 393px; */
  /* padding: 10px 5px 10px 10px; */
  padding: 0 10px;
}

.raycast [cmdk-group] {
  margin-top: 10px;
}

.raycast [cmdk-group]:first-child {
  margin-top: 0;
}

.commandbar-item {
  min-height: 60px;
  /* max-height: 70px; */
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.commandbar-item-content {
  max-width: 100%;
}

.commndbar-item-label {
  font-family: ak-Regular;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--second-color);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: left;
  /* 纯数字时需要换行 */
  /* word-break: break-word; */
}

.commndbar-item-desc {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary-color);
}

/* STUD-992 函数显示过长 */
.commndbar-item-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  /* 纯数字时需要换行 */
  word-break: break-word;
}

.commandbar-blue-print {
  padding: 0 10px;
}

.commandbar-suggestions-loading {
  min-height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commndbar-suggestions-item {
  min-height: 20px;
  /* max-height: 70px; */
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.commndbar-suggestions-item-label {
  font-family: ak-regular;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--second-color);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: left;
  /* 纯数字时需要换行 */
  /* word-break: break-word; */
}

.selected-blueprint-list {
  margin-bottom: 10px;
}
