.sortable {
  position: relative;
  &:hover .dragBar > div {
    // opacity: 1;
  }
}

.dragBar {
  height: 100%;
  position: absolute;
  left: -30px;
  top: 0px;
  z-index: 100000;
  border-radius: 20px;
  > div {
    // opacity: 0;
    width: 20px;
    height: 100%;
    cursor: all-scroll;
    margin-right: 30px;
    background: red;
    border-radius: 20px;
    background: rgba(142, 112, 112, 0.168627451);
  }
}
