.container {
  width: 100%;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 20px;
  }
  .img {
    width: 60px;
    height: 60px;
    border-style: none;
    border-radius: 30px;
  }
  .buttonContaer {
    display: flex;
    flex-direction: row;
  }
  .updateBtn {
    color: rgba(18, 25, 43, 0.65);
    min-width: 80px;
    /* Caption [M] */
    font-family: Ak-Regular;
    font-size: 13px;

    font-weight: 500;
    padding: 0 15px;
    border-radius: 15px;
    background: rgba(2, 12, 38, 0.05);
    border-style: none;
    height: 30px;
    line-height: 30px;
  }
  .deleteBtn {
    border-radius: 25px;
    border-style: none;
    background: rgba(2, 12, 38, 0.05);
    margin-left: 15px;
    display: flex;
    height: 30px;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .description {
    position: relative;
    color: rgba(18, 25, 43, 0.35);
    /* Caption */
    font-family: Ak-Regular;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
}
.editContainer {
  margin-top: 20px;
  position: relative;
  .title {
    color: rgba(18, 25, 43, 0.65);
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .edit {
    height: 50px;
    margin: 5px 0 0;
    padding: 15px 20px;
    border-radius: 10px;
    background: rgba(2, 12, 38, 0.05);
    width: 100%;
    box-sizing: border-box;
    border-style: none;
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .edit:focus {
    border-style: none;
    outline: none;
  }
  .edit.error:focus {
    border-style: solid;
    border: 1px solid rgba(255, 51, 92, 0.35);
  }
  .editIcon {
    position: absolute;
    top: calc(50% + 2.5px);
    transform: translate(0, -50%);
    right: 15px;
  }

  .error {
    border-radius: 10px;
    border: 1px solid rgba(255, 51, 92, 0.35);
    background: #fff;
  }
  .errorText {
    color: #ff335c;
    /* Caption */
    font-family: Ak-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    margin-top: 5px;
    font-stretch: normal;
  }
}

.bottom {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  bottom: 30px;
  right: 30px;
  left: 230px;
  .delete {
    color: #ff335c;
    /* Body */
    font-family: Ak-Regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-style: none;
    background: transparent;
  }
  .save {
    height: 40px;
    padding: 10px 30px;
    border-radius: 25px;
    box-shadow: 0 8px 16px 2px rgba(10, 157, 255, 0.15);
    background-color: #0a9dff;
    border-style: none;

    color: #fff;
    text-align: center;
    /* Body [M] */
    font-family: Ak-Regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    //align-content: space-between
  }
  .disableSave {
    @extend.save;
    background: rgba(2, 12, 38, 0.05);
    box-shadow: 0 8px 16px 2px #00000001;
    color: lightgrey;
  }
}

.phoneEmailContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loaderModal :global(.ant-modal-content) {
  background: transparent;
  box-shadow: unset;
}
.loaderModal :global(.ant-modal-footer) {
  display: none;
}
.loader {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
