.imgBox {
  border-radius: 20px;
  overflow: hidden;
  border: 0.5px solid var(--Dark-10, rgba(18, 25, 43, 0.1));
  img {
    object-fit: cover;
    // 抗img锯齿和模糊
    image-rendering: -webkit-optimize-contrast;
  }
}

.c1Img,
.generatedImg {
  width: 100%;
  height: 225px;
  left: 0;
  top: 0;
  img {
    width: 100%;
    height: 100%;
  }
  .errorImg img {
    transform: translateY(-100%);
  }
}
.c1Img.shrinked {
  position: absolute;
}
.c2CarouselImg {
  height: 100%;
  border: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
}
.c2CarouselErrorImg {
  height: 100%;
}
.c3Img {
  width: 50%;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.c3Img.expanded {
  max-height: 225px;
}
.c4Img {
  width: 20%;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.c4Img.expanded {
  max-height: 225px;
}
.profileCardImg {
  width: 80px;
  padding: 10px 0;
  border: none;
  img {
    width: 100%;
    height: 80px;
    border-radius: 50%;
  }
}
.topicWithRelatedPeoplePersonInfoImg {
  border: none;
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 24px;
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 0.6;
  }
}
.topicWithRelatedPeopleSignificanceImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 44px;
  min-height: 250px;
  .errorImg {
    aspect-ratio: 0.6;
  }
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 0.6;
  }
}
.touristAttractionPlaceImg {
  width: calc((100% - 4px) / 3);
  height: 96px;
  border-radius: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.itineraryPlanLogoImg {
  border: none;
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.youtubeSearchResultsImg {
  border: none;
  width: 105px;
  height: 100%;
  border-radius: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.newsVideoListThumbnailImg {
  border: none;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.dishRecommendationImg,
.restaurantRecommendationImg {
  width: 96px;
  height: 96px;
  border: none;
  border-radius: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.newsDigestImg {
  border: none;
  height: 328px;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.newsHeadlineImg {
  border: none;
  img {
    width: 100%;
    display: block;
    aspect-ratio: 376/256;
  }
}
.shoppingProductIconImg {
  border: none;
  img {
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 20px;
  }
}
.shoppingResultsIconImg {
  border: none;
  width: 94px;
  height: 94px;
  border-radius: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.imageNewsGridImg {
  width: 100%;
  height: 100%;
  border-radius: 44px;
  img {
    width: 100%;
    height: 100%;
  }
}

// errorImg类需要写在最下面
.errorImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 157, 255, 0.35);
  .errorImg {
    aspect-ratio: 0.6;
  }
  img {
    width: 30px;
    height: 30px;
  }
}
.itineraryPlanLogoImg .errorImg img {
  width: 15px;
  height: 15px;
}
.shoppingProductIconImg .errorImg {
  min-height: 200px;
  img {
    border-radius: 0;
  }
}
.newsHeadlineImg .errorImg {
  min-height: 320px;
}
.profileCardImg .errorImg {
  height: 80px;
  border-radius: 50%;
  img {
    border-radius: 0;
  }
}
.c2CarouselImg .errorImg img {
  border-radius: 0%;
}

.img-preview-modal :global(.ant-modal) {
  top: 110px;
  width: 100% !important;
  max-width: 780px !important;
  padding-bottom: 0;
}

.img-preview-modal :global(.ant-modal-content) {
  background-color: unset;
  border-radius: 0 none;
  box-shadow: unset;
}

.img-preview-modal :global(.ant-modal-body) {
  padding: 0;
}

.img-preview-modal :global(.ant-modal-footer) {
  display: none;
}

.img-preview-modal :global(.ant-modal-close-x) {
  color: #12192b;
  font-size: 14px;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  margin: 20px 20px 0 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}

.previewImg {
  /* width: 780px;
  height: 780px; */
  border-radius: 20px;
  width: 100%;
  max-height: 780px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.imgAction {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  .downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: linear-gradient(
        0deg,
        var(--surfaces-transparent, rgba(255, 255, 255, 0.6)) 0%,
        var(--surfaces-transparent, rgba(255, 255, 255, 0.6)) 100%
      ),
      var(--surfaces-faded-medium-inverted, rgba(18, 25, 43, 0.1));
    // backdrop-filter: blur(20px);
    cursor: pointer;
  }
}
