.Chain-Thought-Box {
  position: absolute;
  width: 100%;
  z-index: 9;
  height: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f5faff 100%);
}
.Chain-Thought-Box header {
  padding: 23px 10px 25px;
  width: 100%;
  height: 70px;
  position: relative;
}
.Chain-Thought-Box header p {
  text-align: center;
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #22232f;
}
.Chain-Thought-Box .icon-guanbi1 {
  position: absolute;
  right: 15px;
  top: 20px;
  font-size: 24px;
  cursor: pointer;
}
.Thought-List {
  height: calc(100% - 70px);
  padding: 0 10px 100px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.Thought-List::-webkit-scrollbar {
  display: none;
}
.Thought-Item {
  background: #f2f5f8;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 45px 20px 45px 25px;
  margin-bottom: 10px;
  cursor: pointer;
}
.Thought-Item .Item-Active {
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
}
.Thought-Item p {
  font-family: "sf-Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.38px;
  color: #22232f;
}
.Thought-Item .Item-Active p {
  font-family: "Ak-Regular";
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #22232f;
}
