.chatBox {
  // flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 30px 60px 0px rgba(123, 133, 142, 0.15);
  backdrop-filter: blur(20px);
}

.chatBoxMobile {
  border-radius: 0;
  background: rgb(209, 214, 223);

  .chatHeader {
    display: none;
  }

  .chatboxContent {
    height: 100%;
  }

  .chat-input-box {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-input {
    margin: 0;
    font-size: 15px;
  }

  .chat-send-box {
    width: 20px;
    svg {
      width: 20px;
    }
  }

  .chatboxFooter {
    border-radius: 0;
    background: rgb(224, 226, 232);
  }

  .chatboxContent .chatboxList .chatboxItem .chatboxItemWrapper .content {
    .chatboxRole,
    .chatboxText {
      font-size: 15px;
    }
  }
}

.chatBoxMobileInputBg {
  background: rgb(209, 214, 223);
  .chatboxFooter {
    background: rgb(224, 226, 232);
  }
}

.chatBoxMobileInput {
  z-index: 3;
  background: transparent;
  backdrop-filter: none;
  height: 0;
  .chatHeader {
    display: none;
  }
  .chatboxContent {
    height: 0;
    min-height: 0;
  }
  .chatboxListContainer {
    display: none;
  }
  .chatboxFooter {
    bottom: 0px;
    position: fixed;
  }
}

.mgt20 {
  margin-top: 20px;
}
.type-title {
  color: rgba(2, 12, 38, 0.35);
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  padding: 10px 10px 10px 15px;
  height: 40px;
  line-height: 20px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.max-chatbox {
  max-width: 500px;
  max-height: 372px;
}
.chatbox-item {
  display: flex;
  padding: 15px 5px;
  user-select: text;
}
.chatbox-assistant-item {
  background: #f9f9f9;
}
.chatbox-type {
  min-width: 75px;
  font-family: var(--fontFamily, "Ak-Regular");
  color: #999;
  margin-right: 10px;
  line-height: 25px;
}
.chatbox-text {
  font-family: var(--fontFamily, "Ak-Regular");
  font-weight: 500;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 25px;
}
.chatbox-text pre {
  background: #000;
  position: relative;
  white-space: break-spaces;
  word-wrap: break-word;
}
.chatbox-text code {
  color: #ccc;
}

.copy {
  position: absolute;
  top: 0;
  right: 5px;
}

.copy span {
  color: #ccc;
  cursor: pointer;
}

.preview-code {
  margin: 25px;
  height: calc(100% - 50px);
  background: #ffffff;
  box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
  border-radius: 15px;
  padding: 5px;
}
.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.delete-content img {
  width: 100%;
  height: 100%;
}

:global(.dark),
:global(.dark) .chatbox-assistant-item {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

:global(.dark) .chatbox-content {
  background: none;
}

:global(.dark .ant-btn-default) {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}
:global(.dark .ant-btn-default[disabled]) {
  color: #ffffff52;
}

:global(.dark .ant-btn-default:hover) {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

:global(.dark .ant-btn-default[disabled]:hover) {
  color: #ffffff52;
}

:global(.dark .ant-input-affix-wrapper) {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}
:global(.dark .ant-input-affix-wrapper:hover) {
  border-color: #fff;
  box-shadow: none;
}
:global(.dark .ant-input) {
  color: #ffffff;
  background: none;
  border: none;
  box-shadow: none;
}
:global(.dark .ant-input-affix-wrapper .anticon svg) {
  fill: #fff;
}

.chatHeader {
  padding: 20px;
  display: flex;
  height: 60px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(142, 239, 234, 0.2) 0%,
    rgba(91, 200, 236, 0.2) 25%,
    rgba(160, 147, 244, 0.2) 50%,
    rgba(242, 134, 209, 0.2) 75%,
    rgba(250, 145, 145, 0.2) 100%
  );
  .chatHeaderTitle {
    color: #12192b;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    position: absolute;
    left: 20px;
  }
}

.Icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  background-size: cover;
}

.closeIcon {
  right: 20px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/ACAEB5Cross.svg");
}

.collapseIcon {
  right: 50px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/collapseIcon.svg");
}

.expandIcon {
  right: 50px;
  background-image: url("https://dopniceu5am9m.cloudfront.net/static/assets/230705/expandIcon.svg");
}

.chatboxContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  height: calc(100% - 60px);
  min-height: 370px;
  width: 100%;
  .chatboxListContainer {
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;
    width: calc(100% - 10px);
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #020c261a;
      border-radius: 5px;
    }
    .chatbox-list::-webkit-scrollbar {
      display: none;
    }
  }
  .chatboxList {
    width: 100%;
    flex: 1;
    max-width: 950px;
    margin: 0 auto;
    // height: calc(100% - 70px);
    padding: 20px 20px 8px;
    -webkit-overflow-scrolling: touch;
    .chatboxItem {
      display: inline-block;
      padding: 10px 30px 10px 10px;
      border-radius: 25px;
      font-size: 15px;
      line-height: 20px;
      color: #12192b;
      border: 0.5px solid var(--Light-100, #fff);
      background: rgba(255, 255, 255, 0.65);

      box-shadow: 0px 8px 16px 0px rgba(144, 147, 169, 0.08);
      .chatboxItemWrapper {
        display: flex;
        justify-content: flex-start;
        .userLogo {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background-color: #999;
        }
        .content {
          margin-left: 20px;
          padding: 5px 0;
          &TitleBar {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;
            .talkBuilderTag {
              border-radius: 10px;
              padding: 0 10px;
              background-color: #0a9dff;
              line-height: 20px;
              color: white;
              font-size: 11px;
              font-weight: 600;
            }
          }
          .chatboxTitle {
            font-weight: 500;
            line-height: 20px;
            font-size: 15px;
            color: #12192b;
            margin-bottom: 15px;
            font-family: "Ak-Regular";
          }
          .chatboxRole {
            font-weight: 600;
            font-size: 18px;
            color: #12192b;
            font-family: "Ak-Regular";
          }
          .chatboxText {
            line-height: 25px;
            font-size: 18px;
            font-family: "Ak-Regular";
            white-space: pre-wrap;
            user-select: text;
            word-break: normal;
            ul {
              display: grid;
              gap: 5px;
              list-style: disc;
            }
          }
        }
      }
    }
    .regenerate {
      display: inline-flex;
      align-items: center;
      padding: 10px 20px 10px 10px !important;
      .txt {
        margin-left: 10px;
      }
    }
  }
}

.suggestionWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.suggestion {
  margin: 5px 5px 0px;
  padding: 10px 20px !important;
  max-width: 910px;
  cursor: pointer;
  border-color: #0a9dff !important;

  &:hover {
    background: var(--Dark-5, rgba(2, 12, 38, 0.05)) !important;
  }
  &:active {
    background: var(--Blue-10, rgba(10, 157, 255, 0.1)) !important;
  }
}

.buildButtonLoading {
  margin-right: 10px;
}

.actionBox {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  .button {
    margin-right: 20px;
    display: inline-block;
    height: 40px;
    line-height: 30px;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    border-radius: 25px;
    &:hover {
      box-shadow: 0px 25px 50px rgb(0 0 0 / 5%);
    }
  }
  .buttonDefault {
    border: 0.5px solid #e6f5ff;
    background: #e6f5ff;
  }
  .buttonPrimary {
    color: #fff;
    background: linear-gradient(90deg, #8eefea 0%, #5bc8ec 25%, #a093f4 50%, #f286d1 75%, #fa9191 100%);
  }
  .buttonLoading {
    cursor: no-drop;
  }
}

.chatboxFooter {
  width: 100%;
  // height: 70px;
  text-align: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(20px);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  position: relative;
  .chatboxInput {
    padding: 20px;
    line-height: 30px;
    border: none;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.processBar {
  width: 420px;
  display: flex;
  justify-content: center;
  gap: 10px;

  .step {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .stepLine {
    width: 50px;
    height: 0;
    border: 1px solid #0a9dff;

    &_disabled {
      border: 1px solid #020c260d;
    }
  }

  .stepContent {
    font-weight: 600;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #12192ba6;
    line-height: 20px;
    font-size: 15px;
  }

  .stepIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bde3ff;
    color: #0a9dff;

    &_disabled {
      background-color: #020c260d;
      color: #020c2659;
    }
  }

  .stepTitle_disabled {
    color: #020c2659;
  }
}

.collapsed {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: calc(100% - 30px);
}

.action {
  color: #fff !important;
  background: linear-gradient(90deg, #8eefea 0%, #5bc8ec 25%, #a093f4 50%, #f286d1 75%, #fa9191 100%) !important;
  border: unset !important;
  &:hover {
    background: linear-gradient(90deg, #8eefea 0%, #5bc8ec 25%, #a093f4 50%, #f286d1 75%, #fa9191 100%) !important;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.chat-input-box {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  .tag {
    white-space: nowrap;
    background: rgba(10, 157, 255, 1);
    height: 30px;
    padding: 5px 5px 5px 15px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    .txt {
      font-family: "Ak-Regular";
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
    }
    .close {
      width: 20px;
      height: 20px;
      background: rgba(2, 12, 38, 0.05);
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      &:active {
        background: rgba(18, 25, 43, 0.65);
      }
    }
  }
}

.chat-input-box .chat-input::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.chat-input-box .chat-input::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}

.chat-input-box .chat-input::-webkit-scrollbar-track {
  -webkit-box-shadow: none; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.chat-input {
  margin: 25px;
  padding: 0;
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 16px;
  border: none;
  resize: none;
  outline: none;
  line-height: 20px;
  box-shadow: none;
  &:focus {
    box-shadow: none;
    border: none;
  }
  /* @include themeify {
    color: themed(chatInputTextColor);
  } */
  color: var(--chatInputTextColor, #12192b);
  width: calc(100% - 40px);
  background: transparent;
  &::placeholder {
    /* @include themeify {
      color: themed(chatInputTextPlaceholder);
    } */
    color: var(--chatInputTextPlaceholder, rgba(2, 12, 38, 0.35));
  }
}

.disableInputBox {
  background: rgba(223, 224, 228);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  .chat-input {
    background: rgba(223, 224, 228);
    cursor: not-allowed !important;
  }
}

.chat-input-box:focus {
  border: none;
  resize: none;
  outline: none;
  box-shadow: none !important;
}

.chat-send-box {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentError {
  .errorTitle {
    color: #b92c2c;
  }
}

.options {
  width: 100%;
  bottom: calc(100% + 20px);
  left: 0;
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 5vw;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  .optionItem {
    flex-shrink: 0;
    width: 60vw;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 20px;
    font-family: "Ak-Regular";
    font-size: 14px;
    line-height: 20px;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
  }
  .optionItem:not(:last-child) {
    margin-right: 5vw;
  }
}
