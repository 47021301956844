.commandbar-blue-print-button {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.commandbar-blue-print-list {
  padding: 0 10px;
}

.commandbar-item {
  /* max-height: 70px; */
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.logo-box {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 20px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgba(2, 12, 38, 0.1);
  background: #fff;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue-item {
  margin-bottom: 8px;
  color: #656a75;
  /* Caption */
  font-family: "Ak-Regular";
  font-size: 13px;

  font-weight: 400;
  line-height: 20px;
}
