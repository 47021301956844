.box {
}
.itemBox {
  position: relative;
  border-radius: 44px;
  background-color: white;
  position: relative;
  .back {
    position: absolute;
    right: 0;
    top: 0;
    padding: 25px 25px 16px 16px;
  }
  .iconBox {
    padding: 28px 28px 0;
    position: relative;
  }
  .contentBox {
    padding: 16px 24px 24px;
    .title {
      margin-top: 44px;
      overflow: hidden;
      color: var(--Text-Default, #12192b);
      text-overflow: ellipsis;
      /* Nat 2.0/Subhead */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .priceBox {
      margin-top: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .price {
        overflow: hidden;
        color: var(--Text-Default, #12192b);
        text-overflow: ellipsis;
        /* Nat 2.0/H3 M */
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 125% */
      }
    }
    .payBtn {
      margin-top: 148px;
      display: flex;
      height: 50px;
      padding: 6px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      width: 100%;
      border-radius: 25px;
      background: #12192b;
      border-style: none;
      color: var(--Text-Default-Inverted, #fff);
      text-align: center;
      /* Nat 2.0/Subhead M */
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
    .merchantBox {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .merchantName {
        margin-left: 5px;
        overflow: hidden;
        color: var(--Text-Description, rgba(18, 25, 43, 0.65));
        text-overflow: ellipsis;
        /* Nat 2.0/Body */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .amazonIcon {
      }
    }
  }
}
