.textP {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3;
  overflow: hidden;
  color: inherit;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}
.textP.shrinked {
  -webkit-line-clamp: 3;
}
.c1Paragraph {
  // -webkit-line-clamp: 2;
  // color: #fff;
  letter-spacing: 1px;
}
.c1Paragraph.shrinked {
  -webkit-line-clamp: 2;
  color: #fff;
}
