.model-box {
  z-index: 2;
  position: absolute;
  top: 46px;
  bottom: 0;
  min-height: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 34px 0 54px;
}

.model-box :global(.ant-spin-nested-loading) {
  height: 100%;
}

.model-box :global(.ant-spin-container) {
  height: 100%;
}

.modal-box {
  min-width: 430px;
  max-width: 100%;
  padding: 20px 10px;
  position: relative;
  border: 1px solid #333;
  border-radius: 4px;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 0 5px #eee;
  user-select: none;
  height: 100%;
}

.modal-box::-webkit-scrollbar {
  display: none;
}

.button-box {
  text-align: right;
  padding-top: 13px;
  border-top: 1px solid #eee;
}

.textarea-box {
  width: 100%;
  max-width: 100%;
  height: 120px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  resize: auto !important;
}

.textarea-box:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.textarea-box:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
  outline: 0;
}

.adaptive-height {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 40px);
  -webkit-overflow-scrolling: touch;
}

.maxTokens-checkbox {
  margin-top: -10px;
}

@media only screen and (max-width: 767px) {
  .modal-box {
    min-width: auto;
    width: 100%;
    margin: 0 20px;
  }
}
