.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.loading-box :global(.ant-spin) {
  line-height: unset;
}
.loading-box:global(.uploadfile-playtime) {
  position: relative;
  justify-content: flex-start;
}
