.textStyle {
  position: relative;
  padding: 20px 0 10px;
  transition: opacity 0.2s;

  .header {
    font-family: Ak-Regular;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: rgba(18, 25, 43, 1);
  }

  .des {
    margin-top: 5px;
    font-family: Ak-Regular;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
    color: rgba(18, 25, 43, 0.65);
  }

  .option {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    min-height: 40px;
    .item {
      border-radius: 10px;
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        outline: 2px solid #0a9dff;
        background: #f3f3f4;
        img {
          filter: drop-shadow(#656a75 40px 0);
        }
        p {
          color: #656a75;
        }
      }
    }
  }

  .font {
    position: relative;
    width: 70px;
    height: 40px;
  }
}

.textStyleCollapse {
  opacity: 0;
}

.textPopover {
  :global(.ant-popover-inner) {
    background-color: #12192b;
  }
  :global(.ant-popover-arrow-content) {
    background-color: #12192b;
    --antd-arrow-background-color: #12192b;
  }
}

.tooltipContainer {
  white-space: nowrap;
  color: white;
}
