.unsupportedBlueprintFlow {
  // width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}

.missingApiPanel {
  position: relative;
  flex: 0 0 270px;
  display: flex;
  width: 270px;
  height: 275px;
  padding: var(--spacing-spacing-small, 10px) 10px 10px 10px;
  flex-direction: column;
  align-items: center;
  border-radius: var(--radius-large, 25px);
  border: 0.5px solid var(--Dark-5, #f3f3f4);
  background: var(--light-100, #fff);
  margin: 20px 5px 40px;
}

.missingApiContent {
  width: 100%;
  padding: 10px;
}

.title {
  color: var(--Dark, var(--dark-100, #12192b));
  font-family: Ak-Regular;
  font-size: 15px;

  font-weight: 500;
  line-height: 20px; /* 133.333% */
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  color: var(--Dark-65, #656a75);
  font-family: Ak-Regular;
  font-size: 15px;

  font-weight: 400;
  line-height: 20px; /* 133.333% */
}

.desc {
  padding-top: 20px;
  color: var(--dark-65, rgba(18, 25, 43, 0.65));
  font-family: Ak-Regular;
  font-size: 13px;

  font-weight: 400;
  line-height: 16px; /* 123.077% */
  height: 150px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonWrapper :global(.ant-btn) {
  border-radius: 40px;
}

.unsupportedBlueprintFlowModel :global(.ant-modal-content) {
  background: var(--White-35, #f8f8f8);
}

:global(.a-modal) {
  z-index: 20000 !important;
}

.uploadFile {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: unset;
  box-shadow: unset;
  border: unset;
}

.uploadFile :global(.innerUpload) {
  padding: unset;
  margin-bottom: -5px;
}

.mainTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.titleIcon {
  margin-right: 20px;
}

.subTitleIcon {
  width: 15px;
  height: 15px;
}
