.CardHomeImage {
  width: auto;
  min-height: 190px;
  overflow: hidden;
  /* margin: 20px 0; */
  margin: 5px 0;
  border-radius: 15px;
  background: var(--imageBg, #fff);
  box-shadow: var(--nodeWrapperShadow, none);
  backdrop-filter: var(--nodeBackdropFilter, none);
  color: var(--imageColor, #12192b);
  display: flex;
  flex-direction: column;
}

/* .share {
  margin: 20px 25px;
} */

.CardHomeImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.CardHomeImage.empty-node-image {
  height: auto;
  margin: 0;
  box-shadow: none;
}
.ai-saas .CardHomeImage,
.thinking-app .CardHomeImage {
  grid-column: 1 / span 1;
  height: auto;
  border-radius: 30px;
}
.image-describe {
  padding: 15px;
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--imgTextColor, #12192b);
  height: auto;
  /* 为了保证多个换行符能解析成功 */
  white-space: pre-wrap;
}
.dark .image-describe {
  color: #fff;
}
