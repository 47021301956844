.phoneList {
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  display: flex;
  padding: 10px 10px 10px 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 0.5px solid var(--Dark-10, rgba(18, 25, 43, 0.1));
}
