.feedback-container {
  height: 40px;
  line-height: 40px;
  background: #fff;
  display: flex;
  padding: 0 0 0 10px;
  cursor: pointer;
  border-top: 0;
  // z-index: 2;
  z-index: 3;
  position: relative;
}
.feedback-group-container {
  height: 40px;
  display: flex;
  padding: 0 0 0 20px;
  cursor: pointer;
  border-radius: 0 0 30px 30px;
  border-top: 0;
  margin-top: -1px;
  z-index: 2;
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 20px;
}
.like {
  padding: 0 5px;
  margin-right: 5px;
}
.dislike {
  padding: 0 5px;
  margin-left: 5px;
}
.like-active {
  filter: invert(11%) sepia(95%) saturate(5491%) hue-rotate(245deg) brightness(91%) contrast(150%);
}
.dislike-active {
  filter: invert(16%) sepia(47%) saturate(5732%) hue-rotate(338deg) brightness(97%) contrast(92%);
}
.like-disable {
  filter: invert(75%) sepia(97%) saturate(0%) hue-rotate(317deg) brightness(102%) contrast(78%);
}
.lottie-animation {
  position: absolute;
  bottom: 0;
}
.feedback-content,
.feedback-content1 {
  position: relative;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback-content1 {
  left: -50px;
}
.img-hidden {
  opacity: 0;
}
