.container {
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  border-style: none;
}
.title {
  color: #12192b;
  /* Subheader */
  font-family: Ak-Regular;
  font-size: 17px;

  font-weight: 500;
  line-height: 150%;
}
.detail {
  color: rgba(18, 25, 43, 0.65);
  text-align: center;
  /* Body */
  font-family: Ak-Regular;
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
}
.done {
  margin-top: 30px;
  display: flex;
  height: 40px;
  padding: 5px 30px;
  justify-content: center;
  align-items: center;
  background: #0a9dff;
  box-shadow: 0px 8px 16px 2px rgba(10, 157, 255, 0.15);
  border-style: none;
  border-radius: 20px;

  color: #fff;
  text-align: center;
  /* Body [M] */
  font-family: Ak-Regular;
  font-size: 15px;

  font-weight: 500;
  line-height: 20px;
}
