.ThreeBotsLoading {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ThreeBotsLoading.layer-logic-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ThreeBotsLoading span {
  display: inline-block;
  border-radius: 50%;
  background: #e7edf4;
}
.BotOne {
  width: 20px;
  height: 20px;
  animation: botOneAni 1s infinite linear;
}
.BotTwo {
  width: 8px;
  height: 8px;
  margin: 0 10px;
  animation: botTwoAni 1s infinite linear;
}
.BotThree {
  width: 20px;
  height: 20px;
  animation: botOneAni 1s infinite linear;
}

@keyframes botOneAni {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes botTwoAni {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(1);
  }
}
