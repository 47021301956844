.imgListWrapper {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  .placeImages {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2px;
    align-self: stretch;
    .imagesCount {
      position: absolute;
      height: 96px;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, transparent 5%, rgba(0, 0, 0, 0.3));
      padding: 0 10px 0 15px;
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 125% */
    }
  }
  .fold {
    padding: 10px;
    text-align: center;
    .foldButton {
      cursor: pointer;
      user-select: none;
    }
  }
}
