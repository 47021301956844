.confirmModal {
  max-width: 400px;
  color: #12192b;
}

.confirmModal :global(.ant-modal-content) {
  border-radius: 15px;
  padding: 25px;
  text-align: center;
  font-family: "Ak-Regular";

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.confirmModal :global(.ant-modal-header) {
  border-radius: 15px 15px 0 0;
  border-bottom: none;
  padding: 0;
}

.confirmModal :global(.ant-modal-title) {
  font-family: "Ak-Regular";

  font-weight: 800;
  font-size: 17px;
  line-height: 150%;
}

.confirmModal :global(.ant-modal-close-x) {
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.confirmModal :global(.ant-modal-body) {
  padding: 10px 0 30px 0;
  font-size: 15px;
  line-height: 20px;
}

.confirmModal :global(.ant-modal-footer) {
  border-radius: 15px;
  padding: 0;
  border-top: 0;
  text-align: center;
  display: flex;
}

.confirmModal :global(.ant-modal-footer .ant-btn) {
  flex: 1;
  min-height: 40px;
  border-radius: 999px;
  font-size: 15px;
  font-weight: 500;
}

.confirmModal :global(.ant-modal-footer .ant-btn-default > span) {
  font-family: ak-regular;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: rgba(18, 25, 43, 0.65);
}

.confirmModal :global(.ant-modal-footer .ant-btn-default) {
  border: none;
  background-color: rgba(2, 12, 38, 0.05);
  color: rgba(18, 25, 43, 0.65);
}

.confirmModal :global(.ant-modal-footer .ant-btn-primary) {
  border: none;
  background-color: rgba(10, 157, 255, 1);
  color: rgba(255, 255, 255, 1);
}

.confirmModal {
  :global(.ant-btn-primary[disabled]),
  :global(.ant-btn-primary[disabled]:hover),
  :global(.ant-btn-primary[disabled]:focus),
  :global(.ant-btn-primary[disabled]:active) {
    color: rgba(2, 12, 38, 0.1);
    background: rgba(2, 12, 38, 0.05);
    text-shadow: none;
    box-shadow: none;
  }
}
