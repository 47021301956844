.sortable-item {
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    z-index: 10;
    position: relative;
    border-top: 1px dashed #0a9dff;
    border-bottom: 1px dashed #0a9dff;
  }

  &:focus,
  &:focus-within {
    border-top: 2px solid #0a9dff;
    border-bottom: 2px solid #0a9dff;
  }
}

.sortable-item.topIndex {
  z-index: 11;
}
