@keyframes showValue {
  from {
    opacity: 0;
    transform: scale(1, 0.5);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.value {
  // display: inline-block;
  // letter-spacing: .25em;

  animation-name: showValue;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-iteration-count: 1;
}
