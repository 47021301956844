.proceedPayment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.content {
  width: 100%;
  height: calc(100% - 60px);
  background: #d7d9e0;
  padding: 15px;
}
.tab {
  //styleName: Caption [M];
  font-size: 13px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  color: #12192ba6;
  cursor: pointer;
  margin: 15px auto 20px;
  display: flex;
  justify-content: center;
  div {
    width: 106px;
    height: 40px;
  }
}
.activePay {
  color: #8f79ff;
  border: 1px solid #8f79ff;
  border-radius: 20px;
}
.descriptipn {
  border-top: 1px solid #12192b0d;
  border-bottom: 1px solid #12192b0d;
  padding: 15px 0;
}
.priceRow {
  display: flex;
  justify-content: space-between;
  div {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #12192b;
  }
  .weight {
    font-weight: 600;
    margin-top: 10px;
  }
}
.discount {
  color: #12192ba6;
  margin-top: 5px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
}
.proceed {
  margin-bottom: 20px;
  background-color: #12192b;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footerDes {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #12192ba6;
}
