$inputColor: #12192b;
$placeholderColor: #a9a9a9;

.custom-node {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: var(--fontFamily, "Ak-Regular");
  background: #ffffff;
  /* border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 30px; */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 8px;
}
.reservedButtonHeight {
  margin-bottom: 50px;
}
.target {
  border-radius: 30px 30px 0 0;
  border-bottom: 0;
}
.custom-node textarea {
  outline: none;
  border: none;
  resize: none;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: none !important;
  background-color: #fff;

  font-size: 15px;
  line-height: 20px;

  font-weight: 400;
  font-family: var(--fontFamily, "Ak-Regular");
}

.text-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 15px;
  gap: 5px;
  position: absolute;
  height: 40px;
  left: 10px;
  right: 10px;
  top: 10px;
  background: rgba(2, 12, 38, 0.05);
  border: 0.5px solid rgba(2, 12, 38, 0.05);
  border-radius: 20px;
}
.text-title {
  font-family: var(--fontFamily, "Ak-Regular");

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(2, 12, 38, 0.35);
}

.delete-content {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
}

.text-content {
  width: calc(100% - 30px);
  background: #ffffff;
  border-radius: 10px;
  // height: calc(100% - 20px);
  height: calc(100% - 16px);
  margin: 0 10px;
  // padding: 15px;
  padding: 16px 15px 0;
  font-size: 13px;
  line-height: 20px;
  white-space: pre-wrap;
  position: absolute;
  bottom: 10px;
}

.copy-content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: rgba(2, 12, 38, 0.05);
  border-radius: 20px;
  padding: 12px;
  z-index: 12;
}
.copy-content img,
.delete-content img {
  width: 100%;
  height: 100%;
}

.input-mask {
  width: calc(100% - 30px);
  position: absolute;
  z-index: 10;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
}

.previewing {
  height: calc(100% - 70px);
}

.text-textArea {
  // 将底部空间剩余出来，可进入拖拽，100% 会将剩余内容充满导致无法拖动
  // height: auto;
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.text-textArea::-webkit-scrollbar {
  display: none;
}
.max-textarea-height {
  max-height: 300px;
}
.input-mask.hidden-mask {
  z-index: -1;
}

.alternate-text {
  font-size: 15px;
  line-height: 20px;

  font-weight: 400;
  font-family: var(--fontFamily, "Ak-Regular");
  white-space: pre-wrap;
}
.alternate-text.place-color {
  color: $placeholderColor;
}
.show-text-textArea::-webkit-scrollbar,
.text-content::-webkit-scrollbar,
.custom-api::-webkit-scrollbar,
.custom-node::-webkit-scrollbar,
.custom-node textarea::-webkit-scrollbar,
.input-mask::-webkit-scrollbar {
  width: 5px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}

.custom-node textarea::placeholder {
  color: $placeholderColor;
}

:global(.hidden-mask)::-webkit-scrollbar {
  display: none !important;
}

.show-text-textArea::-webkit-scrollbar-thumb,
.text-content::-webkit-scrollbar-thumb,
.custom-api::-webkit-scrollbar-thumb,
.custom-node::-webkit-scrollbar-thumb,
.custom-node textarea::-webkit-scrollbar-thumb,
.input-mask::-webkit-scrollbar-thumb {
  -webkit-box-shadow: node; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: rgba(2, 12, 38, 0.1); /* 滚动条整体背景，一般被覆盖着 */
}
.show-text-textArea::-webkit-scrollbar-track,
.text-content::-webkit-scrollbar-track,
.custom-api::-webkit-scrollbar-track,
.custom-node::-webkit-scrollbar-track,
.custom-node textarea::-webkit-scrollbar-track,
.input-mask::-webkit-scrollbar-track {
  -webkit-box-shadow: none; /* 滚动条轨道阴影 */
  border-radius: 10px;
  background-color: transparent; /* 滚动条整体背景，一般被覆盖着 */
}
.guide-textnode-disable.custom-node {
}
.guide-textnode-disable .text-box {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}
.guide-textnode-disable .text-content {
  width: 100%;
  margin: 0;
  position: relative;
  bottom: 0;
}
.guide-textnode-disable .text-textArea {
  max-height: unset;
}

.cotInput-right-box {
  background-color: #ffffff;
  padding: 20px 25px 5px;
  border-radius: 20px;
  width: 80%;
  position: absolute;
  right: -90%;
  top: 50%;
  margin-top: -25%;
  box-shadow: 0px 30px 60px rgba(123, 133, 142, 0.15);
  filter: drop-shadow(0px -5px 4px #eee);
}
.right-box-button {
  width: 100%;
  height: 40px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(2, 12, 38, 0.05);

  font-size: 15px;

  font-weight: 500;
  font-family: var(--fontFamily, "Ak-Regular");
  /* white-space: pre-wrap; */
  padding: 0;
  color: $inputColor;
  border-radius: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}
.right-box-button :global(.anticon) {
  vertical-align: 0;
}
.default-bot-box {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  transform: rotate(45deg);
  z-index: -1;
  left: -2px;
  top: calc(50% - 40px / 2 - 0.5px);
}

textarea:not(:focus) {
  cursor: pointer !important;
}
