.talkBuilderBorder {
  border-top: 1px dashed #0a9dff;
  border-bottom: 1px dashed #0a9dff;
}

.outputlabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0 20px 5px;
  color: var(--inputTitleColor, "#020C26");
}

.sortableItemMobile {
  &:hover {
    z-index: 10;
    position: relative;
    border-top: 2px solid #0a9dff;
    border-bottom: 2px solid #0a9dff;
  }

  &:focus,
  &:focus-within {
    border-top: 2px solid #0a9dff;
    border-bottom: 2px solid #0a9dff;
  }

  .talkBuilderBorder {
    border-top: 2px solid #0a9dff;
    border-bottom: 2px solid #0a9dff;
  }
}
