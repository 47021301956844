.TaskList {
  font-size: 15px;
  line-height: 20px;

  font-family: "Ak-Regular";

  padding: 15px 20px;
  background-color: #fff;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: 15px 25px 0;
}
.TaskList .title {
  font-weight: 700;
  color: #22232f;
}
.TaskList .content {
  font-weight: 400;
  align-items: center;
  color: #7d8fa1;
}
