.container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.leftCorners {
  border-radius: 20px 0 0 20px;
  /* @include themeify {
    background-color: themed(useNodeContentBg);
  } */
  background-color: var(--useNodeContentBg, #0a9dff40);
  width: 25px;
  margin-left: 5px;
  flex-shrink: 0;
}

.rightCornersBox {
  position: relative;
  width: 24px;
  flex-shrink: 0;
  display: flex;
}

.rightMessageMask {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;
}

.rightTopCorners {
  border-radius: 0 20px 0 0;
  /* @include themeify {
    background-color: themed(useNodeContentBg);
  } */
  background-color: var(--useNodeContentBg, #0a9dff40);
  width: 20px;
  height: 52%;
  margin-right: 4px;
  position: relative;
  z-index: 1;
}
.rgtTopCorners {
  border-radius: 0 20px 0 0;
  background-color: var(--useNodeContentBg, #0a9dff40);
  width: 20px;
  height: 100%;
}
.showRadiusBottomRgt {
  border-bottom-right-radius: 20px;
}
.chatPubBubbleCorner {
  width: 10px;
  height: 10px;
  margin-top: auto;
}
.rgtMsgMask {
  background: radial-gradient(
    circle at 100% 10%,
    transparent 50%,
    transparent 65%,
    var(--useNodeContentBg, #0a9dff40) 35%
  );
  border-bottom-right-radius: 5px;
}
.rightBottomCorners {
  border-radius: 0 0 20px 0;
  /* @include themeify {
    background-color: themed(useNodeContentBg);
  } */
  background-color: var(--useNodeContentBg, #0a9dff40);
  width: 20px;
  height: 19.5px;
  margin-right: 4px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.messageContainer {
  // border-radius: 20px 0 0 20px;
  /* @include themeify {
    background-color: themed(useNodeContentBg);
  } */
  background-color: var(--useNodeContentBg, #0a9dff40);
  padding: 10px 0 10px 0px;
  cursor: pointer;
}

.message {
  color: #12192b;
  /* @include themeify {
    color: themed(messageColor);
  } */
  color: var(--messageColor, #12192b);
  font-family: var(--fontFamily, "Ak-Regular");
  font-size: 15px;

  font-weight: 400;
  line-height: 20px;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
}

.cornerMark {
  path {
    /* @include themeify {
      fill: themed(useNodeContentBg);
      fill-opacity: 1;
    } */
    fill: var(--useNodeContentBg, #0a9dff40);
    fill-opacity: 1;
  }
}
