.request-box {
  padding: 10px;
  flex: 1;
  height: 50%;
  overflow: hidden;
}
.request-box :global(.ant-tabs-top) {
  height: calc(100% - 40px);
}
.request-box :global(.ant-tabs-content-holder) {
  overflow: auto;
}
.request-box :global(.ant-tabs-content-holder)::-webkit-scrollbar {
  display: none;
}
.request-box :global(textarea.ant-input) {
  max-height: 100%;
  min-height: 100%;
}
.request-box :global(textarea.ant-input)::-webkit-scrollbar {
  display: none;
}
