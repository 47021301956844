.youtubeSearchResultsBox {
  position: relative;
  width: 100%;
  min-height: 380px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 44px;
  border: 0.5px solid rgba(255, 255, 255, 0.35);
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.35);
  h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(18, 25, 43, 1);
  }
  .list {
    overflow: auto;
    max-height: 500px;
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .list::-webkit-scrollbar {
    display: none;
  }
  .item {
    display: flex;
    align-items: center;
    height: 60px;
    cursor: pointer;
  }
  .info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 105px);
    padding: 0px 24px 0px 16px;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgba(18, 25, 43, 1);
    margin-bottom: 5px;
  }
  .duration {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(18, 25, 43, 0.65);
  }
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    button {
      height: 100%;
      padding: 6px 20px 6px 20px;
      border-radius: 20px;
      outline: none;
      border: none;
      background-color: rgba(18, 25, 43, 0.05);

      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: rgba(18, 25, 43, 0.65);
    }
  }
  .youtubeVideoWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
  }
}

.youtubeSearchResultsBox:not(:first-child) {
  margin-top: 10px;
}
